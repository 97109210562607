import { AxiosError } from 'axios';
import { DatabaseId, Errors } from '../types/DataStructures';
import { apiClient } from './config/clients';
import { OFFERS_ENDPOINT } from './config/endpoints';
import { ApiQuery, applyLookup, BUSINESS_NAME, businessParam, LookupsMap, pathParam } from './config/helpers';
import { sanitizeOffer } from './config/sanitizers';
import { Offer } from '../types/Entities';
import { hydrateOffer } from './config/hydrators';

// GET
// Fetch many
export interface OffersGetParams {
  booking?: DatabaseId;
  service?: DatabaseId;
}

export const DEFAULT_OFFERS_LOOKUPS: LookupsMap<OffersGetParams> = {};

export const getOffers: ApiQuery<OffersGetParams, Offer[]> = (params, lookups = DEFAULT_OFFERS_LOOKUPS) =>
  apiClient
    .get<any[]>(OFFERS_ENDPOINT, { params: applyLookup(params, lookups) })
    .then((res) => res.data.map((v) => sanitizeOffer(v)))
    .then((dehydratedOffers) => Promise.all(dehydratedOffers.map(hydrateOffer)));

// Fetch one
export const getOffer = (offerId: DatabaseId) =>
  apiClient
    .get(pathParam(OFFERS_ENDPOINT, offerId))
    .then((res) => sanitizeOffer(res.data))
    .then(hydrateOffer);

// POST
export interface OffersPostPayload {
  booking: DatabaseId;
  service: DatabaseId;
  status: string;
}
export type OffersPostResponse = DatabaseId;
export type OffersPostError = Errors<OffersPostPayload>;
export const createOffer = (payload: OffersPostPayload) =>
  apiClient
    .post<OffersPostResponse>(businessParam(OFFERS_ENDPOINT), payload)
    .then((res) => res.data)
    .catch((err: AxiosError<OffersPostError>) => Promise.reject(err.response?.data));

// PUT
export type OffersPutPayload = Omit<OffersPostPayload, 'booking' | 'service'>;
export type OffersPutResponse = unknown;
export type OffersPutError = Errors<OffersPutPayload>;
export const updateOffer = (offerId: DatabaseId, data: OffersPutPayload) =>
  apiClient
    .put<OffersPutResponse>(pathParam(OFFERS_ENDPOINT, offerId), { ...data, _business: BUSINESS_NAME })
    .catch((err: AxiosError<OffersPutError>) => Promise.reject(err.response?.data));
