import { FormControl } from '@mui/material';
import { DateTimePicker, DateTimeValidationError } from '@mui/x-date-pickers';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import { Moment } from 'moment-timezone';
import { PREFERRED_DATETIME_FORMAT_MOMENT, PREFERRED_DATE_FORMAT_MOMENT } from 'src/app/helpers/constants';
import { formatMomentPST } from 'src/app/helpers/manipulation';

export interface FormDateTimePickerProps {
  label: string;
  onChange: ((value: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => void) | undefined;
  showTimeInput?: boolean;
  value: Date | null | undefined;
}

export default function FormDateTimePicker({ label, onChange, showTimeInput, value }: FormDateTimePickerProps) {
  return (
    <FormControl fullWidth sx={{ marginBottom: 2 }}>
      <DateTimePicker<Moment>
        label={label}
        onChange={onChange}
        value={value ? formatMomentPST(value) : null}
        format={showTimeInput ? PREFERRED_DATETIME_FORMAT_MOMENT : PREFERRED_DATE_FORMAT_MOMENT}
      />
    </FormControl>
  );
}
