import { FormControl } from '@mui/material';
import { DatePicker, DateTimeValidationError } from '@mui/x-date-pickers';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import moment, { Moment } from 'moment-timezone';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types';

export interface FormDatePickerProps extends Omit<DatePickerProps<Moment>, 'value' | 'minDate' | 'maxDate'> {
  label: string;
  dateFormat?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange?: ((value: Moment | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => void) | undefined;
  value: Date | null | undefined;
}

export default function FormDatePicker({
  label,
  dateFormat,
  minDate,
  maxDate,
  onChange,
  value,
  ...props
}: FormDatePickerProps) {
  return (
    <FormControl fullWidth>
      <DatePicker
        label={label}
        onChange={onChange}
        value={value ? moment(value) : null}
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
        format={dateFormat}
        {...props}
      />
    </FormControl>
  );
}
