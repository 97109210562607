import { Col, Row } from 'reactstrap';
import { IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import { ServiceAreaItem } from '../localTypes';
import { findInOptions } from '../../../../../app/helpers/manipulation';
import FormSelect from '../../../../../components/Form/FormSelect/FormSelect';
import { mapObjectForSelect } from '../../../../../app/helpers/mappers';
import { US_STATES } from '../../../../../app/helpers/enum';

export interface ServiceAreaListItemProps {
  removeServiceArea: () => void;
  initialState: ServiceAreaItem;
  stateUpdater: StateUpdater<ServiceAreaItem>;
  // TODO add errors
}

const translationArray = ['onboardings'];

export default function ServiceAreaListItem(props: ServiceAreaListItemProps) {
  const { removeServiceArea, initialState, stateUpdater } = props;

  const { t } = useTranslation(translationArray);

  const [state, setState] = useStateWithCallback(initialState.state, stateUpdater('state'));
  const [county, setCounty] = useStateWithCallback(initialState.county, stateUpdater('county'));
  const [city, setCity] = useStateWithCallback(initialState.city, stateUpdater('city'));
  const [zip, setZip] = useStateWithCallback(initialState.zip, stateUpdater('zip'));

  const stateOptions = mapObjectForSelect(US_STATES, (_, value) => value);

  // TODO make all selects
  return (
    <Row>
      <Col xl={2}>
        <FormSelect
          disableClearable
          options={stateOptions}
          value={findInOptions(stateOptions, state) ?? undefined}
          onChange={(_, stateOption) => stateOption && setState(stateOption.value)}
          renderInput={(inputProps) => <TextField {...inputProps} variant="standard" label={t`state` as string} />}
        />
      </Col>
      <Col>
        <TextField
          fullWidth
          variant="standard"
          label={t`county` as string}
          value={county}
          onChange={(e) => setCounty(e.target.value)}
        />
      </Col>
      <Col>
        <TextField
          fullWidth
          variant="standard"
          label={t`city` as string}
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
      </Col>
      <Col>
        <TextField
          fullWidth
          variant="standard"
          label={t`zip` as string}
          value={zip}
          onChange={(e) => setZip(e.target.value)}
        />
      </Col>
      <Col xs="auto" className="flex-grow-0 align-self-center">
        <IconButton onClick={removeServiceArea}>
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </Col>
    </Row>
  );
}
