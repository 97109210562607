import { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { Col, Container, Row } from 'reactstrap';

export interface FormSectionProps {
  label?: string;
}

export default function FormSection({ children, label }: PropsWithChildren<FormSectionProps>) {
  return (
    <Container className="base-field">
      <Row>
        <Col>
          {label && (
            <Typography variant="formLabel" sx={{ marginBottom: 2 }}>
              {label}
            </Typography>
          )}
        </Col>
      </Row>
      <Row>
        <Col>{children}</Col>
      </Row>
    </Container>
  );
}
