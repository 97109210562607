import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { TextField, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { StateUpdater } from 'src/app/types/Components';
import { Location } from 'src/app/types/Entities';
import { useQuery } from '@tanstack/react-query';
import { CompaniesGetParams, MUTATION_KEYS, getCompanies } from 'src/app/api';
import FormWrapper from 'src/components/Form/FormWrapper';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { mapObjectForSelect } from '../../../../../app/helpers/mappers';
import { COMPANY_TYPES } from '../../../../../app/helpers/enum';
import { arrayRemoveAt, arrayReplaceAt, findInOptions } from '../../../../../app/helpers/manipulation';
import { FormInput } from '../../../../../components/Form/FormInput';
import { useForm, useStateDict } from '../../../../../app/hooks';
import FormSelect from '../../../../../components/Form/FormSelect/FormSelect';
import LocationSection from '../../../../BookingsPage/Onboarding/Fields/LocationSection/LocationSection';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import { CompanyInfoState } from '../localTypes';
import FlatContactList from '../../../../BookingsPage/Onboarding/Fields/FlatContactList/FlatContactList';

export interface CompanyInfoFieldProps {
  formPrefix: string;
  showCompanyInfo: boolean;
  setShowCompanyInfo: (display: boolean) => void;
  setIsInsuranceType: (display: boolean) => void;
  setIsEmployerType: (display: boolean) => void;
  initialState: CompanyInfoState;
  stateUpdater: StateUpdater<CompanyInfoState>;
  errors?: any; // TODO type properly
}

const translationArray = ['onboardings', 'collections'];

function CompanyInfoField(props: CompanyInfoFieldProps) {
  const {
    formPrefix,
    showCompanyInfo,
    setShowCompanyInfo,
    setIsInsuranceType,
    setIsEmployerType,
    initialState,
    stateUpdater,
    errors,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [type, setType] = useStateWithCallback(initialState.type, stateUpdater('type'));
  const [name, setName] = useStateWithCallback(initialState.name, stateUpdater('name'));
  const [parentCompany, setParentCompany] = useStateWithCallback(
    initialState.parentCompany,
    stateUpdater('parentCompany'),
  );

  const [location, setLocation] = useStateWithCallback(initialState.location, stateUpdater('location'));
  const updateLocation = (fields: Partial<Location>) => setLocation({ ...location, ...fields });

  const [flatContacts, setFlatContacts] = useStateWithCallback(initialState.flatContacts, stateUpdater('flatContacts'));

  const addNewFlatContact = () =>
    setFlatContacts([...flatContacts, { uuid: uuidv4(), type: 'phone', data: {}, context: 'personal' }]);

  const replaceFlatContactAt = (index: number, contact: any) =>
    setFlatContacts(arrayReplaceAt(flatContacts, index, contact));

  const removeFlatContactAt = (index: number) => setFlatContacts(arrayRemoveAt(flatContacts, index));

  useEffect(() => {
    // Ensure there is always at least one (flat) contact available to fill
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  // Options
  const typeOptions = mapObjectForSelect(COMPANY_TYPES, (typeKey) =>
    t(`collections:companyTypes.${typeKey.toLowerCase()}`),
  );

  const [companyFilters] = useStateDict<CompaniesGetParams>({});
  const { data: companies, isLoading: isCompaniesLoading } = useQuery([MUTATION_KEYS.COMPANIES, companyFilters], () =>
    getCompanies(companyFilters),
  );

  const parentCompanyOptions = companies ?? [];

  useEffect(() => {
    setIsInsuranceType(type === 'insurance');
    setIsEmployerType(type === 'employer');
  }, [setIsInsuranceType, setIsEmployerType, type]);

  // Display
  const label = (
    <Typography variant="formLabel" sx={{ marginBottom: 2 }}>
      {t`companyInfo`}
    </Typography>
  );

  const display = (
    <Row className="gx-2">
      <Col md={12} xl={6} xxl={2}>
        <FormSelect
          options={typeOptions}
          value={findInOptions(typeOptions, type) ?? null}
          onChange={(_, option) => setType(option?.value!)}
          renderInput={(params) => <TextField placeholder={`${t`type`} *`} {...params} />}
        />
      </Col>
      <Col md={12} xl={6} xxl={4}>
        <FormInput
          label={`${t`name`} *`}
          value={name}
          onChange={(e) => setName(e.target.value)}
          errors={errors?.name}
        />
      </Col>
      <Col md={12} xl={6} xxl={6}>
        <FormSelect
          id={p`parentCompany`}
          options={parentCompanyOptions}
          loading={isCompaniesLoading}
          value={parentCompany}
          onChange={(_event, parentCompanyOption) => {
            setParentCompany(parentCompanyOption ?? undefined);
            return 0;
          }}
          renderInput={(params) => <TextField placeholder={t`parentCompany`} {...params} />}
          getOptionLabel={(c) => c.name}
        />
      </Col>
    </Row>
  );

  return (
    <FormExpandableField // Personal info
      formPrefix={p`personal-info`}
      label={label}
      display={display}
      isOpen={showCompanyInfo}
      toggleOpen={setShowCompanyInfo}
      onCancel={() => setShowCompanyInfo(false)}
    >
      <Row>
        <Col>
          <FormWrapper label={t`address`} className="pb-2">
            <LocationSection errors={errors.location} location={location} update={updateLocation} />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label={t`contactInfo`}>
            <FlatContactList
              items={flatContacts}
              add={addNewFlatContact}
              removeAt={removeFlatContactAt}
              replaceAt={replaceFlatContactAt}
            />
          </FormWrapper>
        </Col>
      </Row>
    </FormExpandableField>
  );
}

export default CompanyInfoField;
