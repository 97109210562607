// TODO: Implement email sharing
// import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import IosShareIcon from '@mui/icons-material/IosShare';
import LinkIcon from '@mui/icons-material/Link';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import './BookingShareButton.scss';

const translationArray = ['booking'];

export default function BookingShareButton() {
  const { t } = useTranslation(translationArray);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const handleShareClick = () => {
    setShowPopup(!showPopup);
  };

  // TODO: Implement email sharing
  // const handleEmailClick = () => {

  //   toast.info('Feature unimplemented');
  // };

  const handleCopyLinkClick = () => {
    const currentURL = window.location.href;
    toast.promise(navigator.clipboard.writeText(currentURL), {
      pending: t`progress.copyLink.started` as string,
      error: t`progress.copyLink.error` as string,
      success: t`progress.copyLink.success` as string,
    });
  };

  return (
    <>
      <Button color="transparent" onClick={handleShareClick}>
        <IosShareIcon fontSize="large" style={{ fill: 'white' }} />
      </Button>
      {showPopup && (
        <div className="small-rectangle-popup">
          <div className="popup-content">
            {/* TODO: Implement email sharing */}
            {/* <div className="button-wrapper">
              <Button color="transparent" onClick={handleEmailClick}>
                <EmailOutlinedIcon className="icon" />
                Email
              </Button>
            </div> */}
            <div className="button-wrapper">
              <Button color="transparent" onClick={handleCopyLinkClick}>
                <LinkIcon className="icon" />
                Copy Link
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
