import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { CSSProperties, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup } from 'reactstrap';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFilterEvents } from 'src/app/hooks/useFilterEvents';
import { FormListRowItemNoAdd, FormListRowItemProps } from '../../../../../components/Form/FormListRowNoAdd';
import { Booking } from '../../../../../app/types/Entities';
import { PREFERRED_DATETIME_FORMAT_MOMENT } from '../../../../../app/helpers/constants';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import { buildSearchParam, userToString } from '../../../../../app/helpers/mappers';
import FormSpan from '../../../../../components/Form/FormSpan';
import { useForm } from '../../../../../app/hooks';
import './ChildBookingView.scss';
import ChildBookingSummaryPopover from './ChildBookingSummaryPopover';

export interface ChildBookingViewProps extends FormListRowItemProps {
  formPrefix: string;
  booking: Booking;
}

const translationArray = ['booking', 'languages'];

export default function ChildBookingView({ formPrefix, booking, ...props }: ChildBookingViewProps) {
  const { t } = useTranslation(translationArray);
  const [eventFilters] = useFilterEvents();
  const { p } = useForm(formPrefix);
  const navigate = useNavigate();

  const referenceEvent = booking.events?.[0];

  const eventDate = moment(referenceEvent?.start_at).format(PREFERRED_DATETIME_FORMAT_MOMENT);

  const referenceService = booking.services?.[0];

  const goToBooking = () =>
    navigate({
      pathname: `/bookings/calendar?start_at__gte=${eventFilters.start_at}&end_at__lte=${eventFilters.end_at}`,
      search: buildSearchParam({ eventId: referenceEvent?.id }),
    });

  const [showSummary, setShowSummary] = useState<boolean>(false);

  return (
    <>
      <FormListRowItemNoAdd
        className="child-booking-view-item"
        removeButtonState={BUTTON_STATE.HIDDEN}
        style={{ '--booking-status-color': booking.cCurrentStatus.color } as CSSProperties}
        {...props}
      >
        <ButtonGroup className="w-100 mb-3">
          <Button className="w-100" color="no-style" onClick={goToBooking}>
            <FormSpan className="item-container mb-0">
              <b>
                {eventDate} — #{booking.public_id} — {t`interpreter`}:{' '}
                {referenceService ? userToString(referenceService.provider) : t`followUpNoInterpreter`}
              </b>
            </FormSpan>
          </Button>
          <Button
            className="summary-trigger-button"
            color="info"
            onClick={() => setShowSummary(true)}
            onBlur={() => setShowSummary(false)}
          >
            <InfoOutlinedIcon id={p`booking-summary-trigger`} />
          </Button>
        </ButtonGroup>
      </FormListRowItemNoAdd>

      <ChildBookingSummaryPopover
        target={p`booking-summary-trigger`}
        trigger="legacy"
        placement="bottom"
        fade={false}
        isOpen={showSummary}
        booking={booking}
      />
    </>
  );
}
