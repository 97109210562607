import { Col, Container, Row } from 'reactstrap';
import { ContainerProps } from 'reactstrap/types/lib/Container';
import classNames from 'classnames';
import { ColProps } from 'reactstrap/types/lib/Col';

export interface MiddleCenterAlignedContainerProps extends ContainerProps {
  colProps?: ColProps;
  dark?: boolean;
  maxWidth?: boolean;
}

export default function MiddleCenterAlignedContainer({
  className = '',
  children,
  colProps = { sm: 5 },
  maxWidth,
  dark,
  ...props
}: MiddleCenterAlignedContainerProps) {
  return (
    <Container
      {...props}
      className={classNames(className, 'w-100 h-100', {
        dark,
        'mw-100': maxWidth,
      })}
    >
      <Row className="w-100 h-100 justify-content-center align-items-center">
        <Col {...colProps}>{children}</Col>
      </Row>
    </Container>
  );
}
