import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Alert, Col, Form, Row } from 'reactstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import FormCheck from 'src/components/Form/FormCheck/FormCheck';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import FormDatePicker from 'src/components/DatePicker/FormDatePicker';
import { FormProps, FormSubmitEvent, FormSubmitProps, RecursiveInputDefault } from '../../../../app/types/Components';
import { Contact, Location, User } from '../../../../app/types/Entities';
import PersonFormGroup from '../../../../components/Form/FormGroups/PersonFormGroup';
import { useForm, useStateArray, useStateDict } from '../../../../app/hooks';
import { FormInput } from '../../../../components/Form/FormInput';
import ContactFormGroup from '../../../../components/Form/FormGroups/ContactFormGroup';
import {
  createUser,
  getUser,
  MUTATION_KEYS,
  registerUser,
  updateUser,
  UsersPostError,
  UsersPostPayload,
  UsersPostResponse,
  UsersPutError,
  UsersPutPayload,
  UsersPutResponse,
} from '../../../../app/api';

import { ALPHANUMERIC_REGEX, PREFERRED_DATE_FORMAT_MOMENT, USERNAME_REGEX } from '../../../../app/helpers/constants';
import { DatabaseId } from '../../../../app/types/DataStructures';
import { arrayReplaceAt, arrayUpdateAt } from '../../../../app/helpers/manipulation';
import arrayToUnorderedList from '../../../../app/helpers/componentMappers';
import FormList from '../../../../components/Form/FormList';
import LocationFormGroup from '../../../../components/Form/FormGroups/LocationFormGroup';

export type UserOnboardingFormDefaults = Partial<RecursiveInputDefault<UsersPostPayload>>;

export interface UserOnboardingFormProps
  extends FormProps,
    FormSubmitProps<UsersPostPayload, UsersPostResponse, UsersPostError> {
  preloadUserId?: DatabaseId;
  allowNotAuthorized?: boolean;
  defaults?: UserOnboardingFormDefaults;
}

export default function UserOnboardingForm({
  id,
  children,
  formPrefix = 'user-onboarding',
  submitPreventDefault = true,
  preSubmit,
  onSubmitSuccess,
  onSubmitFailure,
  postSubmit,
  defaults,
  preloadUserId,
  allowNotAuthorized,
}: UserOnboardingFormProps) {
  const { t } = useTranslation('forms');
  const { p } = useForm(formPrefix);

  const userIdProvided = preloadUserId !== undefined;

  const [errors, setErrors, , clearError] = useStateDict<UsersPostError & UsersPutError>({});
  const clearContactFieldErrorAt = (i: number, field: keyof Contact) =>
    setErrors((prev) => ({
      ...prev,
      contacts: prev.contacts && arrayUpdateAt(prev.contacts, i, { [field]: undefined }),
    }));
  const clearContactErrorsAt = (i: number) =>
    setErrors((prev) => ({
      ...prev,
      contacts: prev.contacts && arrayReplaceAt(prev.contacts, i, {}),
    }));

  const [warningMsg, setWarningMsg] = useState<string | null>(null);

  const { mutateAsync: mutateAsyncCreate } = useMutation<UsersPostResponse, UsersPostError, UsersPostPayload>(
    allowNotAuthorized ? registerUser : createUser,
  );

  const { mutateAsync: mutateAsyncUpdate } = useMutation<
    UsersPutResponse,
    UsersPutError,
    Parameters<typeof updateUser>
  >((args) => updateUser(...args));

  const [username, setUsername] = useState<string>(defaults?.username?.value ?? '');
  const [password, setPassword] = useState<string>('');
  const [confirmation, setConfirmation] = useState<string>('');
  const [firstName, setFirstName] = useState<string>(defaults?.first_name?.value ?? '');
  const [lastName, setLastName] = useState<string>(defaults?.last_name?.value ?? '');
  const [nationalId, setNationalId] = useState<string>(defaults?.national_id?.value ?? '');
  const [ssn, setSSN] = useState<string>(defaults?.ssn?.value ?? '');
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const buildContact = (emailPrefill?: string) => ({
    email: emailPrefill ?? '',
    phone: '',
    fax: '',
  });
  const {
    values: contacts,
    set: setContacts,
    push: pushContact,
    replaceAt: replaceContactAt,
    removeAt: removeContactAt,
  } = useStateArray<Contact>([buildContact(defaults?.email?.value)]);
  const updateContactAt = (i: number, fields: Partial<Contact>) => replaceContactAt(i, { ...contacts[i], ...fields });

  const buildLocation = (): Location => ({
    address: '',
    city: '',
    state: '',
    country: '',
  });
  const [location, setLocation] = useState<Location>(buildLocation());

  const [isDoctor, setDoctor] = useState<boolean>(false);
  const [isPatient, setPatient] = useState<boolean>(false);
  const [isPayer, setPayer] = useState<boolean>(false);

  function prefillFromUser(u: User) {
    setUsername(u.username);
    setFirstName(u.first_name);
    setLastName(u.last_name);
    setNationalId(u.national_id ?? '');
    setSSN(u.ssn ?? '');
    setContacts(!u.contacts || u.contacts?.length <= 0 ? [buildContact(u.email)] : u.contacts);
    if (u.email && (!u.contacts || u.contacts?.length <= 0)) {
      setWarningMsg(
        "WARNING the user's contact information is in an illegal state, please submit the onboarding to fix it",
      );
    }
  }

  useQuery(
    [MUTATION_KEYS.USERS, preloadUserId],
    () => (userIdProvided ? getUser(preloadUserId) : Promise.reject(new Error('User id not set'))),
    {
      enabled: userIdProvided,
      onSuccess: prefillFromUser,
    },
  );

  const onSubmit = async (e: FormSubmitEvent) => {
    if (submitPreventDefault) {
      e.preventDefault();
    }

    const payload: UsersPostPayload = {
      username,
      email: contacts[0].email!,
      password,
      confirmation,
      first_name: firstName,
      last_name: lastName,
      national_id: nationalId,
      ssn,
      date_of_birth: moment(dateOfBirth).utc().format(PREFERRED_DATE_FORMAT_MOMENT),
      contacts,
      location,
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    if (!userIdProvided && !password && !confirmation) {
      const userConfirmation = await Swal.fire({
        title:
          "No password is being set, the user won't be usable unless you assign one (it can be changed later), proceed?",
        showDenyButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
      }).then(({ isConfirmed }) => isConfirmed);

      if (!userConfirmation) {
        return;
      }
    }

    const promise = !userIdProvided
      ? toast
          .promise(mutateAsyncCreate(payload), {
            pending: t`onboarding.user.progress.started` as string,
            error: t`onboarding.user.progress.error` as string,
            success: t`onboarding.user.progress.success` as string,
          })
          .then((newUserId) => onSubmitSuccess?.(newUserId, payload))
      : toast
          .promise(
            mutateAsyncUpdate([
              preloadUserId,
              {
                ...payload,
                username: undefined,
              } as UsersPutPayload,
            ]),
            {
              pending: t`updating.user.progress.started` as string,
              error: t`updating.user.progress.error` as string,
              success: t`updating.user.progress.success` as string,
            },
          )
          .then(() => onSubmitSuccess?.(preloadUserId, payload));

    promise
      .catch((err) => {
        if (err) setErrors(err);
        onSubmitFailure?.(err, payload);
      })
      .finally(postSubmit);
  };

  return (
    <Form id={id} onSubmit={onSubmit}>
      {warningMsg && <Alert color="warning">{warningMsg}</Alert>}
      {errors.non_field_errors && <Alert color="danger">{arrayToUnorderedList(errors.non_field_errors)}</Alert>}
      {userIdProvided && <FormInput disabled readOnly label={t`userId`} value={preloadUserId} />}
      <FormInput
        required
        type="text"
        label={t`username`}
        maxLength={150}
        value={username}
        onChange={(e) => {
          if (!USERNAME_REGEX.test(e.target.value)) {
            return false;
          }

          clearError('username');
          setUsername(e.target.value);

          return true;
        }}
        readOnly={defaults?.username?.readOnly || userIdProvided}
        errors={errors.username}
      />
      <FormInput
        required
        type="email"
        label={t`email`}
        value={contacts[0].email}
        onChange={(e) => {
          clearError('email');
          updateContactAt(0, { email: e.target.value });
        }}
        readOnly={defaults?.email?.readOnly}
        errors={errors.email}
      />
      <FormInput
        id={p`password`}
        type="password"
        label={t`password`}
        invalid={!!errors.password}
        value={password}
        onChange={(e) => {
          clearError('password');
          setPassword(e.target.value);
        }}
        errors={errors.password}
        help={userIdProvided ? 'Input new password, leave empty to keep the current one' : undefined}
      />
      <FormInput
        id={p`passwordConfirmation`}
        type="password"
        label={t`passwordConfirmation`}
        invalid={!!errors.confirmation}
        value={confirmation}
        onChange={(e) => {
          clearError('confirmation');
          setConfirmation(e.target.value);
        }}
        errors={errors.confirmation}
      />

      <legend>{t`personalInformation`}</legend>
      <PersonFormGroup
        formPrefix={p`person`}
        firstNameProps={{
          required: true,
          value: firstName,
          onChange: (e) => {
            clearError('first_name');
            setFirstName(e.target.value);
          },
          readOnly: defaults?.first_name?.readOnly,
          errors: errors.first_name,
        }}
        lastNameProps={{
          required: true,
          value: lastName,
          onChange: (e) => {
            clearError('last_name');
            setLastName(e.target.value);
          },
          readOnly: defaults?.last_name?.readOnly,
          errors: errors.last_name,
        }}
      />
      <Row>
        <Col>
          <FormInput
            required
            type="text"
            label={t`nationalId`}
            value={nationalId}
            onChange={(e) => {
              if (!ALPHANUMERIC_REGEX.test(e.target.value)) {
                return false;
              }

              clearError('national_id');
              setNationalId(e.target.value);

              return true;
            }}
            readOnly={defaults?.national_id?.readOnly}
            errors={errors.national_id}
          />
        </Col>
        <Col>
          <FormInput
            type="text"
            label={t`ssn`}
            value={ssn}
            onChange={(e) => {
              if (!ALPHANUMERIC_REGEX.test(e.target.value)) {
                return false;
              }

              clearError('ssn');
              setSSN(e.target.value);

              return true;
            }}
            readOnly={defaults?.ssn?.readOnly}
            errors={errors.ssn}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormDatePicker
            label={t`dob`}
            dateFormat={PREFERRED_DATE_FORMAT_MOMENT}
            maxDate={new Date()}
            value={dateOfBirth}
            onChange={(date) => date && setDateOfBirth(date.toDate())}
          />
        </Col>
      </Row>

      <legend>{t`contact`}</legend>
      <FormList<Contact>
        canAdd
        onAdd={() => pushContact(buildContact())}
        canRemove={(i) => i !== 0}
        onRemove={(i) => {
          removeContactAt(i);
          clearContactErrorsAt(i);
        }}
        items={contacts}
        mapper={(c, i) => (
          <>
            {errors.contacts?.[i]?.non_field_errors && (
              <Alert color="danger">{arrayToUnorderedList(errors.contacts[i]!.non_field_errors)}</Alert>
            )}
            <ContactFormGroup
              emailProps={{
                hidden: i === 0,
                value: c.email,
                onChange: (e) => {
                  clearContactFieldErrorAt(i, 'email');
                  updateContactAt(i, { email: e.target.value });
                },
                errors: errors.contacts?.[i]?.email,
              }}
              phoneProps={{
                value: c.phone,
                onChange: (e) => {
                  clearContactFieldErrorAt(i, 'phone');
                  updateContactAt(i, { phone: e.target.value });
                },
                errors: errors.contacts?.[i]?.phone,
              }}
              faxProps={{
                value: c.fax,
                onChange: (e) => {
                  clearContactFieldErrorAt(i, 'fax');
                  updateContactAt(i, { fax: e.target.value });
                },
                errors: errors.contacts?.[i]?.fax,
              }}
            />
          </>
        )}
      />

      <legend>{t`location`}</legend>
      <LocationFormGroup
        formPrefix={formPrefix}
        addressProps={{
          required: true,
          value: location.address,
          onChange: (e) => {
            setLocation({ ...location, address: e.target.value });
          },
        }}
        cityProps={{
          required: true,
          value: location.city,
          onChange: (e) => {
            setLocation({ ...location, city: e.target.value });
          },
        }}
        stateProps={{
          required: true,
          value: location.state,
          onChange: (e) => {
            setLocation({ ...location, state: e.target.value });
          },
        }}
        countryProps={{
          required: true,
          value: location.country,
          onChange: (e) => {
            setLocation({ ...location, country: e.target.value });
          },
        }}
        zipProps={{
          required: true,
          value: location.zip,
          onChange: (e) => {
            setLocation({ ...location, zip: e.target.value });
          },
        }}
      />

      <Row className="mb-3">
        <Col>
          <FormCheck checked={isDoctor} label={t`doctor`} onChange={() => setDoctor(!isDoctor)} />
        </Col>
        <Col>
          <FormCheck checked={isPatient} label={t`patient`} onChange={() => setPatient(!isPatient)} />
        </Col>
        <Col>
          <FormCheck checked={isPayer} label={t`payer`} onChange={() => setPayer(!isPayer)} />
        </Col>
      </Row>

      {children}
    </Form>
  );
}
