import { Col, Row } from 'reactstrap';
import { IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import { useQuery } from '@tanstack/react-query';
import { MUTATION_KEYS, getAgenciesWithRoles } from 'src/app/api';
import useStateWithCallback from '../../../../../../app/hooks/useStateWithCallback';
import { PreferredAgencyItem } from '../../localTypes';
import FormSelect from '../../../../../../components/Form/FormSelect/FormSelect';
import { mapObjectForSelect } from '../../../../../../app/helpers/mappers';
import { INSURANCE_AGENCY_RELATIONSHIP } from '../../../../../../app/helpers/enum';
import './ListItem.scss';

export interface PreferredAgencyListItemProps {
  removePreferredAgency: () => void;
  initialState: PreferredAgencyItem;
  stateUpdater: StateUpdater<PreferredAgencyItem>;
  // TODO add errors
}

const translationArray = ['onboardings'];

export default function PreferredAgencyListItem(props: PreferredAgencyListItemProps) {
  const { removePreferredAgency, initialState, stateUpdater } = props;

  const { t } = useTranslation(translationArray);

  const [relationship, setRelationship] = useStateWithCallback(initialState.relationship, stateUpdater('relationship'));
  const relationshipOptions = mapObjectForSelect(INSURANCE_AGENCY_RELATIONSHIP, (k) =>
    t(`collections:insuranceAgencyRelationships.${k}`),
  );

  const [agency, setAgency] = useStateWithCallback(initialState.company_to, stateUpdater('company_to'));
  const { data: agencies, isLoading: isAgencyLoading } = useQuery([MUTATION_KEYS.COMPANIES], () =>
    getAgenciesWithRoles({}),
  );

  return (
    <Row>
      <Col md={12} xl={6} xxl={4} className="preferred-agency-field">
        <FormSelect
          id={t`agency`}
          options={agencies}
          loading={isAgencyLoading}
          value={agencies?.find((a) => a.id === agency)}
          onChange={(_, agencyOption) => setAgency(agencyOption?.id)}
          renderInput={(params) => <TextField label={t`agency`} placeholder={t`agency`} {...params} />}
          getOptionLabel={(c) => c.name}
        />
      </Col>
      <Col md={12} xl={6} xxl={4} className="preferred-agency-field">
        <FormSelect
          id={t`relationship`}
          options={relationshipOptions}
          value={relationshipOptions.find((o) => o.value === relationship)}
          onChange={(_event, relationshipOption) => setRelationship(relationshipOption?.value)}
          renderInput={(params) => <TextField label={t`relationship`} placeholder={t`relationship`} {...params} />}
        />
      </Col>
      <Col xs="auto" className="flex-grow-0 align-self-center">
        <IconButton onClick={removePreferredAgency}>
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </Col>
    </Row>
  );
}
