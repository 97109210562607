import { MUTATION_KEYS } from '../config/helpers';
import { apiClient, queryClient } from '../config/clients';
import { LANGUAGES_ENDPOINT } from '../config/endpoints';
import { sanitizeLanguage } from '../config/sanitizers';

// LANGUAGES
export const loadLanguages = () =>
  queryClient.fetchQuery(
    [MUTATION_KEYS.LANGUAGES],
    () => apiClient.get<any[]>(LANGUAGES_ENDPOINT).then((res) => res.data.map((v) => sanitizeLanguage(v))),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

export const loadLanguage = (alpha3: string) =>
  loadLanguages().then((languages) => languages.find((language) => language.alpha3 === alpha3));
