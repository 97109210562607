import { AxiosError } from 'axios';
import { DEFAULT_USERS_LOOKUPS, UsersGetParams } from './users';
import { Operator } from '../types/Entities';
import { sanitizeOperator } from './config/sanitizers';
import { apiClient } from './config/clients';
import { OPERATORS_ENDPOINT } from './config/endpoints';
import { ApiQuery, applyLookup, BUSINESS_NAME, LookupsMap } from './config/helpers';
import { DatabaseId, Errors } from '../types/DataStructures';

// GET
export type OperatorsGetParams = UsersGetParams;
export const DEFAULT_OPERATORS_LOOKUPS: LookupsMap<OperatorsGetParams> = DEFAULT_USERS_LOOKUPS;
export const getOperators: ApiQuery<OperatorsGetParams, Operator[]> = (params, lookups = DEFAULT_OPERATORS_LOOKUPS) =>
  apiClient
    .get(OPERATORS_ENDPOINT, { params: applyLookup(params, lookups) })
    .then((res) => res.data.map(sanitizeOperator));

// POST
export interface OperatorsPostPayload {
  user: DatabaseId;
  companies: DatabaseId[];
  hiring_date: string;
}

export type OperatorsPostResponse = DatabaseId;
export type OperatorsPostError = Errors<OperatorsPostPayload>;
export const createOperator = (payload: OperatorsPostPayload) =>
  apiClient
    .post<{ operator_id: DatabaseId }>(OPERATORS_ENDPOINT, { ...payload, _business: BUSINESS_NAME })
    .then<OperatorsPostResponse>((res) => res.data.operator_id)
    .catch((err: AxiosError<OperatorsPostError>) => Promise.reject(err.response?.data));
