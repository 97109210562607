import * as booking from './booking.json';
import * as calendar from './calendar.json';
import * as collections from './collections.json';
import * as common from './common.json';
import * as forms from './forms.json';
import * as languages from './languages.json';
import * as menus from './menus.json';
import * as onboardings from './onboardings.json';
import * as roles from './roles.json';
import * as searchbar from './searchbar.json';
import * as tables from './tables.json';
import * as viewers from './viewers.json';

const langResources = {
  booking,
  calendar,
  collections,
  common,
  forms,
  languages,
  menus,
  onboardings,
  roles,
  searchbar,
  tables,
  viewers,
};

export default langResources;
