import { ChangeEvent } from 'react';
import { Stringable } from '../types/DataStructures';

export default function useForm(prefix: string, sep: string = '_') {
  const addPrefix = (strings: TemplateStringsArray | string, ...expr: Stringable[]) => {
    if (typeof strings === 'string') {
      return prefix + sep + strings; // Process as a regular string
    }

    // Process as a template string
    let str = prefix + sep;
    strings.forEach((string, i) => {
      str += string + (expr[i] || '');
    });
    return str;
  };

  const eventValueExtractor =
    (func: (value: string) => void) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      func(event.target.value);

  return {
    p: addPrefix,
    eventValueExtractor,
  };
}
