import { Col, Form, Row } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import currency from 'currency.js';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Alert, AlertTitle, Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FormProps, FormSubmitEvent, FormSubmitProps, StateUpdater } from '../../../../app/types/Components';
import { useForm, useStateDict } from '../../../../app/hooks';
import ServiceTermsField from './ServiceTermsField';
import {
  CertificateItem,
  CertificationState,
  InterpreterNotesState,
  InterpreterOnboardingState,
  IRSW9FormState,
  PaymentInfoState,
  PersonalInfoState,
  ServiceAreaItem,
  ServiceAreaState,
  ServiceRateItem,
  ServiceRateState,
  ServiceTermsState,
} from './localTypes';
import CertificationField from './CertificationField/CertificationField';
import ServiceAreaField from './ServiceAreaField/ServiceAreaField';
import InterpreterNotesField from './NotesField';
import ServiceRateField from './ServiceRateField/ServiceRateField';
import IRSW9FormField from './IRSW9FormField/IRSW9FormField';
import PaymentInfoField from './PaymentInfoField/PaymentInfoField';
import {
  createUserComplex,
  ProvidersPostPayloadComplex,
  ProvidersPutPayloadComplex,
  ServicesPostPayloadComplex,
  ServicesPutPayloadComplex,
  ServiceAreasPostPayloadComplex,
  ServiceAreasPutPayloadComplex,
  updateUserComplex,
  UsersPostErrorComplex,
  UsersPostPayloadComplex,
  UsersPostResponseComplex,
  UsersPutErrorComplex,
  UsersPutPayloadComplex,
  UsersPutResponseComplex,
} from '../../../../app/api';
import {
  buildUsername,
  flattenContacts,
  getDataFromFlatContact,
  unflattenContacts,
} from '../../../../app/helpers/mappers';
import { SERVICE_BILL_RATE, SERVICE_BILL_RATE_TYPE } from '../../../../app/helpers/constants';
import {
  EmailFlatContact,
  Interpretation,
  Interpreter,
  PhoneFlatContact,
  ProviderCertification,
} from '../../../../app/types/Entities';
import { useCacheLanguages } from '../../../../app/api/cache/hooks';
import { DatabaseId, Errors } from '../../../../app/types/DataStructures';
import useStateWithCallback from '../../../../app/hooks/useStateWithCallback';
import PersonalInfoField from './PersonalInfoField';
import { LANGUAGE_ALPHA3 } from '../../../../app/helpers/enum';

export interface InterpreterOnboardingProps extends FormProps, FormSubmitProps<unknown, unknown, unknown> {
  preloadInterpreter?: Interpreter;
}

const translationArray = ['onboardings'];

export default function InterpreterOnboardingForm({
  formPrefix = 'interpreter-onboarding',
  preSubmit,
  onSubmitSuccess,
  onSubmitFailure,
  postSubmit,
  id,
  preloadInterpreter,
}: InterpreterOnboardingProps) {
  const { t } = useTranslation(translationArray);
  const { p } = useForm(formPrefix);

  /* TODO you are missing (remember to implement their validations too):
   * certification
   * payment info
   * irs w9 form
   * others list fields can not be removed from database, they lack the id and deleted flag
   * */

  // Data
  const [errors, setErrors] = useStateDict<UsersPostErrorComplex>({});
  const englishLanguage = useCacheLanguages()?.find(({ alpha3 }) => alpha3 === LANGUAGE_ALPHA3.ENGLISH);

  // State
  const defaultState: InterpreterOnboardingState & { id?: DatabaseId } = {
    id: undefined,
    // Personal info
    firstName: '',
    lastName: '',
    title: undefined,
    suffix: undefined,
    location: undefined,
    flatContacts: [],
    // Certification
    certificates: [],
    // Service area
    serviceAreas: [],
    // Service terms
    minWklyAssignments: 1,
    parkingExtra: false,
    parkingTicket: false,
    bonusExtra: false,
    mileageExtra: false,
    mileageRate: '0.00',
    // Service rates
    serviceRates: [],
    // Payment info
    preferredPaymentMethod: undefined,
    paymentMethods: [],
    // IRS W9 form
    irsW9Form: undefined,
    // Notes
    notes: [],
  };

  const formState = useRef<InterpreterOnboardingState>(defaultState);
  const formStateUpdater: StateUpdater<InterpreterOnboardingState> =
    <K extends keyof InterpreterOnboardingState>(key: K) =>
    (value: InterpreterOnboardingState[K]) => {
      formState.current[key] = value;
    };

  const [initialState, setInitialState] = useStateWithCallback(defaultState, (state) => {
    formState.current = state;
  });

  const resetState = () => {
    if (preloadInterpreter === undefined) {
      setInitialState(defaultState);
      return;
    }

    const preloadedCertifications: CertificateItem[] =
      preloadInterpreter.certifications?.map((certification) => ({
        uuid: uuidv4(),
        certificateId: certification.certificate_id,
        certificateNumber: certification.certificate_number,
        expirationDate: certification.expiration_date ?? undefined,
      })) ?? [];

    const services = preloadInterpreter.services as Interpretation[];
    const preloadedServiceRates: ServiceRateItem[] = services.map((service) => ({
      sourceId: service.id,
      uuid: uuidv4(),
      language: service.target_language,
      serviceRoot: service.root,
      hourRate: service.bill_amount,
      minHours: Number(currency(service.bill_min_payment).divide(service.bill_amount).toString()),
      minMinutes: service.bill_rate_minutes_threshold ?? 0,
      noShowingFee: service.bill_no_show_fee,
    }));

    const preloadedServiceAreas: ServiceAreaItem[] =
      preloadInterpreter.service_areas?.map((service_area) => ({
        sourceId: service_area.id,
        uuid: uuidv4(),
        country: service_area.country,
        state: service_area.state,
        county: service_area.county,
        city: service_area.city,
        zip: service_area.zip,
      })) ?? [];

    const state: InterpreterOnboardingState & { id?: DatabaseId } = {
      ...defaultState,
      id: preloadInterpreter.id,
      // Personal info
      firstName: preloadInterpreter.first_name,
      lastName: preloadInterpreter.last_name,
      title: preloadInterpreter.title,
      suffix: preloadInterpreter.suffix,
      location: preloadInterpreter.location,
      flatContacts: flattenContacts(preloadInterpreter.contacts),
      // Certification
      certificates: preloadedCertifications,
      // // Service area
      serviceAreas: preloadedServiceAreas,
      // // Service terms
      minWklyAssignments: preloadInterpreter.min_weekly_assignments ?? defaultState.minWklyAssignments,
      parkingExtra: !!preloadInterpreter.parking_extra,
      parkingTicket: !!preloadInterpreter.parking_ticket,
      bonusExtra: !!preloadInterpreter.bonus_extra,
      mileageExtra: !!preloadInterpreter.mileage_extra,
      mileageRate: preloadInterpreter.mileage_rate ?? defaultState.mileageRate,
      // Service rates
      serviceRates: preloadedServiceRates,
      // // Payment info
      // preferredPaymentMethod: preloadInterpreter.preferred_payment_method,
      // paymentMethods: preloadInterpreter.payment_methods,
      // // IRS W9 form
      // irsW9Form: preloadInterpreter.irs_w9_form,
      // Notes
      notes: preloadInterpreter.notes ?? [],
    };

    setInitialState(state);
  };

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadInterpreter]);

  // Fields display
  const [showPersonalInfo, setShowPersonalInfo] = useState<boolean>(false);
  const [showCertificationField, setShowCertificationField] = useState<boolean>(false);
  const [showServiceAreaField, setShowServiceAreaField] = useState<boolean>(false);
  const [showServiceRateField, setShowServiceRateField] = useState<boolean>(false);
  const [showPaymentInfoField, setShowPaymentInfoField] = useState<boolean>(false);
  const [showNotes, setShowNotes] = useState<boolean>(false);

  // Mutations
  const { mutateAsync: mutateAsyncCreateUserComplex } = useMutation<
    UsersPostResponseComplex,
    UsersPostErrorComplex,
    UsersPostPayloadComplex
  >(createUserComplex);

  const { mutateAsync: mutateAsyncUpdateUserComplex } = useMutation<
    UsersPutResponseComplex,
    UsersPutErrorComplex,
    Parameters<typeof updateUserComplex>
  >((args) => updateUserComplex(...args));

  // Validation
  const getCertificationErrors = (certificates: CertificateItem[]): Errors<CertificateItem>[] | null => {
    let hasErrors = false;

    const certificateErrors: Errors<CertificateItem>[] = certificates?.map((certificate) => {
      const certificateItemErrors: Errors<CertificateItem> = {};

      if (!certificate.certificateId) {
        certificateItemErrors.certificateId = t`errors.required`;
      }
      if (!certificate.certificateNumber) {
        certificateItemErrors.certificateNumber = t`errors.required`;
      }

      if (!isEmpty(certificateItemErrors)) {
        hasErrors = true;
      }

      return certificateItemErrors;
    });

    return hasErrors ? certificateErrors : null;
  };

  const getServiceRatesErrors = (serviceRates: ServiceRateItem[]): Errors<ServiceRateItem>[] | null => {
    let hasErrors = false;

    const serviceRateErrors: Errors<ServiceRateItem>[] = serviceRates?.map((serviceRate) => {
      const rateItemErrors: Errors<ServiceRateItem> = {};

      if (!serviceRate.language) {
        rateItemErrors.language = t`errors.required`;
      }
      if (!serviceRate.serviceRoot) {
        rateItemErrors.serviceRoot = t`errors.required`;
      }
      if (!serviceRate.hourRate) {
        rateItemErrors.hourRate = t`errors.required`;
      }
      if (serviceRate.minHours === '') {
        rateItemErrors.minHours = t`errors.required`;
      }
      if (serviceRate.minMinutes === '') {
        rateItemErrors.minMinutes = t`errors.required`;
      }
      if (!serviceRate.noShowingFee) {
        rateItemErrors.noShowingFee = t`errors.required`;
      }
      if (serviceRate.language?.id === englishLanguage?.id) {
        rateItemErrors.validation_errors = [t`errors.englishLanguageNotAllowed`];
      }

      if (!isEmpty(rateItemErrors)) {
        hasErrors = true;
      }

      return rateItemErrors;
    });

    return hasErrors ? serviceRateErrors : null;
  };

  const getServiceAreasErrors = (serviceAreas: ServiceAreaItem[]): Errors<ServiceAreaItem>[] | null => {
    let hasErrors = false;

    const serviceAreaErrors: Errors<ServiceAreaItem>[] = serviceAreas?.map((serviceArea) => {
      const areaItemErrors: Errors<ServiceAreaItem> = {};

      if (!serviceArea.country) {
        areaItemErrors.country = t`errors.required`;
      }
      if (!serviceArea.state) {
        areaItemErrors.state = t`errors.required`;
      }

      if (serviceArea.zip) {
        if (!serviceArea.city) {
          areaItemErrors.city = t`errors.required`;
        }
        if (!serviceArea.county) {
          areaItemErrors.county = t`errors.required`;
        }
      }
      if (serviceArea.city) {
        if (!serviceArea.county) {
          areaItemErrors.county = t`errors.required`;
        }
      }
      if (!isEmpty(areaItemErrors)) {
        hasErrors = true;
      }

      return areaItemErrors;
    });

    return hasErrors ? serviceAreaErrors : null;
  };

  const validate = (formData: InterpreterOnboardingState, email: string, phone: string) => {
    const newErrors: Errors<InterpreterOnboardingState> = {};
    const validationErrors: string[] = [];

    // Flat fields
    if (!formData.firstName) {
      newErrors.firstName = t`errors.required`;
    }
    if (!formData.lastName) {
      newErrors.lastName = t`errors.required`;
    }
    if (!email && !phone) {
      validationErrors.push(t`errors.emailOrPhoneRequired`);
    }
    if (!formData.mileageRate) {
      newErrors.mileageRate = t`errors.required`;
    }

    // Certifications
    const certificateErrors = getCertificationErrors(formData.certificates);

    if (certificateErrors !== null) {
      newErrors.certificates = certificateErrors;
    }

    // Service rates
    const rateErrors = getServiceRatesErrors(formData.serviceRates);

    if (rateErrors !== null) {
      newErrors.serviceRates = rateErrors;
    }

    // Service areas
    const areaErrors = getServiceAreasErrors(formData.serviceAreas);

    if (areaErrors !== null) {
      newErrors.serviceAreas = areaErrors;
    }

    // Wrap up
    if (validationErrors.length !== 0) {
      newErrors.validation_errors = validationErrors;
    }
    setErrors(newErrors);
    return isEmpty(newErrors);
  };

  // Submission
  const getFormData = () => {
    const formData = formState.current;

    const emailFlatContact = formData.flatContacts.find(({ type, data }) => type === 'email' && data) as
      | EmailFlatContact
      | undefined;

    const email: string = emailFlatContact ? getDataFromFlatContact(emailFlatContact) : '';

    const phoneFlatContact = formData.flatContacts.find(({ type, data }) => type === 'phone' && data) as
      | PhoneFlatContact
      | undefined;

    const phone: string = phoneFlatContact ? getDataFromFlatContact(phoneFlatContact) : '';

    const contacts = unflattenContacts(formData.flatContacts);

    const certifications: ProviderCertification[] = formData.certificates.map((certification) => ({
      certificate_id: certification.certificateId!,
      certificate_number: certification.certificateNumber,
      expiration_date: certification.expirationDate ?? null,
    }));

    return { formData, email, phone, contacts, certifications };
  };
  // Create
  const submitForCreation = (event: FormSubmitEvent) => {
    event.preventDefault();

    if (preloadInterpreter?.id !== undefined) {
      const msg = 'Tried creating a new interpreter when an ID was provided, please contact a dev';
      console.error(msg);
      toast.error(msg);
      return;
    }

    const { formData, email, phone, contacts, certifications } = getFormData();
    const username = buildUsername(formData.firstName!, formData.lastName!, new Date());

    if (!validate(formData, email, phone)) {
      return;
    }

    // Build payloads
    const servicesPayload: ServicesPostPayloadComplex[] = formData.serviceRates!.map((serviceRate) => ({
      bill_amount: serviceRate.hourRate,
      bill_min_payment: currency(serviceRate.hourRate).multiply(serviceRate.minHours).toString(),
      bill_no_show_fee: serviceRate.noShowingFee,
      bill_rate: SERVICE_BILL_RATE,
      bill_rate_type: SERVICE_BILL_RATE_TYPE,
      bill_rate_minutes_threshold: serviceRate.minMinutes || 0,
      root: serviceRate.serviceRoot!.id,
      source_language_alpha3: englishLanguage!.alpha3,
      target_language_alpha3: serviceRate.language!.alpha3,
    }));

    const serviceAreasPayload: ServiceAreasPostPayloadComplex[] = formData.serviceAreas!.map((serviceArea) => ({
      country: serviceArea.country,
      state: serviceArea.state,
      county: serviceArea.county,
      city: serviceArea.city,
      zip: serviceArea.zip,
    }));

    const providerPayload: ProvidersPostPayloadComplex = {
      companies: [],
      notes: formData.notes,
      min_weekly_assignments: formData.minWklyAssignments,
      parking_extra: formData.parkingExtra,
      parking_ticket: formData.parkingTicket,
      bonus_extra: formData.bonusExtra,
      mileage_extra: formData.mileageExtra,
      mileage_rate: formData.mileageRate,
      certifications,

      _service_datalist: servicesPayload,
      _service_area_datalist: serviceAreasPayload,
    };

    const payload: UsersPostPayloadComplex = {
      username,
      email,
      first_name: formData.firstName!,
      last_name: formData.lastName!,
      title: formData.title,
      suffix: formData.suffix,
      location: formData.location!,
      contacts,

      _provider_data: providerPayload,
      _recipient_data: [],
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    // Display toast for the creation process
    toast
      .promise(mutateAsyncCreateUserComplex(payload), {
        pending: t`progress.interpreterCreate.started` as string,
        success: t`progress.interpreterCreate.success` as string,
        error: t`progress.interpreterCreate.error` as string,
      })
      .catch((err: UsersPostErrorComplex) => {
        console.error(`Errors when creating interpreter "${username}"`, err);
        setErrors(err);
        onSubmitFailure?.(err, payload);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(({ provider_id }) => onSubmitSuccess?.(provider_id, payload))
      .finally(postSubmit);
  };

  // Update
  const submitForUpdate = (event: FormSubmitEvent) => {
    event.preventDefault();

    if (preloadInterpreter === undefined) {
      const msg = 'Tried updating an interpreter but no ID was provided, please contact a dev';
      console.error(msg);
      toast.error(msg);
      return;
    }

    // Generate data
    const { formData, email, phone, contacts, certifications } = getFormData();

    if (!validate(formData, email, phone)) {
      return;
    }

    // Build payloads
    const servicesPayload: ServicesPutPayloadComplex[] = formData.serviceRates!.map((serviceRate) => ({
      id: serviceRate.sourceId,
      _deleted: serviceRate.deleted,
      bill_amount: serviceRate.hourRate,
      bill_min_payment: currency(serviceRate.hourRate).multiply(serviceRate.minHours).toString(),
      bill_no_show_fee: serviceRate.noShowingFee,
      bill_rate: SERVICE_BILL_RATE,
      bill_rate_type: SERVICE_BILL_RATE_TYPE,
      bill_rate_minutes_threshold: serviceRate.minMinutes || 0,
      root: serviceRate.serviceRoot!.id,
      source_language_alpha3: englishLanguage!.alpha3,
      target_language_alpha3: serviceRate.language!.alpha3,
    }));

    const serviceAreasPayload: ServiceAreasPutPayloadComplex[] = formData.serviceAreas!.map((serviceArea) => ({
      id: serviceArea.sourceId,
      _deleted: serviceArea.deleted,
      country: serviceArea.country,
      state: serviceArea.state,
      county: serviceArea.county,
      city: serviceArea.city,
      zip: serviceArea.zip,
    }));

    const providerPayload: ProvidersPutPayloadComplex = {
      companies: [],
      notes: formData.notes,
      min_weekly_assignments: formData.minWklyAssignments,
      parking_extra: formData.parkingExtra,
      parking_ticket: formData.parkingTicket,
      bonus_extra: formData.bonusExtra,
      mileage_extra: formData.mileageExtra,
      mileage_rate: formData.mileageRate,
      certifications,

      _service_datalist: servicesPayload,
      _service_area_datalist: serviceAreasPayload,
    };

    const payload: UsersPutPayloadComplex = {
      email,
      first_name: formData.firstName!,
      last_name: formData.lastName!,
      title: formData.title,
      suffix: formData.suffix,
      location: formData.location!,
      contacts,

      _provider_data: providerPayload,
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    // Display toast for the creation process
    toast
      .promise(mutateAsyncUpdateUserComplex([preloadInterpreter.user_id, payload]), {
        pending: t`progress.interpreterUpdate.started` as string,
        success: t`progress.interpreterUpdate.success` as string,
        error: t`progress.interpreterUpdate.error` as string,
      })
      .catch((err: UsersPostErrorComplex) => {
        console.error(`Errors when updating interpreter "${preloadInterpreter.username}"`, err);
        setErrors(err);
        onSubmitFailure?.(err, payload);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => onSubmitSuccess?.(preloadInterpreter, payload))
      .finally(postSubmit);
  };

  const onSubmit = (event: FormSubmitEvent) =>
    preloadInterpreter === undefined ? submitForCreation(event) : submitForUpdate(event);

  return (
    <Form id={id} key={initialState.id} onSubmit={onSubmit}>
      <Row>
        {!isEmpty(errors) ? (
          <Alert severity="error">
            <AlertTitle>
              There's errors{' '}
              <Button variant="text" onClick={() => setErrors({})}>
                Clear
              </Button>
            </AlertTitle>
            <ul>
              {Object.entries(errors).map(([k, v]) => (
                <li key={k}>
                  {k}: {JSON.stringify(v)}
                </li>
              ))}
            </ul>
          </Alert>
        ) : null}
      </Row>
      <Row>
        <Col xl={6}>
          <PersonalInfoField
            formPrefix={p`personal-info`}
            showPersonalInfo={showPersonalInfo}
            setShowPersonalInfo={setShowPersonalInfo}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<PersonalInfoState>}
            errors={undefined}
          />

          <CertificationField
            formPrefix={p`certifications`}
            showCertificationField={showCertificationField}
            setShowCertificationField={setShowCertificationField}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<CertificationState>}
          />

          <ServiceAreaField
            formPrefix={p`serviceArea`}
            showServiceAreaField={showServiceAreaField}
            setShowServiceAreaField={setShowServiceAreaField}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<ServiceAreaState>}
          />

          <ServiceTermsField
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<ServiceTermsState>}
          />
        </Col>
        <Col xl={6}>
          <ServiceRateField
            formPrefix={p`serviceRate`}
            showServiceRateField={showServiceRateField}
            setShowServiceRateField={setShowServiceRateField}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<ServiceRateState>}
          />

          <PaymentInfoField
            formPrefix={p`paymentInfo`}
            showPaymentInfoField={showPaymentInfoField}
            setShowPaymentInfoField={setShowPaymentInfoField}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<PaymentInfoState>}
          />

          <IRSW9FormField initialState={initialState} stateUpdater={formStateUpdater as StateUpdater<IRSW9FormState>} />

          <InterpreterNotesField
            formPrefix={p`notes`}
            showNotesField={showNotes}
            setShowNotesField={setShowNotes}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<InterpreterNotesState>}
          />
        </Col>
      </Row>
    </Form>
  );
}
