import { FormEventHandler, useState } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import './SignInPage.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import CSRFComponent from './Components/CSRFComponent';
import MiddleCenterAlignedContainer from '../../components/MiddleCenterAlignedContainer';
import { login } from '../../app/api';

export default function SignInPage() {
  const { t } = useTranslation('forms');
  const navigate = useNavigate();

  const { mutate: loginUser, isError } = useMutation(login, {
    onSuccess: () => navigate('/'),
  });

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberme, setRememberMe] = useState<boolean>(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    loginUser({ username, password, rememberme });
  };

  return (
    <MiddleCenterAlignedContainer dark maxWidth>
      <Form onSubmit={handleSubmit} className="login-form h-100">
        <CSRFComponent />
        <h1>{t`login.title`}</h1>
        <h3>{t`login.subtitle`}</h3>

        <FormGroup floating>
          <Input
            id="username"
            name="username"
            type="text"
            placeholder="user@corechs.com"
            invalid={isError}
            required
            onChange={(e) => setUsername(e.target.value)}
          />
          <Label for="username">{t`username`}</Label>
        </FormGroup>

        <FormGroup floating>
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            invalid={isError}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <Label for="password">{t`password`}</Label>
        </FormGroup>

        <FormGroup check>
          <Label check>
            <Input type="checkbox" className="mb-3" onChange={(e) => setRememberMe(e.target.checked)} />{' '}
            <span>{t`rememberMe`}</span>
          </Label>
        </FormGroup>

        <Button color="primary">{t`login.submit`}</Button>
      </Form>
    </MiddleCenterAlignedContainer>
  );
}
