// Models
export const AFFILIATIONS_ENDPOINT = 'affiliations/' as const;
export const AGENTS_ENDPOINT = 'agents/' as const;
export const AUTHORIZATIONS_ENDPOINT = 'authorizations/' as const;
export const BOOKINGS_ENDPOINT = 'bookings/' as const;
export const CATEGORIES_ENDPOINT = 'categories/' as const;
export const COMPANIES_ENDPOINT = 'companies/' as const;
export const COMPANY_RATES_ENDPOINT = 'company_rates/' as const;
export const COMPANY_RELATIONSHIPS_ENDPOINT = 'company_relationships/' as const;
export const EVENTS_ENDPOINT = 'events/' as const;
export const EVENTS_FILTERS_ENDPOINT = 'events_filters/' as const;
export const EVENTS_LIGHT_ENDPOINT = 'events/light/' as const;
export const EVENTS_REPORTS_ENDPOINT = 'reports/' as const;
export const EXPENSES_ENDPOINT = 'expenses/' as const;
export const GLOBAL_SETTINGS_ENDPOINT = 'global_settings/' as const;
export const LANGUAGES_ENDPOINT = 'languages/' as const;
export const NOTIFICATIONS_ENDPOINT = 'notifications/' as const;
export const EMAIL_ENDPOINT = 'email/' as const;
export const OFFERS_ENDPOINT = 'offers/' as const;
export const OPERATORS_ENDPOINT = 'operators/' as const;
export const PAYERS_ENDPOINT = 'payers/' as const;
export const PROVIDERS_ENDPOINT = 'providers/' as const;
export const RATES_ENDPOINT = 'rates/' as const;
export const RECIPIENTS_ENDPOINT = 'recipients/' as const;
export const REQUESTERS_ENDPOINT = 'requesters/' as const;
export const SEARCH_ENDPOINT = 'search/' as const;
export const SERVICES_ENDPOINT = 'services/' as const;
export const SERVICE_AREAS_ENDPOINT = 'service_areas/' as const;
export const SERVICE_ROOTS_ENDPOINT = 'service_roots/' as const;
export const USERS_ENDPOINT = 'users/' as const;
export const VERSION_ENDPOINT = 'version/' as const;

// Auth
export const LOGIN_ENDPOINT = 'token/' as const;
export const REGISTER_ENDPOINT = 'register/' as const;
export const TOKEN_REFRESH_ENDPOINT = 'token/refresh/' as const;
