import { AxiosError } from 'axios';
import { noAuthClient } from './config/clients';
import { REGISTER_ENDPOINT } from './config/endpoints';
import { UsersPostPayload, UsersPostResponse, UsersPostError } from './users';

export type RegisterPostPayload = UsersPostPayload;
export type RegisterPostError = UsersPostResponse;
export const registerUser = (payload: RegisterPostPayload) =>
  noAuthClient
    .post<RegisterPostError>(REGISTER_ENDPOINT, payload)
    .then((res) => res.data)
    .catch((err: AxiosError<UsersPostError>) => Promise.reject(err.response?.data));
