import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Button, Col, Container, FormText, Row } from 'reactstrap';
import { TextField } from '@mui/material';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { useForm, useStateArray, useStateDict } from '../../../../../app/hooks';
import {
  updateUserComplex,
  UsersPutError,
  UsersPutErrorComplex,
  UsersPutPayloadComplex,
  UsersPutResponseComplex,
} from '../../../../../app/api';
import {
  flattenContacts,
  mapArrayForSelect,
  mapObjectForSelect,
  unflattenContacts,
} from '../../../../../app/helpers/mappers';
import { TITLES } from '../../../../../app/helpers/enum';
import { SUFFIXES } from '../../../../../app/helpers/collections';
import { FlatContact, Interpretation, Location, Note } from '../../../../../app/types/Entities';
import { DEFAULT_COUNTRY_FOR_LOCATION } from '../../../../../app/helpers/constants';
import { stringifyDate } from '../../../../../app/helpers/manipulation';
import { FormInput } from '../../../../../components/Form/FormInput';
import FormWrapper from '../../../../../components/Form/FormWrapper';
import LocationSection from '../LocationSection/LocationSection';
import FlatContactList from '../FlatContactList/FlatContactList';
import NotesListField from '../NotesListField/NotesListField';
import { ErrorsAlert } from '../../../../../components/ErrorsAlert';
import { FieldInterpreter } from './localTypes';

export interface UserDataSectionProps {
  showInterpreter: boolean;
  triggerClose: () => void;
  interpreter: FieldInterpreter | null;
  refetchServices: () => Promise<{ data: Interpretation[] | undefined }>;
  onUpdateSuccess: ((updatedService?: Interpretation) => void) | undefined;
}

const formPrefix = 'interpreter';
const translationArray = ['booking', 'forms', 'languages'];

function UserDataSection(props: UserDataSectionProps) {
  const { showInterpreter, triggerClose, interpreter, refetchServices, onUpdateSuccess } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<UsersPutError>({});
  const [showNotes, setShowNotes] = useState<boolean>(false);
  useEffect(() => {
    if (!showInterpreter) {
      setShowNotes(false);
    }
  }, [showInterpreter]);

  const [title, setTitle] = useState<string | undefined>();
  const titleOptions = mapObjectForSelect(TITLES, (titleKey) => t(`collections:titles.${titleKey}`));

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();

  const [suffix, setSuffix] = useState<string | undefined>();
  const suffixOptions = mapArrayForSelect(SUFFIXES, (suffixKey) => t(`collections:suffixes.${suffixKey}`));

  const [location, setLocation] = useState<Location | undefined>();
  const updateLocation = (fields: Partial<Location>) => setLocation((prev) => ({ ...prev, ...fields }));
  const {
    values: flatContacts,
    set: setFlatContacts,
    push: pushFlatContact,
    replaceAt: replaceFlatContactAt,
    removeAt: removeFlatContactAt,
  } = useStateArray<FlatContact>([]);

  const addNewFlatContact = () =>
    pushFlatContact({
      type: 'email',
      data: {},
      context: 'work',
      uuid: uuidv4(),
    });

  const {
    values: notes,
    set: setNotes,
    push: pushNote,
    replaceAt: replaceNoteAt,
    removeAt: stateRemoveNoteAt,
  } = useStateArray<Note>([]);

  const removeNoteAt = (index: number) => {
    if (notes.length <= 1) {
      setShowNotes(false);
    }
    stateRemoveNoteAt(index);
  };

  const prefillForm = () => {
    setTitle(interpreter?.title);
    setFirstName(interpreter?.first_name);
    setLastName(interpreter?.last_name);
    setSuffix(interpreter?.suffix);
    setLocation(interpreter?.location ?? undefined);
    setFlatContacts(flattenContacts(interpreter?.contacts) ?? []);
    setNotes(interpreter?.notes ?? []);
  };

  useEffect(() => {
    prefillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interpreter]);

  useEffect(() => {
    // Ensure there is always at least one (flat) contact available to fill
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  // On save functions
  const { mutateAsync: mutateAsyncUpdateUserComplex } = useMutation<
    UsersPutResponseComplex,
    UsersPutErrorComplex,
    Parameters<typeof updateUserComplex>
  >((args) => updateUserComplex(...args));

  // Validate
  const validate = () => {
    const validationErrors = [];

    if (!interpreter?.id) {
      validationErrors.push(t`errors.expectedInterpreterId`);
    }
    if (!firstName) {
      validationErrors.push(t`errors.noFirstName`);
    }
    if (!lastName) {
      validationErrors.push(t`errors.noLastName`);
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      return false;
    }
    return true;
  };

  // On update
  const updateInterpreter = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? {
            ...location,
            country: DEFAULT_COUNTRY_FOR_LOCATION,
          }
        : undefined;

    const payload: UsersPutPayloadComplex = {
      contacts: unflattenContacts(flatContacts),
      date_of_birth: stringifyDate(interpreter!.date_of_birth!),
      email: interpreter!.email,
      first_name: firstName!,
      last_name: lastName!,
      location: payloadLocation,
      suffix: suffix ?? '',
      title: title ?? '',

      _provider_data: {
        companies: interpreter!.companies,
        notes,
        _service_datalist: undefined,
        _service_area_datalist: undefined,
      },
    };

    toast
      .promise(mutateAsyncUpdateUserComplex([interpreter!.user_id!, payload]), {
        pending: t`progress.interpreterUpdate.started` as string,
        success: t`progress.interpreterUpdate.success` as string,
        error: t`progress.interpreterUpdate.error` as string,
      })
      .catch((err) => {
        if (err) setErrors(err);
        console.error('Errors when updating interpreter', err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => refetchServices())
      .then((refetchData) => {
        const { data: newServices } = refetchData;
        const updatedService = newServices?.find((iterService) => iterService.id === interpreter?.source.id);
        onUpdateSuccess?.(updatedService);
      });
  };

  return (
    <Container className="p-0">
      <Row>
        <Col>
          <Row className="gx-2">
            <Col md={12} xl={12} xxl={6}>
              <FormSelect
                id={p`title`}
                options={titleOptions}
                value={titleOptions.find((o) => o.value === title)}
                onChange={(_event, titleOption) => setTitle(titleOption.value)}
                renderInput={(params) => <TextField placeholder={t`title`} {...params} />}
                disableClearable
              />
            </Col>
            <Col md={12} xl={6}>
              <FormInput
                label={`${t`firstName`} *`}
                value={firstName ?? ''}
                onChange={(e) => setFirstName(e.target.value)}
                errors={errors.first_name}
              />
            </Col>
            <Col md={12} xl={6}>
              <FormInput
                label={`${t`lastName`} *`}
                value={lastName ?? ''}
                onChange={(e) => setLastName(e.target.value)}
                errors={errors.last_name}
              />
            </Col>
            <Col md={12} xl={12} xxl={6}>
              <FormSelect
                id={p`suffix`}
                options={suffixOptions}
                value={suffixOptions.find((o) => o.value === suffix)}
                onChange={(_event, suffixOption) => setSuffix(suffixOption.value)}
                renderInput={(params) => <TextField placeholder={t`suffix`} {...params} />}
                disableClearable
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormWrapper label="Address" className="pb-2">
                <LocationSection errors={errors.location} location={location} update={updateLocation} />
              </FormWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormWrapper label={t`contactInformation`}>
                <FlatContactList
                  items={flatContacts}
                  add={addNewFlatContact}
                  removeAt={removeFlatContactAt}
                  replaceAt={replaceFlatContactAt}
                />
              </FormWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <NotesListField
                formPrefix={p`interpreter-notes`}
                labelText={t`interpreterNotes`}
                showNotesList={showNotes}
                setShowNotesList={setShowNotes}
                notesList={notes}
                pushNote={pushNote}
                replaceNoteAt={replaceNoteAt}
                removeNoteAt={removeNoteAt}
              />
            </Col>
          </Row>

          <ErrorsAlert errorsArray={errors.non_field_errors} />
          <ErrorsAlert errorsArray={errors.validation_errors} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button className="action-button me-4" color="submit" onClick={updateInterpreter}>
            {t`saveInterpreter`}
          </Button>
          <Button
            className="action-button"
            color="cancel"
            onClick={() => {
              triggerClose();
              prefillForm();
            }}
          >
            {t`cancel`}
          </Button>
          <FormText color="required-fields-help">{t`requiredFieldsHelp`}</FormText>
        </Col>
      </Row>
    </Container>
  );
}

export default UserDataSection;
