import { MenuItem } from '@mui/material';
import * as XLSX from 'xlsx';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { config_interpreters, config_reports } from './config';
import { EventsGetParams, getEventReports } from '../api';

interface MRow {
  [key: string]: string | number;
}

function hasPhoneProperty(json: any): boolean {
  return 'phone' in json;
}

async function handleExportExcel() {
  const reportData: EventsGetParams = {};
  const report = await getEventReports(reportData);

  const rows: MRow[] = report.map((row) => {
    const mRow: MRow = {};
    for (const key of config_reports.keys) {
      mRow[key] = row[key];
    }
    // }
    return mRow;
  });

  const hasPhone = hasPhoneProperty(rows[0]);

  const worksheet = XLSX.utils.json_to_sheet(rows);
  if (hasPhone === true) {
    XLSX.utils.sheet_add_aoa(worksheet, [[...config_interpreters.columnNames]], {
      origin: 'A1',
    });
  } else {
    XLSX.utils.sheet_add_aoa(worksheet, [[...config_reports.columnNames]], {
      origin: 'A1',
    });
  }
  const workbook = XLSX.utils.book_new();
  if (hasPhone === true) {
    XLSX.utils.book_append_sheet(workbook, worksheet, config_interpreters.sheetName);
    XLSX.writeFile(workbook, config_interpreters.fileName, { compression: true });
  } else {
    XLSX.utils.book_append_sheet(workbook, worksheet, config_reports.sheetName);
    XLSX.writeFile(workbook, config_reports.fileName, { compression: true });
  }
}

async function handleExportCSV() {
  const csvConfig = mkConfig({ useKeysAsHeaders: true });

  const reportData: EventsGetParams = {};
  const report = await getEventReports(reportData);

  const csvRows = report.map((row) => {
    const mRow: MRow = {};
    for (const key of config_reports.keys) {
      mRow[key] = row[key];
    }
    return mRow;
  });

  if (hasPhoneProperty(csvRows[0]) === true) {
    csvConfig.filename = 'interpreter_list';
  } else {
    csvConfig.filename = 'booking_list';
  }

  const csv = generateCsv(csvConfig)(csvRows);
  download(csvConfig)(csv);
}

export default function ExportMenuItem(props: any) {
  // const apiRef = useGridApiContext();
  const { hideMenu } = props;

  return (
    <>
      <MenuItem
        onClick={() => {
          handleExportExcel();
          // Hide the export menu after the export
          hideMenu?.();
        }}
      >
        Download as Excel
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleExportCSV();
          hideMenu?.();
        }}
      >
        Download as CSV
      </MenuItem>
    </>
  );
}
