import { useTranslation } from 'react-i18next';
import FormListRow, { FormListRowProps } from '../../../../../components/Form/FormListRow';
import { ContextOptions, FlatContact } from '../../../../../app/types/Entities';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import { FlatContactListItem } from './FlatContactListItem';

export interface FlatContactListProps extends FormListRowProps {
  items: FlatContact[];
  add: () => void;
  removeAt: (index: number) => void;
  replaceAt: (index: number, flatContact: FlatContact) => void;
  contextOptions?: ContextOptions;
}

function FlatContactList({ items, add, removeAt, replaceAt, contextOptions }: FlatContactListProps) {
  const { t } = useTranslation('booking');

  return (
    <FormListRow>
      {items.map((flatContact, index, { length }) => {
        const isLastItem = length - 1 === index;
        return (
          <FlatContactListItem
            key={flatContact.uuid}
            flatContact={flatContact}
            update={(newFlatContact) => replaceAt(index, { ...flatContact, ...newFlatContact } as FlatContact)}
            addButtonState={isLastItem ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.INVISIBLE}
            onAdd={add}
            addButtonTooltip={t`tooltip.addContactRow`}
            onRemove={() => removeAt(index)}
            removeButtonTooltip={t`tooltip.removeContactRow`}
            contextOptions={contextOptions}
          />
        );
      })}
    </FormListRow>
  );
}

export default FlatContactList;
