import { EventContentArg } from '@fullcalendar/react';
import './CustomEvent.scss';
import './CustomEvent.css';
import { BEvent, Booking } from 'src/app/types/Entities';
import { CSSProperties } from 'react';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from 'src/app/helpers/constants';

export default function CustomEvent(props: EventContentArg) {
  const booking = props.event.extendedProps.booking as Booking;
  const event = props.event.extendedProps.eventWithoutBooking as BEvent;

  const startAt = moment(event.start_at).tz(DEFAULT_TIMEZONE).format('h:mm');
  const endAt = moment(event.end_at).tz(DEFAULT_TIMEZONE).format('h:mm A');
  const recipientFirstName = event.affiliates && event.affiliates[0]?.recipient?.first_name;
  const recipientLastName = event.affiliates && event.affiliates[0]?.recipient?.last_name;

  return (
    <div className="event-content" style={{ '--booking-status-color': booking.cCurrentStatus.color } as CSSProperties}>
      <div className="event-content-header" />
      <div className="event-content-time">{startAt}</div>
      <div className="event-content-time-end">{`${' - '}${endAt}`}</div>
      <div className="event-content-id">{`${'#'}${booking.public_id}`}</div>
      <div className="event-content-name">{`${recipientFirstName} ${recipientLastName}`}</div>
    </div>
  );
}
