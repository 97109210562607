import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { BUTTON_STATE } from 'src/app/helpers/enum';
import { useForm } from 'src/app/hooks';
import FormExpandableListField from 'src/components/Form/FormExpandableListField';
import EventItem, { BookingEvent } from './EventItem';

export interface EventListFieldProps {
  formPrefix: string;
  showEventField: boolean;
  setShowEventField: (display: boolean) => void;
  events: BookingEvent[];
  // When allow multiple events again uncomment
  // eslint-disable-next-line react/no-unused-prop-types
  pushEvent: (item: BookingEvent) => void;
  setEvents: (items: BookingEvent[]) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  newEvent: () => BookingEvent;
  replaceEventAt: (index: number, item: BookingEvent) => void;
  removeEventAt: (index: number) => void;
  initialEventsList: BookingEvent[];
  followUp: boolean;
}

const translationArray = ['booking', 'forms', 'languages'];

function EventListField(props: EventListFieldProps) {
  const {
    formPrefix,
    showEventField,
    setShowEventField,
    events,
    // pushEvent,
    replaceEventAt,
    removeEventAt,
    setEvents,
    // newEvent,
    initialEventsList,
    followUp,
  } = props;

  // Utils
  const { t } = useTranslation(translationArray);
  const { p } = useForm(formPrefix);

  return (
    <FormExpandableListField // Event
      formPrefix={p`eventList`}
      isOpen={showEventField}
      toggleOpen={setShowEventField}
      canClose={false}
      openButtonState={events.length === 1 ? BUTTON_STATE.HIDDEN : BUTTON_STATE.INTERACTIVE}
      label={<h5>{t`event`}*</h5>}
      footer={
        <Button
          className="action-button"
          color="cancel"
          onClick={() => {
            setShowEventField(false);
            setEvents(initialEventsList);
          }}
        >
          {t`cancel`}
        </Button>
      }
    >
      {events.map((event, index) => {
        return (
          <EventItem
            key={index} // eslint-disable-line react/no-array-index-key -- use a proper key when multiple events are allowed again
            formPrefix={p`eventItem.${index}`}
            event={event}
            isChild={index > 0}
            onRemove={() => removeEventAt(index)}
            setEventStartDate={(start_at: Date) => replaceEventAt(index, { ...event, start_at })}
            setTypeOfAppointment={(description: string) => replaceEventAt(index, { ...event, description })}
            setExpectedDuration={(expected_duration: number | '') =>
              replaceEventAt(index, { ...event, expected_duration })
            }
            setArriveEarly={(arrive_early: number | '') => replaceEventAt(index, { ...event, arrive_early })}
            followUp={followUp}
          />
        );
      })}
    </FormExpandableListField>
  );
}

export default EventListField;
