import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { Autocomplete, Box, createFilterOptions } from '@mui/material';
import { Key, ReactNode, useEffect, useState } from 'react';

type Option<T> = Omit<T, '_inputValue'> | { _inputValue: string };
export interface FormSelectCreatableProps<T> extends AutocompleteProps<Option<T>, false, false, true> {
  addOptionLabel?: string;
  getCreatedOptionLabel?: (inputValue: string) => string;
  getSelectedOptionLabel: (option: T) => string;
  onCreateOption: (inputValue: string) => void;
  onSelectOption: (option: T | null) => void;
  renderCreateOption?: (inputValue: string) => ReactNode;
  renderSelectedOption?: (option: T) => ReactNode;
  requireInputForCreation?: boolean;
  getOptionValue: (option: T) => Key | null | undefined;
  noMarginBottom?: boolean;
}

function FormSelectCreatable<T>({
  getCreatedOptionLabel = () => 'Create',
  getSelectedOptionLabel,
  onCreateOption,
  onSelectOption,
  renderCreateOption = getCreatedOptionLabel,
  renderSelectedOption = getSelectedOptionLabel,
  requireInputForCreation = true,
  getOptionValue,
  noMarginBottom = false,
  ...props
}: FormSelectCreatableProps<T>) {
  const filter = createFilterOptions<Option<T>>();

  const [inputValue, setInputValue] = useState('');
  // Reset input value when value is cleared on create new entry
  useEffect(() => {
    if (inputValue === getCreatedOptionLabel(inputValue) && props.value === null) {
      setInputValue('');
    }
  }, [inputValue, getCreatedOptionLabel, props.value]);

  return (
    <Autocomplete
      autoHighlight
      freeSolo
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') {
          // User pressed enter
          onCreateOption(newValue);
        } else if (newValue !== null && '_inputValue' in newValue) {
          // User chose create new option
          onCreateOption(newValue._inputValue);
        } else {
          onSelectOption(newValue as T);
        }
      }}
      onInputChange={(_event, value, reason) => {
        if (reason === 'clear') {
          onSelectOption(null);
          setInputValue?.('');
        }
        setInputValue?.(value);
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return getCreatedOptionLabel(option);
        }
        if ('_inputValue' in option) {
          return getCreatedOptionLabel(option._inputValue);
        }

        return getSelectedOptionLabel(option as T);
      }}
      renderOption={(renderOptionProps, option) => (
        <Box
          component="li"
          sx={'_inputValue' in option ? { color: 'primary.main' } : {}}
          key={getOptionValue(option as T)}
          {...renderOptionProps}
        >
          {'_inputValue' in option ? renderCreateOption(option._inputValue) : renderSelectedOption(option as T)}
        </Box>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (requireInputForCreation && !params.inputValue) {
          return filtered;
        }

        filtered.push({
          _inputValue: params.inputValue,
        });
        return filtered;
      }}
      inputValue={inputValue}
      sx={{
        marginBottom: noMarginBottom ? undefined : 2,
      }}
      {...props}
    />
  );
}

export default FormSelectCreatable;
