import { DatabaseId, Errors } from '../types/DataStructures';
import { GlobalSetting } from '../types/Entities';
import { apiClient } from './config/clients';
import { GLOBAL_SETTINGS_ENDPOINT } from './config/endpoints';
import { ApiDao, ApiQuery, LookupsMap, applyLookup, pathParam } from './config/helpers';
import { sanitizeGlobalSetting } from './config/sanitizers';

export interface GlobalSettingsGetParams {
  id?: DatabaseId;
  client?: string;
  business?: DatabaseId;
}

export const DEFAULT_GLOBAL_SETTINGS_LOOKUPS: LookupsMap<GlobalSettingsGetParams> = {};

export const getGlobalSettingsDao: ApiDao<GlobalSettingsGetParams, any[]> = (
  params,
  lookups = DEFAULT_GLOBAL_SETTINGS_LOOKUPS,
) => apiClient.get(GLOBAL_SETTINGS_ENDPOINT, { params: applyLookup(params, lookups) });

export const getGlobalSettings: ApiQuery<GlobalSettingsGetParams, GlobalSetting[]> = (
  params,
  lookups = DEFAULT_GLOBAL_SETTINGS_LOOKUPS,
) => getGlobalSettingsDao(params, lookups).then((res) => res.data.map((v) => sanitizeGlobalSetting(v)));

export const getGlobalSetting = (globalSettingId: string) =>
  apiClient.get(pathParam(GLOBAL_SETTINGS_ENDPOINT, globalSettingId)).then((res) => sanitizeGlobalSetting(res.data));

// POST
export interface GlobalSettingsPostPayload {
  client: string;
  business: DatabaseId;
}
export type GlobalSettingsPostResponse = DatabaseId;
export type GlobalSettingsPostError = Errors<GlobalSettingsPostPayload>;

// PUT
export type GlobalSettingsPutPayload = Partial<GlobalSettingsPostPayload>;
