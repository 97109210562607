import { AxiosError } from 'axios';
import { ApiDao, ApiQuery, applyLookup, LookupsMap, pathParam } from './config/helpers';
import { BaseServiceRoot, Interpretation, ServiceRoot } from '../types/Entities';
import { apiClient } from './config/clients';
import { SERVICE_ROOTS_ENDPOINT } from './config/endpoints';
import { sanitizeBaseServiceRoot, sanitizeInterpretation, sanitizeServiceRoot } from './config/sanitizers';
import { DatabaseId, Errors } from '../types/DataStructures';

export interface ServiceRootsGetParams {
  id?: DatabaseId;
  name?: string;
  description?: string;
  categories?: string[];

  page?: number;
  page_size?: number;
}

export const DEFAULT_SERVICE_ROOTS_LOOKUPS: LookupsMap<ServiceRootsGetParams> = {
  name: 'nem',
  description: 'nem',
};

export const getServiceRootsDao: ApiDao<ServiceRootsGetParams, any[]> = (
  params,
  lookups = DEFAULT_SERVICE_ROOTS_LOOKUPS,
) => apiClient.get(SERVICE_ROOTS_ENDPOINT, { params: applyLookup(params, lookups) });

export const getServiceRoots: ApiQuery<ServiceRootsGetParams, ServiceRoot[]> = (
  params,
  lookups = DEFAULT_SERVICE_ROOTS_LOOKUPS,
) => getServiceRootsDao(params, lookups).then((res) => res.data.map((v) => sanitizeServiceRoot(v)));

export const getServiceRootsPaginated: ApiQuery<
  ServiceRootsGetParams,
  { count: number; next: string | null; previous: string | null; results: ServiceRoot[] }
> = (params, lookups = DEFAULT_SERVICE_ROOTS_LOOKUPS) =>
  apiClient
    .get<any>(SERVICE_ROOTS_ENDPOINT, {
      params: applyLookup<ServiceRootsGetParams>(params, lookups),
    })
    .then((res) => res.data)
    .then((data) => ({
      ...data,
      results: data.results.map(sanitizeServiceRoot),
    }));

export const getBaseServiceRoots: ApiQuery<ServiceRootsGetParams, BaseServiceRoot[]> = (
  params,
  lookups = DEFAULT_SERVICE_ROOTS_LOOKUPS,
) => getServiceRootsDao(params, lookups).then((res) => res.data.map((v) => sanitizeBaseServiceRoot(v)));

// Get by ID
export const getServiceRoot = (serviceRootId: DatabaseId) =>
  apiClient.get(pathParam(SERVICE_ROOTS_ENDPOINT, serviceRootId)).then((res) => sanitizeServiceRoot(res.data));

export const getBaseServiceRoot = (serviceRootId: DatabaseId) =>
  apiClient.get(pathParam(SERVICE_ROOTS_ENDPOINT, serviceRootId)).then((res) => sanitizeBaseServiceRoot(res.data));

export const getInterpretationRoots: ApiQuery<ServiceRootsGetParams, ServiceRoot<Interpretation>[]> = (
  params,
  lookups = DEFAULT_SERVICE_ROOTS_LOOKUPS,
) =>
  getServiceRootsDao(params, lookups).then((res) =>
    res.data.map(
      (v) =>
        sanitizeServiceRoot(v, { config: { serviceSanitizer: sanitizeInterpretation } }) as ServiceRoot<Interpretation>,
    ),
  );

// POST
export interface ServiceRootsPostPayload {
  name: string;
  description?: string;
  categories?: DatabaseId[];
}
export type ServiceRootsPostResponse = DatabaseId;
export type ServiceRootsPostError = Errors<ServiceRootsPostPayload>;
export const createServiceRoot = (payload: ServiceRootsPostPayload) =>
  apiClient
    .post<ServiceRootsPostResponse>(SERVICE_ROOTS_ENDPOINT, payload)
    .then((res) => res.data)
    .catch((err: AxiosError<ServiceRootsPostError>) => Promise.reject(err.response?.data));

// PUT
export type ServiceRootsPutPayload = ServiceRootsPostPayload;
export type ServiceRootsPutResponse = unknown;
export type ServiceRootsPutError = Errors<ServiceRootsPutPayload>;
export const updateServiceRoot = (serviceRootId: DatabaseId, data: ServiceRootsPutPayload) =>
  apiClient
    .put<ServiceRootsPutResponse>(pathParam(SERVICE_ROOTS_ENDPOINT, serviceRootId), data)
    .catch((err: AxiosError<ServiceRootsPutError>) => Promise.reject(err.response?.data));

// DELETE
export type ServiceRootsDeleteResponse = unknown;
export type ServiceRootsDeleteError = string;
export const deleteServiceRoot = (ServiceRootId: DatabaseId) =>
  apiClient
    .delete<ServiceRootsDeleteResponse>(pathParam(SERVICE_ROOTS_ENDPOINT, ServiceRootId))
    .catch((err: AxiosError<ServiceRootsDeleteError>) => Promise.reject(err.response?.data));
