import { Box, Tab, Tabs } from '@mui/material';
import { SetStateAction, useState } from 'react';
import './OnboardingPage.scss';
import { Col, Container, Row } from 'reactstrap';

import InterpretersTable from 'src/pages/OnboardingPage/Interpreters/Table';
import CompaniesTable from 'src/pages/OnboardingPage/Companies/Table';
import UsersTable from 'src/pages/OnboardingPage/Users/Table';
import CategoriesTable from 'src/pages/OnboardingPage/Categories/Table';

const AdminPanelBoxStyles = {
  padding: '25px 25px 0px 25px',
  borderBottom: 1,
  borderColor: 'divider',
};

export default function OnboardingPage() {
  const [currentTab, setCurrentTab] = useState('Interpreters');

  const handleTabChange = (_e: any, tab: SetStateAction<string>) => {
    setCurrentTab(tab);
  };

  return (
    <Container className="admin-panel mw-100 p-0">
      <Row>
        <Col>
          <Box sx={AdminPanelBoxStyles}>
            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab label="Interpreters" value="Interpreters" />
              <Tab label="Companies" value="Companies" />
              <Tab label="Categories" value="Categories" />
              <Tab label="Users" value="Users" />
            </Tabs>
          </Box>
        </Col>
      </Row>

      <Row>
        <Col>
          {currentTab === 'Interpreters' && <InterpretersTable />}
          {currentTab === 'Companies' && <CompaniesTable />}
          {currentTab === 'Categories' && <CategoriesTable />}
          {currentTab === 'Users' && <UsersTable />}
        </Col>
      </Row>
    </Container>
  );
}
