import { SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import MuiPagination from '@mui/material/Pagination';
import { TablePaginationProps } from '@mui/material/TablePagination';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridRowParams,
  GridValidRowModel,
  GridPagination,
} from '@mui/x-data-grid';
import moment from 'moment-timezone';
import ExportButton from 'src/app/excel-export/ExportButton';
import { useTranslation } from 'react-i18next';
import { BookingsPutMarked } from 'src/app/api';
import InvoicableButton from 'src/app/excel-export/InvoicableButton';

export interface FormTableProps {
  columns: GridColDef[];
  rows: readonly GridValidRowModel[];
  rowCount?: number;
  onRowClick?: (params: GridRowParams) => void;
  sx?: SxProps<Theme> | undefined;
  initialState?: object;
  paginationModel?: { pageSize: number; page: number };
  paginationMode?: 'client' | 'server';
  setPaginationModel?: (paginationModel: { pageSize: number; page: number }) => void;
  sortingMode?: 'client' | 'server';
  handleSortModelChange?: (sortModel: any) => void;
  loading?: boolean;
  exportable?: boolean;
  filterMode?: 'client' | 'server';
  handleFilterModelChange?: (filterModel: any) => void;
  updateEvents?: (data: any, events: any) => void;
  events?: BookingsPutMarked[];
  invoicable?: boolean;
}

let pagesRef: number;

function CustomToolbar({ exportable, invoicable, rowParams, events, updateEvents }: any) {
  const { t } = useTranslation(['viewers', 'booking']);

  const handleMarkAsInvoicedClick = () => {
    updateEvents(rowParams, events);
  };

  return (
    <GridToolbarContainer>
      {exportable ? <ExportButton /> : undefined}
      {rowParams.length !== 0 && invoicable ? (
        <InvoicableButton handleMarkAsInvoicedClick={handleMarkAsInvoicedClick} text={t`booking.markAsInvoiced`} />
      ) : undefined}
    </GridToolbarContainer>
  );
}

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const pages = pagesRef;

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pages}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props: FormTableProps) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export function FormTable(props: FormTableProps) {
  const {
    columns,
    rows,
    rowCount,
    onRowClick,
    initialState,
    paginationModel,
    paginationMode,
    setPaginationModel,
    sortingMode,
    handleSortModelChange,
    loading,
    sx,
    filterMode,
    handleFilterModelChange,
    exportable,
    updateEvents,
    events,
    invoicable,
  } = props;

  const actualDate = moment().format('YYYY-MM-DD');

  const [pages, setPages] = useState(1);
  useEffect(() => {
    if (rowCount !== undefined && paginationModel !== undefined) {
      setPages(Math.ceil(rowCount / paginationModel.pageSize));
    } else {
      setPages(1);
    }
  }, [rowCount, rows, paginationModel?.pageSize, paginationModel]);

  pagesRef = pages;

  const currentPath = window.location.pathname;
  const [rowParams, setRowParams] = useState<any[]>([]);

  return currentPath === '/admin' && events !== undefined ? (
    <DataGrid
      checkboxSelection
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowParams(newRowSelectionModel);
      }}
      rowSelectionModel={rowParams}
      rows={rows}
      columns={columns}
      density="compact"
      rowCount={rowCount}
      slots={{
        toolbar:
          events !== undefined
            ? () => CustomToolbar({ exportable, invoicable, rowParams, events, updateEvents })
            : undefined,
        pagination: CustomPagination,
      }}
      pageSizeOptions={[10, 25, 50, 75, 100]}
      paginationModel={paginationModel}
      paginationMode={paginationMode}
      onPaginationModelChange={setPaginationModel}
      sortingMode={sortingMode}
      loading={loading}
      onSortModelChange={handleSortModelChange}
      initialState={initialState}
      getRowClassName={(params) => {
        if (params.row.start_date === undefined) {
          return '';
        }
        return moment(params.row.start_date).format('YYYY-MM-DD') === actualDate ? 'today-row' : '';
      }}
      sx={{
        '.today-row': {
          backgroundColor: '#18a3b84d',
          '&:hover': {
            backgroundColor: '#18a3b866',
          },
        },
        '& .MuiDataGrid-columnHeaders': {
          textTransform: 'uppercase',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
          borderBottom: '3px solid',
          borderColor: 'primary.main',
          marginBottom: '-3px',
        },
        '& .MuiDataGrid-sortIcon': {
          color: 'primary.main',
        },
        '& .MuiDataGrid-menuIconButton': {
          color: 'primary.main',
        },
        '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
          'margin-top': '1em',
          'margin-bottom': '1em',
        },
        ...sx,
      }}
    />
  ) : (
    <DataGrid
      rows={rows}
      columns={columns}
      density="compact"
      onRowClick={onRowClick}
      rowCount={rowCount}
      slots={{
        pagination: CustomPagination,
      }}
      pageSizeOptions={[10, 25, 50, 75, 100]}
      paginationModel={paginationModel}
      paginationMode={paginationMode}
      onPaginationModelChange={setPaginationModel}
      sortingMode={sortingMode}
      filterMode={filterMode}
      onFilterModelChange={handleFilterModelChange}
      loading={loading}
      onSortModelChange={handleSortModelChange}
      initialState={initialState}
      getRowClassName={(params) => {
        if (params.row.start_date === undefined) {
          return '';
        }
        return moment(params.row.start_date).format('YYYY-MM-DD') === actualDate ? 'today-row' : '';
      }}
      sx={{
        '.today-row': {
          backgroundColor: '#18a3b84d',
          '&:hover': {
            backgroundColor: '#18a3b866',
          },
        },
        '& .MuiDataGrid-columnHeaders': {
          textTransform: 'uppercase',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
          borderBottom: '3px solid',
          borderColor: 'primary.main',
          marginBottom: '-3px',
        },
        '& .MuiDataGrid-sortIcon': {
          color: 'primary.main',
        },
        '& .MuiDataGrid-menuIconButton': {
          color: 'primary.main',
        },
        '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
          'margin-top': '1em',
          'margin-bottom': '1em',
        },
        ...sx,
      }}
    />
  );
}
