import { FormControl } from '@mui/material';
import { TimePicker, TimeValidationError } from '@mui/x-date-pickers';
import { PickerChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types';
import moment, { Moment } from 'moment-timezone';
import { PREFERRED_TIME_FORMAT_MOMENT } from 'src/app/helpers/constants';

export interface FormTimePickerProps {
  label: string;
  onChange: ((value: Moment | null, context: PickerChangeHandlerContext<TimeValidationError>) => void) | undefined;
  value: Moment | null;
}

export default function FormTimePicker({ label, onChange, value }: FormTimePickerProps) {
  return (
    <FormControl fullWidth sx={{ marginBottom: 2 }}>
      <TimePicker<Moment>
        label={label}
        onChange={onChange}
        value={value ? moment(value) : null}
        format={PREFERRED_TIME_FORMAT_MOMENT}
      />
    </FormControl>
  );
}
