import { useTranslation } from 'react-i18next';
import { Badge, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { StateUpdater } from 'src/app/types/Components';
import { useEffect, useState } from 'react';
import FormExpandableListField from '../../../../../components/Form/FormExpandableListField';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import { useForm } from '../../../../../app/hooks';
import { ServiceRateItem, ServiceRateState } from '../localTypes';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import { arrayRemoveAt, arrayReplaceAt } from '../../../../../app/helpers/manipulation';
import ServiceRateListItem from './Listitem';
import { getInterpretationRoots, MUTATION_KEYS } from '../../../../../app/api';
import { useCacheLanguages } from '../../../../../app/api/cache/hooks';

export interface ServiceRateFieldProps {
  formPrefix: string;
  showServiceRateField: boolean;
  setShowServiceRateField: (display: boolean) => void;
  initialState: ServiceRateState;
  stateUpdater: StateUpdater<ServiceRateState>;
}

const translationArray = ['onboardings'];

function ServiceRateField(props: ServiceRateFieldProps) {
  const { formPrefix, showServiceRateField, setShowServiceRateField, initialState, stateUpdater } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Queries
  const [serviceRootsRefetchEnabled, setServiceRootsRefetchEnabed] = useState<boolean>(true);
  const { data: serviceRoots, isLoading: areServiceRootsLoading } = useQuery(
    [MUTATION_KEYS.SERVICE_ROOTS],
    () => getInterpretationRoots({}),
    {
      enabled: serviceRootsRefetchEnabled,
    },
  );

  useEffect(() => {
    if (serviceRoots) {
      setServiceRootsRefetchEnabed(false);
    }
  }, [serviceRoots]);

  const languages = useCacheLanguages(true);

  // Form data
  const [serviceRates, setServiceRates] = useStateWithCallback(initialState.serviceRates, stateUpdater('serviceRates'));

  const pushNewServiceRate = () =>
    setServiceRates([
      ...serviceRates,
      {
        uuid: uuidv4(),
        serviceRoot: undefined,
        language: languages[0],
        hourRate: '45.00',
        noShowingFee: '25.00',
        minHours: 2,
        minMinutes: 15,
      },
    ]);

  const replaceServiceRateAt = (index: number, serviceRate: ServiceRateItem) =>
    setServiceRates(arrayReplaceAt(serviceRates, index, serviceRate));

  const removeServiceRateAt = (index: number) => {
    const serviceRate = serviceRates[index];
    if (serviceRate.sourceId !== undefined) {
      // Flag as deleted instead of removing
      replaceServiceRateAt(index, { ...serviceRate, deleted: true });
      return;
    }
    setServiceRates(arrayRemoveAt(serviceRates, index));
  };

  // Display
  const label = (
    <Badge
      showZero
      variant="counterBadge"
      color="primary"
      badgeContent={serviceRates.filter(({ deleted }) => !deleted).length}
      sx={{ marginBottom: 2 }}
    >
      <Typography variant="formLabel">{t`serviceRate`}</Typography>
    </Badge>
  );

  const onAdd = () => {
    pushNewServiceRate();
    setShowServiceRateField(true);
  };

  return (
    <FormExpandableListField
      showSeparator
      formPrefix={p`serviceRates`}
      isOpen={showServiceRateField}
      toggleOpen={setShowServiceRateField}
      onAdd={onAdd}
      addLabel={t`addServiceRate`}
      openButtonState={serviceRates.length === 0 ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showServiceRateField ? t`tooltip.hideServiceRates` : t`tooltip.viewServiceRates`}
      defaultDisplay={t`noServiceRates`}
      label={label}
      onCancel={() => {
        setShowServiceRateField(false);
      }}
    >
      {serviceRates.map((serviceRate, index) => {
        return (
          !serviceRate.deleted && (
            <ServiceRateListItem
              key={serviceRate.uuid}
              serviceRoots={serviceRoots}
              areServiceRootsLoading={areServiceRootsLoading}
              languages={languages}
              removeServiceRate={() => removeServiceRateAt(index)}
              initialState={serviceRate}
              stateUpdater={(key: any) => (value: any) => replaceServiceRateAt(index, { ...serviceRate, [key]: value })}
            />
          )
        );
      })}
    </FormExpandableListField>
  );
}

export default ServiceRateField;
