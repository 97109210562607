/* eslint-disable */
import { AxiosError } from 'axios';
import { DatabaseId, Errors } from '../types/DataStructures';
import { sanitizeBooking, sanitizeBookingInterpretation } from './config/sanitizers';
import { Booking, BookingInterpretation } from '../types/Entities';
import { ApiQuery, applyLookup, BUSINESS_NAME, businessParam, LookupsMap, pathParam } from './config/helpers';
import { apiClient } from './config/clients';
import { BOOKINGS_ENDPOINT } from './config/endpoints';
import { NotePayload } from './payloads';
import { NotificationBookingReminderTarget, RequesterType } from '../helpers/enum';
import { hydrateBooking, hydrateBookingInterpretation } from './config/hydrators';

export interface BookingsGetParams {
  id?: number;
  public_id?: string;
  injury_type?: string;
  date_of_injury?: string;
  events_count?: number;
  category?: string;
  parent?: BookingsGetParams;
  services?: string;
  _include_events?: boolean;
  _recipient_id?: number;
  _agents_id?: number;
  created_by?: number;
  group_booking?: boolean;
}
export const DEFAULT_BOOKING_LOOKUPS: LookupsMap<BookingsGetParams> = {
  injury_type: 'nem',
  date_of_injury: 'nem',
  public_id: 'nem',
};

export const getBookings: ApiQuery<BookingsGetParams, Booking[]> = (params, lookups = DEFAULT_BOOKING_LOOKUPS) =>
  apiClient
    .get<any[]>(businessParam(BOOKINGS_ENDPOINT), { params: applyLookup(params, lookups) })
    .then((res) => {
      const data: any = res.data;
      return Promise.all(data.map((v: any) => sanitizeBooking(v)));
    })
    .then((bookings) => Promise.all(bookings.map((v) => hydrateBooking(v))));

export const getPatientsBookings: ApiQuery<BookingsGetParams, Booking[]> = (
  params,
  lookups = DEFAULT_BOOKING_LOOKUPS,
) =>
  apiClient
    .get<any[]>(businessParam(BOOKINGS_ENDPOINT), { params: applyLookup(params, lookups) })
    .then((res) => {
      const data: any = res.data;
      return Promise.all(data.recipientFilteredData.map((v: any) => sanitizeBooking(v)));
    })
    .then((bookings) => Promise.all(bookings.map((v) => hydrateBooking(v))));

export const getAgentBookings: ApiQuery<BookingsGetParams, Booking[]> = (params, lookups = DEFAULT_BOOKING_LOOKUPS) =>
  apiClient
    .get<any[]>(businessParam(BOOKINGS_ENDPOINT), { params: applyLookup(params, lookups) })
    .then((res) => {
      const data: any = res.data;
      return Promise.all(data.agentsFilteredData.map((v: any) => sanitizeBooking(v)));
    })
    .then((bookings) => Promise.all(bookings.map((v) => hydrateBooking(v))));

export const getBookingDao = (bookingId: DatabaseId) => apiClient.get(pathParam(BOOKINGS_ENDPOINT, bookingId));

export const getBooking = (bookingId: DatabaseId): Promise<Booking> =>
  getBookingDao(bookingId)
    .then((res) => sanitizeBooking(res.data))
    .then(hydrateBooking);

export interface BookingsPostPayload {
  id?: DatabaseId;
  companies: DatabaseId[];
  operators: DatabaseId[];
  services: DatabaseId[];
  service_root?: DatabaseId;
  date_of_injury?: string;
  injury_type?: string;
  claim_number?: string;
  notes?: NotePayload[];
  target_language_alpha3?: string;
  requester_company_source?: RequesterType;
  requester?: DatabaseId;
  reminder_targets?: NotificationBookingReminderTarget;
  parent?: DatabaseId;
  children?: DatabaseId[];
  created_by?: DatabaseId;
  group_booking?: boolean;
}

export interface BookingsPutMarked {
  id: DatabaseId;
  booking: DatabaseId;
  affiliates: number[] | undefined;
  agents: number[] | undefined;
  requester: number;
  description: string;
  payer_company: number | null | undefined;
  payer: number | null | undefined;
  start_at: string;
  end_at: string;
  marked_as_invoiced: boolean;
}
[];
export type BookingsPostResponse = DatabaseId;
export type BookingsPostError = Errors<BookingsPostPayload>;
export const createBooking = (payload: BookingsPostPayload) =>
  apiClient
    .post<BookingsPostResponse>(businessParam(BOOKINGS_ENDPOINT), payload)
    .then((res) => res.data)
    .catch((err: AxiosError<BookingsPostError>) => Promise.reject(err.response?.data));

export type BookingsPutPayload = BookingsPostPayload;
export type BookingsPutResponse = unknown;
export type BookingsPutError = Errors<BookingsPutPayload>;
export const updateBooking = (bookingId: DatabaseId, payload: BookingsPutPayload) =>
  apiClient
    .put<BookingsPutResponse>(pathParam(BOOKINGS_ENDPOINT, bookingId), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<BookingsPutError>) => Promise.reject(err.response?.data));

export type BookingsDeleteResponse = unknown;
export type BookingsDeleteError = string;
export const deleteBooking = (bookingId: DatabaseId) =>
  apiClient
    .delete<BookingsDeleteResponse>(pathParam(BOOKINGS_ENDPOINT, bookingId))
    .catch((err: AxiosError<BookingsDeleteError>) => Promise.reject(err.response?.data));

// Booking interpretation
export const getBookingInterpretation = (bookingId: DatabaseId): Promise<BookingInterpretation> =>
  getBookingDao(bookingId)
    .then((res) => sanitizeBookingInterpretation(res.data))
    .then(hydrateBookingInterpretation);

export interface BookingInterpretationsPostPayload extends BookingsPostPayload {
  target_language_alpha3?: string;
}
export const createInterpretationBooking = (payload: BookingInterpretationsPostPayload) => createBooking(payload);

export type BookingInterpretationPutPayload = BookingInterpretationsPostPayload;
export const updateInterpretationBooking = (bookingId: DatabaseId, payload: BookingInterpretationPutPayload) =>
  updateBooking(bookingId, payload);
