import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import NotesListField from '../../../BookingsPage/Onboarding/Fields/NotesListField/NotesListField';
import { useForm } from '../../../../app/hooks';
import { InterpreterNotesState } from './localTypes';
import useStateWithCallback from '../../../../app/hooks/useStateWithCallback';
import { arrayRemoveAt, arrayReplaceAt } from '../../../../app/helpers/manipulation';
import { Note } from '../../../../app/types/Entities';

export interface InterpreterNotesFieldProps {
  formPrefix: string;
  showNotesField: boolean;
  setShowNotesField: (display: boolean) => void;
  initialState: InterpreterNotesState;
  stateUpdater: StateUpdater<InterpreterNotesState>;
}

const translationArray = ['onboardings'];

function InterpreterNotesField(props: InterpreterNotesFieldProps) {
  const { formPrefix, showNotesField, setShowNotesField, initialState, stateUpdater } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [notes, setNotes] = useStateWithCallback(initialState.notes, stateUpdater('notes'));

  const pushNote = (note: Note) => setNotes([...notes, note]);

  const replaceNoteAt = (index: number, note: Note) => setNotes(arrayReplaceAt(notes, index, note));

  const removeNoteAt = (index: number) => setNotes(arrayRemoveAt(notes, index));

  return (
    <NotesListField
      formPrefix={p`notes`}
      showNotesList={showNotesField}
      setShowNotesList={setShowNotesField}
      labelText={t`interpreterNotes`}
      notesList={notes}
      pushNote={pushNote}
      replaceNoteAt={replaceNoteAt}
      removeNoteAt={removeNoteAt}
    />
  );
}

export default InterpreterNotesField;
