import { FormGroup, Input, InputProps, Label } from 'reactstrap';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';

export interface FormCheckProps extends Omit<InputProps, 'type'> {
  id?: string;
  checked?: boolean | null;
  hidden?: boolean;
  label?: string;
  onChange?: (value: boolean) => void;
  isStandalone?: boolean;
}

export default function FormCheck({
  id,
  checked,
  hidden,
  label = '',
  onChange,
  isStandalone = true,
  ...props
}: FormCheckProps) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = checked === null;
    }
  }, [checked]);

  return !hidden ? (
    <FormGroup check className={classNames({ 'mb-3': isStandalone })}>
      <Label check>
        <Input
          id={id}
          innerRef={ref}
          type="checkbox"
          checked={checked ?? false}
          onChange={(e) => onChange?.(e.target.checked)}
          {...props}
        />{' '}
        {label}
      </Label>
    </FormGroup>
  ) : null;
}
