// noinspection JSUnusedGlobalSymbols

import { ValuesOf } from '../types/TypeMappers';

// Status
export const STATUS = {
  PENDING: 'pending',
  NO_CASE: 'no_case',
  NO_PAYER: 'no_payer',
  NO_INTERPRETER: 'no_interpreter',
  NO_FOLLOWUP: 'no_followup',
  NO_REPORT: 'no_report',
  DELIVERED: 'delivered',
  AUTHORIZED: 'authorized',
  OVERRIDE: 'override',
  BOOKED: 'booked',
};

// Agent Roles
export const AGENCY_AGENT_ROLES = {
  AGENCY_STAFF: 'agencyStaff',
  BILLING_DEPARTMENT: 'billingDepartment',
  CARE_COORDINATOR: 'careCoordinator',
  DISPATCH_DEPARTMENT: 'dispatchDepartment',
  OPERATOR: 'operator',
  SCHEDULING_SPECIALIST: 'schedulingSpecialist',
} as const;
export type AgencyAgentRole = ValuesOf<typeof AGENCY_AGENT_ROLES>;

export const CLINIC_AGENT_ROLES = {
  CLINIC_STAFF: 'clinicStaff',
  FRP_COORDINATOR: 'frpCoordinator',
  MEDICAL_ASSISTANT: 'medicalAssistant',
  MEDICAL_PROVIDER: 'medicalProvider',
  NURSE: 'nurse',
  OPERATOR: 'operator',
  SCHEDULING_SPECIALIST: 'schedulingSpecialist',
} as const;
export type ClinicAgentRole = ValuesOf<typeof CLINIC_AGENT_ROLES>;

export const INSURANCE_AGENT_ROLES = {
  ADJUSTER: 'adjuster',
  BILLING_DEPARTMENT: 'billingDepartment',
  INSURANCE_STAFF: 'insuranceStaff',
  SUPERVISOR_OF_THE_ADJUSTER: 'supervisorOfTheAdjuster',
  TEAM_MEMBER_OF_THE_ADJUSTER: 'teamMemberOfTheAdjuster',
} as const;
export type InsuranceAgentRole = ValuesOf<typeof INSURANCE_AGENT_ROLES>;

export const LAW_FIRM_AGENT_ROLES = {
  ASSISTANT_OF_THE_ATTORNEY: 'assistantOfTheAttorney',
  ATTORNEY: 'attorney',
  LAW_FIRM_STAFF: 'lawFirmStaff',
} as const;
export type LawFirmAgentRole = ValuesOf<typeof LAW_FIRM_AGENT_ROLES>;

export type AgentRole = AgencyAgentRole | ClinicAgentRole | InsuranceAgentRole;

// Business
export const AUTHORIZATION_STATUSES = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  REFERRED: 'REFERRED',
  OVERRIDE: 'OVERRIDE',
} as const;
export type AuthorizationStatus = ValuesOf<typeof AUTHORIZATION_STATUSES>;

export const BILL_RATE_TYPES = {
  FLAT: 'FLAT',
  PER_ASSIGNATION: 'PER_ASSIGNATION',
  PER_HOURS: 'PER_HOURS',
  PER_MINUTES: 'PER_MINUTES',
  QUANTITY: 'QUANTITY',
} as const;
export type BillRateType = ValuesOf<typeof BILL_RATE_TYPES>;

export const CATEGORY_PREFIXES = {
  APPOINTMENT: 'appointment-',
  CERTIFICATION: 'certification-',
  MODALITY: 'appointment-',
} as const;
export type CategoryPrefix = ValuesOf<typeof CATEGORY_PREFIXES>;

export const CASE_TYPES = {
  WORKERS_COMPENSATION: 'workersCompensation',
  COMMERCIAL_INSURANCE: 'commercialInsurance',
  PERSONAL_INJURY: 'personalInjury',
  LIEN: 'lien',
  OFFER_FROM_AGENCY: 'offerFromAgency',
  REFERRED_TO_AGENCY: 'referredToAgency',
} as const;
export type CaseType = ValuesOf<typeof CASE_TYPES>;

export const COMPANY_TYPES = {
  AGENCY: 'agency',
  CLINIC: 'clinic',
  EMPLOYER: 'employer',
  INSURANCE: 'insurance',
  LAWFIRM: 'lawfirm',
} as const;
export type CompanyType = ValuesOf<typeof COMPANY_TYPES>;

export const USER_COMPANY_ROLES = {
  AGENT: 'agent',
  OPERATOR: 'operator',
  PAYER: 'payer',
  PROVIDER: 'provider',
  RECIPIENT: 'recipient',
  REQUESTER: 'requester',
} as const;
export type UserCompanyRole = ValuesOf<typeof USER_COMPANY_ROLES>;

export const EVENT_TYPES = {
  ONLINE: 'online',
  ONSITE: 'onsite',
} as const;
export type EventType = ValuesOf<typeof EVENT_TYPES>;

export const LANGUAGE_ALPHA3 = {
  ENGLISH: 'eng',
  SPANISH: 'spa',
} as const;
export type LanguageAlpha3 = ValuesOf<typeof LANGUAGE_ALPHA3> | string;

export const PAYER_TYPES = {
  AGENCY: COMPANY_TYPES.AGENCY,
  CLINIC: COMPANY_TYPES.CLINIC,
  INSURANCE: COMPANY_TYPES.INSURANCE,
  LAWYER: COMPANY_TYPES.LAWFIRM,
  NO_PAYER: 'noPayer',
  PATIENT: 'patient',
} as const;
export type PayerType = ValuesOf<typeof PAYER_TYPES>;

export const SERVICE_TYPES = {
  INTERPRETATION: 'interpretation',
} as const;
export type ServiceType = ValuesOf<typeof SERVICE_TYPES>;

// Contact enums
export const SEND_METHODS = {
  EMAIL: 'email',
  FAX: 'fax',
  PHONE: 'phone',
} as const;
export type SendMethod = ValuesOf<typeof SEND_METHODS>;

export const EMAIL_CONTEXTS = {
  PERSONAL: 'personal',
  WORK: 'work',
} as const;
export type EmailContext = ValuesOf<typeof EMAIL_CONTEXTS>;

export const FAX_CONTEXTS = {
  PERSONAL: 'personal',
  WORK: 'work',
} as const;
export type FaxContext = ValuesOf<typeof FAX_CONTEXTS>;

export const PHONE_CONTEXTS = {
  PERSONAL: 'personal',
  WORK: 'work',
  MOBILE: 'mobile',
} as const;
export type PhoneContext = ValuesOf<typeof PHONE_CONTEXTS>;

export const CONTACT_CONTEXT = {
  ...EMAIL_CONTEXTS,
  ...FAX_CONTEXTS,
  ...PHONE_CONTEXTS,
} as const;
export type ContactContext = ValuesOf<typeof CONTACT_CONTEXT>;

// Notifications
export const NOTIFICATION_BOOKING_REMINDER_TARGETS = {
  ALL: 'all',
  CLINIC: 'clinic',
  INTERPRETER: 'interpreter',
};
export type NotificationBookingReminderTarget = ValuesOf<typeof NOTIFICATION_BOOKING_REMINDER_TARGETS>;

export const NOTIFICATION_STATUSES = {
  PENDING: 'PENDING',
  SENT: 'SENT',
};
export type NotificationStatus = ValuesOf<typeof NOTIFICATION_STATUSES>;

export const NOTIFICATION_SEND_METHODS = {
  EMAIL: 'EMAIL',
  FAX: 'FAX',
};
export type NotificationSendMethod = ValuesOf<typeof NOTIFICATION_SEND_METHODS>;

export const NOTIFICATION_TEMPLATES = {
  PLACEHOLDER: 'placeholder',
};
export type NotificationTemplate = ValuesOf<typeof NOTIFICATION_TEMPLATES>;

// Helpers
export const BUTTON_STATE = {
  INTERACTIVE: 'interactive',
  INVISIBLE: 'invisible',
  HIDDEN: 'hidden',
  DISABLED: 'disabled',
} as const;
export type ButtonState = ValuesOf<typeof BUTTON_STATE>;

// Location enums
export const US_STATES = {
  ALABAMA: 'AL',
  ALASKA: 'AK',
  ARIZONA: 'AZ',
  ARKANSAS: 'AR',
  CALIFORNIA: 'CA',
  COLORADO: 'CO',
  CONNECTICUT: 'CT',
  DELAWARE: 'DE',
  FLORIDA: 'FL',
  GEORGIA: 'GA',
  HAWAII: 'HI',
  IDAHO: 'ID',
  ILLINOIS: 'IL',
  INDIANA: 'IN',
  IOWA: 'IA',
  KANSAS: 'KS',
  KENTUCKY: 'KY',
  LOUISIANA: 'LA',
  MAINE: 'ME',
  MARYLAND: 'MD',
  MASSACHUSETTS: 'MA',
  MICHIGAN: 'MI',
  MINNESOTA: 'MN',
  MISSISSIPPI: 'MS',
  MISSOURI: 'MO',
  MONTANA: 'MT',
  NEBRASKA: 'NE',
  NEVADA: 'NV',
  NEW_HAMPSHIRE: 'NH',
  NEW_JERSEY: 'NJ',
  NEW_MEXICO: 'NM',
  NEW_YORK: 'NY',
  NORTH_CAROLINA: 'NC',
  NORTH_DAKOTA: 'ND',
  OHIO: 'OH',
  OKLAHOMA: 'OK',
  OREGON: 'OR',
  PENNSYLVANIA: 'PA',
  RHODE_ISLAND: 'RI',
  SOUTH_CAROLINA: 'SC',
  SOUTH_DAKOTA: 'SD',
  TENNESSEE: 'TN',
  TEXAS: 'TX',
  UTAH: 'UT',
  VERMONT: 'VT',
  VIRGINIA: 'VA',
  WASHINGTON: 'WA',
  WEST_VIRGINIA: 'WV',
  WISCONSIN: 'WI',
  WYOMING: 'WY',
} as const;
export type UsState = ValuesOf<typeof US_STATES>;

export const TITLES = {
  DR: 'Dr.',
  HONORABLE: 'Honorable',
  MR: 'Mr.',
  MS: 'Ms.',
} as const;
export type Titles = ValuesOf<typeof TITLES>;

export const TYPES_OF_APPOINTMENTS = {
  INITIAL_CONSULTATION: 'Initial Consultation',
  PSYCHOLOGY: 'Psychology',
  PSYCHIATRIC: 'Psychiatric',
  PHYSICAL_THERAPY: 'Physical Therapy',
  CHIROPRACTIC: 'Chiropractic',
  ACUPUNCTURE: 'Acupuncture',
  QME: 'QME',
  CBT: 'CBT',
  MDE: 'MDE',
  SPECIAL_PROGRAM: 'Special Program',
  PROCEDURES: 'Procedures',
} as const;

export type TypesOfAppointments = ValuesOf<typeof TYPES_OF_APPOINTMENTS>;

export const REQUESTER_TYPES = {
  AGENCY_STAFF: 'agency',
  CLINIC_STAFF: 'clinic',
  INSURANCE_STAFF: 'insurance',
  PATIENT: 'patient',
} as const;
export type RequesterType = ValuesOf<typeof REQUESTER_TYPES>;

export const OFFER_STATUS = {
  NOT_REQUESTED: 'NOT_REQUESTED',
  REQUESTED: 'REQUESTED',
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  PENDING_OFFER: 'PENDING_OFFER',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
} as const;
export type OfferStatus = ValuesOf<typeof OFFER_STATUS>;

export const DAYS_OF_WEEK = {
  SUNDAY: 'SUNDAY',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
} as const;
export type DayOfWeek = ValuesOf<typeof DAYS_OF_WEEK>;

export const REPORT_STATUS = {
  UNREPORTED: 'UNREPORTED',
  COMPLETED: 'COMPLETED',
  DOUBLE_BOOKING: 'DOUBLE_BOOKING',
  RESCHEDULED_DR_NO_SHOW: 'RESCHEDULED_DR_NO_SHOW',
  RESCHEDULED_NO_ENGAGE: 'RESCHEDULED_NO_ENGAGE',
  INCOMPLETE_PATIENT_NO_SHOW: 'INCOMPLETE_PATIENT_NO_SHOW',
  INCOMPLETE_PATIENT_CONNECTION_PROBLEM: 'INCOMPLETE_PATIENT_CONNECTION_PROBLEM',
  INCOMPLETE_INTERPRETER_CONNECTION_PROBLEM: 'INCOMPLETE_INTERPRETER_CONNECTION_PROBLEM',
  INCOMPLETE_PATIENT_DOESNT_NEED_INTERPRETATION: 'INCOMPLETE_PATIENT_DOESNT_NEED_INTERPRETATION',
  INCOMPLETE_OTHER_REASON: 'INCOMPLETE_OTHER_REASON',
  OTHER: 'OTHER',
} as const;
export type ReportStatus = ValuesOf<typeof REPORT_STATUS>;

export const SERVICE_AREA_DEFAULTS = {
  DEFAULT_COUNTRY: 'United States of America',
  DEFAULT_STATE: 'CA',
} as const;
export type ServiceAreaDefault = ValuesOf<typeof SERVICE_AREA_DEFAULTS>;

export const INSURANCE_AGENCY_RELATIONSHIP = {
  PREFERRED: 'Preferred',
  IN_NETWORK: 'In Network',
  OUT_OF_NETWORK: 'Out of Network',
} as const;
export type InsuranceAgencyRelatioship = ValuesOf<typeof INSURANCE_AGENCY_RELATIONSHIP>;

export const AUTHORIZATION_PRIORITY = {
  INSURANCE: 'Insurance',
  PREFERRED_AGENCY: 'Preferred Agency',
  IN_NETWORK_AGENCY: 'In Network Agency',
  OUT_OF_NETWORK_AGENCY: 'Out of Network Agency',
} as const;
export type AuthorizationPriority = ValuesOf<typeof AUTHORIZATION_PRIORITY>;

export const SEND_INVOICE_METHOD = {
  EMAIL: 'Email',
  FAX: 'Fax',
  USPS: 'USPS',
  CLIENT_PORTAL: 'Client Portal',
} as const;
export type SendInvoiceMethod = ValuesOf<typeof SEND_INVOICE_METHOD>;

export const INVOICE_FORMAT = {
  PDF: 'PDF',
  PAPER: 'Paper',
  EXCEL: 'Excel',
  MANUAL_ENTRY: 'Manual Entry',
} as const;
export type InvoiceFormat = ValuesOf<typeof INVOICE_FORMAT>;

export const INVOICE_FREQUENCY = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
} as const;
export type InvoiceFrequency = ValuesOf<typeof INVOICE_FREQUENCY>;

export const INVOICE_GROUPING = {
  INDIVIDUAL: 'Individual',
  BATCHED: 'Batched',
} as const;
export type InvoiceGrouping = ValuesOf<typeof INVOICE_GROUPING>;

export const INVOICE_SPECIAL_FIELD = {
  SIGNATURE: 'Signature',
  FOLLOW_UP: 'Follow Up',
} as const;
export type InvoiceSpecialField = ValuesOf<typeof INVOICE_SPECIAL_FIELD>;
