import { FetchStatus } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { BookingLight } from '../../../app/types/Entities';
import FormList from '../../Form/FormList';
import './SearchBarBookingResults.scss';
import { SearchBarBookingSingleResult } from './SearchBarBookingSingleResult';

export interface SearchbarBookingResultsProps {
  bookings?: BookingLight[] | undefined;
  fetchStatus: FetchStatus;
}

export default function SearchBarBookingResults({ bookings, fetchStatus }: SearchbarBookingResultsProps) {
  const { t } = useTranslation('searchbar');

  const renderBookingSingleResult = (booking: BookingLight, i: number) => (
    <SearchBarBookingSingleResult booking={booking} key={i} />
  );

  return (
    <section className="booking-results">
      {(bookings?.length ?? 0) > 0 ? (
        <FormList flush items={bookings ?? []} mapper={renderBookingSingleResult} />
      ) : fetchStatus === 'fetching' ? (
        <section className="message">{t`loading`}</section>
      ) : (
        <section className="message">{t`noResults`}</section>
      )}
    </section>
  );
}
