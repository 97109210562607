import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Agency } from '../../../../../../app/types/Entities';
import GenericCompanyField, { GenericCompanyFieldTranslationProps } from '../GenericCompanyField';
import { COMPANY_TYPES } from '../../../../../../app/helpers/enum';
import { getCompanies, MUTATION_KEYS } from '../../../../../../app/api';

export interface AgencyFieldProps {
  formPrefix: string;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  agency: Agency | null;
  setAgency: (agency: Agency | null) => void;
  agencyList: Agency[] | undefined;
  isLoading: boolean;
  refetchAgencies: () => Promise<{ data: Agency[] | undefined }>;
  onUpdateSuccess: (agency: Agency | null) => void;
}

export default function AgencyField(props: AgencyFieldProps) {
  const { formPrefix, isOpen, toggleOpen, agency, setAgency, agencyList, isLoading, refetchAgencies } = props;

  // Utils
  const { t } = useTranslation('booking');

  // Query
  const [parentCompaniesRefetchEnabled, setParentCompaniesRefetchEnabled] = useState<boolean>(true);
  const { data: parentCompanies, isLoading: isParentCompaniesLoading } = useQuery(
    [MUTATION_KEYS.COMPANIES, {}],
    () => getCompanies({}),
    {
      enabled: parentCompaniesRefetchEnabled,
    },
  );

  useEffect(() => {
    if (parentCompanies) {
      setParentCompaniesRefetchEnabled(false);
    }
  }, [parentCompanies]);

  const onUpdateSuccess = (company: Agency | undefined) => props.onUpdateSuccess(company ?? null);

  const translations: GenericCompanyFieldTranslationProps = {
    company: t`agency`,
    companyName: t`agencyName`,
    newCompany: t`newAgency`,
    selectCompany: t`selectAgency`,
    parentCompany: t`parentCompany`,
    saveCompany: t`saveAgency`,
    cancel: t`cancel`,
    requiredFieldsHelp: t`requiredFieldsHelp`,
    contactInformation: t`contactInformation`,
    companyNotes: t`agencyNotes`,
    errors: {
      noCompanyName: t`errors.noAgencyName`,
      noParentCompany: t`errors.noParentCompany`,
    },
    tooltip: {
      cancelAddCompany: t`tooltip.cancelAddAgency`,
      addCompany: t`tooltip.addAgency`,
      hideCompany: t`tooltip.hideAgency`,
      viewCompany: t`tooltip.viewAgency`,
    },
    progress: {
      companyCreate: {
        started: t`progress.agencyCreate.started`,
        success: t`progress.agencyCreate.success`,
        error: t`progress.agencyCreate.error`,
      },
      companyUpdate: {
        started: t`progress.agencyUpdate.started`,
        success: t`progress.agencyUpdate.success`,
        error: t`progress.agencyUpdate.error`,
      },
    },
  };

  return (
    <GenericCompanyField
      formPrefix={formPrefix}
      showCompanyField={isOpen}
      setShowCompanyField={toggleOpen}
      company={agency}
      setCompany={setAgency}
      companyType={COMPANY_TYPES.AGENCY}
      companies={agencyList}
      isCompanyLoading={isLoading}
      refetchCompanies={refetchAgencies}
      parentCompanies={parentCompanies}
      isParentCompaniesLoading={isParentCompaniesLoading}
      onUpdateSuccess={onUpdateSuccess}
      translations={translations}
    />
  );
}
