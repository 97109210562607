import { useTranslation } from 'react-i18next';
import { Badge, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { SERVICE_AREA_DEFAULTS } from 'src/app/helpers/enum';
import { StateUpdater } from 'src/app/types/Components';
import FormExpandableListField from '../../../../../components/Form/FormExpandableListField';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import { useForm } from '../../../../../app/hooks';
import { ServiceAreaItem, ServiceAreaState } from '../localTypes';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import { arrayRemoveAt, arrayReplaceAt } from '../../../../../app/helpers/manipulation';
import ServiceAreaListItem from './Listitem';

export interface ServiceAreaFieldProps {
  formPrefix: string;
  showServiceAreaField: boolean;
  setShowServiceAreaField: (display: boolean) => void;
  initialState: ServiceAreaState;
  stateUpdater: StateUpdater<ServiceAreaState>;
}

const translationArray = ['onboardings'];

function ServiceAreaField(props: ServiceAreaFieldProps) {
  const { formPrefix, showServiceAreaField, setShowServiceAreaField, initialState, stateUpdater } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [serviceAreas, setServiceAreas] = useStateWithCallback(initialState.serviceAreas, stateUpdater('serviceAreas'));

  const pushNewServiceArea = () =>
    setServiceAreas([
      ...serviceAreas,
      {
        uuid: uuidv4(),
        country: SERVICE_AREA_DEFAULTS.DEFAULT_COUNTRY,
        state: SERVICE_AREA_DEFAULTS.DEFAULT_STATE,
        county: '',
        city: '',
        zip: '',
      },
    ]);

  const replaceServiceAreaAt = (index: number, serviceArea: ServiceAreaItem) =>
    setServiceAreas(arrayReplaceAt(serviceAreas, index, serviceArea));

  const removeServiceAreaAt = (index: number) => {
    const serviceArea = serviceAreas[index];
    if (serviceArea.sourceId !== undefined) {
      replaceServiceAreaAt(index, { ...serviceArea, deleted: true });
      return;
    }
    setServiceAreas(arrayRemoveAt(serviceAreas, index));
  };

  // Display
  const label = (
    <Badge
      showZero
      variant="counterBadge"
      color="primary"
      badgeContent={serviceAreas.filter(({ deleted }) => !deleted).length}
      sx={{ marginBottom: 2 }}
    >
      <Typography variant="formLabel">{t`serviceArea`}</Typography>
    </Badge>
  );

  const onAdd = () => {
    pushNewServiceArea();
    setShowServiceAreaField(true);
  };

  return (
    <FormExpandableListField
      showSeparator
      formPrefix={p`serviceAreas`}
      isOpen={showServiceAreaField}
      toggleOpen={setShowServiceAreaField}
      onAdd={onAdd}
      addLabel={t`addServiceArea`}
      openButtonState={serviceAreas.length === 0 ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showServiceAreaField ? t`tooltip.hideServiceAreas` : t`tooltip.viewServiceAreas`}
      defaultDisplay={t`noServiceAreas`}
      label={label}
      onCancel={() => {
        setShowServiceAreaField(false);
      }}
    >
      {serviceAreas.map((serviceArea, index) => {
        return (
          !serviceArea.deleted && (
            <ServiceAreaListItem
              key={serviceArea.uuid}
              removeServiceArea={() => removeServiceAreaAt(index)}
              initialState={serviceArea}
              stateUpdater={(key: any) => (value: any) => replaceServiceAreaAt(index, { ...serviceArea, [key]: value })}
            />
          )
        );
      })}
    </FormExpandableListField>
  );
}

export default ServiceAreaField;
