import { Col, Row } from 'reactstrap';
import { IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import { CertificateItem } from '../localTypes';
import { Category } from '../../../../../app/types/Entities';
import FormSelect from '../../../../../components/Form/FormSelect/FormSelect';
import FormDatePicker from '../../../../../components/DatePicker/FormDatePicker';
import { PREFERRED_DATE_FORMAT_MOMENT } from '../../../../../app/helpers/constants';

export interface CertificationListItemProps {
  certificationOptions: Category[] | undefined;
  areCertificationsLoading: boolean;
  removeCertificate: () => void;
  initialState: CertificateItem;
  stateUpdater: StateUpdater<CertificateItem>;
  // TODO add errors
}

const translationArray = ['onboardings'];

export default function CertificationListItem(props: CertificationListItemProps) {
  const { certificationOptions, areCertificationsLoading, removeCertificate, initialState, stateUpdater } = props;

  const { t } = useTranslation(translationArray);

  const [certificateId, setCertificateId] = useStateWithCallback(
    initialState.certificateId,
    stateUpdater('certificateId'),
  );
  const [certificateNumber, setCertificateNumber] = useStateWithCallback(
    initialState.certificateNumber,
    stateUpdater('certificateNumber'),
  );
  const [expirationDate, setExpirationDate] = useStateWithCallback(
    initialState.expirationDate,
    stateUpdater('expirationDate'),
  );

  const certificate = certificationOptions?.find(({ id }) => id === certificateId);

  return (
    <Row>
      <Col>
        <FormSelect
          disableClearable
          loading={areCertificationsLoading}
          options={certificationOptions ?? []}
          value={certificate}
          onChange={(_, value) => setCertificateId(value.id)}
          getOptionLabel={({ description }) => description}
          renderInput={(inputProps) => (
            <TextField {...inputProps} label={t`certificate` as string} variant="standard" />
          )}
        />
      </Col>
      <Col>
        <TextField
          fullWidth
          variant="standard"
          label={t`certificateNumber` as string}
          value={certificateNumber}
          onChange={(e) => setCertificateNumber(e.target.value)}
        />
      </Col>
      <Col>
        <FormDatePicker
          disablePast
          label={t`expirationDate`}
          dateFormat={PREFERRED_DATE_FORMAT_MOMENT}
          value={expirationDate}
          onChange={(date) => setExpirationDate(date?.toDate())}
          slotProps={{ textField: { variant: 'standard' } }}
        />
      </Col>
      <Col xs="auto" className="flex-grow-0 align-self-center">
        <IconButton onClick={removeCertificate}>
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </Col>
    </Row>
  );
}
