import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Col, FormText, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { DEFAULT_COUNTRY_FOR_LOCATION } from 'src/app/helpers/constants';
import { ErrorsAlert } from 'src/components/ErrorsAlert';
import FormSelectCreatable from 'src/components/Form/FormSelectCreatable/FormSelectCreatable';
import { TextField } from '@mui/material';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { flattenContacts, unflattenContacts } from '../../../../../app/helpers/mappers';
import { FormInput } from '../../../../../components/Form/FormInput';
import FormWrapper from '../../../../../components/Form/FormWrapper';
import FlatContactList from '../FlatContactList/FlatContactList';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { useForm, useStateArray, useStateDict } from '../../../../../app/hooks';
import {
  CompaniesPostError,
  CompaniesPostPayload,
  CompaniesPostResponse,
  CompaniesPutError,
  CompaniesPutPayload,
  CompaniesPutResponse,
  createCompany as apiCreateCompany,
  updateCompany as apiUpdateCompany,
} from '../../../../../app/api';
import { Company, FlatContact, Location, Note, ParentCompany } from '../../../../../app/types/Entities';
import { BUTTON_STATE, CompanyType, SEND_METHODS } from '../../../../../app/helpers/enum';
import LocationSection from '../LocationSection/LocationSection';
import { DatabaseId, Errors } from '../../../../../app/types/DataStructures';
import NotesListField from '../NotesListField/NotesListField';

export interface GenericCompanyFieldTranslationProps {
  company: string;
  companyName: string;
  newCompany: string;
  selectCompany: string;
  parentCompany: string;
  saveCompany: string;
  cancel: string;
  requiredFieldsHelp: string;
  contactInformation: string;
  companyNotes: string;
  errors: {
    noCompanyName: string;
    noParentCompany: string;
  };
  tooltip: {
    cancelAddCompany: string;
    addCompany: string;
    hideCompany: string;
    viewCompany: string;
  };
  progress: {
    companyCreate: {
      started: string;
      success: string;
      error: string;
    };
    companyUpdate: {
      started: string;
      success: string;
      error: string;
    };
  };
}

export interface GenericCompanyFieldProps<T extends Company> {
  showCompanyField: boolean;
  setShowCompanyField: (display: boolean) => void;
  company: T | null;
  setCompany: (company: T | null) => void;
  onUpdateSuccess?: (company?: T) => void;

  // Generic usage props
  formPrefix: string;
  optional?: boolean;
  companyType: CompanyType;
  translations: GenericCompanyFieldTranslationProps;

  companies: T[] | undefined;
  isCompanyLoading: boolean;
  refetchCompanies: () => Promise<{ data: T[] | undefined }>;
  parentCompanies: ParentCompany[] | undefined;
  isParentCompaniesLoading: boolean;
}

function GenericCompanyField<T extends Company>(props: GenericCompanyFieldProps<T>) {
  const {
    showCompanyField,
    setShowCompanyField,
    company,
    setCompany,
    onUpdateSuccess,
    formPrefix,
    optional,
    companyType,
    translations,
    companies,
    isCompanyLoading,
    refetchCompanies,
    parentCompanies,
    isParentCompaniesLoading,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<CompaniesPutError>({});
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  useEffect(() => {
    if (!showCompanyField) {
      setShowNotes(false);
    }
  }, [showCompanyField]);

  const [companyName, setCompanyName] = useState<string | undefined>();
  const [parentCompany, setParentCompany] = useState<ParentCompany | null>(null);
  const [location, setLocation] = useState<Location | undefined>();
  const updateLocation = (fields: Partial<Location>) => setLocation((prev) => ({ ...prev, ...fields }));

  const {
    values: flatContacts,
    set: setFlatContacts,
    push: pushFlatContact,
    replaceAt: replaceFlatContactAt,
    removeAt: removeFlatContactAt,
  } = useStateArray<FlatContact>([]);

  const addNewFlatContact = () =>
    pushFlatContact({
      type: 'email',
      data: {},
      context: 'work',
      uuid: uuidv4(),
    });

  const {
    values: notes,
    set: setNotes,
    push: pushNote,
    replaceAt: replaceNoteAt,
    removeAt: stateRemoveNoteAt,
  } = useStateArray<Note>([]);

  const removeNoteAt = (index: number) => {
    if (notes.length <= 1) {
      setShowNotes(false);
    }
    stateRemoveNoteAt(index);
  };

  const prefillForm = () => {
    setCompanyName(company?.name);
    setParentCompany(company?.parent_company ?? null);
    setLocation(company?.locations?.[0]);
    setFlatContacts(flattenContacts(company?.contacts) ?? []);
    setNotes(company?.notes ?? []);
  };

  useEffect(() => {
    prefillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  // On saving
  const { mutateAsync: mutateAsyncCreateCompany } = useMutation<
    CompaniesPostResponse,
    CompaniesPostError,
    CompaniesPostPayload
  >(apiCreateCompany);

  const { mutateAsync: mutateAsyncUpdateCompany } = useMutation<
    CompaniesPutResponse,
    CompaniesPutError,
    Parameters<typeof apiUpdateCompany>
  >((args) => apiUpdateCompany(...args));

  // On save functions
  // validate
  const validate = () => {
    const validationErrors = [];

    if (createMode) {
      if (!companyName) {
        validationErrors.push(translations.errors.noCompanyName);
      }
      if (!parentCompany) {
        validationErrors.push(translations.errors.noParentCompany);
      }
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      return false;
    }
    return true;
  };

  // On create
  const createCompany = async () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? [
            {
              ...location,
              country: DEFAULT_COUNTRY_FOR_LOCATION,
            },
          ]
        : undefined;

    const payload: CompaniesPostPayload = {
      name: companyName!,
      parent_company: parentCompany?.id ?? null,
      on_hold: false,
      type: companyType,
      send_method: SEND_METHODS.EMAIL,
      contacts: unflattenContacts(flatContacts),
      locations: payloadLocation ?? [],
      notes,
    };

    // Display toast for the creation process and retain id
    let newCompanyId: DatabaseId | undefined;

    try {
      newCompanyId = await toast.promise(mutateAsyncCreateCompany(payload), {
        pending: translations.progress.companyCreate.started,
        success: translations.progress.companyCreate.success,
        error: translations.progress.companyCreate.error,
      });
    } catch (err: any) {
      setErrors(err as CompaniesPostError);
      console.error(`Errors when creating company "${companyName}"`, err);
      return;
    }

    setShowCompanyField(false);
    setCreateMode(false);

    if (!onUpdateSuccess) {
      return;
    }

    const { data: newCompanies } = await refetchCompanies();
    const newlyCreatedCompany = newCompanies?.find(({ id }) => id === newCompanyId);
    onUpdateSuccess(newlyCreatedCompany);
  };

  // On update
  const updateCompany = () => {
    setErrors({});

    if (!validate()) {
      return;
    }

    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? [
            {
              ...location,
              country: DEFAULT_COUNTRY_FOR_LOCATION,
            },
          ]
        : undefined;

    const payload: CompaniesPutPayload = {
      name: companyName,
      parent_company: parentCompany?.id,
      on_hold: company?.on_hold,
      type: company?.type,
      send_method: company?.send_method,
      contacts: unflattenContacts(flatContacts),
      locations: payloadLocation ?? [],
      notes,
    };

    toast
      .promise(mutateAsyncUpdateCompany([company?.id!, payload]), {
        pending: translations.progress.companyUpdate.started,
        success: translations.progress.companyUpdate.success,
        error: translations.progress.companyUpdate.error,
      })
      .catch((err) => {
        if (err) setErrors(err);
        return Promise.reject();
      })
      .then(() => refetchCompanies())
      .then((refetchData) => {
        const { data: newCompanies } = refetchData;
        const updatedCompany = newCompanies?.find((iterCompany) => iterCompany.id === company?.id);
        onUpdateSuccess?.(updatedCompany);
      });
  };

  const onSave = () => (createMode ? createCompany() : updateCompany());

  // Display
  const label = (
    <h5>
      {translations.company}
      {!optional && ' *'}
    </h5>
  );

  const display = (
    <FormSelectCreatable<any>
      fullWidth
      id={p`company`}
      options={companies ?? []}
      value={createMode ? null : company}
      loading={isCompanyLoading}
      disabled={createMode}
      clearOnBlur
      renderInput={(params) => (
        <TextField {...params} label={createMode ? translations.newCompany : translations.selectCompany} />
      )}
      getCreatedOptionLabel={() => translations.tooltip.addCompany}
      getSelectedOptionLabel={(companyOption) => companyOption.name}
      onCreateOption={(newValue) => {
        setShowCompanyField(!createMode);
        setCreateMode(!createMode);

        setCompanyName(newValue);
      }}
      onSelectOption={(option) => setCompany(option as T)}
      getOptionValue={(option) => option.id}
    />
  );

  const onCancel = () => {
    setShowCompanyField(false);
    setCreateMode(false);
    prefillForm();
  };

  const footer = (
    <>
      <Button className="action-button me-4" color="primary" onClick={onSave}>
        {translations.saveCompany}
      </Button>
      <Button className="action-button" color="cancel" onClick={onCancel}>
        {translations.cancel}
      </Button>
      <FormText color="required-fields-help">{translations.requiredFieldsHelp}</FormText>
    </>
  );

  const onAdd = () => {
    setCompany(null);
    setShowCompanyField(!createMode);
    setCreateMode(!createMode);
  };

  return (
    <FormExpandableField
      formPrefix={formPrefix}
      isOpen={showCompanyField}
      toggleOpen={setShowCompanyField}
      onAdd={onAdd}
      openButtonState={!company || createMode ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showCompanyField ? translations.tooltip.hideCompany : translations.tooltip.viewCompany}
      label={label}
      display={display}
      footer={footer}
      onCancel={onCancel}
    >
      <Row className="gx-2">
        <Col md={5}>
          <FormInput
            label={translations.companyName}
            value={companyName ?? ''}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Col>
        <Col>
          <FormSelect
            id={p`parentCompany`}
            options={parentCompanies ?? []}
            value={parentCompany ?? undefined}
            loading={isParentCompaniesLoading}
            onChange={(_event, parentCompanyOption) => setParentCompany(parentCompanyOption)}
            getOptionLabel={(parentCompanyOption) => parentCompanyOption.name}
            renderInput={(params) => <TextField placeholder={translations.parentCompany} {...params} />}
            disableClearable
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label="Address" className="pb-2">
            <LocationSection
              errors={errors.locations as Errors<Location> | undefined}
              location={location}
              update={updateLocation}
            />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label={translations.contactInformation}>
            <FlatContactList
              items={flatContacts}
              add={addNewFlatContact}
              removeAt={removeFlatContactAt}
              replaceAt={replaceFlatContactAt}
            />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <NotesListField
            formPrefix={p`company-notes`}
            labelText={translations.companyNotes}
            showNotesList={showNotes}
            setShowNotesList={setShowNotes}
            notesList={notes}
            pushNote={pushNote}
            replaceNoteAt={replaceNoteAt}
            removeNoteAt={removeNoteAt}
          />
        </Col>
      </Row>
      <ErrorsAlert errorsArray={errors.non_field_errors} />
      <ErrorsAlert errorsArray={errors.validation_errors} />
    </FormExpandableField>
  );
}

export default GenericCompanyField as typeof GenericCompanyField;
