import { useQuery } from '@tanstack/react-query';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { userToString } from 'src/app/helpers/mappers';
import { Logout } from '@mui/icons-material';
import TranslateIcon from '@mui/icons-material/Translate';
import { useEffect, useState } from 'react';
import { HELP_LINK } from 'src/app/helpers/constants';
import Logo from './core_logo.png';
import './AppHeader.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logoutStore, selectUser } from '../../app/stores/authSlice';
import { EventsGetParams, getUser, MUTATION_KEYS } from '../../app/api';
import AppSearchBar from '../AppSearchBar/AppSearchBar';

export interface AppHeaderProps {
  eventFilters: EventsGetParams | undefined;
}

const translationArray = ['menus', 'common'];

export default function AppHeader(props: AppHeaderProps) {
  const { eventFilters } = props;
  const { t } = useTranslation(translationArray);
  const { user_id } = useAppSelector(selectUser) ?? {};
  const { data: user, isLoading: isUserLoading } = useQuery(
    [MUTATION_KEYS.USERS, user_id],
    () => (user_id !== undefined ? getUser(user_id) : undefined),
    {
      enabled: user_id !== undefined,
    },
  );
  const bookingCalendarRoute = '/bookings/calendar/';

  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  }

  function useRoute() {
    return useRouteMatch([bookingCalendarRoute, '/bookings/list', '/onboarding', '/booking/initiate', '/admin']);
  }

  const routeMatch = useRoute();
  const [currentTab, setCurrentTab] = useState(routeMatch?.pattern?.path);

  useEffect(() => {
    switch (routeMatch?.pattern?.path) {
      case '/bookings/list':
        setCurrentTab(
          `/bookings/calendar/?start_at__gte=${eventFilters?.start_at}&end_at__lte=${eventFilters?.end_at}`,
        );
        break;

      case '/bookings/calendar/':
        setCurrentTab(
          `/bookings/calendar/?start_at__gte=${eventFilters?.start_at}&end_at__lte=${eventFilters?.end_at}`,
        );
        break;

      default:
        setCurrentTab(routeMatch?.pattern?.path);
    }
  }, [currentTab, eventFilters?.end_at, eventFilters?.start_at, routeMatch?.pattern?.path]);

  const dispatch = useAppDispatch();

  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement | null>(null);
  const userMenuOpen = user_id && !!userMenuAnchorEl;
  const openUserMenu = (event: any) => {
    if (user_id) {
      setUserMenuAnchorEl(event.currentTarget);
    }
  };
  const closeUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const handleLanguageSwitch = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <AppBar
      position="static"
      color="inherit"
      sx={{ boxShadow: '0 1px 0 0 #dfe3e8, 0 2px 4px 0 rgba(0, 0, 0, 0.02)', zIndex: 10 }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }} component="nav">
        <IconButton
          sx={{
            ':hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Link to="/home">
            <img src={Logo} alt="Logo" className="core-logo" />
          </Link>
        </IconButton>

        {user_id !== undefined && <AppSearchBar />}

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {user_id !== undefined && (
            <Tabs
              value={currentTab}
              sx={{
                color: '#6b7c93 ',
              }}
            >
              <Tab
                label={t`bookings`}
                value={`/bookings/calendar/?start_at__gte=${eventFilters?.start_at}&end_at__lte=${eventFilters?.end_at}`}
                to={`/bookings/calendar/?start_at__gte=${eventFilters?.start_at}&end_at__lte=${eventFilters?.end_at}`}
                component={Link}
              />
              <Tab label={t`onboarding`} value="/onboarding" to="/onboarding" component={Link} />
              <Tab label={t`admin`} value="/admin" to="/admin" component={Link} disabled={user?.is_admin === false} />
              <Tab label={t`help`} href={HELP_LINK} target="_blank" />
            </Tabs>
          )}
          <Box sx={{ display: 'flex' }} onClick={openUserMenu}>
            <Avatar sx={{ height: 23, width: 23 }}>
              <AccountCircleIcon />
            </Avatar>
            <Typography variant="body1" ml={1} color="#6b7c93" sx={{ display: { xs: 'none', lg: 'block' } }}>
              {user_id === undefined
                ? t`common:notLoggedIn`
                : isUserLoading
                ? t`common:loading`
                : user
                ? userToString(user)
                : t`common:errorLoading`}
            </Typography>
          </Box>
        </Box>
      </Toolbar>

      <Menu
        anchorEl={userMenuAnchorEl}
        id="account-menu"
        open={userMenuOpen}
        onClose={closeUserMenu}
        onClick={closeUserMenu}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => dispatch(logoutStore())}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t`common:logout`}
        </MenuItem>
        <MenuItem onClick={() => dispatch(() => handleLanguageSwitch(i18n.language === 'en' ? 'es' : 'en'))}>
          <TranslateIcon className="translate-icon">
            <Logout fontSize="small" />
          </TranslateIcon>
          {t`common:switchLanguage`}
        </MenuItem>
      </Menu>
    </AppBar>
  );
}
