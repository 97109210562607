import { AxiosError } from 'axios';
import { UsersGetParams } from './users';
import { Payer } from '../types/Entities';
import { DatabaseId, Errors } from '../types/DataStructures';
import { sanitizePayer } from './config/sanitizers';
import { CompaniesGetParams } from './companies';
import { apiClient } from './config/clients';
import { PAYERS_ENDPOINT } from './config/endpoints';
import { ApiQuery, applyLookup, LookupsMap } from './config/helpers';

export interface PayersGetParams extends UsersGetParams {
  companies?: CompaniesGetParams;
  method?: string;
}
export const DEFAULT_PAYERS_LOOKUPS: LookupsMap<PayersGetParams> = {
  method: 'nem',
};
export const getPayers: ApiQuery<PayersGetParams, Payer[]> = (params, lookups = DEFAULT_PAYERS_LOOKUPS) =>
  apiClient.get(PAYERS_ENDPOINT, { params: applyLookup(params, lookups) }).then((res) => res.data.map(sanitizePayer));

export interface PayersPostPayload {
  user: DatabaseId;
  companies: DatabaseId[];
  method: string;
}

export type PayersPostResponse = DatabaseId;
export type PayersPostError = Errors<PayersPostPayload>;
export const createPayer = (payload: PayersPostPayload) =>
  apiClient
    .post<PayersPostResponse>(PAYERS_ENDPOINT, payload)
    .then((res) => res.data)
    .catch((err: AxiosError<PayersPostError>) => Promise.reject(err.response?.data));
