import { Box, Tab, Tabs } from '@mui/material';
import { SetStateAction, useState } from 'react';
import './AdminPage.scss';
import { Col, Container, Row } from 'reactstrap';
import Invoicing from 'src/pages/AdminPage/Invoicing/Invoicing';
import ServiceRootsTable from 'src/pages/AdminPage/ServiceRoots/Table';
import { DatabaseId } from 'src/app/types/DataStructures';
import { useQuery } from '@tanstack/react-query';
import { getGlobalSetting } from 'src/app/api/global_settings';
import { MUTATION_KEYS } from 'src/app/api';
import { GlobalSettingsState } from './Financial/localTypes';
import FinancialView from './Financial/View';
import BookingUpload from './BookingUpload/BookingUpload';

const AdminPanelBoxStyles = {
  padding: '25px 25px 0px 25px',
  borderBottom: 1,
  borderColor: 'divider',
};

interface AdminProps {
  user: any;
}

export default function AdminPage({ user }: AdminProps) {
  const [currentTab, setCurrentTab] = useState('Services');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleTabChange = (_e: any, tab: SetStateAction<string>) => {
    setCurrentTab(tab);
  };

  // State
  const defaultState: GlobalSettingsState & { id?: DatabaseId } = {
    id: undefined,
    client: '',
    business: undefined,
    rates: [],
  };

  const { data: globalSetting } = useQuery([MUTATION_KEYS.GLOBAL_SETTINGS], () => getGlobalSetting('1'));

  return (
    <Container className="admin-panel mw-100 p-0">
      <Row>
        <Col>
          <Box sx={AdminPanelBoxStyles}>
            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab disabled label="General" value="General" />
              <Tab label="Services" value="Services" />
              <Tab label="Financial" value="Financial" />
              <Tab label="Invoicing" value="Invoicing" />
              <Tab label="Booking Upload" value="BookingUpload" />
            </Tabs>
          </Box>
        </Col>
      </Row>

      <Row>
        <Col>
          {/* {currentTab === 'General' && <span>Work in progress</span>} */}
          {currentTab === 'Invoicing' && <Invoicing invoicable exportable />}
          {currentTab === 'Services' && <ServiceRootsTable />}
          {currentTab === 'Financial' && (
            <FinancialView
              defaultState={defaultState}
              preloadGlobalSetting={globalSetting ?? undefined}
              preSubmit={() => {
                setIsSubmitting(true);
                return true;
              }}
              onSubmitSuccess={() => {}}
              postSubmit={() => {
                setIsSubmitting(false);
              }}
              isSubmitting={isSubmitting}
            />
          )}
          {currentTab === 'BookingUpload' && <BookingUpload user={user} />}
        </Col>
      </Row>
    </Container>
  );
}
