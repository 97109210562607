import { AlertTitle, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GlobalSettingsPostErrorComplex,
  GlobalSettingsPostPayloadComplex,
  GlobalSettingsPostResponseComplex,
  GlobalSettingsPutErrorComplex,
  GlobalSettingsPutPayloadComplex,
  GlobalSettingsPutResponseComplex,
  MUTATION_KEYS,
  createGlobalSettingComplex,
  getInterpretationRoots,
  updateGlobalSettingComplex,
} from 'src/app/api';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm, useStateDict } from 'src/app/hooks';
import { FormSubmitEvent, FormSubmitProps, StateUpdater } from 'src/app/types/Components';
import useStateWithCallback from 'src/app/hooks/useStateWithCallback';
import { Alert, Button, Form, FormProps, Row, Spinner } from 'reactstrap';
import CompanyRateField from 'src/pages/OnboardingPage/Companies/Onboarding/RateField/RateField';
import { DatabaseId, Errors } from 'src/app/types/DataStructures';
import { GlobalSettingsPostError } from 'src/app/api/global_settings';
import { toast } from 'react-toastify';
import currency from 'currency.js';
import { SERVICE_BILL_RATE, SERVICE_BILL_RATE_TYPE } from 'src/app/helpers/constants';
import { GlobalSetting } from 'src/app/types/Entities';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import { RatesPostPayload, RatesPutPayload } from 'src/app/api/rates';
import { FinancialState, GlobalSettingsState, RateItem } from './localTypes';

export interface FinancialViewProps extends FormProps, FormSubmitProps<unknown, unknown, unknown> {
  defaultState: GlobalSettingsState & { id?: DatabaseId };
  preloadGlobalSetting?: GlobalSetting;
  isSubmitting?: boolean;
}

const formPrefix = 'financial';
const translationArray = ['onboardings'];

export default function FinancialView({
  defaultState,
  preloadGlobalSetting,
  isSubmitting,
  preSubmit,
  onSubmitSuccess,
  onSubmitFailure,
  postSubmit,
}: FinancialViewProps) {
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Data
  const [errors, setErrors] = useStateDict<GlobalSettingsPostError>({});

  // Queries
  const { data: serviceRoots, isLoading: areServiceRootsLoading } = useQuery([MUTATION_KEYS.SERVICE_ROOTS], () =>
    getInterpretationRoots({}),
  );

  const formState = useRef<GlobalSettingsState>(defaultState);
  const formStateUpdater: StateUpdater<GlobalSettingsState> =
    <K extends keyof GlobalSettingsState>(key: K) =>
    (value: GlobalSettingsState[K]) => {
      formState.current[key] = value;
    };

  const [initialState, setInitialState] = useStateWithCallback(defaultState, (state) => {
    formState.current = state;
  });

  const resetState = () => {
    if (preloadGlobalSetting === undefined) {
      setInitialState(defaultState);
      return;
    }

    const rates = preloadGlobalSetting.rates ?? [];
    const preloadedRates: RateItem[] = rates.map((rate) => ({
      sourceId: rate.id,
      uuid: uuidv4(),
      language: rate.language,
      serviceRoot: rate.root,
      hourRate: rate.bill_amount,
      minHours: Number(currency(rate.bill_min_payment).divide(rate.bill_amount).toString()),
      minMinutes: rate.bill_rate_minutes_threshold ?? 0,
      noShowingFee: rate.bill_no_show_fee,
    }));

    const state: GlobalSettingsState & { id?: DatabaseId } = {
      ...defaultState,
      id: preloadGlobalSetting.id,
      client: preloadGlobalSetting.client,
      business: preloadGlobalSetting.business,
      rates: preloadedRates ?? [],
    };

    setInitialState(state);
  };

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadGlobalSetting]);

  // Fields Display

  const [showCompanyRateField, setShowCompanyRateField] = useState<boolean>(false);

  // Mutations
  const { mutateAsync: mutateAsyncCreateGlobalSettingComplex } = useMutation<
    GlobalSettingsPostResponseComplex,
    GlobalSettingsPostErrorComplex,
    GlobalSettingsPostPayloadComplex
  >(createGlobalSettingComplex);

  const { mutateAsync: mutateAsyncUpdateGlobalSettingComplex } = useMutation<
    GlobalSettingsPutResponseComplex,
    GlobalSettingsPutErrorComplex,
    Parameters<typeof updateGlobalSettingComplex>
  >((args) => updateGlobalSettingComplex(...args));

  // Validation
  const getRatesErrors = (rates: RateItem[]): Errors<RateItem>[] | null => {
    let hasErrors = false;

    const rateErrors: Errors<RateItem>[] = rates?.map((rate) => {
      const rateItemErrors: Errors<RateItem> = {};

      if (!rate.language) {
        rateItemErrors.language = t`errors.required`;
      }
      if (!rate.serviceRoot) {
        rateItemErrors.serviceRoot = t`errors.required`;
      }
      if (!rate.hourRate) {
        rateItemErrors.hourRate = t`errors.required`;
      }
      if (rate.minHours === '') {
        rateItemErrors.minHours = t`errors.required`;
      }
      if (rate.minMinutes === '') {
        rateItemErrors.minMinutes = t`errors.required`;
      }
      if (!rate.noShowingFee) {
        rateItemErrors.noShowingFee = t`errors.required`;
      }

      if (!isEmpty(rateItemErrors)) {
        hasErrors = true;
      }

      return rateItemErrors;
    });

    return hasErrors ? rateErrors : null;
  };

  const validate = (formData: GlobalSettingsState) => {
    const newErrors: Errors<GlobalSettingsState> = {};
    const validationErrors: string[] = [];

    // Company rates
    const rateErrors = getRatesErrors(formData.rates);

    if (rateErrors !== null) {
      newErrors.rates = rateErrors;
    }

    // Wrap up
    if (validationErrors.length !== 0) {
      newErrors.validation_errors = validationErrors;
    }

    setErrors(newErrors);
    return isEmpty(newErrors);
  };

  // Create
  const submitForCreation = (event: FormSubmitEvent) => {
    event.preventDefault();

    const formData = formState.current;

    if (preloadGlobalSetting?.client !== undefined) {
      const msg = 'Tried creating a new company when an ID was provided, please contact a dev';
      toast.error(msg);
      return;
    }

    const companyRatesPayload: RatesPostPayload[] = formData.rates!.map((companyRate) => ({
      bill_amount: companyRate.hourRate,
      bill_min_payment: currency(companyRate.hourRate).multiply(companyRate.minHours).toString(),
      bill_no_show_fee: companyRate.noShowingFee,
      bill_rate: SERVICE_BILL_RATE,
      bill_rate_type: SERVICE_BILL_RATE_TYPE,
      bill_rate_minutes_threshold: companyRate.minMinutes || 0,
      root: companyRate.serviceRoot!.id,
      language: companyRate.language,
      _deleted: companyRate.deleted,
    }));

    const payload: GlobalSettingsPostPayloadComplex = {
      client: '1',
      business: 1,
      _rates_datalist: companyRatesPayload,
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    // Display toast for the creation process
    toast
      .promise(mutateAsyncCreateGlobalSettingComplex(payload), {
        pending: t`progress.globalSetting.started` as string,
        success: t`progress.globalSetting.success` as string,
        error: t`progress.globalSetting.error` as string,
      })
      .catch((err: GlobalSettingsPostErrorComplex) => {
        console.error(`Errors when creating company "${formState.current.client}"`, err);
        setErrors(err);
        onSubmitFailure?.(err, payload);
        return Promise.reject(); // Stop promise chain execution
      })
      .then((global_setting_id) => onSubmitSuccess?.(global_setting_id, payload))
      .finally(postSubmit);
  };

  // Update
  const submitForUpdate = (event: FormSubmitEvent) => {
    event.preventDefault();

    const formData = formState.current;

    if (!preloadGlobalSetting || preloadGlobalSetting.client === undefined) {
      const msg = 'Tried updating an company but no ID was provided, please contact a dev';
      console.error(msg);
      toast.error(msg);
      return;
    }

    if (!validate(formData)) {
      return;
    }

    const ratesPayload: RatesPutPayload[] = formData.rates!.map((rate) => ({
      id: rate.sourceId,
      bill_amount: rate.hourRate,
      bill_min_payment: currency(rate.hourRate).multiply(rate.minHours).toString(),
      bill_no_show_fee: rate.noShowingFee,
      bill_rate: SERVICE_BILL_RATE,
      bill_rate_type: SERVICE_BILL_RATE_TYPE,
      bill_rate_minutes_threshold: rate.minMinutes || 0,
      root: rate.serviceRoot!.id,
      language: rate.language,
      _deleted: rate.deleted,
    }));

    const payload: GlobalSettingsPutPayloadComplex = {
      client: formData.client,
      business: formData.business,
      _rates_datalist: ratesPayload,
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    // Display toast for the update process
    toast
      .promise(mutateAsyncUpdateGlobalSettingComplex([preloadGlobalSetting.client, payload]), {
        pending: t`progress.globalSettingUpdate.started` as string,
        success: t`progress.globalSettingUpdate.success` as string,
        error: t`progress.globalSettingUpdate.error` as string,
      })
      .catch((err: GlobalSettingsPostErrorComplex) => {
        console.error(`Errors when updating global setting "${preloadGlobalSetting.client}"`, err);
        setErrors(err);
        onSubmitFailure?.(err, payload);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => onSubmitSuccess?.(preloadGlobalSetting, payload))
      .finally(postSubmit);
  };

  const onSubmit = (event: FormSubmitEvent) =>
    preloadGlobalSetting === undefined ? submitForCreation(event) : submitForUpdate(event);

  return (
    <Form id="financial_view" key={initialState.id} onSubmit={onSubmit}>
      <Row>
        {!isEmpty(errors) ? (
          <Alert severity="error">
            <AlertTitle>
              There's errors{' '}
              <Button variant="text" onClick={() => setErrors({})}>
                Clear
              </Button>
            </AlertTitle>
            <ul>
              {Object.entries(errors).map(([k, v]) => (
                <li key={k}>
                  {k}: {JSON.stringify(v)}
                </li>
              ))}
            </ul>
          </Alert>
        ) : null}
      </Row>
      <Box className="w-100 h-100 mw-100 p-0 container" sx={{ display: 'flex' }}>
        <Box
          component="main"
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginLeft: 0,
            minHeight: 0,
            minWidth: 0,
            padding: 2,
          }}
        >
          <CompanyRateField
            formPrefix={p`companyRate`}
            showCompanyRateField={showCompanyRateField}
            setShowCompanyRateField={setShowCompanyRateField}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<FinancialState>}
            serviceRoots={serviceRoots}
            areServiceRootsLoading={areServiceRootsLoading}
            showAllLanguages={false}
          />
          <Button className="action-button" color="submit" form="financial_view">
            {isSubmitting === true ? <Spinner className="me-2" size="sm" type="border" color="primary" /> : t`save`}
          </Button>
        </Box>
      </Box>
    </Form>
  );
}
