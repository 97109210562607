import { PropsWithChildren } from 'react';
import './FormWrapper.scss';
import classNames from 'classnames';

export interface FormWrapperProps extends PropsWithChildren {
  className?: string;
  label: string;
}

export default function FormWrapper({ className = '', label, children }: FormWrapperProps) {
  return (
    <section className={classNames('form-wrapper', { [className]: !!className })}>
      <span className="form-wrapper-label">{label}</span>
      {children}
    </section>
  );
}
