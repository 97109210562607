import { AxiosError } from 'axios';
import { DEFAULT_USERS_LOOKUPS, UsersGetParams } from './users';
import { Agent } from '../types/Entities';
import { DatabaseId, Errors } from '../types/DataStructures';
import { sanitizeAgentWithCompanies } from './config/sanitizers';
import { apiClient } from './config/clients';
import { AGENTS_ENDPOINT } from './config/endpoints';
import { LookupsMap, ApiQuery, applyLookup, pathParam, BUSINESS_NAME, businessParam } from './config/helpers';

export interface AgentsGetParams extends UsersGetParams {
  role?: string;
}
export const DEFAULT_AGENTS_LOOKUPS: LookupsMap<AgentsGetParams> = DEFAULT_USERS_LOOKUPS;
export const getAgents: ApiQuery<AgentsGetParams, Agent[]> = (params, lookups = DEFAULT_AGENTS_LOOKUPS) =>
  apiClient
    .get<Agent[]>(AGENTS_ENDPOINT, { params: applyLookup(params, lookups) })
    .then((res) => res.data.map(sanitizeAgentWithCompanies));

export const getAgent = (id: DatabaseId) =>
  apiClient.get(pathParam(AGENTS_ENDPOINT, id)).then((res) => sanitizeAgentWithCompanies(res.data));
export interface AgentsPostPayload {
  user: DatabaseId;
  companies: DatabaseId[];
  role: string;
}
export type AgentsPostResponse = DatabaseId;
export type AgentsPostError = Errors<AgentsPostPayload>;
export const createAgent = (payload: AgentsPostPayload) =>
  apiClient
    .post<{ user_id: DatabaseId }>(businessParam(AGENTS_ENDPOINT), { ...payload, _business: BUSINESS_NAME })
    .then<AgentsPostResponse>((res) => res.data.user_id)
    .catch((err: AxiosError<AgentsPostError>) => Promise.reject(err.response?.data));

// PUT
export type AgentsPutPayload = AgentsPostPayload;
export type AgentsPutResponse = unknown;
export type AgentsPutError = Errors<AgentsPutPayload>;
export const updateAgent = (agentId: DatabaseId, payload: AgentsPutPayload) =>
  apiClient
    .put<AgentsPutResponse>(pathParam(AGENTS_ENDPOINT, agentId), {
      ...payload,
    })
    .catch((err: AxiosError<AgentsPutError>) => Promise.reject(err.response?.data));
