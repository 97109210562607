export const SUFFIXES = [
  'II',
  'III',
  'IV',
  'AMFT',
  'APN',
  'BSN',
  'CFMP',
  'CHP',
  'CMA',
  'CNA',
  'CO',
  'COTA',
  'CPT',
  'DACM',
  'DC',
  'DDS',
  'DO',
  'DPM',
  'DPT',
  'Esq.',
  'FNP',
  'FNP-BC',
  'FNP-C',
  'J.D.',
  'Jr.',
  'LAC',
  'LCP',
  'LCSW',
  'LMFT',
  'LPN',
  'LPCC',
  'LPTA',
  'MD',
  'MSN',
  'NA',
  'NP',
  'OD',
  'OT',
  'OTR/L',
  'PA',
  'PA-C',
  'PhD',
  'PsyD',
  'PT',
  'PTA',
  'QME',
  'RN',
  'RP',
  'RRT',
  'Sr.',
] as const;
export type Suffixes = (typeof SUFFIXES)[number];
