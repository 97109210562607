import { Col, Row } from 'reactstrap';
import { Box, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import FormSelect from '../../../../../components/Form/FormSelect/FormSelect';
import { ServiceRoot } from '../../../../../app/types/Entities';
import { CompanyRateItem } from '../localTypes';

export interface CompanyRateListItemProps {
  serviceRoots: ServiceRoot[] | undefined;
  areServiceRootsLoading: boolean;
  removeServiceRate: () => void;
  languages: { id: string; name: string }[] | undefined;
  initialState: CompanyRateItem;
  stateUpdater: StateUpdater<CompanyRateItem>;
  // TODO add errors
}

const translationArray = ['onboardings'];

export default function CompanyRateListItem(props: CompanyRateListItemProps) {
  const { initialState, stateUpdater, serviceRoots, areServiceRootsLoading, languages, removeServiceRate } = props;

  const { t } = useTranslation(translationArray);

  const [serviceRoot, setServiceRoot] = useStateWithCallback(
    initialState.serviceRoot,
    stateUpdater('serviceRoot'),
    true,
  );
  const [language, setLanguage] = useStateWithCallback(initialState.language, stateUpdater('language'), true);
  const [hourRate, setHourRate] = useStateWithCallback(initialState.hourRate, stateUpdater('hourRate'), true);
  const [noShowingFee, setNoShowingFee] = useStateWithCallback(
    initialState.noShowingFee,
    stateUpdater('noShowingFee'),
    true,
  );
  const [minHours, setMinHours] = useStateWithCallback(initialState.minHours, stateUpdater('minHours'), true);
  const [minMinutes, setMinMinutes] = useStateWithCallback(initialState.minMinutes, stateUpdater('minMinutes'), true);

  return (
    <Row>
      <Col>
        <Row>
          <Col xl={6}>
            <FormSelect
              disableClearable
              options={serviceRoots ?? []}
              value={serviceRoot}
              loading={areServiceRootsLoading}
              onChange={(_, selectedRoot) => setServiceRoot(selectedRoot)}
              getOptionLabel={({ name }) => name}
              renderOption={(renderOptionProps, option) => (
                <Box component="li" key={option.id} {...renderOptionProps}>
                  {option.name}
                </Box>
              )}
              renderInput={(inputProps) => (
                <TextField {...inputProps} variant="standard" label={t`serviceRoot` as string} />
              )}
            />
          </Col>
          <Col xl={6}>
            <FormSelect
              disableClearable
              options={languages?.map((selectedLanguage) => selectedLanguage.name) ?? []}
              value={language}
              onChange={(_, selectedLanguage) => {
                setLanguage(selectedLanguage);
              }}
              renderOption={(renderOptionProps, option) => (
                <Box component="li" key={option} {...renderOptionProps}>
                  {option}
                </Box>
              )}
              renderInput={(inputProps) => (
                <TextField {...inputProps} variant="standard" label={t`language` as string} />
              )}
              getOptionLabel={(selectedLanguage) => selectedLanguage}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={3}>
            <TextField
              fullWidth
              variant="standard"
              label={t`hourRate` as string}
              value={hourRate}
              onChange={(e) => setHourRate(e.target.value)}
              InputProps={{ startAdornment: '$' }}
              sx={{ marginBottom: 2 }}
            />
          </Col>
          <Col xl={3}>
            <TextField
              fullWidth
              variant="standard"
              label={t`noShowingFee` as string}
              value={noShowingFee}
              onChange={(e) => setNoShowingFee(e.target.value)}
              InputProps={{ startAdornment: '$' }}
              sx={{ marginBottom: 2 }}
            />
          </Col>
          <Col xl={3}>
            <TextField
              fullWidth
              variant="standard"
              label={t`minHours` as string}
              value={minHours}
              onChange={(e) => setMinHours(Number(e.target.value))}
              sx={{ marginBottom: 2 }}
            />
          </Col>
          <Col xl={3}>
            <TextField
              fullWidth
              variant="standard"
              label={t`minMinutes` as string}
              value={minMinutes}
              onChange={(e) => {
                const value = Number(e.target.value);
                if (!Number.isNaN(value)) {
                  setMinMinutes(e.target.value === '' ? '' : value);
                }
              }}
              onBlur={(e) => {
                if (e.target.value === '') {
                  setMinMinutes(0);
                }
              }}
              InputProps={{ inputProps: { maxLength: 2 } }}
              sx={{ marginBottom: 2 }}
            />
          </Col>
        </Row>
      </Col>
      <Col xs="auto" className="flex-grow-0 align-self-center">
        <IconButton onClick={removeServiceRate}>
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </Col>
    </Row>
  );
}
