/* eslint-disable import/no-cycle */
import jwt_decode from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthTokens, AuthUser } from '../types/Auth';
import { getAuthTokens } from '../helpers/cookies';
import { updateClients } from '../api/config/clients';

// State
export interface AuthState {
  user?: AuthUser;
  authTokens?: AuthTokens;
}

function loadState(): AuthState {
  const state: AuthState = {};
  const authTokens = getAuthTokens();
  if (authTokens) {
    state.authTokens = authTokens;
    state.user = jwt_decode(authTokens!.access);
  }
  updateClients(authTokens);
  return state;
}

export const selectUser = (state: any) => state.auth.user;
export const selectAuthTokens = (state: any) => state.auth.authTokens;

// Payloads
export interface LoginPayload {
  rawTokens: AuthTokens;
  rememberme: boolean;
}

export type RefreshPayload = AuthTokens;

// Thunks and slice
export const authSlice = createSlice({
  name: 'auth',
  initialState: loadState(),
  reducers: {
    loginStore: (state, action: PayloadAction<LoginPayload>) => {
      const { rawTokens, rememberme } = action.payload;
      const storage = rememberme ? localStorage : sessionStorage;
      storage.setItem('authTokens', JSON.stringify(rawTokens));
      state.authTokens = rawTokens;
      state.user = jwt_decode(rawTokens.access);
      updateClients(rawTokens);
    },
    logoutStore: (state) => {
      state.authTokens = undefined;
      state.user = undefined;
      sessionStorage.removeItem('authTokens');
      localStorage.removeItem('authTokens');
      updateClients();
    },
    refreshStore: (state, action: PayloadAction<RefreshPayload>) => {
      const tokens = action.payload;
      state.authTokens = tokens;
      state.user = jwt_decode(tokens.access);
      const storage = localStorage.getItem('authTokens') ? localStorage : sessionStorage;
      storage.setItem('authTokens', JSON.stringify(tokens));
      updateClients(tokens);
    },
  },
});

export const { loginStore, logoutStore, refreshStore } = authSlice.actions;

export const authReducer = authSlice.reducer;
