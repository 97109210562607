import { useTranslation } from 'react-i18next';
import { Badge, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { StateUpdater } from 'src/app/types/Components';
import FormExpandableListField from 'src/components/Form/FormExpandableListField';
import { BUTTON_STATE } from 'src/app/helpers/enum';
import { arrayRemoveAt, arrayReplaceAt } from 'src/app/helpers/manipulation';
import { useForm } from 'src/app/hooks';
import useStateWithCallback from 'src/app/hooks/useStateWithCallback';
import { useCacheLanguages } from 'src/app/api/cache/hooks';
import { Interpretation, ServiceRoot } from 'src/app/types/Entities';
import { FinancialState } from 'src/pages/AdminPage/Financial/localTypes';
import CompanyRateListItem from './ListItem';
import { CompanyRateItem, CompanyRateState } from '../localTypes';

export interface CompanyRateFieldProps {
  formPrefix: string;
  showCompanyRateField: boolean;
  setShowCompanyRateField: (display: boolean) => void;
  initialState: CompanyRateState | FinancialState;
  stateUpdater: StateUpdater<CompanyRateState> | StateUpdater<FinancialState>;
  serviceRoots: ServiceRoot<Interpretation>[] | undefined;
  areServiceRootsLoading: boolean;
  showAllLanguages?: boolean;
}

const translationArray = ['onboardings'];

function CompanyRateField(props: CompanyRateFieldProps) {
  const {
    formPrefix,
    showCompanyRateField,
    setShowCompanyRateField,
    initialState,
    stateUpdater,
    serviceRoots,
    areServiceRootsLoading,
    showAllLanguages,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Queries
  const fetchedLanguages = useCacheLanguages(true);

  const languages = () => {
    let baseLanguages = [
      { id: 'all', name: 'All Languages' },
      { id: 'common', name: 'Common Languages' },
      { id: 'rare', name: 'Rare Languages' },
    ];

    if (showAllLanguages) {
      baseLanguages = [
        ...baseLanguages,
        ...fetchedLanguages.map((language) => ({ id: language.alpha2, name: language.name })),
      ];
    }

    return baseLanguages;
  };

  // Form data
  const [companyRates, setCompanyRates] = useStateWithCallback(initialState.rates, stateUpdater('rates'));

  const pushNewCompanyRate = () =>
    setCompanyRates([
      ...companyRates,
      {
        uuid: uuidv4(),
        serviceRoot: undefined,
        language: languages()[0].name,
        hourRate: '45.00',
        noShowingFee: '25.00',
        minHours: 2,
        minMinutes: 15,
      },
    ]);

  const replaceCompanyRateAt = (index: number, companyRate: CompanyRateItem) =>
    setCompanyRates(arrayReplaceAt(companyRates, index, companyRate));

  const removeCompanyRateAt = (index: number) => {
    const companyRate = companyRates[index];
    if (companyRate.sourceId !== undefined) {
      // Flag as deleted instead of removing
      replaceCompanyRateAt(index, { ...companyRate, deleted: true });
      return;
    }
    setCompanyRates(arrayRemoveAt(companyRates, index));
  };

  // Display
  const label = (
    <Badge
      showZero
      variant="counterBadge"
      color="primary"
      badgeContent={companyRates.filter(({ deleted }) => !deleted).length}
      sx={{ marginBottom: 2 }}
    >
      <Typography variant="formLabel">{t`companyRates`}</Typography>
    </Badge>
  );

  const onAdd = () => {
    pushNewCompanyRate();
    setShowCompanyRateField(true);
  };

  return (
    <FormExpandableListField
      showSeparator
      formPrefix={p`companyRates`}
      isOpen={showCompanyRateField}
      toggleOpen={setShowCompanyRateField}
      onAdd={onAdd}
      addLabel={t`addCompanyRate`}
      openButtonState={companyRates.length === 0 ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showCompanyRateField ? t`tooltip.hideCompanyRates` : t`tooltip.viewCompanyRates`}
      defaultDisplay={t`noCompanyRates`}
      label={label}
      onCancel={() => {
        setShowCompanyRateField(false);
      }}
    >
      {companyRates.map((companyRate, index) => {
        return (
          !companyRate.deleted && (
            <CompanyRateListItem
              key={companyRate.uuid}
              serviceRoots={serviceRoots}
              areServiceRootsLoading={areServiceRootsLoading}
              languages={languages()}
              removeServiceRate={() => removeCompanyRateAt(index)}
              initialState={companyRate}
              stateUpdater={(key: any) => (value: any) => replaceCompanyRateAt(index, { ...companyRate, [key]: value })}
            />
          )
        );
      })}
    </FormExpandableListField>
  );
}

export default CompanyRateField;
