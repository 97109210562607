import {
  Authorization,
  BEvent,
  Booking,
  BookingInterpretation,
  Interpretation,
  Interpreter,
  Language,
  Offer,
} from '../../types/Entities';
import { loadLanguage } from '../cache/loaders';
import {
  DehydratedAuthorization,
  DehydratedBooking,
  DehydratedBookingInterpretation,
  DehydratedEvent,
  DehydratedInterpretation,
  DehydratedInterpreter,
  DehydratedOffer,
} from './dehydrated_entities';

/*
 * Hydrators
 * These functions take a dehydrated entity and return a hydrated entity
 * Hydrated entities are entities with all their related entities loaded
 * Dehydrated entities are entities with only their IDs
 */

// Hydrators
export const hydrateLanguageFromAlpha3 = async (alpha3: string): Promise<Language> => {
  const language = await loadLanguage(alpha3);
  if (!language) {
    console.error('Language: invalid Alpha3 provided', alpha3);
    return undefined as never;
  }
  return language;
};

// Entity hydrators
export const hydrateInterpretation = async (interpretation: DehydratedInterpretation): Promise<Interpretation> => ({
  ...interpretation,
  source_language: await hydrateLanguageFromAlpha3(interpretation.source_language_alpha3),
  target_language: await hydrateLanguageFromAlpha3(interpretation.target_language_alpha3),
});

export const hydrateInterpreter = async (interpreter: DehydratedInterpreter): Promise<Interpreter> => ({
  ...interpreter,
  services: await Promise.all(interpreter.services.map((v) => hydrateInterpretation(v as DehydratedInterpretation))),
});

export const hydrateOffer = async (offer: DehydratedOffer): Promise<Offer> => ({
  ...offer,
  service: await hydrateInterpretation(offer.service),
});

export const hydrateBooking = async (booking: DehydratedBooking): Promise<Booking> => ({
  ...booking,
  offers: booking.offers ? await Promise.all(booking.offers.map((v) => hydrateOffer(v))) : undefined,
  target_language: booking.target_language_alpha3
    ? await hydrateLanguageFromAlpha3(booking.target_language_alpha3)
    : undefined,
});

export const hydrateBookingInterpretation = async (
  booking: DehydratedBookingInterpretation,
): Promise<BookingInterpretation> => ({
  ...(await hydrateBooking(booking as DehydratedBooking)),
  services: await Promise.all(booking.services.map((v) => hydrateInterpretation(v))),
});

export const hydrateEvent = async (event: DehydratedEvent): Promise<BEvent> => ({
  ...event,
  booking: await hydrateBooking(event.booking),
});

export const hydrateAuthorization = async (authorization: DehydratedAuthorization): Promise<Authorization> => ({
  ...authorization,
  events: await Promise.all(authorization.events.map((v) => hydrateEvent(v))),
});
