import FormWrapper from 'src/components/Form/FormWrapper';
import { Trans, useTranslation } from 'react-i18next';
import FormListRowNoAdd from 'src/components/Form/FormListRowNoAdd';
import { Booking } from '../../../../../app/types/Entities';
import ChildBookingView from './ChildBookingView';
import { useForm } from '../../../../../app/hooks';

export interface FollowUpFieldViewSectionProps {
  formPrefix: string;
  childrenBooking: Booking[];
  parentBooking?: Booking;
}

export default function FollowUpFieldViewSection(props: FollowUpFieldViewSectionProps) {
  const { formPrefix, childrenBooking, parentBooking } = props;

  const { p } = useForm(formPrefix);
  const { t } = useTranslation('booking');

  return (
    <>
      <FormWrapper label={t`parentBooking`}>
        {parentBooking ? (
          <FormListRowNoAdd>
            <ChildBookingView formPrefix={p`parent-booking`} booking={parentBooking} />
          </FormListRowNoAdd>
        ) : (
          <FormListRowNoAdd>
            <p>
              <Trans t={t} i18nKey="noParentBooking" />
            </p>
          </FormListRowNoAdd>
        )}
      </FormWrapper>

      {childrenBooking.length !== 0 ? (
        <FormWrapper label={t('childrenBooking', { count: childrenBooking.length })}>
          {childrenBooking.map((booking) => (
            <FormListRowNoAdd>
              <ChildBookingView key={booking.id} formPrefix={p`item-` + booking.id} booking={booking} />
            </FormListRowNoAdd>
          ))}
        </FormWrapper>
      ) : null}
    </>
  );
}
