import { AxiosError } from 'axios';
import { DatabaseId, Errors } from '../types/DataStructures';
import { PreferredAgency, PreferredAgencyWithCompanies } from '../types/Entities';
import { apiClient } from './config/clients';
import { COMPANY_RELATIONSHIPS_ENDPOINT } from './config/endpoints';
import { ApiDao, ApiQuery, LookupsMap, applyLookup, businessParam, pathParam } from './config/helpers';
import { sanitizePreferredAgency, sanitizePreferredAgencyWithCompanies } from './config/sanitizers';

export interface PreferredAgenciesGetParams {
  id?: DatabaseId;
  company_from?: DatabaseId;
  company_to?: DatabaseId;
  relationship?: string;
  _include_companies?: boolean;
}

export const DEFAULT_PREFERRED_AGENCIES_LOOKUPS: LookupsMap<PreferredAgenciesGetParams> = {
  relationship: 'nem',
};

export const getPreferredAgenciesDao: ApiDao<PreferredAgenciesGetParams, any[]> = (
  params,
  lookups = DEFAULT_PREFERRED_AGENCIES_LOOKUPS,
) => apiClient.get(COMPANY_RELATIONSHIPS_ENDPOINT, { params: applyLookup(params, lookups) });

// TODO: Sanitizer
export const getPreferredAgencies: ApiQuery<PreferredAgenciesGetParams, PreferredAgency[]> = (
  params,
  lookups = DEFAULT_PREFERRED_AGENCIES_LOOKUPS,
) => getPreferredAgenciesDao(params, lookups).then((res) => res.data.map((v) => sanitizePreferredAgency(v)));

export const getPreferredAgenciesWithCompanies: ApiQuery<PreferredAgenciesGetParams, PreferredAgencyWithCompanies[]> = (
  params,
  lookups = DEFAULT_PREFERRED_AGENCIES_LOOKUPS,
) =>
  getPreferredAgenciesDao({ _include_companies: true, ...params }, lookups).then((res) =>
    res.data.map((v) => sanitizePreferredAgencyWithCompanies(v)),
  );

export const getPreferredAgency = (preferredAgencyId: DatabaseId) =>
  apiClient
    .get(pathParam(COMPANY_RELATIONSHIPS_ENDPOINT, preferredAgencyId))
    .then((res) => sanitizePreferredAgency(res.data));

// POST
export interface PreferredAgenciesPostPayload {
  company_to?: DatabaseId;
  company_from?: DatabaseId;
  relationship?: string;
}
export type PreferredAgenciesPostResponse = DatabaseId;
export type PreferredAgenciesPostError = Errors<PreferredAgenciesPostPayload>;
export const createPreferredAgency = (payload: PreferredAgenciesPostPayload) =>
  apiClient
    .post<PreferredAgenciesPostResponse>(businessParam(COMPANY_RELATIONSHIPS_ENDPOINT), payload)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response?.data));

// PUT
export type PreferredAgenciesPutPayload = PreferredAgenciesPostPayload;
export type PreferredAgenciesPutResponse = unknown;
export type PreferredAgenciesPutError = Errors<PreferredAgenciesPutPayload>;
export const updatePreferredAgency = (preferredAgencyId: DatabaseId, payload: PreferredAgenciesPutPayload) =>
  apiClient
    .put<PreferredAgenciesPutResponse>(pathParam(COMPANY_RELATIONSHIPS_ENDPOINT, preferredAgencyId), {
      ...payload,
      // TODO: review this
      // _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<PreferredAgenciesPutError>) => Promise.reject(err.response?.data));

// DELETE
export type PreferredAgenciesDeleteResponse = unknown;
export type PreferredAgenciesDeleteError = string;
export const deletePreferredAgency = (preferredAgencyId: DatabaseId) =>
  apiClient
    .delete<PreferredAgenciesDeleteResponse>(pathParam(COMPANY_RELATIONSHIPS_ENDPOINT, preferredAgencyId))
    .catch((err: AxiosError<PreferredAgenciesDeleteError>) => Promise.reject(err.response?.data));
