import { t } from 'i18next';
import './Header.scss';
import { DAYS_OF_WEEK } from 'src/app/helpers/enum';

interface CalendarHeaderProps {
  date: Date;
}

function CalendarHeader({ date }: CalendarHeaderProps) {
  const daysOfWeek = Object.values(DAYS_OF_WEEK).map((day) => t(`calendar:daysOfWeek.${day}`));
  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const numericalDate = date.getUTCDate();

  return (
    <div className="calendar-header-cell">
      <div className="day-of-week">{dayOfWeek}</div>
      <div className="numerical-date">{numericalDate}</div>
    </div>
  );
}

export default CalendarHeader;
