import { Note as NoteEntity } from 'src/app/types/Entities';
import { Col, Row } from 'reactstrap';
import DateTimeDisplay from 'src/components/DateTimeDisplay/DateTimeDisplay';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { NOTE_DATE_FORMAT_MOMENT, PREFERRED_DATETIME_FORMAT_MOMENT } from 'src/app/helpers/constants';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, IconButton, Input, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonWithTooltip from '../../../../../components/ButtonWithTooltip';
import { useForm } from '../../../../../app/hooks';
import './NoteDisplay.scss';

export interface NoteProps {
  formPrefix: string;
  note: NoteEntity;
  setText: (text: string) => void;
  onRemove: () => void;
  editButtonTooltip?: ReactNode;
  removeButtonTooltip?: ReactNode;
}

function NoteDisplay({
  formPrefix = 'note',
  note,
  setText,
  onRemove,
  editButtonTooltip,
  removeButtonTooltip,
}: NoteProps) {
  const { p } = useForm(formPrefix);
  const { t } = useTranslation('forms');

  const boxRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [showMoreShowLess, setShowMoreShowLess] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  // Recalculate showMoreShowLess on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    const boxHeight = boxRef.current?.offsetHeight;
    const textHeight = textRef.current?.offsetHeight;

    if (boxHeight && textHeight) {
      setShowMoreShowLess(boxHeight < textHeight);
    } else {
      setExpanded(false);
    }
  });

  return (
    <div>
      <Row className="gx-2">
        <Col>
          {editMode ? (
            <Paper elevation={0} sx={{ marginBottom: 1 }}>
              <Input
                fullWidth
                placeholder={t`editNote`}
                multiline
                endAdornment={
                  <IconButton onClick={() => setEditMode(false)} color="primary" sx={{ alignSelf: 'start' }}>
                    <SaveIcon fontSize="medium" />
                  </IconButton>
                }
                required
                value={note.text}
                onChange={(change) => setText(change.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    setText(`${note.text}\n`);
                    event.preventDefault();
                  }
                }}
              />
            </Paper>
          ) : (
            <Paper elevation={0} sx={{ p: '8px 0', display: 'flex', alignItems: 'center', marginBottom: 1 }}>
              <Box ref={boxRef} sx={{ flex: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    flex: 1,
                    display: '-webkit-box',
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-line',
                    WebkitLineClamp: expanded ? undefined : 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                  }}
                >
                  <Box component="span" ref={textRef}>
                    {note.text}
                  </Box>
                  <Typography variant="body1" color="gray" display="inline">
                    {' - '}
                    <DateTimeDisplay
                      dateTime={note.created_at}
                      format={NOTE_DATE_FORMAT_MOMENT}
                      tooltipFormat={PREFERRED_DATETIME_FORMAT_MOMENT}
                      className="details-notes"
                    />
                    {note.created_by_first_name && note.created_by_last_name ? (
                      <span className="details-notes">
                        {` ${t`by`} `}
                        {note.created_by_first_name} {note.created_by_last_name}
                      </span>
                    ) : null}
                  </Typography>
                </Typography>
                {(showMoreShowLess || expanded) && (
                  <Button
                    variant="text"
                    sx={{
                      textTransform: 'capitalize',
                      padding: 0,
                    }}
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? t`showLess` : t`showMore`}
                  </Button>
                )}
              </Box>
              <ButtonWithTooltip
                id={p`remove-button`}
                tooltip={removeButtonTooltip}
                onClick={() => onRemove?.()}
                isIconButton
                sx={{
                  alignSelf: 'start',
                }}
              >
                <DeleteIcon fontSize="medium" />
              </ButtonWithTooltip>
              <ButtonWithTooltip
                id={p`edit-button`}
                color="primary"
                tooltip={editButtonTooltip}
                onClick={() => setEditMode(true)}
                isIconButton
                sx={{
                  alignSelf: 'start',
                }}
              >
                <EditIcon fontSize="medium" />
              </ButtonWithTooltip>
            </Paper>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default NoteDisplay;
