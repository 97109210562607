import { AxiosError } from 'axios';
import { Affiliation, UninsuredAffiliation } from '../types/Entities';
import { CompaniesGetParams, DEFAULT_COMPANIES_LOOKUPS, InsurancesGetParams } from './companies';
import { DEFAULT_RECIPIENT_LOOKUPS, PatientGetParams, RecipientsGetParams } from './recipients';
import { Narrow } from '../types/TypeMappers';
import { DatabaseId, Errors } from '../types/DataStructures';
import { sanitizeAffiliation, sanitizeUninsuredAffiliation } from './config/sanitizers';
import { apiClient } from './config/clients';
import { AFFILIATIONS_ENDPOINT } from './config/endpoints';
import { ApiDao, ApiQuery, applyLookup, businessParam, LookupsMap } from './config/helpers';

export interface AffiliationsGetParams {
  company?: CompaniesGetParams | boolean;
  recipient?: RecipientsGetParams | DatabaseId;
}
export const DEFAULT_AFFILIATIONS_LOOKUPS: LookupsMap<InsuranceAffGetParams> = {
  company: DEFAULT_COMPANIES_LOOKUPS,
  recipient: DEFAULT_RECIPIENT_LOOKUPS,
};
export const getAffiliationsDao: ApiDao<AffiliationsGetParams, any[]> = (
  params,
  lookups = DEFAULT_AFFILIATIONS_LOOKUPS,
) =>
  apiClient.get(AFFILIATIONS_ENDPOINT, {
    params: applyLookup(params, lookups!),
  });

export const getAffiliations: ApiQuery<AffiliationsGetParams, Affiliation[]> = (
  params,
  lookups = DEFAULT_AFFILIATIONS_LOOKUPS,
) => getAffiliationsDao(params, lookups).then((res) => res.data.map((v) => sanitizeAffiliation(v)));

// Uninsured affiliation
export interface UninsuredAffiliationGetParams extends AffiliationsGetParams {
  company?: never;
}

export const DEFAULT_UNINSURED_AFFILIATIONS_LOOKUPS: LookupsMap<UninsuredAffiliationGetParams> = {
  recipient: DEFAULT_RECIPIENT_LOOKUPS,
};

export const getUninsuredAffiliations: ApiQuery<UninsuredAffiliationGetParams, UninsuredAffiliation[]> = (
  params,
  lookups = DEFAULT_UNINSURED_AFFILIATIONS_LOOKUPS,
) =>
  getAffiliationsDao({ ...params, company: true }, { ...lookups, company: 'isnull' }).then((res) =>
    res.data.map((v) => sanitizeUninsuredAffiliation(v)),
  );

// Insurance affiliation
export type InsuranceAffGetParams = Narrow<
  AffiliationsGetParams,
  {
    company?: InsurancesGetParams;
    recipient?: PatientGetParams;
  }
>;

export interface AffiliationPostPayload {
  company: DatabaseId | null;
  recipient: DatabaseId;
}

export type AffiliationPostResponse = DatabaseId;
export type AffiliationPostError = Errors<AffiliationPostPayload>;
export const createAffiliation = (payload: AffiliationPostPayload) =>
  apiClient
    .post<AffiliationPostResponse>(businessParam(AFFILIATIONS_ENDPOINT), payload)
    .then((res) => res.data)
    .catch((err: AxiosError<AffiliationPostError>) => Promise.reject(err.response?.data));
