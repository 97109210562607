import { Col, Input, InputGroup } from 'reactstrap';
import { useEffect, useState } from 'react';
import './TableSearchBar.scss';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import {
  CompaniesGetParams,
  MUTATION_KEYS,
  UsersGetParams,
  getCompanies,
  getUsers,
  getInterpreters,
} from 'src/app/api';

interface TableSearchBarProps {
  onResultsChange: (data: any) => void;
  placeholderOne?: string;
  placeholderTwo?: string;
  queryType?: 'users' | 'interpreters' | 'companies';
  searchParams?: UsersGetParams;
  setSearchParams?: (params: UsersGetParams) => void;
  updateSearchParams?: (params: UsersGetParams) => void;
  companiesSearchParams?: CompaniesGetParams;
  setCompaniesSearchParams?: (params: CompaniesGetParams) => void;
  updateCompaniesSearchParams?: (params: CompaniesGetParams) => void;
}

export default function TableSearchBar({
  onResultsChange,
  placeholderOne,
  placeholderTwo,
  queryType,
  searchParams,
  setSearchParams,
  updateSearchParams,
  companiesSearchParams,
  setCompaniesSearchParams,
  updateCompaniesSearchParams,
}: TableSearchBarProps) {
  const location = useLocation();
  const [focused, setFocused] = useState<boolean>(false);
  const [queryOne, setQueryOne] = useState<string>('');
  const [queryTwo, setQueryTwo] = useState<string>('');

  useEffect(() => {
    if (setSearchParams) {
      setSearchParams({
        ...searchParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchParams]);

  useEffect(() => {
    if (setCompaniesSearchParams) {
      setCompaniesSearchParams({
        ...companiesSearchParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCompaniesSearchParams]);

  const {
    data: interpreters,
    isLoading: isSearchingInterpreters,
    fetchStatus: fetchStatusInterpreters,
  } = useQuery([MUTATION_KEYS.PROVIDERS, searchParams], () => searchParams && getInterpreters(searchParams), {
    enabled: focused && searchParams && Object.values(searchParams).some(Boolean),
  });

  const {
    data: users,
    isLoading: isSearchingUsers,
    fetchStatus: fetchStatusUsers,
  } = useQuery([MUTATION_KEYS.USERS, searchParams], () => searchParams && getUsers(searchParams), {
    enabled: focused && searchParams && Object.values(searchParams).some(Boolean),
  });

  const {
    data: companies,
    isLoading: isSearchingCompanies,
    fetchStatus: fetchStatusCompanies,
  } = useQuery(
    [MUTATION_KEYS.COMPANIES, companiesSearchParams],
    () => companiesSearchParams && getCompanies(companiesSearchParams),
    {
      enabled: focused && companiesSearchParams && Object.values(companiesSearchParams).some(Boolean),
    },
  );

  useEffect(() => {
    switch (queryType) {
      case 'users':
        onResultsChange(users);
        break;
      case 'interpreters':
        onResultsChange(interpreters);
        break;
      case 'companies':
        onResultsChange(companies);
        break;
      default:
        break;
    }
  }, [
    interpreters,
    users,
    onResultsChange,
    queryType,
    isSearchingUsers,
    fetchStatusUsers,
    isSearchingInterpreters,
    fetchStatusInterpreters,
    isSearchingCompanies,
    fetchStatusCompanies,
    companies,
  ]);

  const nameRegex = /\p{L}+/iu;
  const sentenceRegex = /(\p{L}+\s*)+/iu;

  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');

  const computeSearchParams = (firstName: string, lastName: string) => {
    const firstNameMatch = firstName.match(nameRegex)?.[0];
    const lastNameMatch = lastName.match(nameRegex)?.[0];
    if (updateSearchParams) {
      updateSearchParams({ first_name: firstNameMatch?.trim(), last_name: lastNameMatch?.trim() });
    }
  };

  const computeCompaniesSearchParams = (name: string) => {
    const nameMatch = name.match(sentenceRegex)?.[0];
    if (updateCompaniesSearchParams) {
      updateCompaniesSearchParams({ name: nameMatch?.trim() });
    }
  };

  const handleInput = useDebouncedCallback((input: string, inputType: 'first' | 'last' | 'company') => {
    if (queryType === 'companies') {
      computeCompaniesSearchParams(input);
    } else if (inputType === 'first') {
      if (setFirstNameInput) {
        setFirstNameInput(input);
      }
      computeSearchParams(input, lastNameInput);
    } else if (inputType === 'last') {
      if (setLastNameInput) {
        setLastNameInput(input);
      }
      computeSearchParams(firstNameInput, input);
    }
  }, 750);

  useEffect(() => {
    // Remove focus when the location changed
    setFocused(false);
  }, [location]);

  return (
    <div
      className="searchbar"
      tabIndex={0}
      role="button"
      onClick={() => setFocused(true)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setFocused(true);
        }
      }}
      onFocus={() => setFocused(true)}
      onBlur={(e) => {
        const containerDiv = e.currentTarget;
        requestAnimationFrame(() => {
          const newlyFocusedElement = document.activeElement;
          setFocused(containerDiv.contains(newlyFocusedElement));
        });
      }}
    >
      <InputGroup className="inputs">
        <Col sm="5" className="search-col">
          <Input
            type="search"
            placeholder={placeholderOne}
            value={queryOne}
            onChange={(e) => {
              setFocused(e.target.value !== '');
              setQueryOne(e.target.value);
              handleInput(e.target.value, 'first');
            }}
          />
        </Col>
        {queryType !== 'companies' && (
          <Col sm="5">
            <Input
              type="search"
              placeholder={placeholderTwo}
              value={queryTwo}
              onChange={(e) => {
                setFocused(e.target.value !== '');
                setQueryTwo(e.target.value);
                handleInput(e.target.value, 'last');
              }}
            />
          </Col>
        )}
      </InputGroup>
    </div>
  );
}
