import { useTranslation } from 'react-i18next';
import { Badge, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { StateUpdater } from 'src/app/types/Components';
import FormExpandableListField from '../../../../../components/Form/FormExpandableListField';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import { useForm } from '../../../../../app/hooks';
import { PaymentInfoState, PaymentMethodItem } from '../localTypes';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import { arrayRemoveAt, arrayReplaceAt } from '../../../../../app/helpers/manipulation';
import PaymentMethodListItem from './PaymentMethod/ListItem';

export interface PaymentInfoFieldProps {
  formPrefix: string;
  showPaymentInfoField: boolean;
  setShowPaymentInfoField: (display: boolean) => void;
  initialState: PaymentInfoState;
  stateUpdater: StateUpdater<PaymentInfoState>;
}

const translationArray = ['onboardings'];

function PaymentInfoField(props: PaymentInfoFieldProps) {
  const { formPrefix, showPaymentInfoField, setShowPaymentInfoField, initialState, stateUpdater } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [preferredPaymentMethod, setPreferredPaymentMethod] = useStateWithCallback(
    initialState.preferredPaymentMethod,
    stateUpdater('preferredPaymentMethod'),
  );

  const [paymentMethods, setPaymentMethods] = useStateWithCallback(
    initialState.paymentMethods,
    stateUpdater('paymentMethods'),
  );

  const pushNewPaymentMethod = () =>
    setPaymentMethods([
      ...paymentMethods,
      {
        uuid: uuidv4(),
        method: 'achTransfer',
        accountType: 'checking',
        accountNumber: '',
        routingNumber: '',
        bankName: '',
      },
    ]);

  const replacePaymentMethodAt = (index: number, paymentInfo: PaymentMethodItem) =>
    setPaymentMethods(arrayReplaceAt(paymentMethods, index, paymentInfo));

  const removePaymentMethodAt = (index: number) => setPaymentMethods(arrayRemoveAt(paymentMethods, index));

  // Display
  const label = (
    <Badge
      showZero
      variant="counterBadge"
      color="primary"
      badgeContent={paymentMethods.length}
      sx={{ marginBottom: 2 }}
    >
      <Typography variant="formLabel">{t`paymentInfo`}</Typography>
      <Typography variant="overline" color="red">
        Not implemented yet
      </Typography>
    </Badge>
  );

  const onAdd = () => {
    pushNewPaymentMethod();
    setShowPaymentInfoField(true);
  };

  return (
    <FormExpandableListField
      showSeparator
      formPrefix={p`paymentInfos`}
      isOpen={showPaymentInfoField}
      toggleOpen={setShowPaymentInfoField}
      onAdd={onAdd}
      addLabel={t`addPaymentInfo`}
      openButtonState={paymentMethods.length === 0 ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showPaymentInfoField ? t`tooltip.hidePaymentInfos` : t`tooltip.viewPaymentInfos`}
      defaultDisplay={t`noPaymentInfos`}
      label={label}
      onCancel={() => {
        setShowPaymentInfoField(false);
      }}
    >
      {paymentMethods.map((paymentMethod, index) => {
        return (
          <PaymentMethodListItem
            key={paymentMethod.uuid}
            removePaymentMethod={() => removePaymentMethodAt(index)}
            isPreferred={preferredPaymentMethod === paymentMethod.uuid}
            setAsPreferred={() => setPreferredPaymentMethod(paymentMethod.uuid)}
            initialState={paymentMethod}
            stateUpdater={(key: any) => (value: any) =>
              replacePaymentMethodAt(index, { ...paymentMethod, [key]: value })}
          />
        );
      })}
    </FormExpandableListField>
  );
}

export default PaymentInfoField;
