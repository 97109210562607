import { Button, ButtonProps, IconButton, Tooltip } from '@mui/material';
import React, { ReactNode } from 'react';

export interface ButtonWithTooltipProps extends ButtonProps {
  id: string;
  tooltip: ReactNode;
  ref?: React.Ref<any>;
  isIconButton?: boolean;
}

const ButtonWithTooltip = React.forwardRef(
  ({ id, children, tooltip, isIconButton, ...props }: ButtonWithTooltipProps, ref) => {
    return (
      <Tooltip id={id} title={tooltip} ref={ref}>
        <span>
          {isIconButton ? (
            <IconButton id={id} {...props}>
              {children}
            </IconButton>
          ) : (
            <Button id={id} {...props}>
              {children}
            </Button>
          )}
        </span>
      </Tooltip>
    );
  },
);

export default ButtonWithTooltip;
