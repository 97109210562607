import { Dispatch, SetStateAction, useState } from 'react';
import { arrayRemoveAt, arrayReplaceAt } from '../helpers/manipulation';

export interface UseStateArrayReturnType<S> {
  values: S[];
  set: Dispatch<SetStateAction<S[]>>;
  push: (item: S) => void;
  replaceAt: (index: number, item: S) => void;
  removeAt: (index: number) => void;
}

export function useStateArray<S>(initialState: S[] | (() => S[])): UseStateArrayReturnType<S> {
  const [values, setValues] = useState<S[]>(initialState);
  const push = (item: S) => setValues((prev) => [...prev, item]);
  const replaceAt = (index: number, item: S) => setValues((prev) => arrayReplaceAt(prev, index, item));
  const removeAt = (index: number) => setValues((prev) => arrayRemoveAt(prev, index));

  return {
    values,
    set: setValues,
    push,
    replaceAt,
    removeAt,
  };
}
