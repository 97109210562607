import { Button, Col, Container, FormText, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import FormDatePicker from 'src/components/DatePicker/FormDatePicker';
import Swal from 'sweetalert2';
import { PREFERRED_DATE_FORMAT_MOMENT } from 'src/app/helpers/constants';
import { FormInput } from '../../../../../components/Form/FormInput';
import { useForm, useStateDict } from '../../../../../app/hooks';
import { ErrAxios, getInsuranceWithRoles, getLawfirmWithRoles, MUTATION_KEYS, sendEmail } from '../../../../../app/api';
import { CASE_TYPES, COMPANY_TYPES, CaseType } from '../../../../../app/helpers/enum';
import { Errors } from '../../../../../app/types/DataStructures';
import {
  ClaimsAdjuster,
  ClaimsLawyer,
  Company,
  Insurance,
  Lawfirm,
  Location,
  User,
} from '../../../../../app/types/Entities';
import { Claim } from './localTypes';
import { ErrorsAlert } from '../../../../../components/ErrorsAlert';
import InsuranceSection from '../PayerField/InsuranceSection/InsuranceSection';
import LawfirmSection from '../PayerField/LawfirmSection/LawfirmSection';
import AgencySection from '../PayerField/AgencySection/AgencySection';
import { PayerFieldState } from '../stateTypes';
import InterpretationLetter from '../../../../../assets/email/InterpretationLetter';
import './ClaimSection.scss';

export interface ClaimSectionProps {
  user: Omit<User, never> | undefined;
  formPrefix: string;
  createMode: boolean;
  toggleCreateMode: (createMode: boolean) => void;
  bookingExists: boolean;
  toggleShowPayerClaimField: (isOpen: boolean) => void;

  initialClaimNumber?: string;
  claim: Partial<Claim> | null;
  submitClaim: (claim: Claim) => void;
  caseType: CaseType;
  initialPayerFieldState: PayerFieldState;
  start_at: string;
  clinic: string | undefined;
  contactClinic?: Location;
  medicalProvider: string | undefined;
  medicalProviderLastName: string | undefined;
  serviceRoot: string | undefined;
  serviceProvider: string | undefined;
  serviceProviderLastName: string | undefined;
  typeOfCertification: string | undefined;
  duration: Date;
  noteDiagnosis: string | undefined;
  patient_first_name: string | undefined;
  patient_last_name: string | undefined;
}

function ClaimSection(props: ClaimSectionProps) {
  const {
    user,
    formPrefix,
    createMode,
    toggleCreateMode,
    bookingExists,
    toggleShowPayerClaimField,
    initialClaimNumber,
    claim,
    submitClaim,
    caseType,
    initialPayerFieldState,
    start_at,
    clinic,
    contactClinic,
    medicalProvider,
    medicalProviderLastName,
    serviceRoot,
    serviceProvider,
    serviceProviderLastName,
    typeOfCertification,
    duration,
    noteDiagnosis,
    patient_first_name,
    patient_last_name,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation('booking');

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<Errors<{}>>({});

  const [claimNumber, setClaimNumber] = useState<string>('');
  const [dateOfInjury, setDateOfInjury] = useState<Date | null>(new Date());
  const [diagnosis, setDiagnosis] = useState<string>('');

  const [insurance, setInsurance] = useState<Insurance | null>(null);
  const [adjuster, setAdjuster] = useState<ClaimsAdjuster | null>(null);

  const [lawfirm, setLawfirm] = useState<Lawfirm | null>(null);
  const [lawyer, setLawyer] = useState<ClaimsLawyer | null>(null);

  // Query
  const { mutateAsync: fetchInsurance } = useMutation(
    [MUTATION_KEYS.COMPANIES, { type: COMPANY_TYPES.INSURANCE }],
    getInsuranceWithRoles,
  );

  const { mutateAsync: fetchLawfirm } = useMutation(
    [MUTATION_KEYS.COMPANIES, { type: COMPANY_TYPES.LAWFIRM }],
    getLawfirmWithRoles,
  );

  // Prefill
  const prefillForm = () => {
    setClaimNumber(claim?.claimNumber ?? initialClaimNumber ?? '');
    setDateOfInjury(claim?.dateOfInjury ?? null);
    setDiagnosis(claim?.diagnosis ?? '');

    if (claim?.insuranceId) {
      fetchInsurance(claim.insuranceId)
        .then((fetchedInsurance) => {
          const foundAdjuster = fetchedInsurance.payers?.find(({ id }) => id === claim?.adjusterId) ?? null;
          setInsurance(fetchedInsurance);
          setAdjuster(foundAdjuster);
        })
        .catch(() => {
          setInsurance(null);
          setAdjuster(null);
        });
    }

    if (claim?.lawfirmId) {
      fetchLawfirm(claim.lawfirmId)
        .then((fetchedLawfirm) => {
          const foundLawyer = fetchedLawfirm.payers?.find(({ id }) => id === claim?.lawyerId) ?? null;
          setLawfirm(fetchedLawfirm);
          setLawyer(foundLawyer);
        })
        .catch(() => {
          setLawfirm(null);
          setLawyer(null);
        });
    }
  };

  useEffect(() => {
    if (claim?._newlyCreated) {
      return;
    }

    prefillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claim, initialClaimNumber]);

  // On save functions
  // Validate
  const validate = () => {
    const validationErrors = [];

    if (!claimNumber) {
      validationErrors.push(t`errors.noClaimNumber`);
    }
    if (!dateOfInjury) {
      validationErrors.push(t`errors.noDoI`);
    }
    if (!diagnosis) {
      validationErrors.push(t`errors.noDiagnosis`);
    }
    if ((caseType === CASE_TYPES.COMMERCIAL_INSURANCE || caseType === CASE_TYPES.WORKERS_COMPENSATION) && !insurance) {
      validationErrors.push(t`errors.noInsurance`);
    }
    if (caseType === CASE_TYPES.REFERRED_TO_AGENCY && !insurance) {
      validationErrors.push(t`errors.noAgency`);
    }
    if (!insurance?.id && adjuster) {
      validationErrors.push(t`errors.noInsurance`);
    }
    if (!lawfirm?.id && lawyer) {
      validationErrors.push(t`errors.noLawfirm`);
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      return false;
    }
    return true;
  };

  const emailOptions = insurance?.contacts.map((e) => e.email?.concat(' - ', e.email_context ? e.email_context : ''));

  // On create
  const buildAndSubmitClaim = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    submitClaim({
      _newlyCreated: true,
      authorizationIds: claim?.authorizationIds,
      eventId: claim?.eventId ?? undefined,
      claimNumber,
      dateOfInjury: dateOfInjury!,
      diagnosis,
      insuranceId: insurance?.id ?? undefined,
      adjusterId: adjuster?.id ?? undefined,
      lawfirmId: lawfirm?.id ?? undefined,
      lawyerId: lawyer?.id ?? undefined,
    });
  };

  const onCancel = () => {
    prefillForm();
    toggleShowPayerClaimField(false);
    toggleCreateMode(!createMode);
  };

  function SwalSend() {
    Swal.fire({
      customClass: {
        input: 'input',
      },
      title: t`claimTitleSendQuestion`,
      icon: 'question',
      input: 'select',
      html: '<input id="swal-input1" class="html-input" placeholder="Vendor ID">',
      inputOptions: new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            Email: emailOptions,
          });
        }, 1000);
      }),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t`ClaimConfirmButtom`,
      preConfirm(value) {
        return new Promise((resolve) => {
          const vendorIDElement = document.getElementById('swal-input1') as HTMLInputElement;
          const vendorID = vendorIDElement.value;

          const missingValues: string[] = [];

          if (!clinic) {
            missingValues.push(t`missing.clinic`);
          }

          if (!contactClinic) {
            missingValues.push(t`missing.clinicAddress`);
          }

          if (!medicalProvider) {
            missingValues.push(t`missing.providerFirstName`);
          }

          if (!medicalProviderLastName) {
            missingValues.push(t`missing.providerLastName`);
          }

          if (!serviceRoot) {
            missingValues.push(t`missing.Modality`);
          }

          if (!patient_first_name) {
            missingValues.push(t`missing.injuredWorkerFirstName`);
          }

          if (!patient_last_name) {
            missingValues.push(t`missing.injuredWorkerLastName`);
          }

          if (!vendorID) {
            missingValues.push(t`missing.vendorID`);
          }

          const subject = 'Referred Agency';
          const body = InterpretationLetter({
            user,
            adjuster,
            dateOfInjury,
            start_at,
            clinic,
            contactClinic,
            medicalProvider,
            medicalProviderLastName,
            serviceRoot,
            serviceProvider,
            serviceProviderLastName,
            typeOfCertification,
            duration,
            noteDiagnosis,
            patient_first_name,
            patient_last_name,
            vendorID,
          });
          const recipient = emailOptions?.[value]?.split(' - ')[0];

          if (missingValues.length) {
            const Missing = `${t`missing.message`}: ${missingValues.join(', ')}`;
            Swal.fire({
              icon: 'error',
              title: t`ClaimErrorSendTitle`,
              text: Missing,
            });
          } else {
            sendEmail({ subject, body, recipient })
              .then((data) => {
                setTimeout(() => {
                  resolve(data);
                });
              })
              .catch((error: ErrAxios) => {
                Swal.fire({
                  icon: 'error',
                  title: t`ClaimErrorSendTitle`,
                  text: t`ClaimErrorSendText` + error.code,
                });
              });
          }
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: t`claimTitleSendSuccess`,
          text: t`claimTextSendSuccess`,
          icon: 'success',
        });
      }
    });
  }

  function SwalWarning() {
    Swal.fire({
      icon: 'error',
      title: t`ClaimErrorTitle`,
      text: t`ClaimErrorText`,
    });
  }

  return (
    <Container className="p-0">
      <Row className="gx-2">
        <Col md={12} xl={12} xxl={6}>
          <FormInput
            className="claim-field"
            label={`${t`claimNumber`} *`}
            value={claimNumber}
            onChange={(e) => setClaimNumber(e.target.value)}
          />
        </Col>
        <Col md={12} xl={12} xxl={6}>
          <FormDatePicker
            className="claim-field"
            label={`${t`doi`} *`}
            dateFormat={PREFERRED_DATE_FORMAT_MOMENT}
            maxDate={new Date()}
            value={dateOfInjury}
            onChange={(date) => date && setDateOfInjury(date.toDate())}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            className="claim-field"
            label={`${t`claimDiagnosis`} *`}
            value={diagnosis}
            onChange={(e) => setDiagnosis(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {caseType !== CASE_TYPES.REFERRED_TO_AGENCY ? (
            <InsuranceSection
              insuranceOptional={
                caseType !== CASE_TYPES.WORKERS_COMPENSATION && caseType !== CASE_TYPES.COMMERCIAL_INSURANCE
              }
              adjusterOptional
              formPrefix={p`insurance`}
              payerCompany={insurance}
              setPayerCompany={setInsurance as (company: Company | null) => void}
              payer={adjuster}
              setPayer={setAdjuster}
              initialPayerFieldState={initialPayerFieldState}
            />
          ) : (
            <AgencySection
              formPrefix={p`agency`}
              payerCompany={insurance}
              setPayerCompany={setInsurance as (company: Company | null) => void}
              payer={adjuster}
              setPayer={setAdjuster}
              initialPayerFieldState={initialPayerFieldState}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {caseType !== CASE_TYPES.REFERRED_TO_AGENCY ? (
            <LawfirmSection
              lawfirmOptional
              formPrefix={p`lawfirm`}
              payerCompany={lawfirm}
              setPayerCompany={setLawfirm as (company: Company | null) => void}
              payer={lawyer}
              setPayer={setLawyer}
              initialPayerFieldState={initialPayerFieldState}
            />
          ) : null}
        </Col>
      </Row>
      <Row>
        <ErrorsAlert errorsArray={errors.validation_errors} />
      </Row>
      <Row className="mt-4">
        <Col xs="auto" className="flex-grow-0">
          {caseType === CASE_TYPES.REFERRED_TO_AGENCY &&
          insurance?.name !== undefined &&
          insurance?.contacts.map((c) => c.email).length !== 0 ? (
            <Button
              className="action-button me-4"
              color="submit"
              onClick={() => {
                buildAndSubmitClaim();
                SwalSend();
              }}
            >
              {bookingExists ? t`saveClaim` : t`applyClaim`}
            </Button>
          ) : caseType === CASE_TYPES.REFERRED_TO_AGENCY &&
            insurance?.name !== undefined &&
            insurance?.contacts.map((c) => c.email).length === 0 ? (
            <Button
              className="action-button me-4"
              color="submit"
              onClick={() => {
                buildAndSubmitClaim();
                SwalWarning();
              }}
            >
              {bookingExists ? t`saveClaim` : t`applyClaim`}
            </Button>
          ) : (
            <Button className="action-button me-4" color="submit" onClick={buildAndSubmitClaim}>
              {bookingExists ? t`saveClaim` : t`applyClaim`}
            </Button>
          )}
        </Col>
        <Col xs="auto" className="flex-grow-0">
          <Button className="action-button" color="cancel" onClick={onCancel}>
            {t`cancel`}
          </Button>
        </Col>
        <Col>
          <FormText color="required-fields-help">{t`requiredFieldsHelp`}</FormText>
        </Col>
      </Row>
      <Row>
        <Col xs="auto" className="flex-grow-0">
          {!bookingExists ? (
            <FormText color="muted">
              <i>The claim will be {createMode ? 'created' : 'updated'} on booking save</i>
            </FormText>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default ClaimSection;
