import { Button } from 'reactstrap';
import './SignUpPage.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SignUpOnboardingForm from 'src/pages/SignUpPage/Onboarding/Form';
import MiddleCenterAlignedContainer from '../../components/MiddleCenterAlignedContainer';

export default function SignUpPage() {
  const { t } = useTranslation('forms');
  const navigate = useNavigate();

  return (
    <MiddleCenterAlignedContainer maxWidth>
      <h1>{t`signUp.title`}</h1>
      <h3>{t`signUp.subtitle`}</h3>
      <SignUpOnboardingForm
        id="user-onboarding"
        allowNotAuthorized
        onSubmitSuccess={() => {
          navigate('/');
        }}
      />
      <Button form="user-onboarding" color="primary">
        {t`onboarding.user.submit`}
      </Button>
    </MiddleCenterAlignedContainer>
  );
}
