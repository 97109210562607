import { useState, useEffect } from 'react';
import './BookingsHeader.scss';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventsGetParams } from 'src/app/api';
import classNames from 'classnames';

interface BookingsHeaderProps {
  onPrevClick?: () => void;
  onTodayClick?: () => void;
  onNextClick?: () => void;
  onViewChange?: (view: string) => void;
  onToggleCalendar?: () => void;
  onToggleList?: () => void;
  onCreate?: () => void;
  eventFilters: EventsGetParams;
  handleDrawerToggle?: () => void;
  drawerOpen?: boolean;
}

const translationArray = ['calendar'];

export default function BookingsHeader({
  onPrevClick,
  onTodayClick,
  onNextClick,
  onViewChange,
  onToggleCalendar,
  onToggleList,
  onCreate,
  eventFilters,
  handleDrawerToggle,
  drawerOpen,
}: BookingsHeaderProps) {
  const { t } = useTranslation(translationArray);
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [isCalendarDisabled, setIsCalendarDisabled] = useState(true);
  const [isListDisabled, setIsListDisabled] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-param-reassign
    const pathnameCalendar = `${pathname}?start_at__gte=${eventFilters.start_at}&end_at__lte=${eventFilters.end_at}`;
    setIsListDisabled(pathname === '/bookings/list');
    setIsCalendarDisabled(
      pathnameCalendar ===
        `/bookings/calendar/?start_at__gte=${eventFilters.start_at}&end_at__lte=${eventFilters.end_at}`,
    );
  }, [eventFilters.end_at, eventFilters.start_at, navigate, pathname]);

  return (
    <Row className="calendar-list-header w-100">
      <Col className="header-item">
        <Button
          className={classNames('calendar-nav-button display-drawer-button', { isClosed: !drawerOpen })}
          onClick={handleDrawerToggle}
        >
          <MenuOpenIcon fontSize="small" className="display-drawer-icon" />
        </Button>
        <Button
          onClick={() => {
            if (onToggleCalendar) {
              onToggleCalendar();
            }
            setIsCalendarDisabled(!isCalendarDisabled);
            setIsListDisabled(!isListDisabled);
          }}
          disabled={isCalendarDisabled}
          className="bookings-nav-button bookings-nav-button-wide"
        >
          <EventAvailableIcon fontSize="large" className="bookings-nav-header-icon" />
          <span className="ms-Button-label">{t('buttons.calendar')}</span>
        </Button>
        <Button
          onClick={() => {
            if (onToggleList) {
              onToggleList();
            }
            setIsCalendarDisabled(!isCalendarDisabled);
            setIsListDisabled(!isListDisabled);
          }}
          disabled={isListDisabled}
          className="bookings-nav-button"
        >
          <FormatListBulletedIcon fontSize="large" className="bookings-nav-header-icon" />
          <span className="ms-Button-label">{t('buttons.list')}</span>
        </Button>
      </Col>
      {pathname === '/bookings/list' ? (
        <Col className="header-item">
          <Button onClick={onCreate} className="create-button">
            <AddIcon fontSize="medium" className="create-icon" />
          </Button>
        </Col>
      ) : (
        <>
          <Col className="header-item">
            <Button onClick={onPrevClick} className="calendar-nav-button">
              <KeyboardArrowLeftIcon fontSize="medium" className="calendar-nav-arrow-icon left" />
            </Button>
            <Button onClick={onTodayClick} className="calendar-nav-button calendar-nav-button-wide">
              {t('buttons.today')}
            </Button>
            <Button onClick={onNextClick} className="calendar-nav-button">
              <KeyboardArrowRightIcon fontSize="medium" className="calendar-nav-arrow-icon right" />
            </Button>
          </Col>
          <Col className="header-item">
            <Button
              onClick={() => onViewChange?.('timeGridDay')}
              className="calendar-nav-button calendar-nav-button-wide"
            >
              {t('buttons.day')}
            </Button>
            <Button
              onClick={() => onViewChange?.('timeGridWeek')}
              className="calendar-nav-button calendar-nav-button-wide"
            >
              {t('buttons.week')}
            </Button>
            <Button
              onClick={() => onViewChange?.('dayGridMonth')}
              className="calendar-nav-button calendar-nav-button-wide"
            >
              {t('buttons.month')}
            </Button>
            <Button onClick={onCreate} className="create-button">
              <AddIcon fontSize="medium" className="create-icon" />
            </Button>
          </Col>
        </>
      )}
    </Row>
  );
}
