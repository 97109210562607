import { Button, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import moment from 'moment-timezone';
import { BookingEvent } from '../EventList/EventItem';
import { Interpretation, Language, ServiceRoot } from '../../../../../app/types/Entities';
import EventListField from '../EventList/EventListField';
import { useForm, useStateArray } from '../../../../../app/hooks';
import InterpreterField from '../InterpreterField';
import { FollowUpSubmissionPayload } from './localTypes';

export interface FollowUpFieldCreationSectionProps {
  formPrefix: string;
  targetLanguage: Language | null;
  serviceRoot: ServiceRoot<Interpretation> | null;

  createFollowUpBooking: (
    formSubmitEvent: undefined,
    followUpSubmissionPayload: FollowUpSubmissionPayload,
  ) => Promise<unknown>;
  onCancelClick: () => void;
}

const translationArray = ['booking'];

export default function FollowUpFieldCreationSection(props: FollowUpFieldCreationSectionProps) {
  const { formPrefix, targetLanguage, serviceRoot, createFollowUpBooking, onCancelClick } = props;

  // Utils
  const { t } = useTranslation(translationArray);
  const { p } = useForm(formPrefix);

  // Event
  const [showFollowUpEvents, setShowFollowUpEvents] = useState<boolean>(false);

  const newEvent = (): BookingEvent => {
    return {
      start_at: moment().toDate(),
      description: 'Physical Therapy',
      expected_duration: 60,
      arrive_early: 0,
    };
  };

  const {
    values: followUpEvents,
    set: setFollowUpEvents,
    push: pushFollowUpEvents,
    replaceAt: replaceFollowUpEventsAt,
    removeAt: removeFollowUpEventsAt,
  } = useStateArray<BookingEvent>([newEvent()]);

  // Interpreter
  const [showInterpreter, setShowInterpreter] = useState<boolean>(false);
  const [followUpService, setFollowUpService] = useState<Interpretation | null>(null);

  const [followUp] = useState<boolean>(true);

  return (
    <Container className="p-0">
      <Row>
        <Col>
          <EventListField
            formPrefix={p`eventList`}
            showEventField={showFollowUpEvents}
            setShowEventField={setShowFollowUpEvents}
            events={followUpEvents}
            pushEvent={pushFollowUpEvents}
            replaceEventAt={replaceFollowUpEventsAt}
            removeEventAt={removeFollowUpEventsAt}
            setEvents={setFollowUpEvents}
            newEvent={newEvent}
            initialEventsList={[newEvent()]}
            followUp={followUp}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <InterpreterField
            isFromFollowUp
            showInterpreter={showInterpreter}
            setShowInterpreter={setShowInterpreter}
            service={followUpService}
            setService={setFollowUpService}
            targetLanguage={targetLanguage}
            serviceRoot={serviceRoot}
            offers={[]}
            setOffers={() => {}}
            onUpdateSuccess={(newService) => {
              setFollowUpService(newService ?? null);
              setShowInterpreter(false);
            }}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Button
            className="action-button me-4"
            color="submit"
            onClick={() =>
              createFollowUpBooking(undefined, { events: followUpEvents, service: followUpService ?? undefined })
            }
            block
          >
            {t`createFollowUp`}
          </Button>
        </Col>
        <Col>
          <Button
            className="action-button"
            color="cancel"
            onClick={() => {
              setFollowUpEvents([newEvent()]);
              setFollowUpService(null);
              onCancelClick();
            }}
            block
          >
            {t`cancel`}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
