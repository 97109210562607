import { useTranslation } from 'react-i18next';
import { useForm } from '../../../../app/hooks';
import { FormGroupProps } from '../../../../app/types/Components';
import { FormInput, FormInputProps } from '../../../../components/Form/FormInput';

export interface CategoryFormGroupProps extends FormGroupProps {
  descriptionProps?: Partial<FormInputProps>;
  nameProps: Partial<FormInputProps>;
}

export function CategoryFormGroup({ formPrefix = 'category', descriptionProps, nameProps }: CategoryFormGroupProps) {
  const { t } = useTranslation('forms');
  const { p } = useForm(formPrefix);

  return (
    <>
      <FormInput id={p`name`} type="text" label={t`name`} required {...nameProps} />
      <FormInput id={p`description`} type="textarea" label={t`description`} required {...descriptionProps} />
    </>
  );
}
