import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid';
import { CircularProgress, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import 'src/assets/styles/Tables.scss';
import { FormTable } from '../../../components/Form/FormTable/FormTable';
import { MUTATION_KEYS, CategoriesGetParams, getCategoriesPaginated } from '../../../app/api';
import { useStateDict } from '../../../app/hooks';
import { CategoryOnboardingForm } from './Onboarding/Form';
import { Category } from '../../../app/types/Entities';

export default function CategoriesTable() {
  const { t } = useTranslation('tables');

  // Pagination
  const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({ page: 0, pageSize: 25 });

  const [queryFilters, setQueryFilters] = useStateDict<CategoriesGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
  });

  useEffect(() => {
    setQueryFilters({
      ...queryFilters,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, setQueryFilters]);

  // Query
  const {
    data: categoriesPaginatedData,
    isLoading,
    refetch: refetchCategoriesPaginatedData,
  } = useQuery([MUTATION_KEYS.CATEGORIES, queryFilters], () => getCategoriesPaginated(queryFilters));

  const filteredCategories =
    categoriesPaginatedData?.results?.map((category): any => {
      return {
        ...category,
      };
    }) ?? [];

  const [rowCountState, setRowCountState] = useState(categoriesPaginatedData?.count || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      categoriesPaginatedData?.count !== undefined ? categoriesPaginatedData.count : prevRowCountState,
    );
  }, [categoriesPaginatedData?.count, setRowCountState]);

  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>();
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);
  const [categoryPreloadStatus, setPreloadStatus] = useState<'loading' | 'success' | 'error' | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const hideModal = () => {
    setShowCategoryModal(false);
    setSelectedCategory(undefined);
    setPreloadStatus(undefined);
  };

  const rows: GridRowsProp = filteredCategories.map((category) => {
    return {
      id: category.id,
      name: category.name,
      description: category.description,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t`category.name`,
      flex: 1,
    },
    {
      field: 'description',
      headerName: t`category.description`,
      flex: 1,
    },
  ];

  return (
    <>
      <Container className="w-100 h-100 mw-100">
        <Row className="w-100">
          <Col className="d-flex justify-content-end align-items-center">
            <Typography className="me-4">
              {t('category.totalCategories', { categories: categoriesPaginatedData?.count })}
            </Typography>

            <Button
              color="light"
              className="my-3 Table-Add-Button"
              onClick={() => {
                setSelectedCategory(undefined);
                setShowCategoryModal(true);
                setPreloadStatus(undefined);
              }}
            >
              <AddIcon fontSize="large" />
            </Button>
          </Col>
        </Row>

        <Row className="w-100 h-100">
          <Col className="d-flex flex-column">
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <CircularProgress size="4rem" sx={{ color: '#00A2B8' }} />

                <span>
                  <b>{t`loadingTable`}</b>
                </span>
              </div>
            ) : (
              <FormTable
                rows={rows}
                rowCount={rowCountState}
                columns={columns}
                sx={{ backgroundColor: 'white' }}
                onRowClick={(params: GridRowParams) => {
                  const categoryId = params.row.id;
                  const category = categoriesPaginatedData?.results.find(({ id }) => id === categoryId);

                  setSelectedCategory(category);
                  setShowCategoryModal(true);

                  if (categoryPreloadStatus === undefined) {
                    setPreloadStatus('loading');
                  }
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                setPaginationModel={setPaginationModel}
                exportable={false}
              />
            )}
          </Col>
        </Row>
      </Container>

      <Modal fullscreen className="admin-modal" isOpen={showCategoryModal} toggle={hideModal}>
        <ModalHeader className="admin-modal-header">
          <Row className="align-items-center">
            <Col>
              <Button className="action-button" color="submit" disabled={isSubmitting} form="category-onboarding-form">
                {isSubmitting ? <Spinner className="me-2" size="sm" type="border" color="primary" /> : t`save`}
              </Button>
            </Col>

            <Col xs="auto">
              <Button color="transparent" disabled={isSubmitting} onClick={hideModal}>
                <CloseIcon fontSize="large" style={{ fill: 'white' }} />
              </Button>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody className="admin-modal-body">
          <div className="admin-modal-content-header">
            <Row className="align-items-center">
              <Col xs="auto">
                {selectedCategory !== undefined ? selectedCategory.name : t('category.modalTitleNew')}
              </Col>
            </Row>
          </div>

          <CategoryOnboardingForm
            id="category-onboarding-form"
            preloadCategoryId={selectedCategory?.id ?? undefined}
            preSubmit={() => {
              setIsSubmitting(true);

              return true;
            }}
            onSubmitSuccess={() => {
              refetchCategoriesPaginatedData().then(hideModal);
            }}
            postSubmit={() => {
              setIsSubmitting(false);
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
