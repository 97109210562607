import { useTranslation } from 'react-i18next';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { UncontrolledPopoverProps } from 'reactstrap/types/lib/Popover';
import { Booking } from '../../../../../app/types/Entities';
import { CLINIC_AGENT_ROLES, COMPANY_TYPES } from '../../../../../app/helpers/enum';
import { userToString } from '../../../../../app/helpers/mappers';
import './ChildBookingView.scss';

export interface ChildBookingSummaryPopoverProps extends UncontrolledPopoverProps {
  booking: Booking;
}

const translationArray = ['booking'];

export default function ChildBookingSummaryPopover({ booking, ...props }: ChildBookingSummaryPopoverProps) {
  const { t } = useTranslation(translationArray);

  const event = booking.events?.[0];
  const patient = event?.affiliates?.[0].recipient;
  const service = booking.services?.[0];
  const medicalProvider = event?.agents?.find(({ role }) => role === CLINIC_AGENT_ROLES.MEDICAL_PROVIDER);

  return (
    <UncontrolledPopover {...props}>
      <PopoverHeader>Booking #{booking?.public_id}</PopoverHeader>
      <PopoverBody>
        <ul className="booking-summary-list">
          <li>
            <b>{t`patient`}</b>: {patient ? userToString(patient) : t`none`}
          </li>
          <li>
            <b>{t`service`}</b>: {service?.root?.name}, {booking.target_language?.name}
          </li>
          <li>
            <b>{t`clinic`}</b>: {booking.companies.find(({ type }) => type === COMPANY_TYPES.CLINIC)?.name}
          </li>
          <li>
            <b>{t`medicalProvider`}</b>: {medicalProvider ? userToString(medicalProvider) : t`none`}
          </li>
          <li>
            <b>{t`requester`}</b>: {event?.requester ? userToString(event?.requester) : t`none`}
          </li>
          <li>
            <b>{t`interpreter`}</b>: {service?.provider ? userToString(service?.provider) : t`none`}
          </li>
          <li>
            <b>{t`claim`}</b>: #{event?.claim_number ?? t`none`}
          </li>
        </ul>
      </PopoverBody>
    </UncontrolledPopover>
  );
}
