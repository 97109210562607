import { Button, Col, Row } from 'reactstrap';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import { DatabaseId } from 'src/app/types/DataStructures';
import { mapObjectForSelect } from 'src/app/helpers/mappers';
import { TYPES_OF_APPOINTMENTS } from 'src/app/helpers/enum';
import { FormInput } from 'src/components/Form/FormInput';
import './EventItem.scss';
import classNames from 'classnames';
import FormDateTimePicker from 'src/components/DatePicker/FormDateTimePicker';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { TextField } from '@mui/material';
import { useForm } from 'src/app/hooks';

export interface EventItemProps {
  formPrefix: string;
  event: BookingEvent;
  isChild: boolean;
  onRemove: () => void;
  setEventStartDate: (startDate: Date) => void;
  setTypeOfAppointment: (TypeOfAppointments: string) => void;
  setExpectedDuration: (expectedDuration: number | '') => void;
  setArriveEarly: (arriveEarly: number | '') => void;
  followUp: boolean;
}

export interface BookingEvent {
  id?: DatabaseId;
  deleted?: boolean;
  start_at: Date;
  description: string;
  expected_duration: number | '';
  arrive_early: number | '';
}

export default function EventItem({
  formPrefix,
  event,
  isChild,
  onRemove,
  setEventStartDate,
  setTypeOfAppointment,
  setExpectedDuration,
  setArriveEarly,
  followUp,
}: EventItemProps) {
  const { t } = useTranslation('booking');
  const { p } = useForm(formPrefix);

  const typesOfAppointmentsOptions = () => {
    if (followUp) {
      return mapObjectForSelect(TYPES_OF_APPOINTMENTS, (_, value) => value).filter(
        ({ value }) => value !== TYPES_OF_APPOINTMENTS.INITIAL_CONSULTATION,
      );
    }
    return mapObjectForSelect(TYPES_OF_APPOINTMENTS, (_, value) => value);
  };

  return (
    <Row className={classNames('gx-2', { event_item_separator: isChild })}>
      <Row className="gx-2">
        <Col xs="auto" className="flex-grow-1 flex-basis-">
          <FormDateTimePicker
            showTimeInput
            label={t`eventStartDate`}
            onChange={(date) => date && setEventStartDate(date.toDate())}
            value={event.start_at}
          />
        </Col>
        {isChild ? (
          <Col xs="auto" className="flex-grow-0">
            <Button className="add-button" color="action" onClick={() => onRemove?.()}>
              <RemoveIcon fontSize="large" />
            </Button>
          </Col>
        ) : null}
      </Row>
      <Row className="gx-2">
        <Col xs={12}>
          <FormSelect
            id={p`typeOfAppointment`}
            options={typesOfAppointmentsOptions()}
            value={typesOfAppointmentsOptions().find(({ value }) => value === event.description)}
            onChange={(_event: any, newValue) => {
              setTypeOfAppointment(newValue.value);
            }}
            renderInput={(params) => <TextField label={t`typeOfAppointment`} {...params} />}
            disableClearable
            getOptionLabel={(option) => (option.label ? option.label : event.description)}
          />
        </Col>
        <Col xs={12} xxl={6}>
          <FormInput
            label={t`expectedDuration`}
            required
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={3}
            value={event.expected_duration}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (!Number.isNaN(value)) {
                setExpectedDuration(e.target.value && value);
              }
            }}
            onBlur={(e) => {
              if (e.target.value === '') {
                setExpectedDuration(0);
              }
            }}
          />
        </Col>
        <Col xs={12} xxl={6}>
          <FormInput
            label={t`arriveEarly`}
            required
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={3}
            value={event.arrive_early}
            onChange={(e) => {
              const value = Number(e.target.value);
              if (!Number.isNaN(value)) {
                setArriveEarly(e.target.value && value);
              }
            }}
            onBlur={(e) => {
              if (e.target.value === '') {
                setArriveEarly(0);
              }
            }}
          />
        </Col>
      </Row>
    </Row>
  );
}
