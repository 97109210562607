/* eslint-disable @typescript-eslint/no-use-before-define,no-unused-vars */
import validatorStatus from 'src/app/helpers/statusStructure';
import { isEmpty, isNil } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { isArray, isDateStringValid, isObject, parseDate, parseDateTime } from '../../helpers/manipulation';
import {
  Affiliation,
  Agent,
  AuthorizationShallow,
  BaseProvider,
  BaseServiceRoot,
  BEvent,
  BEventShallow,
  BookingLight,
  Category,
  Company,
  Contact,
  EventClaim,
  Expense,
  GlobalSetting,
  Language,
  Location,
  Note,
  Operator,
  ParentCompany,
  Payer,
  PreferredAgency,
  PreferredAgencyWithCompanies,
  Provider,
  ProviderCertification,
  Rate,
  Recipient,
  Report,
  Requester,
  Service,
  ServiceArea,
  ServiceRoot,
  UninsuredAffiliation,
  User,
} from '../../types/Entities';
import { Hint } from '../../types/TypeMappers';
import {
  invokeMap,
  invokeOnNotNil,
  isFromEnum,
  isNotNil,
  isNumeric,
  isNumericOrNil,
  isStringOrNil,
  sanitize,
  sanitizeCurrency,
  sanitizeFK,
  sanitizeId,
  sanitizeOrOmit,
  toBoolean,
  toNumberOrNil,
  toStringOrNil,
} from '../../helpers/sanitizers';
import {
  AUTHORIZATION_STATUSES,
  AuthorizationPriority,
  AuthorizationStatus,
  BILL_RATE_TYPES,
  BillRateType,
  CASE_TYPES,
  CaseType,
  CompanyType,
  InvoiceFormat,
  InvoiceFrequency,
  InvoiceGrouping,
  InvoiceSpecialField,
  PAYER_TYPES,
  PayerType,
  REQUESTER_TYPES,
  SEND_METHODS,
  SendInvoiceMethod,
  SendMethod,
  TYPES_OF_APPOINTMENTS,
  TypesOfAppointments,
} from '../../helpers/enum';
// import { computeBookingStatus } from '../../helpers/services';
import {
  DehydratedAuthorization,
  DehydratedBooking,
  DehydratedBookingInterpretation,
  DehydratedEvent,
  DehydratedInterpretation,
  DehydratedInterpreter,
  DehydratedOffer,
} from './dehydrated_entities';

/*
 * Sanitizers layer
 * Sanitizers are functions that take a data object of unknown type and return a sanitized version of it.
 * They are used to ensure that the data we receive from the API is valid.
 * They are needed because of the generic model and the extras.
 */

export type Sanitizer<R> = (data: Hint<R>) => R;
export type SanitizerWithArgs<R, TConfig = never> = <TOmit extends keyof R = never>(
  data: Hint<R>,
  args?: {
    config?: TConfig;
    omit?: TOmit;
  },
) => Omit<R, TOmit>;

// Category
export const sanitizeCategory: Sanitizer<Category> = (data) => {
  if (!isObject(data)) {
    console.error('Category: invalid data type');
  }
  return {
    id: sanitizeId(data),
    description: sanitize(data, 'description', String, isNotNil),
    name: sanitize(data, 'name', String, isNotNil),
  };
};

// Global Setting
export const sanitizeGlobalSetting: Sanitizer<GlobalSetting> = (data) => {
  if (!isObject(data)) {
    console.error('Global Setting: invalid data type');
  }
  return {
    id: sanitizeId(data),
    client: sanitize(data, 'client', String),
    business: sanitize(data, 'business', Number),
    rates: sanitize(data, 'rates', invokeMap(sanitizeRate), isArray),
  };
};

// Rate
export const sanitizeRate: Sanitizer<Rate> = (data) => {
  if (!isObject(data)) {
    console.error('Rate: invalid data type');
  }
  return {
    id: sanitizeId(data),
    root: sanitize(data, 'root', invokeOnNotNil(sanitizeBaseServiceRoot)),
    bill_amount: sanitizeCurrency(data, 'bill_amount'),
    bill_rate: sanitize(data, 'bill_rate', Number, isNumeric),
    bill_rate_type: sanitize(data, 'bill_rate_type', (v) => String(v) as BillRateType, isFromEnum(BILL_RATE_TYPES)),
    bill_rate_minutes_threshold: sanitize(data, 'bill_rate_minutes_threshold', invokeOnNotNil(Number), isNumericOrNil),
    bill_min_payment: sanitizeCurrency(data, 'bill_min_payment'),
    bill_no_show_fee: sanitizeCurrency(data, 'bill_no_show_fee'),
    language: sanitize(data, 'language', String, isNotNil),
  };
};

// Contact
export const sanitizeContact: Sanitizer<Contact> = (data) => {
  if (!isObject(data)) {
    console.error('Contact: invalid data type');
  }
  return {
    id: sanitizeId(data),
    email: sanitize(data, 'email', String),
    fax: sanitize(data, 'fax', String),
    phone: sanitize(data, 'phone', String),
    email_context: sanitize(data, 'email_context', String),
    fax_context: sanitize(data, 'fax_context', String),
    phone_context: sanitize(data, 'phone_context', String),
  };
};

// Language
export const sanitizeLanguage: Sanitizer<Language> = (data) => {
  if (!isObject(data)) {
    console.error('Language: invalid data type');
  }
  return {
    id: sanitizeId(data),
    alpha2: sanitize(data, 'alpha2', String),
    alpha3: sanitize(data, 'alpha3', String),
    available: sanitize(data, 'available', Boolean),
    common: sanitize(data, 'common', Boolean),
    description: sanitize(data, 'description', String),
    name: sanitize(data, 'name', String),
  };
};

// Location
export const sanitizeLocation: Sanitizer<Location> = (data) => {
  if (!isObject(data)) {
    console.error('Contact: invalid data type');
  }
  return {
    id: sanitizeId(data),
    address: sanitize(data, 'address', invokeOnNotNil(String)),
    unit_number: sanitize(data, 'unit_number', invokeOnNotNil(String)),
    city: sanitize(data, 'city', invokeOnNotNil(String)),
    state: sanitize(data, 'state', invokeOnNotNil(String)),
    country: sanitize(data, 'country', invokeOnNotNil(String)),
    zip: sanitize(data, 'zip', invokeOnNotNil(String)),
  };
};

// Notes
export const sanitizeNote: SanitizerWithArgs<Note> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Note: invalid data type');
  }
  return {
    id: sanitizeId(data),
    created_at: sanitizeOrOmit(
      data,
      omit,
      'created_at',
      invokeOnNotNil(parseDateTime),
      (v) => isNil(v) || isDateStringValid(v),
    ),
    last_updated_at: sanitizeOrOmit(
      data,
      omit,
      'last_updated_at',
      invokeOnNotNil(parseDateTime),
      (v) => isNil(v) || isDateStringValid(v),
    ),
    created_by: sanitize(data, 'created_by', invokeOnNotNil(Number), isNumericOrNil),
    created_by_first_name: sanitize(data, 'created_by_first_name', invokeOnNotNil(String), isStringOrNil),
    created_by_last_name: sanitize(data, 'created_by_last_name', invokeOnNotNil(String), isStringOrNil),
    text: sanitize(data, 'text', String),
    uuid: uuidv4(),
  };
};

// Company
export const sanitizeBaseCompany: Sanitizer<ParentCompany> = (data) => {
  if (!isObject(data)) {
    console.error('Company: invalid data type');
  }
  return {
    id: sanitizeId(data),
    name: sanitize(data, 'name', String, isNotNil),
    type: sanitize(data, 'type', (v) => String(v) as CompanyType),
    send_method: sanitize(data, 'send_method', (v) => String(v) as SendMethod),
    on_hold: sanitize(data, 'on_hold', Boolean),
    contacts: sanitize(data, 'contacts', invokeMap(sanitizeContact), isArray),
    locations: sanitize(data, 'locations', invokeMap(sanitizeLocation), isArray),
    notes: sanitize(data, 'notes', invokeMap(sanitizeNote), isArray),

    min_weekly_assignments: sanitize(data, 'min_weekly_assignments', invokeOnNotNil(Number), isNumericOrNil),
    parking_extra: sanitize(data, 'parking_extra', toBoolean),
    parking_ticket: sanitize(data, 'parking_ticket', toBoolean),
    bonus_extra: sanitize(data, 'bonus_extra', toBoolean),
    mileage_extra: sanitize(data, 'mileage_extra', toBoolean),
    mileage_rate: sanitize(data, 'mileage_rate', invokeOnNotNil(String), isNumericOrNil),
    preferred_agencies: sanitize(
      data,
      'company_relationships_from',
      invokeOnNotNil(invokeMap(sanitizePreferredAgency)),
      (v) => isNil(v) || isArray(v),
    ),

    authorization_priority: sanitize(data, 'authorization_priority', (v) => String(v)) as AuthorizationPriority,

    send_invoice_method: sanitize(data, 'send_invoice_method', (v) => String(v) as SendInvoiceMethod),
    invoice_format: sanitize(data, 'invoice_format', (v) => String(v) as InvoiceFormat),
    invoice_grouping: sanitize(data, 'invoice_grouping', (v) => String(v) as InvoiceGrouping),
    invoice_batch_size: sanitize(data, 'invoice_batch_size', invokeOnNotNil(String), isNumericOrNil),
    invoice_deadline: sanitize(data, 'invoice_deadline', invokeOnNotNil(String), isNumericOrNil),
    invoice_frequency: sanitize(data, 'invoice_frequency', (v) => String(v) as InvoiceFrequency),
    invoice_special_field: sanitize(data, 'invoice_special_field', (v) => String(v) as InvoiceSpecialField),
    aliases: sanitize(data, 'aliases', invokeMap(toString), isArray),
  };
};

export const sanitizeCompany: Sanitizer<Company> = (data) => {
  if (!isObject(data)) {
    console.error('Company: invalid data type');
  }
  return {
    ...sanitizeBaseCompany(data),
    parent_company: sanitize(data, 'parent_company', invokeOnNotNil(sanitizeBaseCompany)),
  };
};

export const sanitizeCompanyWithRoles: Sanitizer<Company> = (data) => {
  if (!isObject(data)) {
    console.error('Company: invalid data type');
  }
  return {
    ...sanitizeCompany(data),
    agents: sanitize(data, 'agents', invokeMap(sanitizeAgent), isArray),
    operators: sanitize(data, 'operators', invokeMap(sanitizeOperator), isArray),
    payers: sanitize(data, 'payers', invokeMap(sanitizePayer), isArray),
    providers: sanitize(data, 'providers', invokeMap(sanitizeProvider), isArray),
    recipients: sanitize(data, 'recipients', invokeMap(sanitizeRecipient), isArray),
    requesters: sanitize(data, 'requesters', invokeMap(sanitizeRequester), isArray),
    rates: sanitize(data, 'rates', invokeOnNotNil(invokeMap(sanitizeRate)), isArray),
  };
};

// User
export const sanitizeUser: SanitizerWithArgs<User> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('User: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    user_id: sanitizeOrOmit(data, omit, 'user_id', Number),
    contacts: sanitizeOrOmit(data, omit, 'contacts', invokeMap(sanitizeContact), isArray),
    location: sanitizeOrOmit(data, omit, 'location', invokeOnNotNil(sanitizeLocation)),
    username: sanitizeOrOmit(data, omit, 'username', String, isNotNil),
    email: sanitizeOrOmit(data, omit, 'email', String, isNotNil),
    first_name: sanitizeOrOmit(data, omit, 'first_name', String),
    last_name: sanitizeOrOmit(data, omit, 'last_name', String),
    national_id: sanitizeOrOmit(data, omit, 'national_id', toStringOrNil, isStringOrNil),
    ssn: sanitizeOrOmit(data, omit, 'ssn', toStringOrNil, isStringOrNil),
    date_of_birth: sanitizeOrOmit(
      data,
      omit,
      'date_of_birth',
      invokeOnNotNil(parseDate),
      (v) => isNil(v) || isDateStringValid(v),
    ),
    title: sanitizeOrOmit(data, omit, 'title', toStringOrNil, isStringOrNil),
    suffix: sanitizeOrOmit(data, omit, 'suffix', toStringOrNil, isStringOrNil),
    agents_id: sanitizeOrOmit(data, omit, 'agents_id', invokeMap(toNumberOrNil), isArray),
    operator_id: sanitizeOrOmit(data, omit, 'operator_id', toNumberOrNil, isNumericOrNil),
    payer_id: sanitizeOrOmit(data, omit, 'payer_id', toNumberOrNil, isNumericOrNil),
    provider_id: sanitizeOrOmit(data, omit, 'provider_id', toNumberOrNil, isNumericOrNil),
    recipient_id: sanitizeOrOmit(data, omit, 'recipient_id', toNumberOrNil, isNumericOrNil),
    requester_id: sanitizeOrOmit(data, omit, 'requester_id', toNumberOrNil, isNumericOrNil),
    admin_id: sanitizeOrOmit(data, omit, 'admin_id', toNumberOrNil, isNumericOrNil),
    is_operator: sanitizeOrOmit(data, omit, 'is_operator', Boolean),
    is_provider: sanitizeOrOmit(data, omit, 'is_provider', Boolean),
    is_recipient: sanitizeOrOmit(data, omit, 'is_recipient', Boolean),
    is_requester: sanitizeOrOmit(data, omit, 'is_requester', Boolean),
    is_payer: sanitizeOrOmit(data, omit, 'is_payer', Boolean),
    is_admin: sanitizeOrOmit(data, omit, 'is_admin', Boolean),
    aliases: sanitizeOrOmit(data, omit, 'aliases', invokeMap(toString), isArray),
  };
};

// Agent
export const sanitizeAgent: Sanitizer<Agent> = (data) => {
  if (!isObject(data)) {
    console.error('Agent: invalid data type');
  }
  return {
    ...sanitizeUser(data),
    companies: sanitize(data, 'companies', invokeMap(Number), isArray),
    role: sanitize(data, 'role', String, isNotNil),
  };
};

export const sanitizeAgentWithCompanies: Sanitizer<Agent> = (data) => {
  if (!isObject(data)) {
    console.error('Agent: invalid data type');
  }
  return {
    ...sanitizeUser(data),
    companies: sanitize(data, 'companies', invokeMap(sanitizeCompany), isArray),
    role: sanitize(data, 'role', String, isNotNil),
  };
};

// Operator
export const sanitizeOperator: Sanitizer<Operator> = (data) => {
  if (!isObject(data)) {
    console.error('Operator: invalid data type');
  }
  return {
    ...sanitizeUser(data),
  };
};

// Payer
export const sanitizePayer: Sanitizer<Payer> = (data) => {
  if (!isObject(data)) {
    console.error('Payer: invalid data type');
  }
  return {
    ...sanitizeUser(data),
    companies: sanitize(data, 'companies', invokeMap(sanitizeCompany), isArray),
    notes: sanitize(data, 'notes', invokeMap(sanitizeNote), isArray),
  };
};

// Provider
export const sanitizeProviderCertification: Sanitizer<ProviderCertification> = (data) => {
  if (!isObject(data)) {
    console.error('Provider certification: invalid data type');
  }
  return {
    certificate_id: sanitize(data, 'certificate_id', Number),
    certificate_number: sanitize(data, 'certificate_number', String),
    expiration_date: sanitize(
      data,
      'expiration_date',
      invokeOnNotNil(parseDate),
      (v) => isNil(v) || isDateStringValid(v),
    ),
  };
};

export const sanitizeBaseProvider: Sanitizer<BaseProvider> = (data) => {
  if (!isObject(data)) {
    console.error('Provider: invalid data type');
  }
  return {
    ...(sanitizeUser(data) as User),
    companies: sanitize(data, 'companies', invokeMap(Number), isArray),
    notes: sanitize(data, 'notes', invokeOnNotNil(invokeMap(sanitizeNote)), (v) => isNil(v) || isArray(v)),
    min_weekly_assignments: sanitize(data, 'min_weekly_assignments', invokeOnNotNil(Number), isNumericOrNil),
    parking_extra: sanitize(data, 'parking_extra', toBoolean),
    parking_ticket: sanitize(data, 'parking_ticket', toBoolean),
    bonus_extra: sanitize(data, 'bonus_extra', toBoolean),
    mileage_extra: sanitize(data, 'mileage_extra', toBoolean),
    mileage_rate: sanitize(data, 'mileage_rate', invokeOnNotNil(String), isNumericOrNil),
    certifications: sanitize(
      data,
      'certifications',
      invokeOnNotNil(invokeMap(sanitizeProviderCertification)),
      (v) => isNil(v) || isArray(v),
    ),
    service_areas: sanitize(
      data,
      'service_areas',
      invokeOnNotNil(invokeMap(sanitizeServiceArea)),
      (v) => isNil(v) || isArray(v),
    ),
  };
};

export const sanitizeProvider: Sanitizer<Provider> = (data) => {
  return {
    ...sanitizeBaseProvider(data),
    services: sanitize(
      data,
      'notes',
      invokeMap((v) => sanitizeService(v, { omit: 'provider' })),
      isArray,
    ),
  };
};

export const sanitizeInterpreter: Sanitizer<DehydratedInterpreter> = (data) => {
  return {
    ...sanitizeBaseProvider(data),
    services: sanitize(
      data,
      'services',
      invokeMap((v) => sanitizeInterpretation(v, { omit: 'provider' })),
      isArray,
    ),
  };
};

// Recipient
export const sanitizeRecipient: SanitizerWithArgs<Recipient> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Recipient: invalid data type');
  }
  return {
    ...(sanitizeUser(data) as User),
    affiliations: sanitizeOrOmit(
      data,
      omit,
      'affiliations',
      invokeMap((v) => sanitizeAffiliation(v, { omit: 'recipient' })),
      isArray,
    ),
    companies: sanitizeOrOmit(data, omit, 'companies', invokeMap(Number), isArray),
    notes: sanitize(data, 'notes', invokeOnNotNil(invokeMap(sanitizeNote)), (v) => isNil(v) || isArray(v)),
  };
};

// Requester
export const sanitizeRequester: Sanitizer<Requester> = (data) => {
  if (!isObject(data)) {
    console.error('Requester: invalid data type');
  }
  return {
    ...sanitizeUser(data),
  };
};

// Affiliation
export interface AffiliationSanitizerConfig {
  companyType?: CompanyType;
}

export const sanitizeUninsuredAffiliation: Sanitizer<UninsuredAffiliation> = (data) => {
  if (!isObject(data)) {
    console.error('Uninsured affiliation: invalid data type');
  }
  return {
    id: sanitizeId(data),
    company: sanitize(data, 'company', () => null, isEmpty),
    recipient: sanitize(data, 'recipient', (v) => sanitizeRecipient(v, { omit: 'affiliations' })),
  };
};

export const sanitizeAffiliation: SanitizerWithArgs<Affiliation, AffiliationSanitizerConfig> = (
  data,
  { omit } = {},
) => {
  if (!isObject(data)) {
    console.error('Affiliation: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    company: sanitizeOrOmit(
      data,
      omit,
      'company',
      invokeOnNotNil((v) => sanitizeCompany(v)),
    ),
    recipient: sanitizeOrOmit(data, omit, 'recipient', (v) => sanitizeRecipient(v, { omit: 'affiliations' })),
  };
};

// Service root
export interface SanitizerConfigServiceRoot {
  serviceSanitizer?: typeof sanitizeService;
}

export const sanitizeServiceRoot: SanitizerWithArgs<ServiceRoot, SanitizerConfigServiceRoot> = (
  data,
  { config, omit } = {},
) => {
  if (!isObject(data)) {
    console.error('Service root: invalid data type');
  }
  return {
    id: sanitizeId(data),
    categories: sanitizeOrOmit(data, omit, 'categories', invokeMap(sanitizeCategory), isArray),
    description: sanitize(data, 'description', String),
    name: sanitize(data, 'name', String),
    services: sanitizeOrOmit(
      data,
      omit,
      'services',
      invokeMap((v) => (config?.serviceSanitizer ?? sanitizeService)(v, { omit: 'root' }) as Omit<Service, 'root'>),
      isArray,
    ),
  };
};

export const sanitizeBaseServiceRoot: SanitizerWithArgs<BaseServiceRoot> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Service root: invalid data type');
  }
  return {
    id: sanitizeId(data),
    categories: sanitizeOrOmit(data, omit, 'categories', invokeMap(sanitizeCategory), isArray),
    description: sanitize(data, 'description', String),
    name: sanitize(data, 'name', String),
  };
};

// Service
export interface SanitizerConfigService {
  providerSanitizer?: typeof sanitizeBaseProvider;
}

export const sanitizeService: SanitizerWithArgs<Service, SanitizerConfigService> = (data, { config, omit } = {}) => {
  if (!isObject(data)) {
    console.error('Service: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    provider: sanitizeOrOmit(data, omit, 'provider', config?.providerSanitizer ?? sanitizeBaseProvider),
    root: sanitizeOrOmit(data, omit, 'root', invokeOnNotNil(sanitizeBaseServiceRoot)),
    bill_amount: sanitizeCurrency(data, 'bill_amount', omit),
    bill_rate: sanitizeOrOmit(data, omit, 'bill_rate', Number, isNumeric),
    bill_rate_type: sanitizeOrOmit(
      data,
      omit,
      'bill_rate_type',
      (v) => String(v) as BillRateType,
      isFromEnum(BILL_RATE_TYPES),
    ),
    bill_rate_minutes_threshold: sanitizeOrOmit(
      data,
      omit,
      'bill_rate_minutes_threshold',
      invokeOnNotNil(Number),
      isNumericOrNil,
    ),
    bill_min_payment: sanitizeCurrency(data, 'bill_min_payment', omit),
    bill_no_show_fee: sanitizeCurrency(data, 'bill_no_show_fee', omit),
  };
};

// Service Area
export interface SanitizerConfigServiceArea {
  providerSanitizer?: typeof sanitizeBaseProvider;
}

export const sanitizeServiceArea: SanitizerWithArgs<ServiceArea, SanitizerConfigServiceArea> = (
  data,
  { config, omit } = {},
) => {
  if (!isObject(data)) {
    console.error('Service area: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    provider: sanitizeOrOmit(data, omit, 'provider', config?.providerSanitizer ?? sanitizeBaseProvider),
    country: sanitize(data, 'country', invokeOnNotNil(String)),
    state: sanitize(data, 'state', invokeOnNotNil(String)),
    county: sanitize(data, 'county', invokeOnNotNil(String)),
    city: sanitize(data, 'city', invokeOnNotNil(String)),
    zip: sanitize(data, 'zip', invokeOnNotNil(String)),
  };
};

export const sanitizeInterpretation: SanitizerWithArgs<DehydratedInterpretation, {}> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Interpretation: invalid data type');
  }
  return {
    ...(sanitizeService(data, {
      omit: omit as keyof Service,
    }) as Service),
    source_language_alpha3: sanitize(data, 'source_language_alpha3', invokeOnNotNil(String), isStringOrNil),
    target_language_alpha3: sanitize(data, 'target_language_alpha3', invokeOnNotNil(String), isStringOrNil),
  };
};

// Preferred Agency
export interface SanitizerConfigPreferredAgency {
  companySanitizer?: typeof sanitizeBaseCompany;
}

export const sanitizePreferredAgency: SanitizerWithArgs<PreferredAgency, SanitizerConfigPreferredAgency> = (
  data,
  { omit } = {},
) => {
  if (!isObject(data)) {
    console.error('Service area: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    company_from: sanitize(data, 'company_from', invokeOnNotNil(Number), isNumericOrNil),
    company_to: sanitize(data, 'company_to', invokeOnNotNil(Number), isNumericOrNil),
    relationship: sanitize(data, 'relationship', invokeOnNotNil(String)),
  };
};

export const sanitizePreferredAgencyWithCompanies: SanitizerWithArgs<
  PreferredAgencyWithCompanies,
  SanitizerConfigPreferredAgency
> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Service area: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    company_from: sanitize(data, 'company_from', invokeOnNotNil(Number), isNumericOrNil),
    company_to: sanitize(data, 'company_to', invokeOnNotNil(sanitizeCompany), isNotNil),
    relationship: sanitize(data, 'relationship', invokeOnNotNil(String)),
  };
};

// Expense
export const sanitizeExpense: Sanitizer<Expense> = (data) => {
  if (!isObject(data)) {
    console.error('Expense: invalid data type');
  }
  return {
    id: sanitizeId(data),
    booking_id: sanitizeFK(data, 'booking_id'),
    amount: sanitizeCurrency(data, 'amount'),
    description: sanitize(data, 'description', String),
    quantity: sanitize(data, 'quantity', Number, isNumeric),
  };
};

// Authorization
export const sanitizeShallowAuthorization: Sanitizer<AuthorizationShallow> = (data) => {
  if (!isObject(data)) {
    console.error('Authorization (from Event): invalid data type');
  }
  return {
    id: sanitizeId(data),
    authorizer: sanitizeFK(data, 'authorizer'),
    company: sanitizeFK(data, 'company'),
    contact: sanitize(data, 'contact', invokeOnNotNil(Number), (v) => isNil(v) || isNumeric(v)),
    contact_via: sanitize(
      data,
      'contact_via',
      invokeOnNotNil((v) => String(v) as SendMethod),
      (v) => isNil(v) || isFromEnum(SEND_METHODS)(v),
    ),
    events: sanitize(data, 'events', invokeMap(Number), isArray),
    last_updated_at: sanitize(data, 'last_updated_at', parseDateTime, isDateStringValid),
    status: sanitize(data, 'status', (v) => String(v) as AuthorizationStatus, isFromEnum(AUTHORIZATION_STATUSES)),
  };
};

export const sanitizeAuthorization: Sanitizer<DehydratedAuthorization> = (data) => {
  if (!isObject(data)) {
    console.error('Authorization: invalid data type');
  }
  return {
    id: sanitizeId(data),
    authorizer: sanitize(data, 'authorizer', sanitizePayer, isObject),
    company: sanitize(data, 'company', sanitizeCompany, isObject),
    contact: sanitize(data, 'contact', invokeOnNotNil(sanitizeContact)),
    contact_via: sanitize(
      data,
      'contact_via',
      invokeOnNotNil((v) => String(v) as SendMethod),
      (v) => isNil(v) || isFromEnum(SEND_METHODS)(v),
    ),
    events: sanitize(data, 'events', invokeMap(sanitizeEvent), isArray),
    last_updated_at: sanitize(data, 'last_updated_at', parseDateTime, isDateStringValid),
    status: sanitize(data, 'status', (v) => String(v) as AuthorizationStatus, isFromEnum(AUTHORIZATION_STATUSES)),
  };
};

// Event
export const sanitizeEventClaim: Sanitizer<EventClaim> = (data) => {
  if (!isObject(data)) {
    console.error('Event claim: invalid data type');
  }
  return {
    claim_number: sanitize(data, 'claim_number', invokeOnNotNil(String)),
    date_of_injury: sanitize(data, 'date_of_injury', invokeOnNotNil(parseDate)),
    diagnosis: sanitize(data, 'diagnosis', invokeOnNotNil(String)),
    claim_insurance_id: sanitize(data, 'claim_insurance_id', invokeOnNotNil(Number), isNumericOrNil),
    claim_adjuster_id: sanitize(data, 'claim_adjuster_id', invokeOnNotNil(Number), isNumericOrNil),
    claim_lawfirm_id: sanitize(data, 'claim_lawfirm_id', invokeOnNotNil(Number), isNumericOrNil),
    claim_lawyer_id: sanitize(data, 'claim_lawyer_id', invokeOnNotNil(Number), isNumericOrNil),
  };
};

export const sanitizeBaseEvent: Sanitizer<Omit<BEvent, 'booking'>> = (data) => {
  if (!isObject(data)) {
    console.error('Event: invalid data type');
  }
  return {
    id: sanitizeId(data),
    affiliates: sanitize(
      data,
      'affiliates',
      invokeOnNotNil(invokeMap(sanitizeAffiliation)),
      (v) => isNil(v) || isArray(v),
    ),
    agents: sanitize(
      data,
      'agents',
      invokeOnNotNil(invokeMap(sanitizeAgentWithCompanies)),
      (v) => isNil(v) || isArray(v),
    ),
    authorizations: sanitize(data, 'authorizations', invokeMap(sanitizeShallowAuthorization), isArray),
    requester: sanitize(data, 'requester', sanitizeRequester, isNotNil),

    description: sanitize(
      data,
      'description',
      invokeOnNotNil((v) => String(v) as TypesOfAppointments),
      (v) => isNil(v) || isFromEnum(TYPES_OF_APPOINTMENTS)(v),
    ),
    location: sanitize(data, 'location', invokeOnNotNil(sanitizeLocation)),
    meeting_url: sanitize(data, 'meeting_url', invokeOnNotNil(String)),
    start_at: sanitize(data, 'start_at', parseDateTime, isDateStringValid),
    arrive_at: sanitize(data, 'arrive_at', invokeOnNotNil(parseDate), (v) => isNil(v) || isDateStringValid(v)),
    end_at: sanitize(data, 'end_at', parseDateTime, isDateStringValid),

    payer_company_type: sanitize(
      data,
      'payer_company_type',
      invokeOnNotNil((v) => String(v) as PayerType),
      (v) => isNil(v) || isFromEnum(PAYER_TYPES)(v),
    ),
    payer_company: sanitize(data, 'payer_company', invokeOnNotNil(sanitizeCompany)),
    payer: sanitize(data, 'payer', invokeOnNotNil(sanitizePayer)),

    case_type: sanitize(
      data,
      'case_type',
      invokeOnNotNil((v) => String(v) as CaseType),
      (v) => isNil(v) || isFromEnum(CASE_TYPES)(v),
    ),

    reports: sanitize(data, 'reports', invokeOnNotNil(invokeMap(sanitizeReport)), (v) => isNil(v) || isArray(v)),

    ...sanitizeEventClaim(data),
  };
};

export const sanitizeShallowEvent: Sanitizer<BEventShallow> = (data) => {
  if (!isObject(data)) {
    console.error('Event (from Booking): invalid data type');
  }
  return {
    ...sanitizeBaseEvent(data),
    booking: sanitize(data, 'booking', invokeOnNotNil(Number), (v) => isNil(v) || isNumeric(v)),
  };
};

// Booking
export interface SanitizerConfigBooking {
  referenceEvent?: Omit<BEvent, 'booking'>;
  serviceSanitizer?: typeof sanitizeService;
}

export const sanitizeBookingLight: SanitizerWithArgs<BookingLight, SanitizerConfigBooking> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Booking: invalid data type');
  }

  const booking: BookingLight = {
    id: sanitizeId(data, omit),
    first_name: sanitizeOrOmit(data, omit, 'events__affiliates__recipient__user__first_name', invokeOnNotNil(String)),
    last_name: sanitizeOrOmit(data, omit, 'events__affiliates__recipient__user__last_name', invokeOnNotNil(String)),
    public_id: sanitizeOrOmit(data, omit, 'public_id', invokeOnNotNil(String)),
    event_id: sanitizeFK(data, 'events__id'),
    date_of_birth: sanitize(
      data,
      'events__affiliates__recipient__user__date_of_birth',
      parseDateTime,
      isDateStringValid,
    ),
    arrive_at: sanitize(data, 'events__arrive_at', parseDateTime, isDateStringValid),
  };

  // return { ...booking, cCurrentStatus: computeBookingStatus(booking, config?.referenceEvent) };
  return booking;
};

export const sanitizeBooking: SanitizerWithArgs<DehydratedBooking, SanitizerConfigBooking> = (
  data,
  { config, omit } = {},
) => {
  if (!isObject(data)) {
    console.error('Booking: invalid data type');
  }

  const booking: Omit<DehydratedBooking, 'cCurrentStatus'> = {
    id: sanitizeId(data, omit),
    companies: sanitizeOrOmit(data, omit, 'companies', invokeMap(sanitizeCompany), isArray),
    notes: sanitize(data, 'notes', invokeMap(sanitizeNote), isArray),
    offers: sanitizeOrOmit(data, omit, 'offers', invokeMap(sanitizeOffer), isArray),
    events: sanitizeOrOmit(data, omit, 'events', invokeMap(sanitizeShallowEvent), isArray),
    events_count: sanitizeOrOmit(data, omit, 'events_count', Number, isNumeric),
    expenses: sanitizeOrOmit(data, omit, 'expenses', invokeMap(sanitizeExpense), isArray),
    operators: sanitizeOrOmit(data, omit, 'operators', invokeMap(sanitizeOperator), isArray),
    status: sanitize(data, 'status', String, isNotNil),
    services: sanitizeOrOmit(
      data,
      omit,
      'services',
      invokeMap((v) => (config?.serviceSanitizer ?? sanitizeService)(v)),
      isArray,
    ),
    service_root: sanitizeOrOmit(
      data,
      omit,
      'service_root',
      invokeOnNotNil((v) => sanitizeServiceRoot(v, { omit: 'services' })),
    ),
    created_at: sanitizeOrOmit(data, omit, 'created_at', parseDateTime, isDateStringValid),
    public_id: sanitizeOrOmit(data, omit, 'public_id', invokeOnNotNil(String)),
    date_of_injury: sanitizeOrOmit(
      data,
      omit,
      'date_of_injury',
      invokeOnNotNil(parseDate),
      (v) => isNil(v) || isDateStringValid(v),
    ),
    injury_type: sanitizeOrOmit(data, omit, 'injury_type', invokeOnNotNil(String)),
    claim_number: sanitizeOrOmit(data, omit, 'claim_number', invokeOnNotNil(String)),
    requester_company_source: sanitize(
      data,
      'requester_company_source',
      invokeOnNotNil((v) => String(v)),
      (v) => isNil(v) || isFromEnum(REQUESTER_TYPES)(v),
    ),
    target_language_alpha3: sanitize(data, 'target_language_alpha3', invokeOnNotNil(String), isStringOrNil),
    parent: sanitize(data, 'parent', invokeOnNotNil(Number), (v) => isNil(v) || isNumeric(v)),
    children: sanitize(
      data,
      'children',
      invokeOnNotNil((v) => v.id),
      (v) => isNil(v) || isArray(v),
    ),
    created_by: sanitize(data, 'created_by', invokeOnNotNil(Number), isNumericOrNil),
    group_booking: sanitize(data, 'group_booking', toBoolean),
  };

  // return { ...booking, cCurrentStatus: computeBookingStatus(booking, config?.referenceEvent) };
  return { ...booking, cCurrentStatus: validatorStatus(booking) };
};

export const sanitizeBookingInterpretation: Sanitizer<DehydratedBookingInterpretation> = (data) => {
  if (!isObject(data)) {
    console.error('Booking: invalid data type');
  }
  return sanitizeBooking(data, {
    config: { serviceSanitizer: sanitizeInterpretation },
  }) as DehydratedBookingInterpretation;
};

// Event with booking
export const sanitizeEvent: Sanitizer<DehydratedEvent> = (data) => {
  if (!isObject(data)) {
    console.error('Event: invalid data type');
  }

  const baseEvent = sanitizeBaseEvent(data);

  return {
    ...baseEvent,
    booking: sanitize(
      data,
      'booking',
      invokeOnNotNil((v) => sanitizeBooking(v, { omit: 'events', config: { referenceEvent: baseEvent } })),
    ),
  };
};

export const sanitizeOffer: Sanitizer<DehydratedOffer> = (data) => {
  if (!isObject(data)) {
    console.error('Offer: invalid data type');
  }
  return {
    id: sanitizeId(data),
    booking: sanitize(data, 'booking', Number, isNumeric),
    last_updated_at: sanitize(data, 'last_updated_at', parseDateTime, isDateStringValid),
    status: sanitize(data, 'status', String, isNotNil),
    service: sanitize(data, 'service', invokeOnNotNil(sanitizeInterpretation)),
  };
};

export const sanitizeReport: Sanitizer<Report> = (data) => {
  if (!isObject(data)) {
    console.error('Report: invalid data type');
  }
  return {
    id: sanitizeId(data),
    event: sanitize(data, 'event', Number, isNumeric),
    status: sanitize(data, 'status', String, isNotNil),
    arrive_at: sanitize(data, 'arrive_at', invokeOnNotNil(parseDateTime), (v) => isNil(v) || isDateStringValid(v)),
    start_at: sanitize(data, 'start_at', invokeOnNotNil(parseDateTime), (v) => isNil(v) || isDateStringValid(v)),
    end_at: sanitize(data, 'end_at', invokeOnNotNil(parseDateTime), (v) => isNil(v) || isDateStringValid(v)),
    observations: sanitize(data, 'observations', String, isNotNil),
  };
};
