import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { StateUpdater } from 'src/app/types/Components';
import FormExpandableField from '../../../../components/Form/FormExpandableField';
import { mapArrayForSelect, mapObjectForSelect } from '../../../../app/helpers/mappers';
import { TITLES } from '../../../../app/helpers/enum';
import { SUFFIXES } from '../../../../app/helpers/collections';
import { arrayRemoveAt, arrayReplaceAt, findInOptions } from '../../../../app/helpers/manipulation';
import { FormInput } from '../../../../components/Form/FormInput';
import { useForm } from '../../../../app/hooks';
import FormSelect from '../../../../components/Form/FormSelect/FormSelect';
import LocationSection from '../../../BookingsPage/Onboarding/Fields/LocationSection/LocationSection';
import { Location } from '../../../../app/types/Entities';
import useStateWithCallback from '../../../../app/hooks/useStateWithCallback';
import { PersonalInfoState } from './localTypes';
import FlatContactList from '../../../BookingsPage/Onboarding/Fields/FlatContactList/FlatContactList';

export interface PersonalInfoFieldProps {
  formPrefix: string;
  showPersonalInfo: boolean;
  setShowPersonalInfo: (display: boolean) => void;
  initialState: PersonalInfoState;
  stateUpdater: StateUpdater<PersonalInfoState>;
  errors?: any; // TODO type properly
}

const translationArray = ['onboardings', 'collections'];

function PersonalInfoField(props: PersonalInfoFieldProps) {
  const { formPrefix, showPersonalInfo, setShowPersonalInfo, initialState, stateUpdater, errors } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [firstName, setFirstName] = useStateWithCallback(initialState.firstName, stateUpdater('firstName'));
  const [lastName, setLastName] = useStateWithCallback(initialState.lastName, stateUpdater('lastName'));
  const [title, setTitle] = useStateWithCallback(initialState.title, stateUpdater('title'));
  const [suffix, setSuffix] = useStateWithCallback(initialState.suffix, stateUpdater('suffix'));

  const [location, setLocation] = useStateWithCallback(initialState.location, stateUpdater('location'));
  const updateLocation = (fields: Partial<Location>) => setLocation({ ...location, ...fields });

  const [flatContacts, setFlatContacts] = useStateWithCallback(initialState.flatContacts, stateUpdater('flatContacts'));

  const addNewFlatContact = () =>
    setFlatContacts([...flatContacts, { uuid: uuidv4(), type: 'phone', data: {}, context: 'personal' }]);

  const replaceFlatContactAt = (index: number, contact: any) =>
    setFlatContacts(arrayReplaceAt(flatContacts, index, contact));

  const removeFlatContactAt = (index: number) => setFlatContacts(arrayRemoveAt(flatContacts, index));

  useEffect(() => {
    // Ensure there is always at least one (flat) contact available to fill
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  // Options
  const titleOptions = mapObjectForSelect(TITLES, (titleKey) => t(`collections:titles.${titleKey}`));
  const suffixOptions = mapArrayForSelect(SUFFIXES, (suffixKey) => t(`collections:suffixes.${suffixKey}`));

  // Display
  const label = (
    <Typography variant="formLabel" sx={{ marginBottom: 2 }}>
      {t`personalInfo`}
    </Typography>
  );

  const display = (
    <Row className="gx-2">
      <Col md={12} xl={6} xxl={2}>
        <FormSelect
          options={titleOptions}
          value={findInOptions(titleOptions, title) ?? null}
          onChange={(_, option) => setTitle(option?.value)}
          renderInput={(inputProps) => <FormInput {...inputProps} label={t`title`} errors={errors?.title} />}
        />
      </Col>
      <Col md={12} xl={6} xxl={4}>
        <FormInput
          label={`${t`firstName`} *`}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          errors={errors?.first_name}
        />
      </Col>
      <Col md={12} xl={6} xxl={4}>
        <FormInput
          label={`${t`lastName`} *`}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          errors={errors?.last_name}
        />
      </Col>
      <Col md={12} xl={6} xxl={2}>
        <FormSelect
          options={suffixOptions}
          value={findInOptions(suffixOptions, suffix) ?? null}
          onChange={(_, option) => setSuffix(option?.value)}
          renderInput={(inputProps) => <FormInput {...inputProps} label={t`suffix`} errors={errors?.suffix} />}
        />
      </Col>
    </Row>
  );

  return (
    <FormExpandableField // Personal info
      formPrefix={p`personal-info`}
      label={label}
      display={display}
      isOpen={showPersonalInfo}
      toggleOpen={setShowPersonalInfo}
      onCancel={() => setShowPersonalInfo(false)}
    >
      <Row>
        <Col>
          <Typography variant="formLabel" sx={{ marginBottom: 2 }}>
            {t`address`}
          </Typography>
          <LocationSection location={location} update={updateLocation} errors={errors?.location} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography variant="formLabel" sx={{ marginBottom: 2 }}>
            {t`contactInfo`}
          </Typography>
          <FlatContactList
            items={flatContacts}
            add={addNewFlatContact}
            removeAt={removeFlatContactAt}
            replaceAt={replaceFlatContactAt}
          />
        </Col>
      </Row>
    </FormExpandableField>
  );
}

export default PersonalInfoField;
