import { Dispatch, SetStateAction, useState } from 'react';

export default function useStateWithCallback<T>(
  initialState: T,
  callback: (newValue: T, isInitial: boolean) => void,
  skipInitialCallback: boolean = false,
): [T, (newValue: T) => void, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    // Inform callback about initial state
    if (!skipInitialCallback) {
      callback(initialState, true);
    }
    return initialState;
  });

  const setValueWithCallback = (newValue: T) => {
    setValue(newValue);
    callback(newValue, false);
  };

  return [value, setValueWithCallback, setValue];
}
