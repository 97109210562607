import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormSelect from '../../../../../../components/Form/FormSelect/FormSelect';
import { PaymentMethod } from '../../localTypes';

const paymentMethods: PaymentMethod['method'][] = ['achTransfer', 'paypal', 'zelle'];

export interface PaymentMethodSelectProps {
  selectedMethod: PaymentMethod['method'];
  setSelectedMethod: (selectedMethod: PaymentMethod['method']) => void;
}

const translationArray = ['onboardings'];

export default function PaymentMethodSelect(props: PaymentMethodSelectProps) {
  const { selectedMethod, setSelectedMethod } = props;

  const { t } = useTranslation(translationArray);

  return (
    <FormSelect
      disableClearable
      options={paymentMethods}
      value={selectedMethod}
      onChange={(_, value) => setSelectedMethod(value)}
      getOptionLabel={t}
      renderInput={(inputProps) => <TextField {...inputProps} label={t`paymentMethod` as string} variant="standard" />}
    />
  );
}
