import { GridToolbarExportContainer } from '@mui/x-data-grid';
import ExportMenuItem from './ExportMenuItem';

export default function ExportButton(props: any) {
  return (
    <GridToolbarExportContainer {...props}>
      <ExportMenuItem />
    </GridToolbarExportContainer>
  );
}
