import { Authorization } from '../../../../../../app/types/Entities';
import AuthorizationRequestsListItem from './AuthorizationRequestsListItem';
import FormListRow from '../../../../../../components/Form/FormListRow';
import { BUTTON_STATE } from '../../../../../../app/helpers/enum';
import { DatabaseId } from '../../../../../../app/types/DataStructures';

export interface AuthorizationRequestsListProps {
  authorizations: Authorization[];
  checkedAuthorizationsIds: DatabaseId[];
  setCheckedAuthorizationsIds: (ids: DatabaseId[]) => void;
  updateAuthorization: (authorization: Authorization) => void;
}

export default function AuthorizationRequestsList(props: AuthorizationRequestsListProps) {
  const { authorizations, checkedAuthorizationsIds, setCheckedAuthorizationsIds, updateAuthorization } = props;

  return (
    <FormListRow displaySeparator>
      {authorizations.map((authorization) => (
        <AuthorizationRequestsListItem
          key={authorization.id}
          removeButtonState={BUTTON_STATE.HIDDEN}
          addButtonState={BUTTON_STATE.HIDDEN}
          authorization={authorization}
          checked={checkedAuthorizationsIds.indexOf(authorization.id!) >= 0}
          toggleChecked={(checked) =>
            authorization.id &&
            setCheckedAuthorizationsIds(
              checked
                ? [...checkedAuthorizationsIds, authorization.id]
                : checkedAuthorizationsIds.filter((id) => id !== authorization.id),
            )
          }
          onStatusChange={(status) => updateAuthorization({ ...authorization, status })}
        />
      ))}
    </FormListRow>
  );
}
