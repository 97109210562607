import { useQuery } from '@tanstack/react-query';
import { MUTATION_KEYS } from '../config/helpers';
import { getLanguages } from '../languages';
import { Language } from '../../types/Entities';

export type UseCacheLanguages = {
  (defaultAsArray?: false): Language[] | undefined;
  (defaultAsArray: true): Language[];
};

export const useCacheLanguages: UseCacheLanguages = (defaultAsArray: boolean = false) => {
  const { data } = useQuery([MUTATION_KEYS.LANGUAGES], () => getLanguages({}), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  return (data || (defaultAsArray ? [] : undefined)) as typeof defaultAsArray extends false
    ? Language[] | undefined
    : Language[];
};
