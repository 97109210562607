import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

type useConfirmState<T> = [value: T, setWithConfirmation: (newValue: T) => void, set: Dispatch<SetStateAction<T>>];

const translationArray = ['common'];

export default function useStateWithConfirmation<T>(
  initialState: T,
  isPreloaded: boolean,
  field: string,
  skipInitialConfirmation = false,
): useConfirmState<T> {
  const { t } = useTranslation(translationArray);

  const warningDisplayer = async () => {
    if (isPreloaded) {
      const { isConfirmed } = await Swal.fire({
        title: t`fieldSaveMessage`,
        text: `You are about to change ${field} field`,
        showDenyButton: true,
        confirmButtonText: t`accept`,
        denyButtonText: t`cancel`,
        confirmButtonColor: '#00a2b8',
        customClass: {
          confirmButton: 'btn action-button',
          denyButton: 'btn action-button',
        },
      });
      return isConfirmed;
    }
    return true;
  };

  const [value, setValue] = useState<T>(() => {
    // Inform callback about initial state
    if (!skipInitialConfirmation) {
      warningDisplayer();
    }
    return initialState;
  });

  const setValueConfirmation = (newValue: T) => {
    warningDisplayer().then((shouldUpdate: boolean) => {
      if (shouldUpdate) {
        setValue(newValue);
      }
    });
  };

  return [value, setValueConfirmation, setValue];
}
