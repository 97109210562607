import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../../app/hooks';
import { FormGroupProps } from '../../../app/types/Components';
import { FormInput, FormInputProps } from '../FormInput';

export interface ContactFormGroupProps extends FormGroupProps {
  emailProps: FormInputProps;
  phoneProps: FormInputProps;
  faxProps: FormInputProps;
}

export default function ContactFormGroup(props: ContactFormGroupProps) {
  const { formPrefix = 'contact', emailProps, phoneProps, faxProps } = props;

  const { t } = useTranslation('forms');
  const { p } = useForm(formPrefix);

  return (
    <>
      <FormInput id={p`email`} type="email" label={t`email`} {...emailProps} />
      <Row>
        <Col>
          <FormInput id={p`phone`} type="tel" label={t`phone`} help={t`phoneExample`} {...phoneProps} />
        </Col>
        <Col>
          <FormInput id={p`fax`} type="tel" label={t`fax`} help={t`faxExample`} {...faxProps} />
        </Col>
      </Row>
    </>
  );
}
