import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid';
import { CircularProgress, Typography } from '@mui/material';
import { ServiceRoot } from 'src/app/types/Entities';
import CloseIcon from '@mui/icons-material/Close';
import 'src/assets/styles/Tables.scss';
import { FormTable } from '../../../components/Form/FormTable/FormTable';
import { MUTATION_KEYS, ServiceRootsGetParams, getServiceRootsPaginated } from '../../../app/api';
import { useStateDict } from '../../../app/hooks';
import { ServiceRootOnboardingForm } from './Onboarding/Form';

const translationArray = ['tables', 'collections'];

export default function ServiceRootsTable() {
  const { t } = useTranslation(translationArray);

  // Pagination

  const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({ page: 0, pageSize: 25 });

  const [queryFilters, setQueryFilters] = useStateDict<ServiceRootsGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
  });
  useEffect(() => {
    setQueryFilters({
      ...queryFilters,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, setQueryFilters]);

  // Query
  const {
    data: serviceRootsPaginatedData,
    isLoading,
    refetch: refetchServiceRootsPaginatedData,
  } = useQuery([MUTATION_KEYS.SERVICES, queryFilters], () => getServiceRootsPaginated(queryFilters));

  const [rowCountState, setRowCountState] = useState(serviceRootsPaginatedData?.count || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      serviceRootsPaginatedData?.count !== undefined ? serviceRootsPaginatedData.count : prevRowCountState,
    );
  }, [serviceRootsPaginatedData?.count, setRowCountState]);

  const [selectedServiceRoot, setSelectedServiceRoot] = useState<ServiceRoot | undefined>();
  const [showServiceRootModal, setShowServiceRootModal] = useState<boolean>(false);
  const [serviceRootPreloadStatus, setPreloadStatus] = useState<'loading' | 'success' | 'error' | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const hideModal = () => {
    setShowServiceRootModal(false);
    setSelectedServiceRoot(undefined);
    setPreloadStatus(undefined);
  };

  const rows: GridRowsProp =
    serviceRootsPaginatedData?.results.map((serviceRoot) => {
      return {
        id: serviceRoot.id ?? 0,
        name: serviceRoot.name ?? '',
        description: serviceRoot.description ?? '',
      };
    }) || [];

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t`serviceRoot.name`,
      flex: 1,
    },
    {
      field: 'description',
      headerName: t`serviceRoot.description`,
      flex: 1,
    },
  ];

  return (
    <>
      <Container className="w-100 h-100 mw-100">
        <Row className="w-100">
          <Col className="d-flex justify-content-end align-items-center">
            <Typography className="me-4">
              {t('serviceRoot.totalServiceRoots', { serviceRoots: serviceRootsPaginatedData?.count || 0 })}
            </Typography>

            {/* <Button
              color="light"
              className="my-3 Table-Add-Button"
              onClick={() => {
                setSelectedServiceRoot(undefined);
                setShowServiceRootModal(true);
                setPreloadStatus(undefined);
              }}
            >
              <AddIcon fontSize="large" />
            </Button> */}
          </Col>
        </Row>

        <Row className="w-100 h-100">
          <Col className="d-flex flex-column">
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <CircularProgress size="4rem" sx={{ color: '#00A2B8' }} />
                <span>
                  <b>{t`loadingTable`}</b>
                </span>
              </div>
            ) : (
              <FormTable
                rows={rows}
                rowCount={rowCountState}
                columns={columns}
                sx={{ backgroundColor: 'white' }}
                onRowClick={(params: GridRowParams) => {
                  const serviceRootId = params.row.id;
                  const serviceRoot = serviceRootsPaginatedData?.results.find(({ id }) => id === serviceRootId);

                  setSelectedServiceRoot(serviceRoot);
                  // setShowServiceRootModal(true);
                  if (serviceRootPreloadStatus === undefined) {
                    setPreloadStatus('loading');
                  }
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                setPaginationModel={setPaginationModel}
                exportable
              />
            )}
          </Col>
        </Row>
      </Container>

      <Modal fullscreen className="admin-modal" isOpen={showServiceRootModal} toggle={hideModal}>
        <ModalHeader className="admin-modal-header">
          <Row className="align-items-center">
            <Col>
              <Button
                className="action-button"
                color="submit"
                disabled={isSubmitting}
                form="service=root-onboarding-form"
              >
                {isSubmitting ? <Spinner className="me-2" size="sm" type="border" color="primary" /> : t`save`}
              </Button>
            </Col>

            <Col xs="auto">
              <Button color="transparent" disabled={isSubmitting} onClick={hideModal}>
                <CloseIcon fontSize="large" style={{ fill: 'white' }} />
              </Button>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody className="admin-modal-body">
          <div className="admin-modal-content-header">
            <Row className="align-items-center">
              <Col xs="auto">
                {selectedServiceRoot !== undefined ? selectedServiceRoot.name : t('serviceRoot.modalTitleNew')}
              </Col>
            </Row>
          </div>

          <ServiceRootOnboardingForm
            id="serviceRoot-onboarding-form"
            preloadService={selectedServiceRoot ?? undefined}
            preSubmit={() => {
              setIsSubmitting(true);
              return true;
            }}
            onSubmitSuccess={() => {
              refetchServiceRootsPaginatedData().then(hideModal);
            }}
            postSubmit={() => {
              setIsSubmitting(false);
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
