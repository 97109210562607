import { EventInput } from '@fullcalendar/react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { BEvent, Contact, Entity, FlatContact, Person, Service, User } from '../types/Entities';
import { orderNumbers } from './manipulation';
import { Dictionary } from '../types/DataStructures';
import { FormSelectOptions } from '../types/Components';
import { ValuesOf } from '../types/TypeMappers';
import { COMPANY_TYPES, SEND_METHODS } from './enum';
import { DEFAULT_BOOKING_STAGE_COLOR, SORTER_PICK_RIGHT } from './constants';
import { extractBookingFromEvent } from './services';

export const buildSearchParam = (params: Dictionary) => new URLSearchParams(params).toString();

export const mapObjectForSelect = <T extends Dictionary>(
  obj: T,
  translate: (key: keyof T, value: ValuesOf<T>) => string = String,
): FormSelectOptions<ValuesOf<T>> =>
  Object.entries(obj).map(([k, v]) => ({
    label: translate(k, v),
    value: v,
  }));

export const mapMultipleObjectsForSelect = <T extends Dictionary>(
  objects: T[],
  translate: (key: keyof T, value: ValuesOf<T>) => string = String,
): FormSelectOptions<ValuesOf<T>>[] =>
  objects.map((obj) =>
    Object.entries(obj).map(([k, v]) => ({
      label: translate(k, v),
      value: v,
    })),
  );

export const mapArrayForSelect = <T>(
  array: ReadonlyArray<T>,
  translate: (item: T) => string = String,
  groupId?: string,
): FormSelectOptions<T> =>
  array.map((item) => ({
    label: translate(item),
    value: item,
    groupId: groupId || null,
  }));

// Extractors
export const getDataFromFlatContact = (flatContact: FlatContact) => {
  if (flatContact.type === 'email') {
    return flatContact.data.address!;
  }
  if (flatContact.type === 'phone' || flatContact.type === 'fax') {
    const { number = '', extension = '' } = flatContact.data;
    return number + (extension && ` Ext. ${extension}`);
  }
  return '';
};

// Model to datastructures
export const buildUsername = (firstName: string, lastName: string, dateOfReference: Date) => {
  // Remove characters unfit for a username
  const sanitizeString = (input: string) => input.replace(/[^a-z0-9_+.@-]/gi, '').toLowerCase();
  const dateStr = moment(dateOfReference).format('YYYYMMDD');

  return `${sanitizeString(lastName)}_${sanitizeString(firstName)}_${dateStr}`;
};

export const mapUserToName = (u: User) => `${u.first_name} ${u.last_name} (${u.username})`;

export const mapEventToCalendar = (event: BEvent): EventInput => {
  const patientInfo = event.affiliates?.map((a) => mapUserToName(a.recipient)).join(', ');
  const clinicInfo = event.booking.companies.find((c) => c.type === COMPANY_TYPES.CLINIC)?.name;
  const service: Service | undefined = event.booking.services[0];
  const interpreterInfo = service ? mapUserToName(service.provider) : 'None';
  const { booking, ...eventWithoutBooking } = event;

  return {
    id: String(event.id),
    start: event.start_at,
    end: event.end_at,
    title: `[Patient] ${patientInfo} - [Clinic] ${clinicInfo} - [Interpreter] ${interpreterInfo}`,
    color: event.booking.cCurrentStatus?.color ?? DEFAULT_BOOKING_STAGE_COLOR,
    // Extended props
    booking: extractBookingFromEvent(event),
    eventWithoutBooking,
  };
};

export const flattenContacts = (contacts: Contact[] | undefined): FlatContact[] =>
  (contacts ?? []).flatMap((contact) =>
    Object.values(SEND_METHODS)
      .filter((sendMethod) => !!contact[sendMethod])
      .map((sendMethod) => {
        const data = contact[sendMethod]!;
        const context = contact[`${sendMethod}_context`];

        let flatContactData = {};
        if (sendMethod === 'email') {
          flatContactData = { address: data };
        } else if (sendMethod === 'phone' || sendMethod === 'fax') {
          const [number, extension] = data.split('Ext.').map((str) => str.trim());
          flatContactData = {
            number,
            extension,
          };
        }

        return {
          context: context as any, // We don't really care about type-checking the context
          data: flatContactData,
          sourceContactId: contact.id,
          type: sendMethod,
          uuid: uuidv4(),
        };
      }),
  );

export const unflattenContacts = (flatContacts: FlatContact[]): Contact[] => {
  const contacts: Contact[] = [];

  let contactsIndex: number = -1;

  [...flatContacts] // Sort works in-place, so we need to create a copy of the array
    .sort((flatContact1, flatContact2) =>
      orderNumbers(flatContact1.sourceContactId, flatContact2.sourceContactId, SORTER_PICK_RIGHT),
    )
    .forEach((flatContact) => {
      // Pick contact to update
      let contact: Contact; // Contact to update
      let index: number; // Index to place the contact in within contacts (the array to be returned)

      if (flatContact.type === undefined) {
        return;
      }

      if (flatContact.sourceContactId) {
        // Contact entry came from DB
        if (contactsIndex >= 0 && flatContact.sourceContactId === contacts[contactsIndex].id) {
          // Reuse previous contact, this always corresponds to the same id'd contact because they are sorted by id
          contact = contacts[contactsIndex];
        } else {
          // Create new contact
          contact = { id: flatContact.sourceContactId };
          contactsIndex += 1;
        }
        index = contactsIndex;
      } else {
        // This is a new contact entry
        index = contacts.findIndex((iterContact) => {
          if (flatContact.type === undefined) {
            return false;
          }
          return !iterContact[flatContact.type];
        }); // Look for a contact with an empty slot for this type
        if (index >= 0) {
          // Found a contact with an empty slot for this type
          contact = contacts[index];
        } else {
          // Create new contact
          contact = {};
          contactsIndex += 1;
          index = contactsIndex;
        }
      }

      // Write data into contact
      contact[flatContact.type] = getDataFromFlatContact(flatContact);
      contact[`${flatContact.type}_context`] = flatContact.context;

      // Update contact
      contacts[index] = contact;
    });

  return contacts.filter((contact) => contact.email || contact.fax || contact.phone);
};

// To string
interface PersonToStringOptions {
  includeTitle?: boolean;
  includeSuffix?: boolean;
}
export const personToString = (
  person: Person,
  { includeTitle = true, includeSuffix = true }: PersonToStringOptions = {},
): string => {
  const words = [person.first_name, person.last_name];

  if (includeTitle && person.title) {
    words.unshift(person.title);
  }
  if (includeSuffix && person.suffix) {
    words.push(person.suffix);
  }

  return words.join(' ');
};

export const userToString = (user: User | undefined, includeUsername: boolean = false): string =>
  user?.first_name && user?.last_name
    ? `${personToString(user)}${includeUsername ? ` (${user?.username})` : ''}`
    : user?.username ?? '';

export const getEntityIdAsString = (entity: Entity) => String(entity.id);
