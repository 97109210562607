import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { PREFERRED_DATE_FORMAT_MOMENT } from 'src/app/helpers/constants';
import { buildSearchParam } from '../../../app/helpers/mappers';
import { BookingLight } from '../../../app/types/Entities';

export interface SearchBarBookingSingleResultProps {
  booking: BookingLight;
  key: number;
}

const translationArray = ['searchbar'];

export function SearchBarBookingSingleResult({ booking, key }: SearchBarBookingSingleResultProps) {
  const { t } = useTranslation(translationArray);
  const navigate = useNavigate();

  const hasEvents = !!booking.id;
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  // const [closestEvent]: ClosestDateData<BEventShallow> = getClosestDateFromEventList(booking.events ?? []);

  const patient = `${booking.first_name} ${booking.last_name}`;

  useEffect(() => {
    if (currentPath !== window.location.pathname) {
      setCurrentPath(window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <section
      key={key}
      className={classNames('single-result', { 'not-clickable': !hasEvents })}
      onClick={() =>
        hasEvents &&
        navigate({
          pathname: currentPath,
          search: buildSearchParam({ eventId: booking.event_id }),
        })
      }
    >
      {!hasEvents ? (
        <p>{t`noEvents`}</p>
      ) : (
        <div>
          <p>
            <span>{t`bookingID`}</span>
            <span style={{ fontWeight: 'bold' }}>{t('bookingId', { bookingId: booking.public_id })}</span>

            <span>
              <Trans
                t={t}
                i18nKey="patientName"
                values={{
                  patientName: patient || 'No patient',
                }}
              />
            </span>
            <span style={{ color: 'red' }}>
              <Trans
                t={t}
                i18nKey="patientDob"
                values={{
                  dob: booking.date_of_birth
                    ? moment(booking.date_of_birth).utc().format(PREFERRED_DATE_FORMAT_MOMENT)
                    : 'No dob',
                }}
              />
            </span>
            <span>
              <Trans
                t={t}
                i18nKey="startDate"
                values={{
                  startDate: booking.arrive_at
                    ? moment(booking.arrive_at).utc().format(PREFERRED_DATE_FORMAT_MOMENT)
                    : 'No event',
                }}
              />
            </span>
          </p>
        </div>
      )}
    </section>
  );
}
