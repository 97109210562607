import FullCalendar, { EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { buildSearchParam } from 'src/app/helpers/mappers';
import { EventsGetParams } from 'src/app/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_TIMEZONE } from 'src/app/helpers/constants';
import { DisplayBookingModalFunction } from 'src/pages/BookingsPage/localTypes';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import contains, { transformDateString } from 'src/app/stores/contains';
import { SweetAlertResult } from 'sweetalert2';
import CalendarHeader from './Components/Header/Header';
import NowIndicator from './Components/NowIndicator/NowIndicator';
import CustomEvent from './Components/CustomEvent/CustomEvent';
import './Calendar.scss';

interface CalendarProps {
  setHandlePrevClick: Dispatch<SetStateAction<(() => void) | undefined>>;
  setHandleNextClick: Dispatch<SetStateAction<(() => void) | undefined>>;
  setHandleTodayClick: Dispatch<SetStateAction<(() => void) | undefined>>;
  setHandleViewChange: Dispatch<SetStateAction<(() => void) | undefined>>;
  displayBookingModal: DisplayBookingModalFunction;
  calendarRef: React.RefObject<FullCalendar>;
  setEventFilters: Dispatch<SetStateAction<EventsGetParams>>;
  isLoading: boolean;
  calendarEvents: EventInput[] | undefined;
  paramEventId: string | null;
  fetchUrl: EventsGetParams;
  urlValues: EventsGetParams;
  setUrlValues: Dispatch<SetStateAction<EventsGetParams>>;
  getIsGroupBooking: () => Promise<SweetAlertResult<any>>;
  setGroupBooking: Dispatch<SetStateAction<boolean>>;
}

export default function Calendar({
  setHandlePrevClick,
  setHandleNextClick,
  setHandleTodayClick,
  setHandleViewChange,
  displayBookingModal,
  calendarRef,
  setEventFilters,
  isLoading,
  calendarEvents,
  paramEventId,
  fetchUrl,
  urlValues,
  setUrlValues,
  getIsGroupBooking,
  setGroupBooking,
}: CalendarProps) {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('booking');

  const [currentView] = useState<string | undefined>('timeGridWeek');
  const calendarApiCopy = calendarRef?.current?.getApi();

  useEffect(() => {
    if (urlValues.start_at !== fetchUrl.start_at && urlValues.start_at !== undefined) {
      navigate(`/bookings/calendar/?start_at__gte=${urlValues.start_at}&end_at__lte=${urlValues.end_at}`);
    } else if (!contains(search, '?eventId') && !contains(pathname, '/list')) {
      navigate(`/bookings/calendar/?start_at__gte=${fetchUrl.start_at}&end_at__lte=${fetchUrl.end_at}`);
    }
    setHandlePrevClick(() => () => {
      return calendarApiCopy?.prev();
    });
  }, [
    setHandlePrevClick,
    calendarApiCopy,
    navigate,
    fetchUrl.start_at,
    fetchUrl.end_at,
    search,
    pathname,
    urlValues.start_at,
    urlValues.end_at,
    fetchUrl,
    setUrlValues,
  ]);

  useEffect(() => {
    if (urlValues.start_at !== fetchUrl.start_at && urlValues.start_at !== undefined) {
      navigate(`/bookings/calendar/?start_at__gte=${urlValues.start_at}&end_at__lte=${urlValues.end_at}`);
    } else if (!contains(search, '?eventId') && !contains(pathname, '/list')) {
      navigate(`/bookings/calendar/?start_at__gte=${fetchUrl.start_at}&end_at__lte=${fetchUrl.end_at}`);
    }
    setHandleNextClick(() => () => {
      return calendarApiCopy?.next();
    });
  }, [
    setHandleNextClick,
    calendarApiCopy,
    navigate,
    fetchUrl.start_at,
    fetchUrl.end_at,
    search,
    pathname,
    urlValues.start_at,
    urlValues.end_at,
    fetchUrl,
    setUrlValues,
  ]);

  useEffect(() => {
    setHandleTodayClick(() => () => {
      setUrlValues({
        start_at: transformDateString(fetchUrl.start_at),
        end_at: transformDateString(fetchUrl.end_at),
      });
      return calendarApiCopy?.today();
    });
  }, [setHandleTodayClick, calendarApiCopy, setUrlValues, fetchUrl]);

  useEffect(() => {
    setHandleViewChange(() => (view: string) => {
      calendarApiCopy?.changeView(view);
    });
  }, [setHandleViewChange, calendarApiCopy]);

  return (
    <Container className="w-100 h-100 mw-100 p-0">
      {isLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <CircularProgress size="4rem" sx={{ color: '#00A2B8' }} />
          <span>
            <b>{t`calendarLoading`}</b>
          </span>
        </div>
      )}
      <FullCalendar // WONTFIX if you wrap FullCalendar in a React wrapper, it breaks
        ref={calendarRef}
        plugins={[momentTimezonePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
        selectable
        nowIndicator
        allDaySlot={false}
        timeZone={DEFAULT_TIMEZONE}
        initialView={currentView}
        businessHours={{
          startTime: '06:00:00',
          endTime: '18:00:00',
        }}
        height="100%"
        views={{
          timeGrid: {
            dayHeaderContent: CalendarHeader,
            nowIndicatorContent: NowIndicator,
            eventContent: CustomEvent,
            slotLabelFormat: {
              hour: 'numeric',
              minute: '2-digit',
              omitZeroMinute: true,
              meridiem: 'uppercase' as any, // Uppercase is recognized by full calendar but not by TS.
            },
          },
          timeGridWeek: {
            eventMaxStack: 3,
            slotEventOverlap: false,
          },
        }}
        headerToolbar={false}
        eventDisplay="list-item"
        events={calendarEvents}
        eventClick={(info) => {
          const bookingToDisplay = info.event.extendedProps.booking;
          displayBookingModal(
            bookingToDisplay.public_id,
            info.event.start ?? undefined,
            info.event.end ?? undefined,
            bookingToDisplay.cCurrentStatus,
            undefined,
            null,
          );
          // eslint-disable-next-line eqeqeq
          if (paramEventId != info.event.id) {
            navigate({
              pathname: '/bookings/calendar/',
              search: buildSearchParam({ eventId: info.event.id }),
            });
          }
        }}
        select={async ({ start, end }) => {
          // Set up creating a new booking
          const isGroupBooking = await getIsGroupBooking();
          if (isGroupBooking.isConfirmed) {
            setGroupBooking(true);
          } else if (isGroupBooking.isDenied) {
            setGroupBooking(false);
          } else if (isGroupBooking.isDismissed) {
            return undefined;
          }

          displayBookingModal(
            null,
            start,
            end,
            {
              name: 'pending',
              color: '#ff7976',
              step: 'initiate',
            },
            'success',
            undefined,
          );
          return undefined;
        }}
        datesSet={(info) => {
          if (urlValues.start_at !== fetchUrl.start_at && urlValues.start_at !== undefined) {
            setEventFilters({
              start_at: transformDateString(info.startStr),
              end_at: transformDateString(info.endStr),
            });
          } else {
            setEventFilters({
              start_at: transformDateString(info.startStr),
              end_at: transformDateString(info.endStr),
            });
            setUrlValues({
              start_at: transformDateString(info.startStr),
              end_at: transformDateString(info.endStr),
            });
          }
        }}
      />
    </Container>
  );
}
