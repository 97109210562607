import { Col, Row } from 'reactstrap';
import { IconButton, Radio } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { StateUpdater } from 'src/app/types/Components';
import { PaymentMethod, PaymentMethodACHTransfer, PaymentMethodPaypal, PaymentMethodZelle } from '../../localTypes';
import useStateWithCallback from '../../../../../../app/hooks/useStateWithCallback';
import PaymentMethodItemPaypal from './ItemPaypal';
import PaymentMethodItemZelle from './ItemZelle';
import PaymentMethodItemACHTransfer from './ItemACHTransfer';

export interface PaymentMethodListItemProps {
  removePaymentMethod: () => void;
  isPreferred: boolean;
  setAsPreferred: () => void;
  initialState: PaymentMethod;
  stateUpdater: StateUpdater<PaymentMethod>;
  // TODO add errors
}

export default function PaymentMethodListItem(props: PaymentMethodListItemProps) {
  const { removePaymentMethod, isPreferred, setAsPreferred, initialState, stateUpdater } = props;

  const [selectedMethod, setSelectedMethod] = useStateWithCallback(initialState.method, (value) => {
    stateUpdater('method')(value);

    // Reset payment method info
    stateUpdater('accountNumber')('');
    if (value === 'achTransfer') {
      const castedStateUpdater = stateUpdater as StateUpdater<PaymentMethodACHTransfer>;
      castedStateUpdater('bankName')('');
      castedStateUpdater('accountType')('checking');
      castedStateUpdater('routingNumber')('');
    }
  });

  return (
    <Row>
      <Col xs="auto" className="flex-grow-0 align-self-center">
        <Radio checked={isPreferred} onChange={setAsPreferred} />
      </Col>
      <Col>
        {selectedMethod === 'achTransfer' ? (
          <PaymentMethodItemACHTransfer
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            initialState={initialState as PaymentMethodACHTransfer}
            stateUpdater={stateUpdater as StateUpdater<PaymentMethodACHTransfer>}
          />
        ) : selectedMethod === 'paypal' ? (
          <PaymentMethodItemPaypal
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            initialState={initialState as PaymentMethodPaypal}
            stateUpdater={stateUpdater}
          />
        ) : (
          <PaymentMethodItemZelle
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
            initialState={initialState as PaymentMethodZelle}
            stateUpdater={stateUpdater}
          />
        )}
      </Col>
      <Col xs="auto" className="flex-grow-0 align-self-center">
        <IconButton onClick={removePaymentMethod}>
          <DeleteIcon fontSize="medium" />
        </IconButton>
      </Col>
    </Row>
  );
}
