import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, FormProps } from 'reactstrap';
import {
  MUTATION_KEYS,
  ServiceRootsPostError,
  ServiceRootsPostPayload,
  ServiceRootsPostResponse,
  ServiceRootsPutError,
  ServiceRootsPutResponse,
  createServiceRoot,
  getBaseServiceRoot,
  updateServiceRoot,
} from 'src/app/api';
import { useStateDict } from 'src/app/hooks';
import { FormSubmitEvent, FormSubmitProps } from 'src/app/types/Components';
import { ErrorsAlert } from 'src/components/ErrorsAlert/ErrorsAlert';
import { CategoriesGetParams, getCategories } from 'src/app/api/categories';
import { CATEGORY_PREFIXES } from 'src/app/helpers/enum';
import { Category, ServiceRoot } from 'src/app/types/Entities';
import { DatabaseId } from 'src/app/types/DataStructures';
import { ServiceRootFormGroup } from './Group';

export interface ServiceRootOnboardingFormProps
  extends FormProps,
    FormSubmitProps<ServiceRootsPostPayload, ServiceRootsPostResponse, ServiceRootsPostError> {
  preloadServiceRootId?: DatabaseId;
}

export function ServiceRootOnboardingForm({
  id,
  children,
  formPrefix = 'serviceRoot',
  submitPreventDefault = true,
  preSubmit,
  onSubmitSuccess,
  onSubmitFailure,
  postSubmit,
  preloadServiceRootId,
}: ServiceRootOnboardingFormProps) {
  const { t } = useTranslation('forms');
  const serviceRootIdProvided = preloadServiceRootId !== undefined;

  const [errors, setErrors] = useStateDict<ServiceRootsPostError>({});

  const { mutateAsync: mutateAsyncCreate } = useMutation<
    ServiceRootsPostResponse,
    ServiceRootsPostError,
    ServiceRootsPostPayload
  >(createServiceRoot);
  const { mutateAsync: mutateAsyncUpdate } = useMutation<
    ServiceRootsPutResponse,
    ServiceRootsPutError,
    Parameters<typeof updateServiceRoot>
  >((args) => updateServiceRoot(...args));

  const [queryFiltersCategories] = useStateDict<CategoriesGetParams>({});
  const { data: categories } = useQuery([MUTATION_KEYS.CATEGORIES], () => getCategories(queryFiltersCategories));

  const modalityList = categories?.filter((category) => {
    return category.name.startsWith(CATEGORY_PREFIXES.APPOINTMENT);
  });
  const [modality, setModality] = useState<Category | null>(null);

  const certificationList = categories?.filter((category) => {
    return category.name.startsWith(CATEGORY_PREFIXES.CERTIFICATION);
  });
  const [certification, setCertification] = useState<Category | null>(null);

  const [description, setDescription] = useState<string>('');
  const [name, setName] = useState<string>('');

  function prefillFromServiceRoot(service_root: ServiceRoot) {
    setDescription(service_root.description);
    setName(service_root.name);
    setModality(service_root.categories.find((category) => category.name.startsWith(CATEGORY_PREFIXES.APPOINTMENT))!);
    setCertification(
      service_root.categories.find((category) => category.name.startsWith(CATEGORY_PREFIXES.CERTIFICATION))!,
    );
  }

  useQuery(
    [MUTATION_KEYS.SERVICE_ROOTS, preloadServiceRootId],
    () =>
      preloadServiceRootId
        ? getBaseServiceRoot(preloadServiceRootId)
        : Promise.reject(new Error('Service root id not set')),
    {
      enabled: serviceRootIdProvided,
      onSuccess: prefillFromServiceRoot,
    },
  );

  const onSubmit = (e: FormSubmitEvent) => {
    if (submitPreventDefault) {
      e.preventDefault();
    }

    const payload: ServiceRootsPostPayload = {
      name,
      description,
      categories: [modality!.id!, certification!.id!],
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    const promise = !preloadServiceRootId
      ? toast // Create
          .promise(mutateAsyncCreate(payload), {
            pending: t`onboarding.serviceRoot.progress.started` as string,
            error: t`onboarding.serviceRoot.progress.error` as string,
            success: t`onboarding.serviceRoot.progress.success` as string,
          })
          .then((resp) => onSubmitSuccess?.(resp, payload))
      : toast // Update
          .promise(mutateAsyncUpdate([preloadServiceRootId, payload]), {
            pending: t`updating.serviceRoot.progress.started` as string,
            error: t`updating.serviceRoot.progress.error` as string,
            success: t`updating.serviceRoot.progress.success` as string,
          })
          .then(() => onSubmitSuccess?.(preloadServiceRootId, payload));

    promise
      .catch((err) => {
        if (err) setErrors(err);
        onSubmitFailure?.(err, payload);
      })
      .finally(postSubmit);
  };

  return (
    <Form id={id} onSubmit={onSubmit}>
      <ErrorsAlert errorsArray={errors.non_field_errors} />
      {children}
      <ServiceRootFormGroup
        formPrefix={formPrefix}
        nameProps={{
          value: name,
          onChange: (e) => setName(e.target.value),
          errors: errors.name,
        }}
        certificationProps={{
          options: certificationList,
          value: certification,
          getOptionLabel: (c) => c.name,
          getOptionValue: (c) => c.name,
          onChange: (c) => setCertification(c),
        }}
        modalityProps={{
          options: modalityList,
          value: modality,
          getOptionLabel: (m) => m.name,
          getOptionValue: (m) => m.name,
          onChange: (m) => setModality(m),
        }}
        descriptionProps={{
          value: description,
          onChange: (e) => setDescription(e.target.value),
          errors: errors.description,
        }}
      />
    </Form>
  );
}
