import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { TextField } from '@mui/material';
import { FormListRowItem, FormListRowItemProps } from '../../../../../components/Form/FormListRow';
import { ContextOptions, FlatContact } from '../../../../../app/types/Entities';
import { mapObjectForSelect } from '../../../../../app/helpers/mappers';
import { ContactContext, SEND_METHODS } from '../../../../../app/helpers/enum';
import { findInOptions } from '../../../../../app/helpers/manipulation';
import {
  FlatContactListItemEmailField,
  FlatContactListItemFaxField,
  FlatContactListItemPhoneField,
} from './FlatContactListItemFields';
import './FlatContactListItem.scss';

export interface FlatContactListItemProps extends FormListRowItemProps {
  flatContact: FlatContact;
  update: (flatContact: Partial<FlatContact>) => void;
  contextOptions?: ContextOptions;
}

export function FlatContactListItem({ flatContact, update, contextOptions, ...props }: FlatContactListItemProps) {
  const { t } = useTranslation('booking');
  const { context, type, data } = flatContact;

  const typeOptions = mapObjectForSelect(SEND_METHODS, (_, value) => t(`contact.${value}`));
  const getDefaultContext: () => ContactContext = () => 'personal';
  return (
    <FormListRowItem {...props} className="contact-item-separated">
      <Row className="gx-2">
        <Col xxl={2}>
          <FormSelect
            options={typeOptions}
            value={findInOptions(typeOptions, type)}
            onChange={(_event, typeOption) =>
              typeOption &&
              update({
                type: typeOption.value,
                data: {},
                context: getDefaultContext(),
              } as FlatContact)
            }
            renderInput={(params) => <TextField placeholder={t`contact.type`} {...params} />}
            disableClearable
          />
        </Col>
        {type === 'email' ? (
          <FlatContactListItemEmailField
            address={data.address}
            context={context}
            contextOptions={contextOptions?.email}
            update={update}
          />
        ) : type === 'phone' ? (
          <FlatContactListItemPhoneField
            number={data.number}
            extension={data.extension}
            context={context}
            contextOptions={contextOptions?.phone}
            update={update}
          />
        ) : type === 'fax' ? (
          <FlatContactListItemFaxField
            number={data.number}
            extension={data.extension}
            context={context}
            contextOptions={contextOptions?.fax}
            update={update}
          />
        ) : null}
      </Row>
    </FormListRowItem>
  );
}
