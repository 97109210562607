import { Container, FormText } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';

export default function SearchBarHint() {
  const { t } = useTranslation('searchbar');

  return (
    <Container className="searchbar-hint my-3">
      <p>
        <Trans t={t} i18nKey="hintNames" />
      </p>
      <p>
        <Trans
          t={t}
          i18nKey="hintDates"
          components={{
            code: <code />,
          }}
        />
      </p>
      <p>
        <Trans
          t={t}
          i18nKey="hintBookingId"
          components={{
            code: <code />,
          }}
        />
      </p>
      <FormText color="muted">
        <Trans
          t={t}
          i18nKey="hintExampleOpening"
          components={{
            code: <code />,
          }}
        />
        <ul>
          <li>{t`hintExampleFirstName`}</li>
          <li>{t`hintExampleLastName`}</li>
          <li>{t`hintExampleDate`}</li>
        </ul>
      </FormText>
    </Container>
  );
}
