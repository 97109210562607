import { Tooltip } from '@mui/material';
import moment from 'moment-timezone';
import { HTMLAttributes } from 'react';
import { DEFAULT_TIMEZONE, WEEK_MS } from 'src/app/helpers/constants';

export interface DateDisplayProps extends HTMLAttributes<HTMLTimeElement> {
  dateTime?: Date;
  format?: string;
  tooltipFormat?: string;
}

export default function DateTimeDisplay({
  dateTime,
  format = 'MM/DD/YYYY',
  tooltipFormat,
  ...props
}: DateDisplayProps) {
  const dateTimeMoment = moment(dateTime);

  const diffInMs = moment().diff(dateTimeMoment);

  const title = dateTimeMoment.tz(DEFAULT_TIMEZONE).format(tooltipFormat);

  return dateTime !== null ? (
    tooltipFormat ? (
      <Tooltip title={title}>
        <time dateTime={dateTimeMoment.utc().toISOString()} {...props}>
          {diffInMs >= WEEK_MS
            ? dateTimeMoment.tz(DEFAULT_TIMEZONE).format(format)
            : dateTimeMoment.tz(DEFAULT_TIMEZONE).fromNow()}
        </time>
      </Tooltip>
    ) : (
      <time dateTime={dateTimeMoment.utc().toISOString()} {...props}>
        {dateTimeMoment.tz(DEFAULT_TIMEZONE).format(format)}
      </time>
    )
  ) : null;
}
