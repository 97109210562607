import { Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm } from '../../../../../../app/hooks';
import InsuranceField from './InsuranceField';
import AdjusterField from './AdjusterField';
import { getInsurancesWithRoles, MUTATION_KEYS } from '../../../../../../app/api';
import { COMPANY_TYPES } from '../../../../../../app/helpers/enum';
import { Insurance } from '../../../../../../app/types/Entities';
import { PayerSectionProps } from '../localTypes';

export interface InsuranceSectionProps extends PayerSectionProps {
  insuranceOptional?: boolean;
  adjusterOptional?: boolean;
}

function InsuranceSection(props: InsuranceSectionProps) {
  const {
    formPrefix,
    payer,
    setPayer,
    payerCompany,
    setPayerCompany,
    insuranceOptional,
    adjusterOptional,
    initialPayerFieldState,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);

  // Form data
  const [showInsurance, setShowInsurance] = useState<boolean>(false);
  const [showAdjuster, setShowAdjuster] = useState<boolean>(false);

  // Query
  const [insurancesRefetchEnabled, setInsurancesRefetchEnabled] = useState<boolean>(true);
  const {
    data: insurances,
    isLoading: isInsuranceLoading,
    refetch: refetchInsurances,
  } = useQuery([MUTATION_KEYS.COMPANIES, { type: COMPANY_TYPES.INSURANCE }], () => getInsurancesWithRoles({}), {
    enabled: insurancesRefetchEnabled,
  });

  useEffect(() => {
    if (insurances) {
      setInsurancesRefetchEnabled(false);
    }
  }, [insurances]);

  const adjusters = payerCompany?.payers ?? [];

  // Handle fields visibility
  useEffect(() => {
    if (!payerCompany && initialPayerFieldState && payerCompany !== initialPayerFieldState.payerCompany) {
      setPayer(null);
      setShowAdjuster(false);
    }
  }, [initialPayerFieldState, payerCompany, setPayer]);

  return (
    <Container className="p-0">
      <Row>
        <Col>
          <InsuranceField
            formPrefix={p`insurance`}
            optional={insuranceOptional}
            isOpen={showInsurance}
            toggleOpen={setShowInsurance}
            insurance={payerCompany as Insurance | null}
            setInsurance={setPayerCompany}
            insuranceList={insurances}
            isLoading={isInsuranceLoading}
            refetchInsurances={refetchInsurances}
            onUpdateSuccess={(newInsurance) => {
              setPayerCompany(newInsurance);
              setShowInsurance(false);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <AdjusterField
            formPrefix={p`adjuster`}
            optional={adjusterOptional}
            isDisabled={!payerCompany}
            isOpen={showAdjuster}
            toggleOpen={setShowAdjuster}
            adjuster={payer}
            setAdjuster={setPayer}
            adjusterList={adjusters}
            isLoading={isInsuranceLoading}
            refetchAdjusters={() =>
              refetchInsurances().then(({ data: newInsurances = [] }) => ({
                data: newInsurances.flatMap(({ payers }) => payers ?? []),
              }))
            }
            insurance={payerCompany as Insurance | null}
            onUpdateSuccess={(newAdjuster) => {
              setPayer(newAdjuster);
              setShowAdjuster(false);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default InsuranceSection;
