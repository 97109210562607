import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Button, Col, Container, FormText, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import FormSelectCreatable from 'src/components/Form/FormSelectCreatable/FormSelectCreatable';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import {
  createUserComplex,
  updateUser,
  UsersPostErrorComplex,
  UsersPostPayloadComplex,
  UsersPostResponseComplex,
  UsersPutError,
  UsersPutPayload,
  UsersPutResponse,
} from '../../../../../app/api';
import { Company, FlatContact, Payer } from '../../../../../app/types/Entities';
import { useForm, useStateArray, useStateDict } from '../../../../../app/hooks';
import {
  buildUsername,
  flattenContacts,
  mapArrayForSelect,
  mapObjectForSelect,
  unflattenContacts,
  userToString,
} from '../../../../../app/helpers/mappers';
import { BUTTON_STATE, TITLES } from '../../../../../app/helpers/enum';
import { SUFFIXES } from '../../../../../app/helpers/collections';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { FormInput } from '../../../../../components/Form/FormInput';
import FormWrapper from '../../../../../components/Form/FormWrapper';
import FlatContactList from '../FlatContactList/FlatContactList';
import { ErrorsAlert } from '../../../../../components/ErrorsAlert';
import { DatabaseId } from '../../../../../app/types/DataStructures';

export interface GenericPayerFieldTranslationProps {
  firstName: string;
  lastName: string;
  suffix: string;
  title: string;
  errors: {
    noFirstName: string;
    noLastName: string;
    expectedPayerId: string;
    expectedCompanyId: string;
  };
  progress: {
    payerCreate: {
      started: string;
      success: string;
      error: string;
    };
    payerUpdate: {
      started: string;
      success: string;
      error: string;
    };
  };
  tooltip: {
    cancelAddPayer: string;
    addPayer: string;
    hidePayer: string;
    viewPayer: string;
  };
  selectPayer: string;
  savePayer: string;
  cancel: string;
  requiredFieldsHelp: string;
  payer: string;
  contactInformation: string;
}

export interface GenericPayerFieldProps {
  formPrefix: string;
  optional?: boolean;
  isDisabled: boolean;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  payer: Payer | null;
  setPayer: (payer: Payer | null) => void;
  payerList: Payer[] | undefined;
  isLoading: boolean;
  refetchPayers: () => Promise<{ data: Payer[] | undefined }>;
  company: Company | null;
  onUpdateSuccess?: (updatedPayer: Payer | null) => void;
  translations: GenericPayerFieldTranslationProps;
}

const translationArray = ['booking', 'collections'];

function GenericPayerField(props: GenericPayerFieldProps) {
  const {
    formPrefix,
    optional,
    isDisabled,
    isOpen,
    toggleOpen,
    payer,
    setPayer,
    payerList,
    isLoading,
    refetchPayers,
    company,
    onUpdateSuccess,
    translations,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<UsersPutError>({});
  const [createMode, setCreateMode] = useState<boolean>(false);

  const [title, setTitle] = useState<string | undefined>();
  const titleOptions = mapObjectForSelect(TITLES, (titleKey) => t(`collections:titles.${titleKey}`));

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();

  const [suffix, setSuffix] = useState<string | undefined>();
  const suffixOptions = mapArrayForSelect(SUFFIXES, (suffixKey) => t(`collections:suffixes.${suffixKey}`));

  const {
    values: flatContacts,
    set: setFlatContacts,
    push: pushFlatContact,
    replaceAt: replaceFlatContactAt,
    removeAt: removeFlatContactAt,
  } = useStateArray<FlatContact>([
    {
      type: 'email',
      data: {},
      context: 'work',
      uuid: uuidv4(),
    },
  ]);

  const addNewFlatContact = () =>
    pushFlatContact({
      type: 'email',
      data: {},
      context: 'work',
      uuid: uuidv4(),
    });

  useEffect(() => {
    // Ensure there is always at least one (flat) contact available to fill
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  const prefillForm = () => {
    setTitle(payer?.title);
    setFirstName(payer?.first_name);
    setLastName(payer?.last_name);
    setSuffix(payer?.suffix);
    setFlatContacts(flattenContacts(payer?.contacts) ?? []);
  };

  useEffect(() => {
    prefillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payer]);

  useEffect(() => {
    if (isDisabled) {
      toggleOpen(false);
      setCreateMode(false);
    }
  }, [isDisabled, toggleOpen]);

  // On save functions
  const { mutateAsync: mutateAsyncCreateUserComplex } = useMutation<
    UsersPostResponseComplex,
    UsersPostErrorComplex,
    UsersPostPayloadComplex
  >(createUserComplex);

  const { mutateAsync: mutateAsyncUpdateUser } = useMutation<
    UsersPutResponse,
    UsersPutError,
    Parameters<typeof updateUser>
  >((args) => updateUser(...args));

  // Validate
  const validate = () => {
    const validationErrors = [];

    if (!firstName) {
      validationErrors.push(translations.errors.noFirstName);
    }
    if (!lastName) {
      validationErrors.push(translations.errors.noLastName);
    }

    if (!createMode && !payer?.id) {
      validationErrors.push(translations.errors.expectedPayerId);
    }
    if (createMode && !company?.id) {
      validationErrors.push(translations.errors.expectedCompanyId);
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      return false;
    }
    return true;
  };

  // On create
  const createPayer = async () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payloadEmailFlatContact = flatContacts.find(({ type, data }) => type !== 'email' && data) as Extract<
      FlatContact,
      { type?: 'email' }
    >;

    const payloadUsername = buildUsername(firstName!, lastName!, new Date());

    const payload: UsersPostPayloadComplex = {
      contacts: unflattenContacts(flatContacts),
      email: payloadEmailFlatContact?.data.address ?? '',
      first_name: firstName!,
      last_name: lastName!,
      suffix: suffix ?? '',
      title: title ?? '',
      username: payloadUsername,

      _payer_data: {
        companies: [company!.id!],
        method: '',
      },
      _recipient_data: {},
      _requester_data: {
        companies: [company!.id!],
      },
    };

    // Display toast for the creation process and retain id
    let newPayerId: DatabaseId | undefined;

    try {
      const idsCollection = await toast.promise(mutateAsyncCreateUserComplex(payload), {
        pending: translations.progress.payerCreate.started,
        success: translations.progress.payerCreate.success,
        error: translations.progress.payerCreate.error,
      });
      newPayerId = idsCollection.payer_id;
    } catch (err: unknown) {
      setErrors(err as UsersPostErrorComplex);
      console.error(`Errors when creating payer "${payloadUsername}"`, err);
      return;
    }

    toggleOpen(false);
    setCreateMode(false);

    if (!onUpdateSuccess) {
      return;
    }

    const { data: newPayers } = await refetchPayers();
    const updatedPayer = newPayers?.find(({ payer_id }) => payer_id === newPayerId) ?? null;
    onUpdateSuccess(updatedPayer);
  };

  // On update
  const updatePayer = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payload: UsersPutPayload = {
      contacts: unflattenContacts(flatContacts),
      email: payer!.email,
      first_name: firstName!,
      last_name: lastName!,
      location: payer!.location,
      suffix: suffix ?? '',
      title: suffix ?? '',
    };

    toast
      .promise(mutateAsyncUpdateUser([payer!.user_id!, payload]), {
        pending: translations.progress.payerUpdate.started,
        success: translations.progress.payerUpdate.success,
        error: translations.progress.payerUpdate.error,
      })
      .catch((err) => {
        if (err) setErrors(err);
        console.error('Errors when updating payer', err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => refetchPayers())
      .then((refetchData) => {
        const { data: newPayers } = refetchData;
        const updatedPayer = newPayers?.find((iterPayer) => iterPayer.id === payer?.id);
        toggleOpen(false);
        setCreateMode(false);
        onUpdateSuccess?.(updatedPayer ?? null);
      });
  };

  const onSubmit = () => (createMode ? createPayer() : updatePayer());

  // Display
  const label = (
    <h5>
      {translations.payer}
      {!optional && ' *'}
    </h5>
  );

  const display = (
    <FormSelectCreatable<Payer>
      fullWidth
      id={p`payer-select`}
      options={payerList ?? []}
      value={createMode ? null : payer}
      loading={isLoading}
      disabled={createMode || isDisabled}
      clearOnBlur
      renderInput={(params) => (
        <TextField {...params} label={createMode ? translations.tooltip.addPayer : translations.selectPayer} />
      )}
      getCreatedOptionLabel={() => translations.tooltip.addPayer}
      getSelectedOptionLabel={(payerOption) => userToString(payerOption)}
      onCreateOption={(newValue) => {
        toggleOpen(!isOpen);
        setCreateMode(!isOpen);

        const [newFirstName, newLastName] = newValue.split(' ', 2);
        setFirstName(newFirstName);
        setLastName(newLastName);
      }}
      onSelectOption={(option) => setPayer(option as Payer)}
      getOptionValue={(option) => option.id}
    />
  );

  const onCancel = () => {
    prefillForm();
    toggleOpen(false);
    setCreateMode(false);
  };

  const footer = (
    <Row className="mt-4">
      <Col>
        <Button className="action-button me-4" color="submit" onClick={onSubmit}>
          {translations.savePayer}
        </Button>
        <Button className="action-button" color="cancel" onClick={onCancel}>
          {translations.cancel}
        </Button>
        <FormText color="required-fields-help">{translations.requiredFieldsHelp}</FormText>
      </Col>
    </Row>
  );

  const onAdd = () => {
    setPayer(null);
    toggleOpen(!isOpen);
    setCreateMode(!isOpen);
  };

  return (
    <FormExpandableField
      formPrefix={formPrefix}
      label={label}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      onAdd={onAdd}
      addButtonState={!isDisabled ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.DISABLED}
      openButtonState={payer && !isDisabled && !createMode ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.DISABLED}
      openButtonTooltip={isOpen ? translations.tooltip.hidePayer : translations.tooltip.viewPayer}
      display={display}
      footer={footer}
      onCancel={onCancel}
    >
      <Container className="p-0">
        <Row>
          <Col>
            <Row className="gx-2">
              <Col md={12} xl={12} xxl={6}>
                <FormSelect
                  id={p`title`}
                  options={titleOptions}
                  value={titleOptions.find((o) => o.value === title)}
                  onChange={(_event, titleOption) => setTitle(titleOption.value)}
                  renderInput={(params) => <TextField placeholder={t`title`} {...params} />}
                  disableClearable
                />
              </Col>
              <Col md={12} xl={6}>
                <FormInput
                  label={`${translations.firstName} *`}
                  value={firstName ?? ''}
                  onChange={(e) => setFirstName(e.target.value)}
                  errors={errors.first_name}
                />
              </Col>
              <Col md={12} xl={6}>
                <FormInput
                  label={`${translations.lastName} *`}
                  value={lastName ?? ''}
                  onChange={(e) => setLastName(e.target.value)}
                  errors={errors.last_name}
                />
              </Col>
              <Col md={12} xl={12} xxl={6}>
                <FormSelect
                  id={p`suffix`}
                  options={suffixOptions}
                  value={suffixOptions.find((o) => o.value === suffix)}
                  onChange={(_event, suffixOption) => setSuffix(suffixOption.value)}
                  renderInput={(params) => <TextField placeholder={t`suffix`} {...params} />}
                  disableClearable
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormWrapper label={translations.contactInformation}>
                  <FlatContactList
                    items={flatContacts}
                    add={addNewFlatContact}
                    removeAt={removeFlatContactAt}
                    replaceAt={replaceFlatContactAt}
                  />
                  <ErrorsAlert errorsArray={errors.contacts?.map((err) => Object.values(err)).flat(2)} />
                </FormWrapper>
              </Col>
            </Row>

            <ErrorsAlert errorsArray={errors.non_field_errors} />
            <ErrorsAlert errorsArray={errors.validation_errors} />
          </Col>
        </Row>
      </Container>
    </FormExpandableField>
  );
}

export default GenericPayerField as typeof GenericPayerField;
