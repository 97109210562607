/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Col, FormText, Row, Spinner } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { TITLES } from 'src/app/helpers/enum';
import { SUFFIXES } from 'src/app/helpers/collections';
import { Checkbox, FormControlLabel, TextField, Tooltip } from '@mui/material';
import FormDatePicker from 'src/components/DatePicker/FormDatePicker';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import Swal from 'sweetalert2';
import { DatabaseId } from 'src/app/types/DataStructures';
import {
  buildUsername,
  flattenContacts,
  mapArrayForSelect,
  mapObjectForSelect,
  unflattenContacts,
  userToString,
} from '../../../../../app/helpers/mappers';
import { FormInput } from '../../../../../components/Form/FormInput';
import FormWrapper from '../../../../../components/Form/FormWrapper';
import FlatContactList from '../FlatContactList/FlatContactList';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { useForm, useStateArray, useStateDict } from '../../../../../app/hooks';
import {
  createUserComplex,
  getRecipients,
  getUninsuredAffiliations,
  MUTATION_KEYS,
  UninsuredAffiliationGetParams,
  updateUserComplex,
  UsersPostErrorComplex,
  UsersPostPayloadComplex,
  UsersPostResponseComplex,
  UsersPutError,
  UsersPutErrorComplex,
  UsersPutPayloadComplex,
  UsersPutResponse,
} from '../../../../../app/api';
import { FlatContact, Location, Note, UninsuredAffiliation } from '../../../../../app/types/Entities';
import { DEFAULT_COUNTRY_FOR_LOCATION, PREFERRED_DATE_FORMAT_MOMENT } from '../../../../../app/helpers/constants';
import LocationSection from '../LocationSection/LocationSection';
import { ErrorsAlert } from '../../../../../components/ErrorsAlert';
import { stringifyDate } from '../../../../../app/helpers/manipulation';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import NotesListField from '../NotesListField/NotesListField';
import PatientFieldDisplaySelect from './PatientFieldDisplaySelect';

export interface PatientFieldProps {
  showPatientField: boolean;
  setShowPatientField: (display: boolean) => void;
  affiliation: UninsuredAffiliation | null;
  setAffiliation: (affiliation: UninsuredAffiliation | null) => void;
  preloadBookingId?: DatabaseId;
  onUpdateSuccess?: (updatedAffiliation?: UninsuredAffiliation) => void;
}

const formPrefix = 'patient';
const translationArray = ['booking', 'forms', 'languages'];

function PatientField(props: PatientFieldProps) {
  const { showPatientField, setShowPatientField, affiliation, setAffiliation, onUpdateSuccess, preloadBookingId } =
    props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<UsersPostErrorComplex & UsersPutErrorComplex>({});
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  useEffect(() => {
    if (!showPatientField) {
      setShowNotes(false);
    }
  }, [showPatientField]);

  const [title, setTitle] = useState<string | undefined>();
  const titleOptions = mapObjectForSelect(TITLES, (titleKey) => t(`collections:titles.${titleKey}`));

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [suffix, setSuffix] = useState<string | undefined>();
  const suffixOptions = mapArrayForSelect(SUFFIXES, (suffixKey) => t(`collections:suffixes.${suffixKey}`));

  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
  const [omitDateOfBirth, setOmitDateOfBirth] = useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [location, setLocation] = useState<Location | undefined>();
  const updateLocation = (fields: Partial<Location>) => setLocation((prev) => ({ ...prev, ...fields }));

  const {
    values: flatContacts,
    set: setFlatContacts,
    push: pushFlatContact,
    replaceAt: replaceFlatContactAt,
    removeAt: removeFlatContactAt,
  } = useStateArray<FlatContact>([]);

  const addNewFlatContact = () =>
    pushFlatContact({
      type: undefined,
      data: {},
      context: 'personal',
      uuid: uuidv4(),
    });

  const {
    values: notes,
    set: setNotes,
    push: pushNote,
    replaceAt: replaceNoteAt,
    removeAt: stateRemoveNoteAt,
  } = useStateArray<Note>([]);

  const removeNoteAt = (index: number) => {
    if (notes.length <= 1) {
      setShowNotes(false);
    }
    stateRemoveNoteAt(index);
  };

  const prefillForm = () => {
    const recipient = affiliation?.recipient ?? undefined;

    setTitle(recipient?.title);
    setFirstName(recipient?.first_name);
    setLastName(recipient?.last_name);
    setSuffix(recipient?.suffix);
    setDateOfBirth(recipient?.date_of_birth);
    setLocation(recipient?.location ?? undefined);
    setFlatContacts(flattenContacts(recipient?.contacts) ?? []);
    setNotes(recipient?.notes ?? []);
    if (!recipient?.date_of_birth) {
      setOmitDateOfBirth(true);
    }
    if (affiliation?.recipient === undefined) {
      setOmitDateOfBirth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    prefillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliation, setFlatContacts, setNotes, preloadBookingId]);

  useEffect(() => {
    // Ensure there is always at least one (flat) contact available to fill
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  // Query
  const [affiliationFilters] = useStateDict<UninsuredAffiliationGetParams>({});
  const [affiliationsRefetchEnabled, setAffiliationsRefetchEnabled] = useState<boolean>(true);
  const {
    data: affiliations,
    isLoading: isAffiliationLoading,
    refetch: refetchAffiliations,
  } = useQuery([MUTATION_KEYS.AFFILIATIONS, affiliationFilters], () => getUninsuredAffiliations(affiliationFilters), {
    enabled: affiliationsRefetchEnabled,
  });

  useEffect(() => {
    if (affiliations) {
      setAffiliationsRefetchEnabled(false);
    }
  }, [affiliations]);

  const { mutateAsync: fetchDoB } = useMutation([MUTATION_KEYS.USERS, { dateOfBirth }], (DoB: string) =>
    getRecipients({ date_of_birth: DoB }),
  );

  // Formatting
  const formatAffiliationToLabel = (affiliationToFormat: UninsuredAffiliation | null) => {
    if (!affiliationToFormat) {
      return '';
    }

    const { recipient } = affiliationToFormat;
    const recipientDoB = recipient.date_of_birth ?? null;
    const mainPhone = recipient.contacts?.[0]?.phone ?? null;

    return `${userToString(recipient)} ${
      recipientDoB ? `— DoB: ${moment(recipientDoB).format(PREFERRED_DATE_FORMAT_MOMENT)}` : ''
    } ${mainPhone ? `— Tel: ${mainPhone}` : ''}`;
  };

  // On saving
  const { mutateAsync: mutateAsyncCreateUserComplex } = useMutation<
    UsersPostResponseComplex,
    UsersPostErrorComplex,
    UsersPostPayloadComplex
  >(createUserComplex);

  const { mutateAsync: mutateAsyncUpdateUserComplex } = useMutation<
    UsersPutResponse,
    UsersPutError,
    Parameters<typeof updateUserComplex>
  >((args) => updateUserComplex(...args));

  // On save functions
  // Validate
  const validate = async () => {
    const validationErrors = [];
    let shouldValidate = true;

    if (omitDateOfBirth) {
      shouldValidate = await Swal.fire({
        icon: 'warning',
        title: t`alerts.noDoBTitle`,
        text: t`alerts.noDoBMessage`,
        footer: t`alerts.noDoBFooter`,
        showCancelButton: true,
        confirmButtonText: t`alerts.noDoBYes`,
        cancelButtonText: t`alerts.noDoBNo`,
      }).then(({ isConfirmed }) => isConfirmed);
    } else if (!dateOfBirth) {
      validationErrors.push(t`errors.noDateOfBirth`);
    }
    if (!firstName) {
      validationErrors.push(t`errors.noFirstName`);
    }
    if (!lastName) {
      validationErrors.push(t`errors.noLastName`);
    }

    if (!createMode && !affiliation?.recipient.id) {
      validationErrors.push(t`errors.expectedRecipientId`);
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      return false;
    }
    return shouldValidate;
  };

  // On create
  const createPatient = async () => {
    setErrors({});
    if (!(await validate())) {
      return;
    }

    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? {
            ...location,
            country: DEFAULT_COUNTRY_FOR_LOCATION,
          }
        : undefined;

    const payloadEmailFlatContact = flatContacts.find(({ type, data }) => type === 'email' && data) as Extract<
      FlatContact,
      { type?: 'email' }
    >;

    const payloadDateOfBirth = omitDateOfBirth || !dateOfBirth ? null : stringifyDate(dateOfBirth);

    const payloadUsername = buildUsername(firstName!, lastName!, dateOfBirth ?? new Date());

    const payload: UsersPostPayloadComplex = {
      contacts: unflattenContacts(flatContacts),
      date_of_birth: payloadDateOfBirth,
      email: payloadEmailFlatContact?.data.address ?? '',
      first_name: firstName!,
      last_name: lastName!,
      location: payloadLocation,
      suffix: suffix ?? '',
      title: title ?? '',
      username: payloadUsername,

      _recipient_data: {
        companies: [],
        notes,
        _affiliation_datalist: undefined, // Default behavior
      },

      _requester_data: {
        companies: [],
      },
    };

    let canCreate = true;

    if (payload.date_of_birth) {
      const fetchedDoB = await fetchDoB(payload.date_of_birth);
      if (fetchedDoB.length > 0) {
        const usernames = fetchedDoB.map((patient) => `${patient.first_name} ${patient.last_name}`);
        canCreate = await Swal.fire({
          title: 'Are you sure you want to save this patient?',
          html: `<p>This patient have a Date Of Birth that is already registered, these are the patients:<p>
        <ul>
          ${usernames.map((name) => `<li>${name}</li>`).join('')} 
        </ul>`,
          icon: 'warning',
          confirmButtonText: t`actions.save`,
          confirmButtonColor: '#00a2b8',
          showCancelButton: true,
          cancelButtonText: t`actions.discard`,
          cancelButtonColor: '#dc3741',
          allowOutsideClick: true,
          customClass: {
            confirmButton: 'btn action-button',
            cancelButton: 'btn action-button',
          },
        }).then(({ isConfirmed }) => isConfirmed);
      }
    }

    if (!canCreate) {
      return;
    }

    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 5000);

    // Display toast for the creation process
    toast
      .promise(mutateAsyncCreateUserComplex(payload), {
        pending: t`progress.patientCreate.started` as string,
        success: t`progress.patientCreate.success` as string,
        error: t`progress.patientCreate.error` as string,
      })
      .catch((err: UsersPostErrorComplex) => {
        setErrors(err);
        console.error(`Errors when creating patient "${payloadUsername}"`, err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(async (idsCollection) => {
        // Refetch affiliations without losing the created affiliation's ID
        const createdAffiliationId = idsCollection.affiliation_ids[0]; // We only expect one affiliation to be created
        return [await refetchAffiliations(), createdAffiliationId] as const;
      })
      .then(([refetchData, createdAffiliationId]) => {
        const { data: newAffiliations } = refetchData;
        const newlyCreatedAffiliation = newAffiliations?.find((aff) => aff.id === createdAffiliationId);
        setShowPatientField(false);
        setCreateMode(false);
        onUpdateSuccess?.(newlyCreatedAffiliation);
      });
  };

  // On update
  const updatePatient = async () => {
    setErrors({});
    if (!(await validate())) {
      return;
    }

    const recipient = affiliation?.recipient!;
    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? {
            ...location,
            country: DEFAULT_COUNTRY_FOR_LOCATION,
          }
        : undefined;

    const payloadDateOfBirth = omitDateOfBirth || !dateOfBirth ? null : stringifyDate(dateOfBirth);

    const payload: UsersPutPayloadComplex = {
      contacts: unflattenContacts(flatContacts),
      date_of_birth: payloadDateOfBirth,
      email: recipient.email,
      first_name: firstName!,
      last_name: lastName!,
      location: payloadLocation,
      suffix: suffix ?? '',
      title: title ?? '',

      _recipient_data: {
        companies: recipient.companies,
        notes,
      },
      _requester_data: {
        companies: [],
      },
    };

    setIsSubmitting(true);
    setTimeout(() => {
      setIsSubmitting(false);
    }, 5000);

    toast
      .promise(mutateAsyncUpdateUserComplex([recipient.user_id!, payload]), {
        pending: t`progress.patientUpdate.started` as string,
        success: t`progress.patientUpdate.success` as string,
        error: t`progress.patientUpdate.error` as string,
      })
      .catch((err) => {
        if (err) setErrors(err);
        console.error('Errors when updating patient', err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => refetchAffiliations())
      .then((refetchData) => {
        const { data: newAffiliations } = refetchData;
        const updatedAffiliation = newAffiliations?.find(
          (iterAffiliation) => iterAffiliation.recipient.id === recipient.id,
        );
        onUpdateSuccess?.(updatedAffiliation);
      });
  };

  const onSave = () => (createMode ? createPatient() : updatePatient());

  // Display
  const label = <h5>{t`patient`} *</h5>;

  const display = (
    <PatientFieldDisplaySelect
      id={p`affiliation`}
      affiliation={affiliation}
      affiliations={affiliations}
      formatAffiliationToLabel={formatAffiliationToLabel}
      setAffiliation={setAffiliation}
      setShowPatientField={setShowPatientField}
      isAffiliationLoading={isAffiliationLoading}
      createMode={createMode}
      setCreateMode={setCreateMode}
      setFirstName={setFirstName}
      setLastName={setLastName}
    />
  );

  const onCancel = () => {
    setShowPatientField(false);
    setCreateMode(false);
    prefillForm();
  };

  const footer = (
    <Row>
      <Col>
        <Button className="action-button me-4" color="submit" onClick={onSave} disabled={isSubmitting} block>
          {isSubmitting ? <Spinner className="me-2" size="sm" type="border" color="primary" /> : t`savePatient`}
        </Button>
      </Col>
      <Col>
        <Button className="action-button" color="cancel" onClick={onCancel} block>
          {t`cancel`}
        </Button>
      </Col>
      <Col>
        <FormText color="required-fields-help">{t`requiredFieldsHelp`}</FormText>
      </Col>
    </Row>
  );

  return (
    <FormExpandableField // Patient (uninsured affiliation)
      formPrefix={formPrefix}
      isOpen={showPatientField}
      toggleOpen={setShowPatientField}
      canClose={!createMode}
      openButtonState={!affiliation?.recipient || createMode ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showPatientField ? t`tooltip.hidePatient` : t`tooltip.viewPatient`}
      label={label}
      display={display}
      footer={footer}
      onCancel={onCancel}
    >
      <Row className="gx-2">
        <Col md={12} xxl={4}>
          <FormSelect
            id={p`title`}
            options={titleOptions}
            value={titleOptions.find((o) => o.value === title)}
            onChange={(_event, titleOption) => setTitle(titleOption.value)}
            renderInput={(params) => <TextField label={t`title`} placeholder={t`title`} {...params} />}
            disableClearable
          />
        </Col>
        <Col md={12} xl={6} xxl={4}>
          <FormInput
            label={`${t`firstName`} *`}
            value={firstName ?? ''}
            onChange={(e) => setFirstName(e.target.value)}
            errors={errors.first_name}
          />
        </Col>
        <Col md={12} xl={6} xxl={4}>
          <FormInput
            label={`${t`lastName`} *`}
            value={lastName ?? ''}
            onChange={(e) => setLastName(e.target.value)}
            errors={errors.last_name}
          />
        </Col>
        <Col md={12} xxl={4}>
          <FormSelect
            id={p`suffix`}
            options={suffixOptions}
            value={suffixOptions.find((o) => o.value === suffix)}
            onChange={(_event, suffixOption) => setSuffix(suffixOption.value)}
            renderInput={(params) => <TextField label={t`suffix`} placeholder={t`suffix`} {...params} />}
            disableClearable
          />
        </Col>
        <Col md={12} xxl={4}>
          <FormDatePicker
            label={`${t`dob`} *`}
            dateFormat={PREFERRED_DATE_FORMAT_MOMENT}
            maxDate={new Date()}
            value={omitDateOfBirth ? undefined : dateOfBirth}
            disabled={omitDateOfBirth}
            onChange={(date) => date && setDateOfBirth(date.toDate())}
          />
        </Col>
        <Col xs="auto" className="flex-grow-1">
          <Tooltip title={t`tooltip.omitDateOfBirthExplanation`} placement="right">
            <FormControlLabel
              label={t`omitDateOfBirth`}
              sx={{
                marginTop: {
                  xs: '7px',
                },
              }}
              control={
                <Checkbox
                  checked={omitDateOfBirth}
                  onChange={(e) => setOmitDateOfBirth(e.target.checked)}
                  sx={{
                    '&.Mui-checked': {
                      color: 'red',
                    },
                    '& .MuiSvgIcon-root': { fontSize: 24 },
                  }}
                />
              }
            />
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label="Address" className="pb-2">
            <LocationSection errors={errors.location} location={location} update={updateLocation} />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label={t`contactInformation`}>
            <FlatContactList
              items={flatContacts}
              add={addNewFlatContact}
              removeAt={removeFlatContactAt}
              replaceAt={replaceFlatContactAt}
            />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <NotesListField
            formPrefix={p`patient-notes`}
            labelText={t`patientNotes`}
            showNotesList={showNotes}
            setShowNotesList={setShowNotes}
            notesList={notes}
            pushNote={pushNote}
            replaceNoteAt={replaceNoteAt}
            removeNoteAt={removeNoteAt}
          />
        </Col>
      </Row>

      <ErrorsAlert errorsArray={errors.non_field_errors} />
      <ErrorsAlert errorsArray={errors.validation_errors} />
    </FormExpandableField>
  );
}

export default PatientField;
