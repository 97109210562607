import { Col, Row } from 'reactstrap';
import { IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropzoneArea } from 'mui-file-dropzone';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { StateUpdater } from 'src/app/types/Components';
import FormSection from '../../../../../components/Form/FormSection';
import './IRSW9FormField.scss';
import { IRSW9FormState } from '../localTypes';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';

export interface IRSW9FormFieldProps {
  initialState: IRSW9FormState;
  stateUpdater: StateUpdater<IRSW9FormState>;
}

const translationArray = ['onboardings'];

function IRSW9FormField(props: IRSW9FormFieldProps) {
  const { initialState, stateUpdater } = props;

  const { t } = useTranslation(translationArray);

  const [file, setFile] = useStateWithCallback(initialState.irsW9Form, stateUpdater('irsW9Form'));

  return (
    <FormSection label={t`irsW9Form`}>
      <Typography variant="overline" color="red">
        Not implemented yet
      </Typography>
      {!file ? (
        <DropzoneArea
          dropzoneClass="dropzone-area"
          fileObjects={file ? [file] : []}
          filesLimit={1}
          onChange={(files) => setFile(files[0])}
          onDelete={() => setFile(undefined)}
          onAlert={(message, variant) => toast(message, { type: variant })}
          clearOnUnmount={false}
          showAlerts={false}
          showPreviews={false}
        />
      ) : (
        <Row>
          <Col className="align-self-center">
            <Typography>{file.name}</Typography>
          </Col>
          <Col xs="auto" className="flex-grow-0 align-self-center">
            <IconButton onClick={() => setFile(undefined)}>
              <DeleteIcon fontSize="medium" />
            </IconButton>
          </Col>
        </Row>
      )}
    </FormSection>
  );
}

export default IRSW9FormField;
