import React, { ReactNode } from 'react';
import classNames from 'classnames';
import FormExpandableField, { FormExpandableFieldProps } from '../FormExpandableField';
import './FormExpandableListField.scss';

export interface FormExpandableListFieldProps extends Omit<FormExpandableFieldProps, 'display'> {
  defaultDisplay?: ReactNode;
  showSeparator?: boolean;
}

function FormExpandableListField({
  isOpen,
  defaultDisplay,
  showSeparator = false,
  children,
  ...props
}: FormExpandableListFieldProps) {
  const childrenArray = React.Children.toArray(children).map((child, index) => (
    <div
      className={classNames('expandable-list-field-item', {
        'show-separator': index > 0 && isOpen && showSeparator,
      })}
    >
      {child}
    </div>
  ));

  const itemToDisplay = childrenArray[0];
  const itemsInside = childrenArray.slice(1);

  return (
    <FormExpandableField
      className={classNames('expandable-list-field', props.className)}
      isOpen={isOpen}
      display={childrenArray.length > 0 ? itemToDisplay : defaultDisplay}
      showDisplaySeparator={false}
      {...props}
    >
      {itemsInside}
    </FormExpandableField>
  );
}

export default FormExpandableListField;
