import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { TextField } from '@mui/material';
import { ContactContext, EMAIL_CONTEXTS, FAX_CONTEXTS, PHONE_CONTEXTS } from '../../../../../app/helpers/enum';
import { FlatContact } from '../../../../../app/types/Entities';
import { mapArrayForSelect, mapObjectForSelect } from '../../../../../app/helpers/mappers';
import { FormInput } from '../../../../../components/Form/FormInput';
import { findInOptions } from '../../../../../app/helpers/manipulation';

interface FlatContactListItemEmailFieldProps {
  address: string | undefined;
  context: ContactContext | string;
  contextOptions?: string[];
  update: (flatContact: Partial<FlatContact>) => void;
}

export function FlatContactListItemEmailField({
  address,
  context,
  contextOptions,
  update,
}: FlatContactListItemEmailFieldProps) {
  const { t } = useTranslation('booking');
  const contextOptionsSelect = contextOptions
    ? mapArrayForSelect(contextOptions, (k) => t(`${k}`))
    : mapObjectForSelect(EMAIL_CONTEXTS, (_, value) => t(`contact.${value}`));

  return (
    <>
      <Col xxl={6}>
        <FormInput
          type="email"
          label={t`contact.emailAddress`}
          value={address ?? ''}
          onChange={(e) => {
            update({
              data: { address: e.target.value },
            });
          }}
        />
      </Col>
      <Col xxl={4}>
        <FormSelect
          options={contextOptionsSelect}
          value={findInOptions(contextOptionsSelect, context)}
          onChange={(_event, contextOption) =>
            contextOption &&
            update({
              context: contextOption.value,
            })
          }
          renderInput={(params) => <TextField placeholder={t`contact.context`} {...params} />}
          disableClearable
        />
      </Col>
    </>
  );
}

interface FlatContactListItemPhoneFieldProps {
  number: string | undefined;
  extension?: string;
  context: ContactContext | string;
  contextOptions?: string[];
  update: (flatContact: Partial<FlatContact>) => void;
}

export function FlatContactListItemPhoneField({
  number,
  extension,
  context,
  contextOptions,
  update,
}: FlatContactListItemPhoneFieldProps) {
  const { t } = useTranslation('booking');
  const contextOptionsSelect = contextOptions
    ? mapArrayForSelect(contextOptions, (k) => t(`${k}`))
    : mapObjectForSelect(PHONE_CONTEXTS, (_, value) => t(`contact.${value}`));

  return (
    <>
      <Col xxl={6}>
        <FormInput
          label={t`contact.phoneNumber`}
          value={number ?? ''}
          onChange={(e) => {
            update({
              data: {
                number: e.target.value,
                extension,
              },
            });
          }}
        />
      </Col>
      <Col xxl={2}>
        <FormInput
          label={t`contact.phoneExtension`}
          value={extension ?? ''}
          onChange={(e) => {
            update({
              data: {
                number,
                extension: e.target.value,
              },
            });
          }}
        />
      </Col>
      <Col xxl={2}>
        <FormSelect
          options={contextOptionsSelect}
          value={findInOptions(contextOptionsSelect, context)}
          onChange={(_event, contextOption) =>
            contextOption &&
            update({
              context: contextOption.value,
            })
          }
          renderInput={(params) => <TextField placeholder={t`contact.context`} {...params} />}
          disableClearable
        />
      </Col>
    </>
  );
}

interface FlatContactListItemFaxFieldProps {
  number: string | undefined;
  extension?: string;
  context: ContactContext | string;
  contextOptions?: string[];
  update: (flatContact: Partial<FlatContact>) => void;
}

export function FlatContactListItemFaxField({
  number,
  extension,
  context,
  contextOptions,
  update,
}: FlatContactListItemFaxFieldProps) {
  const { t } = useTranslation('booking');
  const contextOptionsSelect = contextOptions
    ? mapArrayForSelect(contextOptions, (k) => t(`${k}`))
    : mapObjectForSelect(FAX_CONTEXTS, (_, value) => t(`contact.${value}`));

  return (
    <>
      <Col xxl={6}>
        <FormInput
          label={t`contact.faxNumber`}
          value={number ?? ''}
          onChange={(e) => {
            update({
              data: {
                number: e.target.value,
                extension,
              },
            });
          }}
        />
      </Col>
      <Col xxl={2}>
        <FormInput
          label={t`contact.faxExtension`}
          value={extension ?? ''}
          onChange={(e) => {
            update({
              data: {
                number,
                extension: e.target.value,
              },
            });
          }}
        />
      </Col>
      <Col xxl={2}>
        <FormSelect
          options={contextOptionsSelect}
          value={findInOptions(contextOptionsSelect, context)}
          onChange={(_event, contextOption) =>
            contextOption &&
            update({
              context: contextOption.value,
            })
          }
          renderInput={(params) => <TextField placeholder={t`contact.context`} {...params} />}
          disableClearable
        />
      </Col>
    </>
  );
}
