import { Col, Row } from 'reactstrap';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import { PaymentMethod, PaymentMethodZelle } from '../../localTypes';
import useStateWithCallback from '../../../../../../app/hooks/useStateWithCallback';
import PaymentMethodSelect from './Select';

export interface PaymentMethodItemZelleProps {
  selectedMethod: PaymentMethod['method'];
  setSelectedMethod: (selectedMethod: PaymentMethod['method']) => void;
  initialState: PaymentMethodZelle;
  stateUpdater: StateUpdater<PaymentMethodZelle>;
  // TODO add errors
}

const translationArray = ['onboardings'];

export default function PaymentMethodItemZelle(props: PaymentMethodItemZelleProps) {
  const { selectedMethod, setSelectedMethod, initialState, stateUpdater } = props;

  const { t } = useTranslation(translationArray);

  const [accountNumber, setAccountNumber] = useStateWithCallback(
    initialState.accountNumber,
    stateUpdater('accountNumber'),
  );

  return (
    <Row>
      <Col>
        <PaymentMethodSelect selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} />
      </Col>
      <Col>
        <TextField
          fullWidth
          variant="standard"
          label={t`zelleNumber` as string}
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
        />
      </Col>
    </Row>
  );
}
