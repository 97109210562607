import { Form } from 'reactstrap';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useStateDict } from '../../../../app/hooks';
import { Category } from '../../../../app/types/Entities';
import { FormProps, FormSubmitEvent, FormSubmitProps, RecursiveInputDefault } from '../../../../app/types/Components';
import {
  CategoriesPostError,
  CategoriesPostPayload,
  CategoriesPostResponse,
  CategoriesPutError,
  CategoriesPutResponse,
  createCategory,
  getCategory,
  updateCategory,
} from '../../../../app/api/categories';
import { CategoryFormGroup } from './Group';
import { ErrorsAlert } from '../../../../components/ErrorsAlert';
import { DatabaseId } from '../../../../app/types/DataStructures';
import { MUTATION_KEYS } from '../../../../app/api';

export type CategoryOnboardingFormDefaults = Partial<RecursiveInputDefault<Category>>;

export interface CompanyOnboardingFormProps
  extends FormProps,
    FormSubmitProps<CategoriesPostPayload, CategoriesPostResponse, CategoriesPostError> {
  defaults?: CategoryOnboardingFormDefaults;
  preloadCategoryId?: DatabaseId;
}

export function CategoryOnboardingForm({
  id,
  children,
  formPrefix = 'category',
  submitPreventDefault = true,
  preSubmit,
  onSubmitSuccess,
  onSubmitFailure,
  postSubmit,
  defaults,
  preloadCategoryId,
}: CompanyOnboardingFormProps) {
  const { t } = useTranslation('forms');
  const categoryIdProvided = preloadCategoryId !== undefined;

  const [errors, setErrors] = useStateDict<CategoriesPostError>({});

  const { mutateAsync: mutateAsyncCreate } = useMutation<
    CategoriesPostResponse,
    CategoriesPostError,
    CategoriesPostPayload
  >(createCategory);

  const { mutateAsync: mutateAsyncUpdate } = useMutation<
    CategoriesPutResponse,
    CategoriesPutError,
    Parameters<typeof updateCategory>
  >((args) => updateCategory(...args));

  const [description, setDescription] = useState<string>(defaults?.description?.value ?? '');
  const [name, setName] = useState<string>(defaults?.name?.value ?? '');

  function prefillFromCategory(c: Category) {
    setDescription(c.description);
    setName(c.name);
  }

  useQuery(
    [MUTATION_KEYS.CATEGORIES, preloadCategoryId],
    () => (categoryIdProvided ? getCategory(preloadCategoryId) : Promise.reject(new Error('Category id not set'))),
    {
      enabled: categoryIdProvided,
      onSuccess: prefillFromCategory,
    },
  );
  const onSubmit = (e: FormSubmitEvent) => {
    if (submitPreventDefault) {
      e.preventDefault();
    }

    const payload: CategoriesPostPayload = {
      description,
      name,
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    const promise = !preloadCategoryId
      ? toast // Create
          .promise(mutateAsyncCreate(payload), {
            pending: t`onboarding.category.progress.started` as string,
            error: t`onboarding.category.progress.error` as string,
            success: t`onboarding.category.progress.success` as string,
          })
          .then((resp) => onSubmitSuccess?.(resp, payload))
      : toast // Update
          .promise(mutateAsyncUpdate([preloadCategoryId, payload]), {
            pending: t`updating.category.progress.started` as string,
            error: t`updating.category.progress.error` as string,
            success: t`updating.category.progress.success` as string,
          })
          .then(() => onSubmitSuccess?.(preloadCategoryId, payload));

    promise
      .catch((err) => {
        if (err) setErrors(err);
        onSubmitFailure?.(err, payload);
      })
      .finally(postSubmit);
  };

  return (
    <Form id={id} onSubmit={onSubmit}>
      <ErrorsAlert errorsArray={errors.non_field_errors} />
      <CategoryFormGroup
        formPrefix={formPrefix}
        nameProps={{
          readOnly: defaults?.name?.readOnly,
          value: name,
          onChange: (e) => setName(e.target.value),
          errors: errors.name,
        }}
        descriptionProps={{
          readOnly: defaults?.description?.readOnly,
          value: description,
          onChange: (e) => setDescription(e.target.value),
          errors: errors.description,
        }}
      />

      {children}
    </Form>
  );
}
