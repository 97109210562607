import { AxiosError } from 'axios';
import { DatabaseId, Errors } from '../types/DataStructures';
import { ApiDao, ApiQuery, applyLookup, LookupsMap, pathParam } from './config/helpers';
import { apiClient } from './config/clients';
import { CATEGORIES_ENDPOINT } from './config/endpoints';
import { sanitizeCategory } from './config/sanitizers';
import { Category } from '../types/Entities';
import { CategoryPrefix } from '../helpers/enum';

// GET
export interface CategoriesGetParams {
  id?: DatabaseId;
  description?: string;
  name?: string;

  page?: number;
  page_size?: number;
}
export const DEFAULT_CATEGORIES_LOOKUPS: LookupsMap<CategoriesGetParams> = {
  description: 'nem',
  name: 'nem',
};

// Get many
export const getCategoriesDao: ApiDao<CategoriesGetParams, any[]> = (params, lookups = DEFAULT_CATEGORIES_LOOKUPS) =>
  apiClient.get<any[]>(CATEGORIES_ENDPOINT, { params: applyLookup(params, lookups) });

export const getCategories: ApiQuery<CategoriesGetParams, Category[]> = (
  params,
  lookups = DEFAULT_CATEGORIES_LOOKUPS,
) => getCategoriesDao(params, lookups).then((res) => res.data.map(sanitizeCategory));

export const getCategoriesPaginated: ApiQuery<
  CategoriesGetParams,
  { count: number; next: string | null; previous: string | null; results: Category[] }
> = (params, lookups = DEFAULT_CATEGORIES_LOOKUPS) =>
  apiClient
    .get<any>(CATEGORIES_ENDPOINT, {
      params: applyLookup<CategoriesGetParams>(params, lookups),
    })
    .then((res) => res.data)
    .then((data) => ({
      ...data,
      results: data.results.map(sanitizeCategory),
    }));

// Get by ID
export const getCategory = (categoryId: DatabaseId) =>
  apiClient.get(pathParam(CATEGORIES_ENDPOINT, categoryId)).then((res) => sanitizeCategory(res.data));

// Get by name
export const CATEGORIES_BY_PREFIX_LOOKUPS: LookupsMap<CategoriesGetParams> = {
  ...DEFAULT_CATEGORIES_LOOKUPS,
  name: 'nem',
};

export const getCategoriesByPrefix = (prefix: CategoryPrefix | string) =>
  getCategoriesDao({ name: prefix }, CATEGORIES_BY_PREFIX_LOOKUPS).then((res) => res.data.map(sanitizeCategory));

// POST
export interface CategoriesPostPayload {
  description: string;
  name: string;
}
export type CategoriesPostResponse = DatabaseId;
export type CategoriesPostError = Errors<CategoriesPostPayload>;
export const createCategory = (payload: CategoriesPostPayload) =>
  apiClient
    .post<CategoriesPostResponse>(CATEGORIES_ENDPOINT, payload)
    .then((res) => res.data)
    .catch((err: AxiosError<CategoriesPostError>) => Promise.reject(err.response?.data));

// PUT
export type CategoriesPutPayload = CategoriesPostPayload;
export type CategoriesPutResponse = unknown;
export type CategoriesPutError = Errors<CategoriesPutPayload>;
export const updateCategory = (categoryId: DatabaseId, data: CategoriesPutPayload) =>
  apiClient
    .put<CategoriesPutResponse>(pathParam(CATEGORIES_ENDPOINT, categoryId), data)
    .catch((err: AxiosError<CategoriesPutError>) => Promise.reject(err.response?.data));

// DELETE
export type CategoriesDeleteResponse = unknown;
export type CategoriesDeleteError = string;
export const deleteCategory = (categoryId: DatabaseId) =>
  apiClient
    .delete<CategoriesDeleteResponse>(pathParam(CATEGORIES_ENDPOINT, categoryId))
    .catch((err: AxiosError<CategoriesDeleteError>) => Promise.reject(err.response?.data));
