import { useTranslation } from 'react-i18next';
import { Agency, ClaimsAgent } from '../../../../../../app/types/Entities';
import GenericPayerField, { GenericPayerFieldTranslationProps } from '../GenericPayerField';

export interface AgentFieldProps {
  formPrefix: string;
  optional?: boolean;

  isDisabled: boolean;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;

  agent: ClaimsAgent | null;
  setAgent: (agent: ClaimsAgent | null) => void;

  agentList: ClaimsAgent[] | undefined;
  isLoading: boolean;
  refetchAgents: () => Promise<{ data: ClaimsAgent[] | undefined }>;

  agency: Agency | null;

  onUpdateSuccess?: (updatedAdjuster: ClaimsAgent | null) => void;
}

export default function AgentField(props: AgentFieldProps) {
  const {
    formPrefix,
    optional,
    isDisabled,
    isOpen,
    toggleOpen,
    agent,
    setAgent,
    agentList,
    isLoading,
    refetchAgents,
    agency,
    onUpdateSuccess,
  } = props;

  const { t } = useTranslation('booking');

  const translations: GenericPayerFieldTranslationProps = {
    firstName: t`firstName`,
    lastName: t`lastName`,
    suffix: t`suffix`,
    title: t`title`,
    errors: {
      noFirstName: t`errors.noFirstName`,
      noLastName: t`errors.noLastName`,
      expectedPayerId: t`errors.expectedPayerId`,
      expectedCompanyId: t`errors.expectedCompanyId`,
    },
    progress: {
      payerCreate: {
        started: t`progress.agentCreate.started`,
        success: t`progress.agentCreate.success`,
        error: t`progress.agentCreate.error`,
      },
      payerUpdate: {
        started: t`progress.agentUpdate.started`,
        success: t`progress.agentUpdate.success`,
        error: t`progress.agentUpdate.error`,
      },
    },
    tooltip: {
      cancelAddPayer: t`tooltip.cancelAddAgent`,
      addPayer: t`tooltip.addAgent`,
      hidePayer: t`tooltip.hideAgent`,
      viewPayer: t`tooltip.viewAgent`,
    },
    selectPayer: t`selectAgent`,
    savePayer: t`saveAgent`,
    cancel: t`cancel`,
    requiredFieldsHelp: t`requiredFieldsHelp`,
    payer: t`agent`,
    contactInformation: t`contactInformation`,
  };

  return (
    <GenericPayerField
      formPrefix={formPrefix}
      optional={optional}
      isDisabled={isDisabled}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      payer={agent}
      setPayer={setAgent}
      payerList={agentList}
      isLoading={isLoading}
      refetchPayers={refetchAgents}
      company={agency}
      onUpdateSuccess={onUpdateSuccess}
      translations={translations}
    />
  );
}
