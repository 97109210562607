import { HTMLProps } from 'react';
import './FormSpan.scss';
import classNames from 'classnames';

export type FormSpanProps = HTMLProps<HTMLSpanElement>;

export default function FormSpan({ className = '', children, ...props }: FormSpanProps) {
  return (
    <span
      className={classNames('form-span', 'form-control', 'd-flex', 'align-items-center', { [className]: !!className })}
      {...props}
    >
      {children}
    </span>
  );
}
