import { AxiosError } from 'axios';
import { DatabaseId, Errors } from '../types/DataStructures';
import { apiClient } from './config/clients';
import { AUTHORIZATIONS_ENDPOINT } from './config/endpoints';
import { ApiQuery, applyLookup, LookupsMap, pathParam } from './config/helpers';
import { sanitizeAuthorization } from './config/sanitizers';
import { AuthorizationStatus, SendMethod } from '../helpers/enum';
import { EventsGetParams } from './events';
import { Authorization } from '../types/Entities';
import { hydrateAuthorization } from './config/hydrators';

// GET
// Fetch many
export interface AuthorizationsGetParams {
  authorizer?: unknown;
  company?: unknown;
  contact_via?: unknown;
  events?: EventsGetParams;
  event_id?: number | undefined;
  last_updated_at?: string;
  status?: AuthorizationStatus;
}

export const DEFAULT_AUTHORIZATIONS_LOOKUPS: LookupsMap<AuthorizationsGetParams> = {};

export const getAuthorizations: ApiQuery<AuthorizationsGetParams, Authorization[]> = (
  params,
  lookups = DEFAULT_AUTHORIZATIONS_LOOKUPS,
) =>
  apiClient
    .get<any[]>(AUTHORIZATIONS_ENDPOINT, { params: applyLookup(params, lookups) })
    .then((res) => res.data.map((v) => v))
    .then((dehydratedAuthorizations) => Promise.all(dehydratedAuthorizations.map((v) => v)));

// Fetch one
export const getAuthorization = (authorizationId: DatabaseId) =>
  apiClient
    .get(pathParam(AUTHORIZATIONS_ENDPOINT, authorizationId))
    .then((res) => sanitizeAuthorization(res.data))
    .then(hydrateAuthorization);

// POST
export interface AuthorizationsPostPayload {
  authorizer: DatabaseId;
  company: DatabaseId;
  contact?: DatabaseId;
  contact_via?: SendMethod;
  events?: DatabaseId[];
  _events_query?: EventsGetParams;
  status: AuthorizationStatus;
}
export type AuthorizationsPostResponse = DatabaseId;
export type AuthorizationsPostError = Errors<AuthorizationsPostPayload>;
export const createAuthorization = (payload: AuthorizationsPostPayload) =>
  apiClient
    .post<AuthorizationsPostResponse>(AUTHORIZATIONS_ENDPOINT, payload)
    .then((res) => res.data)
    .catch((err: AxiosError<AuthorizationsPostError>) => Promise.reject(err.response?.data));

// PUT
export type AuthorizationsPutPayload = AuthorizationsPostPayload;
export type AuthorizationsPutResponse = unknown;
export type AuthorizationsPutError = Errors<AuthorizationsPutPayload>;
export const updateAuthorization = (authorizationId: DatabaseId, data: AuthorizationsPutPayload) =>
  apiClient
    .put<AuthorizationsPutResponse>(pathParam(AUTHORIZATIONS_ENDPOINT, authorizationId), data)
    .catch((err: AxiosError<AuthorizationsPutError>) => Promise.reject(err.response?.data));

// DELETE
export type AuthorizationsDeleteResponse = unknown;
export type AuthorizationsDeleteError = string;
export const deleteAuthorization = (authorizationId: DatabaseId) =>
  apiClient
    .delete<AuthorizationsDeleteResponse>(pathParam(AUTHORIZATIONS_ENDPOINT, authorizationId))
    .catch((err: AxiosError<AuthorizationsDeleteError>) => Promise.reject(err.response?.data));
