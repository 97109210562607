import Button from '@mui/material/Button';
import TelegramIcon from '@mui/icons-material/Telegram';

interface InvoicableProps {
  handleMarkAsInvoicedClick: () => void;
  text: string;
}
export default function InvoicableButton({ handleMarkAsInvoicedClick, text }: InvoicableProps) {
  return (
    <Button variant="text" onClick={handleMarkAsInvoicedClick}>
      <TelegramIcon />
      {text}
    </Button>
  );
}
