import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid';
import { CircularProgress, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import 'src/assets/styles/Tables.scss';
import { isEmpty } from 'lodash';
import { FormTable } from '../../../components/Form/FormTable/FormTable';
import { getUsersPaginated, MUTATION_KEYS, UsersGetParams } from '../../../app/api';
import { useStateDict } from '../../../app/hooks';
import UserOnboardingForm from './Onboarding/Form';
import { Interpreter, User } from '../../../app/types/Entities';
import { userToString } from '../../../app/helpers/mappers';
import TableSearchBar from '../TableSearchBar/TableSearchBar';

export default function UsersTable() {
  const { t } = useTranslation('tables');

  // Pagination
  const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({ page: 0, pageSize: 25 });

  const [queryFilters, setQueryFilters] = useStateDict<UsersGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
  });

  useEffect(() => {
    setQueryFilters({
      ...queryFilters,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, setQueryFilters]);

  // SearchBar
  const [searchParams, setSearchParams, updateSearchParams] = useStateDict<UsersGetParams>({});
  const [results, setResults] = useState<Interpreter[] | null>(null);

  const handleResultsChange = (data: any) => {
    setResults(data);
  };

  const transformedResults =
    results?.map((user): any => {
      const phone = user.contacts?.find((contact) => contact.phone)?.phone;

      return {
        ...user,
        phone,
      };
    }) ?? [];

  const noSearchParams = isEmpty(searchParams.first_name) && isEmpty(searchParams.last_name);

  // Query
  const {
    data: usersPaginatedData,
    isLoading,
    refetch: refetchUsersPaginatedData,
  } = useQuery([MUTATION_KEYS.USERS, queryFilters], () => getUsersPaginated(queryFilters));

  const filteredUsers =
    usersPaginatedData?.results?.map((user): any => {
      const phone = user.contacts?.find((contact) => contact.phone)?.phone;

      return {
        ...user,
        phone,
      };
    }) ?? [];

  const [rowCountState, setRowCountState] = useState(usersPaginatedData?.count || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      usersPaginatedData?.count !== undefined ? usersPaginatedData.count : prevRowCountState,
    );
  }, [usersPaginatedData?.count, setRowCountState]);

  const [selectedUser, setSelectedUser] = useState<User | undefined>();
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [userPreloadStatus, setPreloadStatus] = useState<'loading' | 'success' | 'error' | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const hideModal = () => {
    setShowUserModal(false);
    setSelectedUser(undefined);
    setPreloadStatus(undefined);
  };

  const rows: GridRowsProp = (noSearchParams ? filteredUsers : transformedResults).map((user) => {
    return {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      phone: user.phone,
      email: user.email,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: t`user.firstName`,
      flex: 1,
    },
    {
      field: 'last_name',
      headerName: t`user.lastName`,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: t`user.phone`,
      flex: 1,
    },
    {
      field: 'email',
      headerName: t`user.email`,
      flex: 1,
    },
  ];

  return (
    <>
      <Container className="w-100 h-100 mw-100">
        <Row className="w-100">
          <Col className="d-flex align-items-center">
            <TableSearchBar
              onResultsChange={handleResultsChange}
              placeholderOne={t('user.filterFirstName')}
              placeholderTwo={t('user.filterLastName')}
              queryType="users"
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              updateSearchParams={updateSearchParams}
            />
          </Col>

          <Col className="d-flex justify-content-end align-items-center">
            <Typography className="me-4">{t('user.totalUsers', { users: usersPaginatedData?.count })}</Typography>

            <Button
              color="light"
              className="my-3 Table-Add-Button"
              onClick={() => {
                setSelectedUser(undefined);
                setShowUserModal(true);
                setPreloadStatus(undefined);
              }}
            >
              <AddIcon fontSize="large" />
            </Button>
          </Col>
        </Row>

        <Row className="w-100 h-100">
          <Col className="d-flex flex-column">
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <CircularProgress size="4rem" sx={{ color: '#00A2B8' }} />

                <span>
                  <b>{t`loadingTable`}</b>
                </span>
              </div>
            ) : (
              <FormTable
                rows={rows}
                rowCount={rowCountState}
                columns={columns}
                sx={{ backgroundColor: 'white', minHeight: '200px' }}
                onRowClick={(params: GridRowParams) => {
                  const userId = params.row.id;
                  const user = usersPaginatedData?.results.find(({ id }) => id === userId);

                  setSelectedUser(user);
                  setShowUserModal(true);

                  if (userPreloadStatus === undefined) {
                    setPreloadStatus('loading');
                  }
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                setPaginationModel={setPaginationModel}
                exportable={false}
              />
            )}
          </Col>
        </Row>
      </Container>

      <Modal fullscreen className="admin-modal" isOpen={showUserModal} toggle={hideModal}>
        <ModalHeader className="admin-modal-header">
          <Row className="align-items-center">
            <Col>
              <Button className="action-button" color="submit" disabled={isSubmitting} form="user-onboarding-form">
                {isSubmitting ? <Spinner className="me-2" size="sm" type="border" color="primary" /> : t`save`}
              </Button>
            </Col>

            <Col xs="auto">
              <Button color="transparent" disabled={isSubmitting} onClick={hideModal}>
                <CloseIcon fontSize="large" style={{ fill: 'white' }} />
              </Button>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody className="admin-modal-body">
          <div className="admin-modal-content-header">
            <Row className="align-items-center">
              <Col xs="auto">{selectedUser !== undefined ? userToString(selectedUser) : t('user.modalTitleNew')}</Col>
            </Row>
          </div>

          <UserOnboardingForm
            id="user-onboarding-form"
            preloadUserId={selectedUser?.id ?? undefined}
            preSubmit={() => {
              setIsSubmitting(true);

              return true;
            }}
            onSubmitSuccess={() => {
              refetchUsersPaginatedData().then(hideModal);
            }}
            postSubmit={() => {
              setIsSubmitting(false);
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
