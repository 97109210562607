/* eslint-disable import/no-cycle,no-param-reassign */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import qs from 'qs';
import Swal from 'sweetalert2';
import { QueryClient } from '@tanstack/react-query';
import { store } from '../../stores/store';
import { AuthTokens } from '../../types/Auth';
import { logoutStore, refreshStore } from '../../stores/authSlice';
import { TOKEN_REFRESH_ENDPOINT } from './endpoints';
import { isEmptyObject, isObject } from '../../helpers/manipulation';

// Clients
export const queryClient = new QueryClient();

export const noAuthClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Function to update the client headers with new tokens
export function updateClients(authTokens?: AuthTokens) {
  apiClient.defaults.headers.common.Authorization = authTokens ? `Bearer ${authTokens.access}` : '';
}

// Token refresh queue
let isRefreshing = false;
let refreshQueue: Array<Function> = [];

const processQueue = (tokens: AuthTokens) => {
  isRefreshing = false;
  refreshQueue.forEach((requestAwaitingPromise) => requestAwaitingPromise(tokens));
  refreshQueue = [];
};

// Interceptors
apiClient.interceptors.request.use((cfg: AxiosRequestConfig) => {
  const { authTokens } = store.getState().auth;
  const { user } = store.getState().auth;

  if (!authTokens || !user) {
    return cfg;
  }
  const isExpired = moment.unix(user.exp).diff(moment()) < 1;
  if (!isExpired) {
    return cfg;
  }

  // Add the token refresh request to the queue if one is already in progress
  if (isRefreshing) {
    // Stop this request from continuing until the new tokens are refreshed
    return new Promise((resolve) => {
      refreshQueue.push((tokens: AuthTokens) => {
        cfg.headers!.Authorization = `Bearer ${tokens.access}`;
        resolve(cfg);
      });
    });
  }

  isRefreshing = true;

  return axios
    .post(process.env.REACT_APP_API_URL + TOKEN_REFRESH_ENDPOINT, {
      refresh: authTokens.refresh,
    })
    .then((res: AxiosResponse<AuthTokens>) => {
      const tokens = res.data;
      store.dispatch(refreshStore(tokens));
      updateClients(tokens);
      processQueue(tokens);
      cfg.headers!.Authorization = `Bearer ${tokens.access}`;
      return cfg;
    })
    .catch(() => {
      processQueue(authTokens); // Call the queued requests with the original tokens
      const controller = new AbortController();
      const newCfg: AxiosRequestConfig = {
        ...cfg,
        signal: controller.signal,
      };
      controller.abort('Unable to refresh authentication');

      Swal.fire({
        title: 'Session expired, log out?',
        showDenyButton: true,
        showCancelButton: false,
        allowOutsideClick: true,
        confirmButtonColor: '#00a2b8',
        customClass: {
          confirmButton: 'btn action-button-popup',
          denyButton: 'btn action-button-popup',
        },
      }).then(({ isConfirmed }) => isConfirmed && store.dispatch(logoutStore()));

      return newCfg;
    });
});

// Defaults
function removeEmpty(obj: any): any {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => v !== null && v !== undefined && v !== '' && !isEmptyObject(v))
      .map(([k, v]) => [k, isObject(v) ? removeEmpty(v) : v]),
  );
}

const paramsSerializer = (params?: any) => {
  const paramsCleaned = removeEmpty(params);
  const filtered = _.pickBy(paramsCleaned, (v) => v !== null && v !== undefined && v !== '' && !isEmptyObject(v));
  return qs.stringify(filtered, { allowDots: true });
};

noAuthClient.defaults.paramsSerializer = paramsSerializer;
apiClient.defaults.paramsSerializer = paramsSerializer;
