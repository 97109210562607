import { store } from '../stores/store';
import { loginStore } from '../stores/authSlice';
import { noAuthClient } from './config/clients';
import { LOGIN_ENDPOINT } from './config/endpoints';

export interface LoginPostPayload {
  username: string;
  password: string;
  rememberme: boolean;
}
export const login = (payload: LoginPostPayload) =>
  noAuthClient.post(LOGIN_ENDPOINT, payload).then((res) => {
    store.dispatch(
      loginStore({
        rawTokens: res.data,
        rememberme: payload.rememberme,
      }),
    );
  });
