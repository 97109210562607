import { Col, FormText, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Authorization } from '../../../../../../app/types/Entities';
import { FormListRowItem, FormListRowItemProps } from '../../../../../../components/Form/FormListRow';
import FormSpan from '../../../../../../components/Form/FormSpan';
import { FormSelectEntry } from '../../../../../../app/types/Components';
import { AUTHORIZATION_STATUSES, AuthorizationStatus, SendMethod } from '../../../../../../app/helpers/enum';
import { userToString } from '../../../../../../app/helpers/mappers';
import { findInOptions } from '../../../../../../app/helpers/manipulation';
import DateTimeDisplay from '../../../../../../components/DateTimeDisplay/DateTimeDisplay';
import { SHORT_DATETIME_FORMAT_MOMENT } from '../../../../../../app/helpers/constants';
import FormCheck from '../../../../../../components/Form/FormCheck/FormCheck';

export interface AuthorizationRequestsListItemProps extends FormListRowItemProps {
  authorization: Authorization;
  checked: boolean;
  toggleChecked: (checked: boolean) => void;
  onStatusChange: (status: AuthorizationStatus) => void;
}

export type AuthorizationStatusOption = FormSelectEntry<AuthorizationStatus> & { color: string };

const translationArray = ['booking', 'collections'];

export default function AuthorizationRequestsListItem({
  authorization,
  checked,
  toggleChecked,
  onStatusChange,
  ...props
}: AuthorizationRequestsListItemProps) {
  // Utils
  const { t } = useTranslation(translationArray);
  const translateStatus = (statusTranslationKey: string) =>
    t(`collections:authorizationStatus.${statusTranslationKey}`);

  const translateSendMethod = (sendMethod: SendMethod) => t(`collections:sendMethods.${sendMethod}`);

  // Authorization status
  const statusOptions: AuthorizationStatusOption[] = [
    { value: AUTHORIZATION_STATUSES.PENDING, label: translateStatus('PENDING'), color: '#ff0' },
    { value: AUTHORIZATION_STATUSES.ACCEPTED, label: translateStatus('ACCEPTED'), color: '#49dcbb' },
    { value: AUTHORIZATION_STATUSES.REJECTED, label: translateStatus('REJECTED'), color: '#f00' },
    { value: AUTHORIZATION_STATUSES.REFERRED, label: translateStatus('REFERRED'), color: '#00f' },
    { value: AUTHORIZATION_STATUSES.OVERRIDE, label: translateStatus('OVERRIDE'), color: '#eda93e' },
  ];

  const formatAuthorizationStatusOptionLabel = (option: AuthorizationStatusOption) => (
    <Row className="gx-0">
      <Col xs="auto" className="flex-grow-0">
        <CircleIcon className="dot-icon" style={{ color: option.color }} />
      </Col>
      <Col>
        <b>{option.label}</b>
        {authorization.status === option.value ? (
          <FormText color="muted">
            {' '}
            <DateTimeDisplay dateTime={authorization.last_updated_at} format={SHORT_DATETIME_FORMAT_MOMENT} />
          </FormText>
        ) : null}
      </Col>
    </Row>
  );

  return (
    <FormListRowItem {...props}>
      <Row className="gx-2">
        <Col xs="auto" className="d-flex flex-grow-0 align-items-center">
          <FormCheck
            className="authorization-request-checkbox"
            disabled={authorization.status === AUTHORIZATION_STATUSES.OVERRIDE}
            checked={checked}
            onChange={() => toggleChecked(!checked)}
          />
        </Col>

        {authorization.status !== AUTHORIZATION_STATUSES.OVERRIDE ? (
          <>
            <Col>
              <FormSpan>
                <b>
                  {t`to`}: {userToString(authorization.authorizer)}
                </b>
                &nbsp;- {authorization.company.type === 'insurance' ? t`adjuster` : t`agent`}
              </FormSpan>
            </Col>
            {authorization.contact_via && authorization.contact && (
              <Col xs="12">
                <FormSpan>
                  {translateSendMethod(authorization.contact_via)}: {authorization.contact[authorization.contact_via]}
                </FormSpan>
              </Col>
            )}
          </>
        ) : (
          <Col>
            <FormSpan>
              <b>
                {t`to`}: {userToString(authorization.authorizer)}
              </b>
            </FormSpan>
          </Col>
        )}
        <Col xs="12">
          <Select
            fullWidth
            open={authorization.status !== AUTHORIZATION_STATUSES.OVERRIDE && undefined}
            value={findInOptions(statusOptions, authorization.status)?.value}
            onChange={(event: SelectChangeEvent) => onStatusChange(event.target.value as AuthorizationStatus)}
            variant="outlined"
            sx={{
              marginBottom: 2,
            }}
          >
            {statusOptions.map(
              (option) =>
                (option.value !== AUTHORIZATION_STATUSES.OVERRIDE ||
                  authorization.status === AUTHORIZATION_STATUSES.OVERRIDE) && (
                  <MenuItem key={option.value} value={option.value}>
                    {formatAuthorizationStatusOptionLabel(option)}
                  </MenuItem>
                ),
            )}
          </Select>
        </Col>
      </Row>
    </FormListRowItem>
  );
}
