import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { EventsGetParams, EventsGetApiParams } from '../api';
import { useStateDict } from './useStateDict';

// Dispatch type
type FilterEventsHook = [EventsGetApiParams, Dispatch<SetStateAction<EventsGetParams>>, () => void];

// eslint-disable-next-line import/prefer-default-export
export const useFilterEvents = (): FilterEventsHook => {
  const [eventFilters, setEventFilters] = useStateDict<EventsGetParams>({
    start_at: new Date().toISOString(),
    end_at: new Date().toISOString(),
  });

  const dispatch = useDispatch();

  const updateEventFilters = () => {
    dispatch({ type: eventFilters });
    return [eventFilters, setEventFilters];
  };

  const updateEventFiltersRef = useRef(updateEventFilters);

  useEffect(() => {
    updateEventFiltersRef.current = updateEventFilters;
  }, [eventFilters, updateEventFilters]);

  return [eventFilters, setEventFilters, updateEventFiltersRef.current];
};
