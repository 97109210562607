/* eslint-disable react/no-unstable-nested-components */
import { Col, Row } from 'reactstrap';
import { ReactNode } from 'react';
import { OFFER_STATUS } from 'src/app/helpers/enum';
import { mapObjectForSelect } from 'src/app/helpers/mappers';
import { useTranslation } from 'react-i18next';
import { findInOptions } from 'src/app/helpers/manipulation';
import FormCheck from 'src/components/Form/FormCheck/FormCheck';
import { Interpretation, Language, Offer } from 'src/app/types/Entities';
import FormSelect from '../../../../../components/Form/FormSelectLegacy';

export interface LocalOffer extends Offer {
  checked: boolean;
}

export interface OfferRowProps {
  offer: LocalOffer;
  formatOffer: (offer: LocalOffer) => ReactNode;
  onOfferChange: (offer: LocalOffer, hasCheckboxChanged: boolean) => Promise<void>;
  setInterpreter: (interpreter: Interpretation) => void;
  targetLanguage: Language | null;
}

export default function OfferRow(props: OfferRowProps) {
  const { formatOffer, offer, onOfferChange, setInterpreter, targetLanguage } = props;
  const { t } = useTranslation('collections');

  const statusOptions = mapObjectForSelect(OFFER_STATUS, (_, value) => t(`offerStatus.${value}`));

  const statusColor = (status: string | undefined | null) => {
    switch (status) {
      case OFFER_STATUS.REQUESTED:
        return 'purple';
      case OFFER_STATUS.ACCEPTED:
        return 'green';
      case OFFER_STATUS.NOT_AVAILABLE:
        return 'red';
      case OFFER_STATUS.PENDING_OFFER:
        return 'orange';
      case OFFER_STATUS.AVAILABLE:
        return 'yellow';
      case OFFER_STATUS.REJECTED:
        return 'blue';
      case OFFER_STATUS.CANCELED:
        return 'gray';
      default:
        return 'black';
    }
  };

  return (
    <Row className="gx-2 mb-3">
      <Col xs="auto" className="d-flex flex-grow-0 align-items-center">
        <FormCheck
          label=""
          className="offer-checkbox"
          checked={offer.checked && offer.service.target_language.id === targetLanguage?.id}
          onChange={() => onOfferChange({ ...offer, checked: !offer.checked }, true)}
          disabled={
            offer.service.target_language.id !== targetLanguage?.id ||
            (offer.status !== OFFER_STATUS.NOT_REQUESTED && offer.status !== OFFER_STATUS.AVAILABLE)
          }
        />
      </Col>
      <Col>{formatOffer(offer)}</Col>
      <Col xs="3" className="flex-grow-0">
        <FormSelect
          noBottomMargin
          placeholder="Offer options"
          options={statusOptions}
          value={findInOptions(statusOptions, offer.status)}
          onChange={(change) =>
            onOfferChange({ ...offer, status: change?.value }, false).then(() => {
              if (change?.value === OFFER_STATUS.ACCEPTED) {
                setInterpreter(offer.service);
              }
            })
          }
          formatOptionLabel={(option) => (
            <Row className="gx-3">
              <Col xs="auto" className="flex-grow-0" style={{ position: 'relative' }}>
                <div
                  className="offer-status-option"
                  style={{
                    backgroundColor: statusColor(option.value),
                  }}
                />
              </Col>
              <Col>
                <span>{option.label}</span>
              </Col>
            </Row>
          )}
          isSearchable={false}
          isDisabled={offer.service.target_language.id !== targetLanguage?.id}
        />
      </Col>
    </Row>
  );
}
