import './FormSelectLegacy.scss';
import Select, { CSSObjectWithLabel, GroupBase, Props as SelectProps } from 'react-select';
import { FormText, Label } from 'reactstrap';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import classNames from 'classnames';
import InputMessages from '../../InputMessages';

interface FormSelectExtraProps {
  creatable?: boolean;
  canBeOpen?: boolean;
  filtered?: boolean;
  help?: string;
  hidden?: boolean;
  label?: string;
  errors?: string[];
  noBottomMargin?: boolean;
}

export type FormSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = FormSelectExtraProps &
  (
    | ({ creatable: true } & CreatableProps<Option, IsMulti, Group>)
    | ({ creatable?: false } & SelectProps<Option, IsMulti, Group>)
  );

export default function FormSelectLegacy<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  creatable,
  canBeOpen = true,
  filtered,
  help,
  hidden,
  label,
  errors,
  noBottomMargin,
  ...props
}: FormSelectProps<Option, IsMulti, Group>) {
  const { className = '' } = props;
  const isInvalid = errors && errors?.length > 0;

  const styles = {
    // Could add props: SelectProps
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      height: 58,
    }),
  };

  const componentProps: FormSelectProps<Option, IsMulti, Group> = {
    styles,
    menuIsOpen: canBeOpen && undefined,
    'aria-invalid': isInvalid,
    className: classNames({ 'is-invalid': isInvalid }),
    ...props,
  };

  return !hidden ? (
    <div className={classNames({ [className]: className, 'mb-3': !noBottomMargin })}>
      {label && <Label>{label}</Label>}
      {creatable ? (
        <CreatableSelect<Option, IsMulti, Group> {...componentProps} />
      ) : (
        <Select<Option, IsMulti, Group> {...componentProps} />
      )}
      {help && <FormText color="muted">{help}</FormText>}
      <InputMessages messages={errors} />
      {filtered && (
        <FormText color="muted">
          <FilterAltIcon /> Filtered
        </FormText>
      )}
    </div>
  ) : null;
}
