import { Button, Card, CardBody, Input, InputGroup, Spinner } from 'reactstrap';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import './AppSearchBar.scss';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import { useStateDict } from '../../app/hooks';
import { SearchGetParams, searchInterpretations } from '../../app/api/search';
import { MUTATION_KEYS } from '../../app/api';
import SearchBarBookingResults from './BookingResults/SearchBarBookingResults';
import SearchBarHint from './Hint/SearchBarHint';

export default function AppSearchBar() {
  const { t } = useTranslation('searchbar');
  const location = useLocation();

  const [focused, setFocused] = useState<boolean>(false);

  const [query, setQuery] = useState<string>('');
  const [previousQuery, setPreviousQuery] = useState<string>('');
  const [searchParams, setSearchParams, updateSearchParams] = useStateDict<SearchGetParams>({});
  const { data: bookings, fetchStatus } = useQuery(
    [MUTATION_KEYS.SEARCH, searchParams],
    () =>
      searchInterpretations(searchParams).then((results) =>
        results.sort((b1, b2) => moment(b2.arrive_at).diff(moment(b1.arrive_at))),
      ),
    {
      enabled: Object.values(searchParams).some(Boolean),
    },
  );

  const isoDateRegex = /(?:^|\s)(\d{4})(?:[-/\\](\d{1,2})(?:[-/\\](\d{1,2}))?)?/;
  const usDateRegex = /(?:^|\s)(\d{1,2})(?:[-/\\](\d{1,2})(?:[-/\\](\d{4}))?)?/;
  const nameRegex = /\p{L}+/iu;
  const sentenceRegex = /(\p{L}+\s*)+/iu;
  const bookingIdRegex = /#\d(\d{0,5})?(-\d{1,3})?/;

  const padStringForDate = (str?: string) => (str ?? '') && str!.padStart(2, '0');

  const buildDateStringFromISO = (year: string, month?: string, day?: string) =>
    `${year}-${padStringForDate(month)}${month ? `-${padStringForDate(day)}` : ''}`;

  const buildDateStringFromUSFormat = (month: string, day?: string, year?: string) =>
    `${year || ''}-${padStringForDate(month)}-${month ? padStringForDate(day) : ''}`;

  function computeSearchParams(input: string) {
    // Parse query
    setSearchParams({});
    let didIsoDateMatch = false;
    input
      .replace(bookingIdRegex, (bookingId) => {
        updateSearchParams({ booking_id: bookingId.replace('#', '') });
        return '';
      })
      .replace(isoDateRegex, (_match, year, month, day) => {
        didIsoDateMatch = true;
        updateSearchParams({ date: buildDateStringFromISO(year, month, day) });
        return '';
      })
      .replace(usDateRegex, (_match, month, day, year) => {
        if (!didIsoDateMatch) {
          updateSearchParams({ date: buildDateStringFromUSFormat(month, day, year) });
        }
        return '';
      })
      .replace(nameRegex, (name) => {
        updateSearchParams({ first_name: name?.trim() });
        return '';
      })
      .replace(sentenceRegex, (lastName) => {
        updateSearchParams({ last_name: lastName?.trim() });
        return '';
      });
  }

  useEffect(() => {
    // Remove focus when the location changed
    setFocused(false);
  }, [location]);

  return (
    <div
      className="searchbar"
      role="button"
      tabIndex={0}
      onClick={() => setFocused(true)}
      onFocus={() => setFocused(true)}
      onBlur={(e) => {
        // Check on the next frame if we are shifting focus to a child of this div, if not, set focused to false
        const containerDiv = e.currentTarget;
        requestAnimationFrame(() => {
          const newlyFocusedElement = document.activeElement;
          setFocused(containerDiv.contains(newlyFocusedElement));
        });
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setFocused(true);
        }
      }}
    >
      <InputGroup className="inputs">
        <Input
          type="search"
          placeholder={t`placeholderBookings`}
          value={query}
          onChange={(e) => {
            setFocused(e.target.value !== '');
            setQuery(e.target.value);

            if (e.target.value === '') {
              setSearchParams({});
              setPreviousQuery('');
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && query !== previousQuery) {
              computeSearchParams(query);
              setPreviousQuery(query);
            }
          }}
        />
        <Button
          color="primary"
          onClick={() => {
            if (query !== previousQuery) {
              computeSearchParams(query);
              setPreviousQuery(query);
            }
          }}
          className="search-button"
        >
          {fetchStatus === 'fetching' ? (
            <Spinner size="sm" type="border" color="light" style={{ width: '1em', height: '1em' }} />
          ) : (
            <SearchIcon style={{ fill: 'white' }} />
          )}
        </Button>
      </InputGroup>
      <Card
        className={classNames('preview', { closed: !focused })}
        onClick={() => setFocused(true)}
        onFocus={() => setFocused(true)}
      >
        <CardBody>
          {bookings ? (
            <SearchBarBookingResults bookings={bookings} fetchStatus={fetchStatus} />
          ) : !bookings && !query ? (
            <SearchBarHint />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
}
