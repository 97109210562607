import { CSVImporter } from 'csv-import-react';
import { useState } from 'react';
import UploadingPage from './UploadingPage';

interface BookingUploadProps {
  user: any;
}

function BookingUpload({ user }: BookingUploadProps) {
  const [uploadData, setUploadData] = useState();
  const [hidden, setHidden] = useState(true);
  return (
    <div>
      {hidden ? (
        <CSVImporter
          isModal={false}
          darkMode={false}
          onComplete={(data) => {
            data.columns.push({ key: 'status', name: 'status' });
            return setUploadData(data);
          }}
          template={{
            columns: [
              {
                name: 'First Name',
                key: 'first_name',
                required: true,
                description: 'The first name of patient',
                suggested_mappings: ['Patient Firstname', 'Nombre del Paciente'],
              },
              {
                name: 'Last Name',
                key: 'last_name',
                required: true,
                description: 'The last name of patient',
                suggested_mappings: ['Patient Lastname', 'Apellido del Paciente'],
              },
              {
                name: 'Dob',
                key: 'dob',
                required: true,
                description: 'The DOB of patient',
                suggested_mappings: ['Dob'],
              },
              {
                name: 'Date Event',
                key: 'date',
                required: true,
                description: 'The Date of Event',
                suggested_mappings: ['Schedule date', 'Fecha de Cita'],
              },
              {
                name: 'Hour Event',
                key: 'hour',
                required: true,
                description: 'The Hour of Event',
                suggested_mappings: ['Schedule Time', 'Hora'],
              },
              {
                name: 'Type Appointment',
                key: 'type_appointment',
                required: true,
                description: 'The Type of Appointment',
                suggested_mappings: ['Case Type', 'Tipo de Cita'],
              },
              {
                name: 'Modality Event',
                key: 'modality',
                required: true,
                description: 'The Modality of Event',
                suggested_mappings: ['Procedure', 'Modalidad'],
              },
              {
                name: 'Medic FirstName',
                key: 'medic_first_name',
                required: true,
                description: 'The Medic First Name of Event',
                suggested_mappings: ['Provider Firstname', 'nombre proveedor'],
              },
              {
                name: 'Medic LastName',
                key: 'medic_last_name',
                required: true,
                description: 'The Medic Last Name of Event',
                suggested_mappings: ['Provider Lastname', 'apellido proveedor'],
              },
              {
                name: 'Clinic Event',
                key: 'clinic',
                required: true,
                description: 'The Clinic of Event',
                suggested_mappings: ['Office Name', 'Clinica'],
              },
              {
                name: 'Language',
                key: 'language',
                required: true,
                description: 'The Language of the patient',
                suggested_mappings: ['Primary Language', 'Lenguaje'],
              },
            ],
          }}
          onSubmit={() => setHidden(false)}
        />
      ) : (
        <UploadingPage data={uploadData} userId={user.user_id} />
      )}
    </div>
  );
}
export default BookingUpload;
