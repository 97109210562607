import { AxiosError } from 'axios';
import { DatabaseId, Errors } from '../types/DataStructures';
import { Language } from '../types/Entities';
import { apiClient } from './config/clients';
import { LANGUAGES_ENDPOINT } from './config/endpoints';
import { ApiQuery, applyLookup, LookupsMap, pathParam } from './config/helpers';
import { sanitizeLanguage } from './config/sanitizers';

// GET
// Fetch many
export interface LanguagesGetParams {
  alpha2?: string;
  alpha3?: string;
  available?: boolean;
  common?: boolean;
  description?: string;
  name?: string;
}

export const DEFAULT_LANGUAGES_LOOKUPS: LookupsMap<LanguagesGetParams> = {};

export const getLanguages: ApiQuery<LanguagesGetParams, Language[]> = (params, lookups = DEFAULT_LANGUAGES_LOOKUPS) =>
  apiClient
    .get<any[]>(LANGUAGES_ENDPOINT, { params: applyLookup(params, lookups) })
    .then((res) => res.data.map((v) => sanitizeLanguage(v)));

// Fetch one
export const getLanguage = (languageId: DatabaseId) =>
  apiClient.get(pathParam(LANGUAGES_ENDPOINT, languageId)).then((res) => sanitizeLanguage(res.data));

// POST
export interface LanguagesPostPayload {
  alpha2: string;
  alpha3: string;
  available: boolean;
  common: boolean;
  description: string;
  name: string;
}

export type LanguagesPostResponse = DatabaseId;
export type LanguagesPostError = Errors<LanguagesPostPayload>;
export const createLanguage = (payload: LanguagesPostPayload) =>
  apiClient
    .post<LanguagesPostResponse>(LANGUAGES_ENDPOINT, payload)
    .then((res) => res.data)
    .catch((err: AxiosError<LanguagesPostError>) => Promise.reject(err.response?.data));

// PUT
export type LanguagesPutPayload = LanguagesPostPayload;
export type LanguagesPutResponse = unknown;
export type LanguagesPutError = Errors<LanguagesPutPayload>;
export const updateLanguage = (languageId: DatabaseId, data: LanguagesPutPayload) =>
  apiClient
    .put<LanguagesPutResponse>(pathParam(LANGUAGES_ENDPOINT, languageId), data)
    .catch((err: AxiosError<LanguagesPutError>) => Promise.reject(err.response?.data));

// DELETE
export type LanguagesDeleteResponse = unknown;
export type LanguagesDeleteError = unknown;
export const deleteLanguage = (languageId: DatabaseId) =>
  apiClient
    .delete<LanguagesDeleteResponse>(pathParam(LANGUAGES_ENDPOINT, languageId))
    .catch((err: AxiosError<LanguagesDeleteError>) => Promise.reject(err.response?.data));
