import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import { Badge } from '@mui/material';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import FormSpan from '../../../../../components/Form/FormSpan';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import { DatabaseId } from '../../../../../app/types/DataStructures';
import { Interpretation, Language, ServiceRoot } from '../../../../../app/types/Entities';
import FollowUpFieldCreationSection from './FollowUpFieldCreationSection';
import { FollowUpSubmissionPayload } from './localTypes';
import { ValuesOf } from '../../../../../app/types/TypeMappers';
import { getBooking, getBookings, MUTATION_KEYS } from '../../../../../app/api';
import FollowUpFieldViewSection from './FollowUpFieldViewSection';
import { useForm } from '../../../../../app/hooks';
import { getClosestDateFromEventList } from '../../../../../app/helpers/services';
import { PREFERRED_DATETIME_FORMAT_MOMENT } from '../../../../../app/helpers/constants';

export interface FollowUpFieldProps {
  formPrefix: string;
  showFollowUp: boolean;
  setShowFollowUp: (isOpen: boolean) => void;

  bookingId: DatabaseId | undefined;
  targetLanguage: Language | null;
  serviceRoot: ServiceRoot<Interpretation> | null;

  createFollowUpBooking: (
    formSubmitEvent: undefined,
    followUpSubmissionPayload: FollowUpSubmissionPayload,
  ) => Promise<unknown>;

  parentBookingId: DatabaseId | undefined;
}

const SECTION_DISPLAY = {
  CREATION: 0,
  VIEW: 1,
} as const;

const translationArray = ['booking'];

function FollowUpField(props: FollowUpFieldProps) {
  const {
    formPrefix,
    showFollowUp,
    setShowFollowUp,
    bookingId,
    targetLanguage,
    serviceRoot,
    createFollowUpBooking,
    parentBookingId,
  } = props;

  // Utils
  const { t } = useTranslation(translationArray);
  const { p } = useForm(formPrefix);
  const [openSection, setOpenSection] = useState<ValuesOf<typeof SECTION_DISPLAY>>(SECTION_DISPLAY.VIEW);

  // Query
  const childrenFilters = {
    _include_events: true,
    parent: { id: bookingId },
  };

  const [childrenBookingRefetchEnabled, setChildrenBookingRefetchEnabled] = useState(true);
  const { data: childrenBooking, refetch: refetchChildrenBooking } = useQuery(
    [MUTATION_KEYS.BOOKINGS, childrenFilters],
    () => (bookingId ? getBookings(childrenFilters) : undefined),
    {
      enabled: childrenBookingRefetchEnabled,
    },
  );

  useEffect(() => {
    if (childrenBooking) {
      setChildrenBookingRefetchEnabled(false);
    }
  }, [childrenBooking]);

  // Query
  const [parentBookingIdRefetchEnabled, setParentBookingIdRefetchEnabled] = useState(true);
  const { data: parentBooking } = useQuery(
    [MUTATION_KEYS.BOOKINGS, parentBookingId],
    () => (parentBookingId ? getBooking(parentBookingId) : undefined),
    {
      enabled: parentBookingIdRefetchEnabled,
    },
  );

  useEffect(() => {
    if (parentBooking) {
      setParentBookingIdRefetchEnabled(false);
    }
  }, [parentBooking]);

  // Closest event
  const [closestEvent, closestEventState] = getClosestDateFromEventList(
    childrenBooking?.flatMap((booking) => booking.events ?? []) ?? [],
  );

  // Display
  const label = (
    <Badge color="primary" badgeContent={childrenBooking?.length} showZero variant="counterBadge">
      <h5>{t`followUp`}</h5>
    </Badge>
  );

  const display = (
    <Row className="gx-2">
      <Col>
        <FormSpan>
          {!bookingId
            ? 'N/A'
            : closestEventState === 'current'
            ? t`currentFollowUp`
            : closestEventState === 'last'
            ? `${t`lastFollowUp`} ${moment(closestEvent?.start_at).format(PREFERRED_DATETIME_FORMAT_MOMENT)}`
            : closestEventState === 'next'
            ? `${t`nextFollowUp`} ${moment(closestEvent?.start_at).format(PREFERRED_DATETIME_FORMAT_MOMENT)}`
            : t`noFollowUp`}
        </FormSpan>
      </Col>
    </Row>
  );

  const toggleOpen = (isOpen: boolean) => {
    if (!showFollowUp) {
      setOpenSection(SECTION_DISPLAY.VIEW);
    }
    setShowFollowUp(isOpen);
  };

  const onAdd = () => {
    if (!showFollowUp) {
      setOpenSection(SECTION_DISPLAY.CREATION);
    }
    setShowFollowUp(!showFollowUp);
  };

  const onCancel = () => {
    setShowFollowUp(false);
  };

  return (
    <FormExpandableField
      formPrefix={formPrefix}
      isOpen={showFollowUp}
      toggleOpen={toggleOpen}
      addButtonState={bookingId !== undefined ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.DISABLED}
      addLabel={t`tooltip.addFollowUp`}
      onAdd={onAdd}
      openButtonState={bookingId !== undefined ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.DISABLED}
      label={label}
      display={display}
      onCancel={onCancel}
    >
      {openSection === SECTION_DISPLAY.VIEW ? (
        <FollowUpFieldViewSection
          formPrefix={p`view`}
          childrenBooking={childrenBooking ?? []}
          parentBooking={parentBooking}
        />
      ) : (
        <FollowUpFieldCreationSection
          formPrefix={p`creation-section`}
          targetLanguage={targetLanguage}
          serviceRoot={serviceRoot}
          createFollowUpBooking={(...args) => createFollowUpBooking(...args).then(() => refetchChildrenBooking())}
          onCancelClick={() => {
            setShowFollowUp(false);
          }}
        />
      )}
    </FormExpandableField>
  );
}

export default FollowUpField;
