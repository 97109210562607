import { AxiosError } from 'axios';
import { PatientPostPayload, PatientPutPayload } from './recipients';
import { DatabaseId, Errors } from '../types/DataStructures';
import { apiClient } from './config/clients';
import { BUSINESS_NAME, businessParam, pathParam } from './config/helpers';
import { BOOKINGS_ENDPOINT, COMPANIES_ENDPOINT, GLOBAL_SETTINGS_ENDPOINT, USERS_ENDPOINT } from './config/endpoints';
import { UsersPostPayload } from './users';
import { AffiliationPostPayload } from './affiliations';
import { ProvidersPostPayload, ProvidersPutPayload } from './providers';
import { BookingsPostPayload, BookingsPutPayload } from './bookings';
import { EventsPostPayload, EventsPutPayload } from './events';
import { AgentsPostPayload, AgentsPutPayload } from './agents';
import { RequestersPostPayload, RequestersPutPayload } from './requesters';
import { PayersPostPayload } from './payers';
import { OffersPostPayload } from './offers';
import { OperatorsPostPayload } from './operators';
import { ReportPutPayload } from './report';
import { ServicesPostPayload, ServicesPutPayload } from './services';
import { ServiceAreasPostPayload, ServiceAreasPutPayload } from './service_areas';
import { CompaniesPostPayload, CompaniesPutPayload } from './companies';
import { CompanyStaffItem, PreferredAgencyItem } from '../../pages/OnboardingPage/Companies/Onboarding/localTypes';
import { GlobalSettingsPostPayload, GlobalSettingsPutPayload } from './global_settings';
import { RatesPostPayload, RatesPutPayload } from './rates';

/* GLOBAL SETTINGS */
// POST

export interface GlobalSettingsPostPayloadComplex extends GlobalSettingsPostPayload {
  _rates_datalist: RatesPostPayload[];
}

export interface GlobalSettingsPostResponseComplex {
  rates_ids: DatabaseId[];
  global_setting_id: DatabaseId;
}

export type GlobalSettingsPostErrorComplex = Errors<GlobalSettingsPostPayloadComplex>;

export const createGlobalSettingComplex = (payload: GlobalSettingsPostPayloadComplex) =>
  apiClient
    .post<GlobalSettingsPostResponseComplex>(GLOBAL_SETTINGS_ENDPOINT, {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .then((res) => res.data)
    .catch((err: AxiosError<GlobalSettingsPostErrorComplex>) => Promise.reject(err.response?.data));

// PUT
export interface GlobalSettingsPutPayloadComplex extends GlobalSettingsPutPayload {
  _rates_datalist?: RatesPutPayload[];
}
export type GlobalSettingsPutResponseComplex = unknown;
export type GlobalSettingsPutErrorComplex = Errors<GlobalSettingsPutPayloadComplex>;

export const updateGlobalSettingComplex = (globalSettingClient: string, payload: GlobalSettingsPutPayloadComplex) =>
  apiClient
    .put<GlobalSettingsPutResponseComplex>(pathParam(GLOBAL_SETTINGS_ENDPOINT, globalSettingClient), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<GlobalSettingsPutErrorComplex>) => Promise.reject(err.response?.data));

/* USERS */
// POST
type AffiliationPostPayloadComplex = Omit<AffiliationPostPayload, 'recipient'>;

interface RecipientsPostPayloadComplex extends Omit<PatientPostPayload, 'user'> {
  _affiliation_datalist: Array<AffiliationPostPayloadComplex> | undefined;
}

type AgentPostPayloadComplex = Omit<AgentsPostPayload, 'user'>;

type OperatorsPostPayloadComplex = Omit<OperatorsPostPayload, 'user'>;

type PayersPostPayloadComplex = Omit<PayersPostPayload, 'user'>;

export interface ServicesPostPayloadComplex extends Omit<ServicesPostPayload, 'provider'> {
  source_language_alpha3: string;
  target_language_alpha3: string;
}
export interface ServiceAreasPostPayloadComplex extends Omit<ServiceAreasPostPayload, 'provider'> {}

export interface ProvidersPostPayloadComplex extends Omit<ProvidersPostPayload, 'user'> {
  _service_datalist: Array<ServicesPostPayloadComplex> | undefined;
  _service_area_datalist: Array<ServiceAreasPostPayloadComplex> | undefined;
}

type RequestersPostPayloadComplex = Omit<RequestersPostPayload, 'user'>;

export interface UsersPostPayloadComplex extends UsersPostPayload {
  _agent_data?: AgentPostPayloadComplex;
  _operator_data?: OperatorsPostPayloadComplex;
  _payer_data?: PayersPostPayloadComplex | {};
  _provider_data?: ProvidersPostPayloadComplex | {};
  _recipient_data?: RecipientsPostPayloadComplex | {};
  _requester_data?: RequestersPostPayloadComplex | {};
}

export interface UsersPostResponseComplex {
  affiliation_ids: DatabaseId[];
  agent_id: DatabaseId;
  operator_id?: DatabaseId;
  payer_id: DatabaseId;
  provider_id?: DatabaseId;
  recipient_id: DatabaseId;
  requester_id: DatabaseId;
  service_ids?: DatabaseId;
  user_id: DatabaseId;
}

export type UsersPostErrorComplex = Errors<UsersPostPayloadComplex>;

/**
 * Create a user with the addition of other entities such as Recipient role and Affiliation
 *
 * @param payload each underscore-preceded attribute creates another entity in
 * an atomic query.
 * If empty ({} or []), they prevent the default behavior,
 * if undefined, they proceed with default behavior
 */
export const createUserComplex = (payload: UsersPostPayloadComplex) =>
  apiClient
    .post<UsersPostResponseComplex>(businessParam(USERS_ENDPOINT), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .then((res) => res.data)
    .catch((err: AxiosError<UsersPostErrorComplex>) => Promise.reject(err.response?.data));

// PUT
export interface ServicesPutPayloadComplex extends Omit<ServicesPutPayload, 'provider'> {
  id?: DatabaseId;
  _deleted?: boolean;
  source_language_alpha3: string;
  target_language_alpha3: string;
}
export interface ServiceAreasPutPayloadComplex extends Omit<ServiceAreasPutPayload, 'provider'> {}
export interface ProvidersPutPayloadComplex extends Omit<ProvidersPutPayload, 'user'> {
  _service_datalist: Array<ServicesPutPayloadComplex> | undefined;
  _service_area_datalist: Array<ServiceAreasPutPayloadComplex> | undefined;
}

export type RecipientsPutPayloadComplex = Omit<PatientPutPayload, 'user'>;

export type AgentsPutPayloadComplex = Omit<AgentsPutPayload, 'user'>;

export type RequestersPutPayloadComplex = Omit<RequestersPutPayload, 'user'>;

export interface UsersPutPayloadComplex extends Omit<UsersPostPayload, 'username'> {
  _agent_data?: AgentsPutPayloadComplex | undefined;
  _provider_data?: ProvidersPutPayloadComplex | undefined;
  _recipient_data?: RecipientsPutPayloadComplex | undefined;
  _requester_data?: RequestersPutPayloadComplex | undefined;
}

export type UsersPutResponseComplex = unknown;

export type UsersPutErrorComplex = Errors<UsersPutPayloadComplex>;

/**
 * Update a user along with its Recipient role data
 *
 * @param userId
 * @param payload each underscore-preceded attribute updates another entity in
 * an atomic query
 */
export const updateUserComplex = (userId: DatabaseId, payload: UsersPutPayloadComplex) =>
  apiClient
    .put<UsersPutResponseComplex>(pathParam(USERS_ENDPOINT, userId), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<UsersPutErrorComplex>) => Promise.reject(err.response?.data));

/* BOOKINGS */
// POST
export type EventsPostPayloadComplex = Omit<EventsPostPayload, 'booking'>;
export type OffersPostPayloadComplex = Omit<OffersPostPayload, 'booking'>;

export interface BookingPostPayloadComplex extends BookingsPostPayload {
  _event_datalist: Array<EventsPostPayloadComplex> | undefined;
  _offer_datalist: Array<OffersPostPayloadComplex> | undefined;
}

export interface BookingPostResponseComplex {
  booking_id: DatabaseId;
  event_ids: DatabaseId[];
}

export type BookingPostErrorComplex = Errors<BookingPostPayloadComplex>;

export const createBookingComplex = (payload: BookingPostPayloadComplex) =>
  apiClient
    .post<BookingPostResponseComplex>(businessParam(BOOKINGS_ENDPOINT), payload)
    .then((res) => res.data)
    .catch((err: AxiosError<BookingPostErrorComplex>) => Promise.reject(err.response?.data));

// PUT
type ReportPutPayloadComplex = ReportPutPayload;

export interface EventsPutPayloadComplex extends Omit<EventsPutPayload, 'user'> {
  _report_datalist?: Array<ReportPutPayloadComplex>;
}

export interface BookingPutPayloadComplex extends Omit<BookingsPutPayload, 'username'> {
  _event_datalist?: Array<EventsPutPayloadComplex>;
}

export type BookingPutResponseComplex = unknown;

export type BookingPutErrorComplex = Errors<BookingPutPayloadComplex>;

/**
 * Update a booking along with its events data
 *
 * @param bookingId
 * @param payload each underscore-preceded attribute updates another entity in
 * an atomic query
 */
export const updateBookingComplex = (bookingId: DatabaseId, payload: BookingPutPayloadComplex) =>
  apiClient
    .put<BookingPutResponseComplex>(pathParam(BOOKINGS_ENDPOINT, bookingId), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<BookingPutErrorComplex>) => Promise.reject(err.response?.data));

// COMPANIES
// POST
type StaffPostPayloadComplex = Omit<CompanyStaffItem, 'uuid'>;

type PreferredAgenciesPostPayloadComplex = PreferredAgencyItem;

export interface CompaniesPostPayloadComplex extends CompaniesPostPayload {
  _agents_data?: StaffPostPayloadComplex[];
  _rates_datalist?: RatesPostPayload[];
  _preferred_agency_data?: PreferredAgenciesPostPayloadComplex[];
}

export interface CompaniesPostResponseComplex {
  agents_ids: DatabaseId[];
  rates_ids: DatabaseId[];
  company_id: DatabaseId;
}

export type CompaniesPostErrorComplex = Errors<CompaniesPostPayloadComplex>;

/**
 * Create a company with the addition of other entities such as Staffs
 *
 * @param payload each underscore-preceded attribute creates another entity in
 * an atomic query.
 * If empty ({} or []), they prevent the default behavior,
 * if undefined, they proceed with default behavior
 */
export const createCompanyComplex = (payload: CompaniesPostPayloadComplex) =>
  apiClient
    .post<CompaniesPostResponseComplex>(COMPANIES_ENDPOINT, {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .then((res) => res.data)
    .catch((err: AxiosError<CompaniesPostErrorComplex>) => Promise.reject(err.response?.data));

// PUT

export type StaffPutPayloadComplex = StaffPostPayloadComplex;
export type PreferredAgenciesPutPayloadComplex = PreferredAgenciesPostPayloadComplex;
export interface CompaniesPutPayloadComplex extends CompaniesPutPayload {
  _agents_data?: StaffPutPayloadComplex[];
  _rates_datalist?: RatesPutPayload[];
  _preferred_agency_data?: PreferredAgenciesPutPayloadComplex[];
}

export type CompaniesPutResponseComplex = unknown;
export type CompaniesPutErrorComplex = Errors<CompaniesPutPayloadComplex>;

export const updateCompanyComplex = (companyId: DatabaseId, payload: CompaniesPutPayloadComplex) =>
  apiClient
    .put<CompaniesPutResponseComplex>(pathParam(COMPANIES_ENDPOINT, companyId), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<CompaniesPutErrorComplex>) => Promise.reject(err.response?.data));
