import { AxiosError } from 'axios';
import { Contact, Location, User } from '../types/Entities';
import { DatabaseId, Errors } from '../types/DataStructures';
import { sanitizeUser } from './config/sanitizers';
import { ApiQuery, applyLookup, BUSINESS_NAME, businessParam, LookupsMap, pathParam } from './config/helpers';
import { apiClient } from './config/clients';
import { USERS_ENDPOINT } from './config/endpoints';

// GET
export interface UsersGetParams {
  id?: DatabaseId;
  username?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  national_id?: string;
  ssn?: string;
  date_of_birth?: string;
  title?: string;
  suffix?: string;

  page?: number;
  page_size?: number;
}

export const DEFAULT_USERS_LOOKUPS: LookupsMap<UsersGetParams> = {
  username: 'nem',
  email: 'nem',
  first_name: 'nem',
  last_name: 'nem',
  date_of_birth: 'nem',
  national_id: 'nem',
  ssn: 'nem',
};
export const getUsers: ApiQuery<UsersGetParams, User[]> = (params: UsersGetParams, lookups = DEFAULT_USERS_LOOKUPS) =>
  apiClient
    .get<any[]>(USERS_ENDPOINT, { params: applyLookup(params, lookups) })
    .then((res) => res.data.map((v) => sanitizeUser(v)));

export const getUsersPaginated: ApiQuery<
  UsersGetParams,
  { count: number; next: string | null; previous: string | null; results: User[] }
> = (params, lookups = DEFAULT_USERS_LOOKUPS) =>
  apiClient
    .get<any>(USERS_ENDPOINT, {
      params: applyLookup<UsersGetParams>(params, lookups),
    })
    .then((res) => res.data)
    .then((data) => ({
      ...data,
      results: data.results.map(sanitizeUser),
    }));

export const getUser = (id: DatabaseId) =>
  apiClient.get(pathParam(USERS_ENDPOINT, id)).then((res) => sanitizeUser(res.data));

// POST
export interface UsersPostPayload {
  username: string;
  email: string;
  password?: string;
  confirmation?: string;
  first_name: string;
  last_name: string;
  national_id?: string;
  ssn?: string;
  date_of_birth?: string | null;
  title?: string;
  suffix?: string;
  contacts: Contact[];
  location?: Location;
}

export type UsersPostResponse = DatabaseId;
export type UsersPostError = Errors<UsersPostPayload>;
export const createUser = (payload: UsersPostPayload) =>
  apiClient
    .post<{ user_id: DatabaseId }>(businessParam(USERS_ENDPOINT), { ...payload, _business: BUSINESS_NAME })
    .then<UsersPostResponse>((res) => res.data.user_id)
    .catch((err: AxiosError<UsersPostError>) => Promise.reject(err.response?.data));

// PUT
export type UsersPutPayload = Partial<Omit<UsersPostPayload, 'username'>>;
export type UsersPutResponse = unknown;
export type UsersPutError = Errors<UsersPutPayload>;
export const updateUser = (userId: DatabaseId, payload: UsersPutPayload) =>
  apiClient
    .put<UsersPutResponse>(pathParam(USERS_ENDPOINT, userId), { ...payload, _business: BUSINESS_NAME })
    .catch((err: AxiosError<UsersPutError>) => Promise.reject(err.response?.data));

// DELETE
export type UsersDeleteResponse = unknown;
export type UsersDeleteError = string;
export const deleteUser = (userId: DatabaseId) =>
  apiClient
    .delete<UsersDeleteResponse>(pathParam(USERS_ENDPOINT, userId))
    .catch((err: AxiosError<UsersDeleteError>) => Promise.reject(err.response?.data));
