import { Location, Payer, User } from 'src/app/types/Entities';
import { formatDate } from 'src/app/stores/contains';

interface Props {
  user: Omit<User, never> | undefined;
  adjuster: Payer | null;
  dateOfInjury: Date | null;
  start_at: string;
  clinic?: string;
  contactClinic?: Location;
  medicalProvider?: string;
  medicalProviderLastName?: string;
  serviceRoot?: string;
  serviceProvider?: string;
  serviceProviderLastName?: string;
  typeOfCertification?: string;
  duration: Date;
  noteDiagnosis?: string;
  patient_first_name?: string;
  patient_last_name?: string;
  vendorID: string;
}
export default function InterpretationLetter(props: Props) {
  const {
    user,
    adjuster,
    dateOfInjury,
    start_at,
    clinic,
    contactClinic,
    medicalProvider,
    medicalProviderLastName,
    serviceRoot,
    serviceProvider,
    serviceProviderLastName,
    typeOfCertification,
    duration,
    noteDiagnosis,
    patient_first_name,
    patient_last_name,
    vendorID,
  } = props;

  let DOI;

  if (dateOfInjury !== null) {
    const preDoi = dateOfInjury?.toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
    });
    DOI = new Date(preDoi);
    DOI = formatDate(DOI);
  }

  return `<div>
      <p>Dear ${adjuster !== null ? `${adjuster.first_name} ${adjuster.last_name}` : 'Madam / Sir'},
      </p>

      <p>
        We hope you are doing well. We are CORE Communications, a scheduling facilitator for medical interpretation. We
        are coordinating the following service:
      </p>

      <div style={{ fontWeight: 'bold' }}>REFERRAL TYPE: MEDICAL INTERPRETATION</div>

      <ul>
        <li>
          <b>Injured Worker:</b> ${patient_first_name} ${patient_last_name} 
        </li>
        <li><b>DOI</b>: ${DOI}</li>
        <li>
          <b>Date of Service:</b> ${start_at} 
        </li>
        <li>
          <b>Time:</b> ${duration.toTimeString().split('GMT')[0]}
        </li>
        <li>
          <b>Provider:</b> ${medicalProvider} ${medicalProviderLastName}
        </li>
        <li>
          <b>Medical Facility:</b> ${clinic}
        </li>
        <li>
          <b>Address:</b> ${contactClinic?.address} ${contactClinic?.city} ${contactClinic?.country} 
          ${contactClinic?.state} ${contactClinic?.zip}
        </li>
        <li>
          <b>Modality:</b> ${serviceRoot} 
        </li>
        <li>
        <b>Interpreter:</b> ${
          serviceProvider === undefined && serviceProviderLastName === undefined
            ? 'N/A'
            : `${serviceProvider} ${serviceProviderLastName}`
        }</li>
        <li>
          <b>Type of Certification:</b> ${typeOfCertification === undefined ? 'N/A' : typeOfCertification}
        </li>
        <li>
          <b>Note / Special instructions:</b> ${noteDiagnosis === undefined ? 'N/A' : noteDiagnosis}
        </li>
      </ul>

      <div style={{ fontWeight: 'bold' }}>AVAILABILITY NOTE</div>

      <ul>CORE Communications information:</ul>

      <ul>
        <li>
          <b>VENDOR ID:</b> ${vendorID}
         </li>
        <li>Name: CORE Care and Health Systems Corp DBA CORE Communications</li>
        <li>Phone: (650) 318-1852 / (234) 267-3468</li>
        <li>Email: scheduling@COREinterpreters.net</li>
      </ul>

      <p>Best regards,</p>

      ${
        user?.first_name === '' && user?.last_name === ''
          ? ''
          : `
            <p>${user?.first_name} ${user?.last_name}</p>

            <p>Scheduling Specialist</p>

            <p>====================</p>
            `
      }

      <p>Scheduling Department</p>

      <p>CORE Communications</p>
      <p>Ph: (234) 267-3468</p>
      <p>Fax: (234) 267-3329</p>
      <img style="width: 10%;" src="https://be.corechs.com/static/media/core_logo.2ddb05fefea936abd7fb.png" alt="core_logo" />
      <p>
        CORE Communications is a scheduling coordinator that excels in interpreting services for special medical
        appointments
      </p>
      <p>
        workers’ compensation appointments, FRP programs, rehabilitation programs, psych consultations, medical
        equipment
      </p>
      <p>training, group/large-audiences/high-tech setup interpreting, personal injuries, and others.</p>
    </div>`;
}
