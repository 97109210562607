import { useTranslation } from 'react-i18next';
import { Badge, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { StateUpdater } from 'src/app/types/Components';
import { useEffect, useState } from 'react';
import FormExpandableListField from '../../../../../components/Form/FormExpandableListField';
import { BUTTON_STATE, CATEGORY_PREFIXES } from '../../../../../app/helpers/enum';
import { useForm } from '../../../../../app/hooks';
import { CertificateItem, CertificationState } from '../localTypes';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import { arrayRemoveAt, arrayReplaceAt } from '../../../../../app/helpers/manipulation';
import CertificationListItem from './ListItem';
import { getCategoriesByPrefix } from '../../../../../app/api/categories';
import { MUTATION_KEYS } from '../../../../../app/api';

export interface CertificationFieldProps {
  formPrefix: string;
  showCertificationField: boolean;
  setShowCertificationField: (display: boolean) => void;
  initialState: CertificationState;
  stateUpdater: StateUpdater<CertificationState>;
}

const translationArray = ['onboardings'];

function CertificationField(props: CertificationFieldProps) {
  const { formPrefix, showCertificationField, setShowCertificationField, initialState, stateUpdater } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [certificates, setCertificates] = useStateWithCallback(initialState.certificates, stateUpdater('certificates'));

  const pushNewCertificate = () =>
    setCertificates([
      ...certificates,
      { certificateId: undefined, certificateNumber: '', expirationDate: undefined, uuid: uuidv4() },
    ]);

  const replaceCertificateAt = (index: number, certificate: CertificateItem) =>
    setCertificates(arrayReplaceAt(certificates, index, certificate));

  const removeCertificateAt = (index: number) => setCertificates(arrayRemoveAt(certificates, index));

  const [certificationsRefetchEnabled, setCertificationsRefetchEnabled] = useState<boolean>(true);
  const { data: certifications, isLoading: areCertificationsLoading } = useQuery(
    [MUTATION_KEYS.CATEGORIES, { name: CATEGORY_PREFIXES.CERTIFICATION }],
    () => getCategoriesByPrefix(CATEGORY_PREFIXES.CERTIFICATION),
    {
      enabled: certificationsRefetchEnabled,
    },
  );

  useEffect(() => {
    if (certifications) {
      setCertificationsRefetchEnabled(false);
    }
  }, [certifications]);

  // Display
  const label = (
    <Badge showZero variant="counterBadge" color="primary" badgeContent={certificates.length} sx={{ marginBottom: 2 }}>
      <Typography variant="formLabel">{t`certification`}</Typography>
    </Badge>
  );

  const onAdd = () => {
    pushNewCertificate();
    setShowCertificationField(true);
  };

  return (
    <FormExpandableListField
      showSeparator
      formPrefix={p`certifications`}
      isOpen={showCertificationField}
      toggleOpen={setShowCertificationField}
      onAdd={onAdd}
      addLabel={t`addCertificate`}
      openButtonState={certificates.length === 0 ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showCertificationField ? t`tooltip.hideCertificates` : t`tooltip.viewCertificates`}
      defaultDisplay={t`noCertificates`}
      label={label}
      onCancel={() => setShowCertificationField(false)}
    >
      {certificates.map((certificate, index) => {
        return (
          <CertificationListItem
            key={certificate.uuid}
            certificationOptions={certifications}
            areCertificationsLoading={areCertificationsLoading}
            removeCertificate={() => removeCertificateAt(index)}
            initialState={certificate}
            stateUpdater={(key: any) => (value: any) => replaceCertificateAt(index, { ...certificate, [key]: value })}
          />
        );
      })}
    </FormExpandableListField>
  );
}

export default CertificationField;
