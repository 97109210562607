import { Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm } from '../../../../../../app/hooks';
import LawfirmField from './LawfirmField';
import { getLawfirmsWithRoles, MUTATION_KEYS } from '../../../../../../app/api';
import { COMPANY_TYPES } from '../../../../../../app/helpers/enum';
import { Lawfirm } from '../../../../../../app/types/Entities';
import LawyerField from './LawyerField';
import { PayerSectionProps } from '../localTypes';

export interface LawfirmSectionProps extends PayerSectionProps {
  lawfirmOptional?: boolean;
}

function LawfirmSection(props: LawfirmSectionProps) {
  const { formPrefix, payer, setPayer, payerCompany, setPayerCompany, lawfirmOptional, initialPayerFieldState } = props;

  // Utils
  const { p } = useForm(formPrefix);

  // Form data
  const [showLawfirm, setShowLawfirm] = useState<boolean>(false);
  const [showLawyer, setShowLawyer] = useState<boolean>(false);

  // Query
  const [lawfirmsRefetchEnabled, setLawfirmsRefetchEnabled] = useState<boolean>(true);
  const {
    data: lawfirms,
    isLoading: isLawfirmLoading,
    refetch: refetchLawfirms,
  } = useQuery([MUTATION_KEYS.COMPANIES, { type: COMPANY_TYPES.LAWFIRM }], () => getLawfirmsWithRoles({}), {
    enabled: lawfirmsRefetchEnabled,
  });

  useEffect(() => {
    if (lawfirms) {
      setLawfirmsRefetchEnabled(false);
    }
  }, [lawfirms]);

  const lawyers = payerCompany?.payers ?? [];

  // Handle fields visibility
  useEffect(() => {
    if (!payerCompany && initialPayerFieldState && payerCompany !== initialPayerFieldState.payerCompany) {
      setPayer(null);
      setShowLawyer(false);
    }
  }, [initialPayerFieldState, payerCompany, setPayer]);

  return (
    <Container className="p-0">
      <Row>
        <Col>
          <LawfirmField
            formPrefix={p`agency`}
            optional={lawfirmOptional}
            isOpen={showLawfirm}
            toggleOpen={setShowLawfirm}
            lawfirm={payerCompany as Lawfirm | null}
            setLawfirm={setPayerCompany}
            lawfirmList={lawfirms}
            isLoading={isLawfirmLoading}
            refetchLawfirms={refetchLawfirms}
            onUpdateSuccess={(newAgency) => {
              setPayerCompany(newAgency);
              setShowLawfirm(false);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <LawyerField
            optional
            formPrefix={p`agent`}
            isDisabled={!payerCompany}
            isOpen={showLawyer}
            toggleOpen={setShowLawyer}
            lawyer={payer}
            setLawyer={setPayer}
            lawyerList={lawyers}
            isLoading={isLawfirmLoading}
            refetchLawyers={() =>
              refetchLawfirms().then(({ data: newAgencies = [] }) => ({
                data: newAgencies.flatMap(({ payers }) => payers ?? []),
              }))
            }
            lawfirm={payerCompany as Lawfirm | null}
            onUpdateSuccess={(newAgent) => {
              setPayer(newAgent);
              setShowLawyer(false);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default LawfirmSection;
