import { Col, Row } from 'reactstrap';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import { PaymentMethod, PaymentMethodACHTransfer } from '../../localTypes';
import useStateWithCallback from '../../../../../../app/hooks/useStateWithCallback';
import PaymentMethodSelect from './Select';
import FormSelect from '../../../../../../components/Form/FormSelect/FormSelect';

const accountTypeOptions: PaymentMethodACHTransfer['accountType'][] = ['checking', 'savings'];

export interface PaymentMethodItemACHTransferProps {
  selectedMethod: PaymentMethod['method'];
  setSelectedMethod: (selectedMethod: PaymentMethod['method']) => void;
  initialState: PaymentMethodACHTransfer;
  stateUpdater: StateUpdater<PaymentMethodACHTransfer>;
  // TODO add errors
}

const translationArray = ['onboardings'];

export default function PaymentMethodItemACHTransfer(props: PaymentMethodItemACHTransferProps) {
  const { selectedMethod, setSelectedMethod, initialState, stateUpdater } = props;

  const { t } = useTranslation(translationArray);

  const [bankName, setBankName] = useStateWithCallback(initialState.bankName, stateUpdater('bankName'));
  const [accountType, setAccountType] = useStateWithCallback(initialState.accountType, stateUpdater('accountType'));
  const [routingNumber, setRoutingNumber] = useStateWithCallback(
    initialState.routingNumber,
    stateUpdater('routingNumber'),
  );
  const [accountNumber, setAccountNumber] = useStateWithCallback(
    initialState.accountNumber,
    stateUpdater('accountNumber'),
  );

  return (
    <>
      <Row>
        <Col>
          <PaymentMethodSelect selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} />
        </Col>
        <Col>
          <TextField
            fullWidth
            variant="standard"
            label={t`bankName` as string}
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormSelect
            disableClearable
            options={accountTypeOptions}
            value={accountType}
            onChange={(_, value) => setAccountType(value)}
            getOptionLabel={t}
            renderInput={(inputProps) => (
              <TextField {...inputProps} label={t`accountType` as string} variant="standard" />
            )}
          />
        </Col>
        <Col>
          <TextField
            fullWidth
            variant="standard"
            label={t`abaRoutingNumber` as string}
            value={routingNumber}
            onChange={(e) => setRoutingNumber(e.target.value)}
          />
        </Col>
        <Col>
          <TextField
            fullWidth
            variant="standard"
            label={t`accountNumber` as string}
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
}
