import FormSection from 'src/components/Form/FormSection';
import { StateUpdater } from 'src/app/types/Components';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { useTranslation } from 'react-i18next';
import useStateWithCallback from 'src/app/hooks/useStateWithCallback';
import { TextField } from '@mui/material';
import { Row, Col } from 'reactstrap';
import { findInOptions } from 'src/app/helpers/manipulation';
import {
  INVOICE_FORMAT,
  INVOICE_FREQUENCY,
  INVOICE_GROUPING,
  INVOICE_SPECIAL_FIELD,
  SEND_INVOICE_METHOD,
} from 'src/app/helpers/enum';
import { mapObjectForSelect } from 'src/app/helpers/mappers';
import { InvoicingState } from '../localTypes';
import './InvoicingField.scss';

export interface InvoicingFieldProps {
  initialState: InvoicingState;
  stateUpdater: StateUpdater<InvoicingState>;
  // TODO add errors
}

const translationArray = ['collections', 'onboardings'];

function InvoicingField(props: InvoicingFieldProps) {
  const { initialState, stateUpdater } = props;

  // Utils
  const { t } = useTranslation(translationArray);

  // Form data
  // TODO: Add actucal more fields
  const [sendInvoiceMethod, setSendInvoiceMethod] = useStateWithCallback(
    initialState.sendInvoiceMethod,
    stateUpdater('sendInvoiceMethod'),
  );
  const [invoiceFormat, setInvoiceFormat] = useStateWithCallback(
    initialState.invoiceFormat,
    stateUpdater('invoiceFormat'),
  );
  const [invoiceGrouping, setInvoiceGrouping] = useStateWithCallback(
    initialState.invoiceGrouping,
    stateUpdater('invoiceGrouping'),
  );
  const [invoiceBatchSize, setInvoiceBatchSize] = useStateWithCallback(
    initialState.invoiceBatchSize,
    stateUpdater('invoiceBatchSize'),
  );
  const [invoiceDeadline, setInvoiceDeadline] = useStateWithCallback(
    initialState.invoiceDeadline,
    stateUpdater('invoiceDeadline'),
  );
  const [invoiceFrequency, setInvoiceFrequency] = useStateWithCallback(
    initialState.invoiceFrequency,
    stateUpdater('invoiceFrequency'),
  );
  const [invoiceSpecialField, setInvoiceSpecialField] = useStateWithCallback(
    initialState.invoiceSpecialField,
    stateUpdater('invoiceSpecialField'),
  );

  // Options
  const sendInvoiceMethodOptions = mapObjectForSelect(SEND_INVOICE_METHOD, (typeKey) =>
    t(`collections:sendInvoiceMethodOptions.${typeKey.toLowerCase()}`),
  );
  const invoiceFormatOptions = mapObjectForSelect(INVOICE_FORMAT, (typeKey) =>
    t(`collections:invoiceFormatOptions.${typeKey.toLowerCase()}`),
  );
  const invoiceGroupingOptions = mapObjectForSelect(INVOICE_GROUPING, (typeKey) =>
    t(`collections:invoiceGroupingOptions.${typeKey.toLowerCase()}`),
  );
  const invoiceFrequencyOptions = mapObjectForSelect(INVOICE_FREQUENCY, (typeKey) =>
    t(`collections:invoiceFrequencyOptions.${typeKey.toLowerCase()}`),
  );
  const invoiceSpecialFieldOptions = mapObjectForSelect(INVOICE_SPECIAL_FIELD, (typeKey) =>
    t(`collections:invoiceSpecialFieldOptions.${typeKey.toLowerCase()}`),
  );

  return (
    <FormSection label={`${t`onboardings:invoicingSection`}`}>
      <Row className="mb-2">
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col md={2} xl={6} xxl={12}>
              <FormSelect
                options={sendInvoiceMethodOptions}
                value={findInOptions(sendInvoiceMethodOptions, sendInvoiceMethod) ?? null}
                onChange={(_, option) => setSendInvoiceMethod(option?.value!)}
                renderInput={(params) => <TextField placeholder={`${t`onboardings:sendInvoiceMethod`} `} {...params} />}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col md={2} xl={6} xxl={12}>
              <FormSelect
                options={invoiceFormatOptions}
                value={findInOptions(invoiceFormatOptions, invoiceFormat) ?? null}
                onChange={(_, option) => setInvoiceFormat(option?.value!)}
                renderInput={(params) => <TextField placeholder={`${t`onboardings:invoiceFormat`} `} {...params} />}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col md={2} xl={6} xxl={12}>
              <FormSelect
                options={invoiceGroupingOptions}
                value={findInOptions(invoiceGroupingOptions, invoiceGrouping) ?? null}
                onChange={(_, option) => setInvoiceGrouping(option?.value!)}
                renderInput={(params) => <TextField placeholder={`${t`onboardings:invoiceGrouping`} `} {...params} />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col md={2} xl={6} xxl={12}>
              <TextField
                className="textFieldStyle"
                placeholder={`${t`onboardings:invoiceBatchSize`} `}
                value={invoiceBatchSize}
                onChange={(e) => setInvoiceBatchSize(e.target.value)}
                InputProps={{ inputProps: { maxLength: 3 } }}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col md={2} xl={6} xxl={12}>
              <TextField
                className="textFieldStyle"
                placeholder={`${t`onboardings:invoiceDeadline`} `}
                value={invoiceDeadline}
                onChange={(e) => setInvoiceDeadline(e.target.value)}
                InputProps={{ inputProps: { maxLength: 3 } }}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col md={2} xl={6} xxl={12}>
              <FormSelect
                options={invoiceFrequencyOptions}
                value={findInOptions(invoiceFrequencyOptions, invoiceFrequency) ?? null}
                onChange={(_, option) => setInvoiceFrequency(option?.value!)}
                renderInput={(params) => <TextField placeholder={`${t`onboardings:invoiceFrequency`} `} {...params} />}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col md={2} xl={6} xxl={12}>
              <FormSelect
                // TODO: Add options
                options={[]}
                value={findInOptions(invoiceSpecialFieldOptions, invoiceSpecialField) ?? null}
                onChange={(_, option) => setInvoiceSpecialField(option?.value!)}
                renderInput={(params) => (
                  <TextField placeholder={`${t`onboardings:invoiceSpecialField`} `} {...params} />
                )}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </FormSection>
  );
}

export default InvoicingField;
