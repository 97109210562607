import { Alert } from 'reactstrap';
import { isEmpty } from 'lodash';
import arrayToUnorderedList from '../../app/helpers/componentMappers';
import './ErrorsAlert.scss';

export interface ErrorsAlertProps {
  errorsArray: Array<string> | undefined | null;
}

export function ErrorsAlert(props: ErrorsAlertProps) {
  const { errorsArray } = props;

  return (
    (!isEmpty(errorsArray) || null) && (
      <Alert className="errors-alert" color="danger">
        {arrayToUnorderedList(errorsArray)}
      </Alert>
    )
  );
}
