import { Dispatch, SetStateAction, useState } from 'react';

export type UseStateDictReturnType<S> = [
  S,
  Dispatch<SetStateAction<S>>,
  (fields: Partial<S>) => void,
  (field: keyof S) => void,
  <P extends keyof S>(parent: P, fields: Partial<S[P]>) => void,
];

export function useStateDict<S extends object>(initialState: S | (() => S)): UseStateDictReturnType<S> {
  const [value, setValue] = useState<S>(initialState);
  const updateValue = (fields: Partial<S>) => setValue((prev) => ({ ...prev!, ...fields }));
  const clearField = (field: keyof S) => setValue((prev) => ({ ...prev!, [field]: undefined }));
  const updateNestedValue = <P extends keyof S>(parent: P, fields: Partial<S[P]>) =>
    updateValue({
      [parent]: { ...value[parent], ...fields },
    } as unknown as Partial<S>);
  return [value, setValue, updateValue, clearField, updateNestedValue];
}
