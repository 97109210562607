// Business constants
import { BILL_RATE_TYPES } from './enum';

export const QUERY_PARAM_KEY_SEP = '.' as const;
export const SERVICE_BILL_RATE = 1;
export const SERVICE_BILL_RATE_TYPE = BILL_RATE_TYPES.PER_HOURS;

// Regex
export const ALPHANUMERIC_REGEX = /^[0-9a-z]*$/i;

export const USERNAME_REGEX = /^[0-9a-z@.+\-_]*$/i;

// Helper constants
export const SORTER_PICK_LEFT = 1;

export const SORTER_PICK_RIGHT = -1;

// Libraries constants
export const ISO8601_DATE_FORMAT_MOMENT = 'YYYY-MM-DD' as const;

export const YYYYMMMDD_DATE_FORMAT_MOMENT = 'YYYY MMM DD' as const;

export const PREFERRED_DATE_FORMAT_MOMENT = 'YYYY-MM-DD' as const;

export const PREFERRED_DATETIME_FORMAT_MOMENT = 'YYYY/MM/DD hh:mm A';

export const NOTE_DATE_FORMAT_MOMENT = 'MMM DD, YYYY';

export const PREFERRED_TIME_FORMAT_MOMENT = 'h:mm A';

export const SHORT_DATETIME_FORMAT_MOMENT = 'YY/M/D h:mma';

export const PREFERRED_TOAST_AUTOCLOSE = 5000;

// Defaults

export const DEFAULT_COUNTRY_FOR_LOCATION = 'United States of America';

export const DEFAULT_BOOKING_STAGE_COLOR = '#808080';

export const DEFAULT_EVENT_DURATION = 30;

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const WEEK_MS = 604800000;

export const MINUTE_MS = 60000;

export const HELP_LINK =
  'https://docs.google.com/document/d/11qQ-tPhUU-Bxa38KI3rPi7C6GEy9PTWrm4O-2nQmKvY/edit#heading=h.qjv48u2iqhrt';
