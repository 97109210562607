import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Lawfirm } from '../../../../../../app/types/Entities';
import GenericCompanyField, { GenericCompanyFieldTranslationProps } from '../GenericCompanyField';
import { COMPANY_TYPES } from '../../../../../../app/helpers/enum';
import { getCompanies, MUTATION_KEYS } from '../../../../../../app/api';

export interface LawfirmFieldProps {
  formPrefix: string;
  optional?: boolean;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  lawfirm: Lawfirm | null;
  setLawfirm: (lawfirm: Lawfirm | null) => void;
  lawfirmList: Lawfirm[] | undefined;
  isLoading: boolean;
  refetchLawfirms: () => Promise<{ data: Lawfirm[] | undefined }>;
  onUpdateSuccess: (lawfirm: Lawfirm | null) => void;
}

export default function LawfirmField(props: LawfirmFieldProps) {
  const { formPrefix, optional, isOpen, toggleOpen, lawfirm, setLawfirm, lawfirmList, isLoading, refetchLawfirms } =
    props;

  // Utils
  const { t } = useTranslation('booking');

  // Query
  const [parentCompaniesRefetchEnabled, setParentCompaniesRefetchEnabled] = useState<boolean>(true);
  const { data: parentCompanies, isLoading: isParentCompaniesLoading } = useQuery(
    [MUTATION_KEYS.COMPANIES, {}],
    () => getCompanies({}),
    { enabled: parentCompaniesRefetchEnabled },
  );

  useEffect(() => {
    if (parentCompanies) {
      setParentCompaniesRefetchEnabled(false);
    }
  }, [parentCompanies]);

  const onUpdateSuccess = (company: Lawfirm | undefined) => props.onUpdateSuccess(company ?? null);

  const translations: GenericCompanyFieldTranslationProps = {
    company: t`lawfirm`,
    companyName: t`lawfirmName`,
    newCompany: t`newLawfirm`,
    selectCompany: t`selectLawfirm`,
    parentCompany: t`parentCompany`,
    saveCompany: t`saveLawfirm`,
    cancel: t`cancel`,
    requiredFieldsHelp: t`requiredFieldsHelp`,
    contactInformation: t`contactInformation`,
    companyNotes: t`lawfirmNotes`,
    errors: {
      noCompanyName: t`errors.noLawfirmName`,
      noParentCompany: t`errors.noParentCompany`,
    },
    tooltip: {
      cancelAddCompany: t`tooltip.cancelAddLawfirm`,
      addCompany: t`tooltip.addLawfirm`,
      hideCompany: t`tooltip.hideLawfirm`,
      viewCompany: t`tooltip.viewLawfirm`,
    },
    progress: {
      companyCreate: {
        started: t`progress.lawfirmCreate.started`,
        success: t`progress.lawfirmCreate.success`,
        error: t`progress.lawfirmCreate.error`,
      },
      companyUpdate: {
        started: t`progress.lawfirmUpdate.started`,
        success: t`progress.lawfirmUpdate.success`,
        error: t`progress.lawfirmUpdate.error`,
      },
    },
  };

  return (
    <GenericCompanyField<Lawfirm>
      formPrefix={formPrefix}
      optional={optional}
      showCompanyField={isOpen}
      setShowCompanyField={toggleOpen}
      company={lawfirm}
      setCompany={setLawfirm}
      companyType={COMPANY_TYPES.LAWFIRM}
      companies={lawfirmList}
      isCompanyLoading={isLoading}
      refetchCompanies={refetchLawfirms}
      parentCompanies={parentCompanies}
      isParentCompaniesLoading={isParentCompaniesLoading}
      onUpdateSuccess={onUpdateSuccess}
      translations={translations}
    />
  );
}
