import { Button, Modal, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  BookingPostErrorComplex,
  BookingPostPayloadComplex,
  BookingPostResponseComplex,
  MUTATION_KEYS,
  createBookingComplex,
  getAffiliations,
  getAgents,
  getLanguages,
  getOperators,
  getServiceRootsDao,
  getUsers,
  queryClient,
} from 'src/app/api';
import { TYPES_OF_APPOINTMENTS } from 'src/app/helpers/enum';
import { stringifyDateTime } from 'src/app/helpers/manipulation';
import contains, {
  ExtractLastWord,
  dateFormattedList,
  decimalToTime,
  decimalToTimeEnd,
  formatDateTime,
  numberToDate,
} from 'src/app/stores/contains';
import { DatabaseId } from 'src/app/types/DataStructures';

interface UploadingBookProps {
  data: any;
  userId: DatabaseId;
}

const translationArray = ['booking'];

export default function UploadingPage({ data, userId }: UploadingBookProps) {
  const { t } = useTranslation(translationArray);
  const [dataStatus, setDataStatus] = useState<any[]>();
  const [dataStatusUpdated, setDataStatusUpdated] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const handleOpen = (rowIndex: number) => {
    setOpen(true);
    setSelectedRowIndex(rowIndex);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRowIndex(null);
  };

  const { data: eventsListRequester } = useQuery([MUTATION_KEYS.AGENTS], async () => {
    const dataAsync = await getAgents({});
    return dataAsync;
  });

  const { data: eventsListUser } = useQuery([MUTATION_KEYS.USERS], async () => {
    const dataAsync = await getUsers({});
    return dataAsync;
  });

  const { data: eventsListLanguages } = useQuery([MUTATION_KEYS.LANGUAGES], async () => {
    const dataAsync = await getLanguages({});
    return dataAsync;
  });

  const { data: eventsListOperators } = useQuery([MUTATION_KEYS.OPERATORS], async () => {
    const dataAsync = await getOperators({});
    return dataAsync;
  });

  const { data: eventsListAffiliation } = useQuery([MUTATION_KEYS.AFFILIATIONS], async () => {
    const dataAsync = await getAffiliations({});
    return dataAsync;
  });

  const { data: eventsListServiceRoots } = useQuery([MUTATION_KEYS.SERVICE_ROOTS], async () => {
    const dataAsync = await getServiceRootsDao({});
    return dataAsync;
  });

  const ListOfAppointments = Object.values(TYPES_OF_APPOINTMENTS);

  const handleData = useCallback(() => {
    if (eventsListUser !== undefined) {
      const dataStat = data.rows.map((row: { [x: string]: any }) => {
        const listPatient: (string | undefined)[] = [];
        const listMedic: (string | undefined)[] = [];
        const valuePatient: any = [];
        const valueMedic: any = [];
        const valueRequester: any = [];
        const valueLanguage: (string | undefined)[] = [];
        const valueOperator: any = [];
        const valueCompanies: (any[] | undefined)[] = [];
        const valueTypeOfAppointment: (string | undefined)[] = [];
        const valueModality: (string | undefined)[] = [];
        const valueTime: (string | undefined)[] = [];
        const valueTimeEnd: (string | undefined)[] = [];
        const DOB = numberToDate(row.values.dob);
        const Date = numberToDate(row.values.date);
        const Hour = decimalToTime(row.values.hour);
        const HourEnd = decimalToTimeEnd(Hour);
        if (
          Hour !== undefined &&
          Hour !== null &&
          Hour !== '' &&
          row.values.clinic !== undefined &&
          row.values.clinic !== null
        ) {
          const dateTime = formatDateTime(Date, Hour);
          const dateTimeEnd = formatDateTime(Date, HourEnd);
          const uniqueClinic = ExtractLastWord(row.values.clinic);

          const dobAndPatientList = [dateFormattedList(DOB.split('/')), row.values.first_name, row.values.last_name];
          eventsListRequester?.map((values) => {
            const Value: any = values?.companies?.[0];
            const SplitClinic = row.values.clinic.split(' ');
            if (Value !== undefined) {
              const Companies = Value.name;
              SplitClinic.map((partClinic: string | undefined) => {
                if (
                  contains(Companies, partClinic) &&
                  (contains(values.first_name, row.values.medic_first_name) ||
                    contains(values.last_name, row.values.medic_last_name) ||
                    contains(values.last_name, row.values.medic_first_name) ||
                    contains(values.first_name, row.values.medic_last_name))
                ) {
                  if (
                    ((values?.first_name === row.values.medic_first_name &&
                      values?.last_name === row.values.medic_last_name) ||
                      (values?.first_name === row.values.medic_last_name &&
                        values?.last_name === row.values.medic_first_name)) &&
                    Companies === `Boomerang Healthcare - ${uniqueClinic}`
                  ) {
                    listMedic.push('Exact Match ✅');
                    valueMedic.push(values?.agents_id);
                    valueRequester.push(values?.requester_id);
                    valueCompanies.push([Value.id]);
                  } else {
                    listMedic.push(`${values.first_name} ${values.last_name} ${Companies}`);
                  }
                }
                return '';
              });
            }
            return listMedic;
          });
          eventsListUser?.map((values) => {
            if (
              values?.date_of_birth?.toISOString().includes(dobAndPatientList[0][0]) === true &&
              ((values?.first_name === dobAndPatientList[1] && values?.last_name === dobAndPatientList[2]) ||
                (values?.first_name === dobAndPatientList[2] && values?.last_name === dobAndPatientList[1]))
            ) {
              listPatient.push('Exact Match ✅');
              valueTime.push(stringifyDateTime(dateTime));
              valueTimeEnd.push(stringifyDateTime(dateTimeEnd));
              eventsListAffiliation?.map((affiliates) => {
                if (affiliates.recipient.user_id === values?.user_id) {
                  valuePatient.push([affiliates?.id]);
                }
                return '';
              });
            } else if (values?.date_of_birth?.toISOString().includes(dobAndPatientList[0][0]) === true) {
              listPatient.push(
                `${values?.first_name} ${values?.last_name} ${values?.date_of_birth?.toISOString().split('T')[0]}`,
              );
            }
            return '';
          });
          eventsListLanguages?.map((values) => {
            if (values?.name.startsWith(row.values.language)) {
              valueLanguage.push(values?.alpha3);
            }
            return '';
          });
          eventsListOperators?.map((values) => {
            if (values.user_id === userId) {
              valueOperator.push([values.id]);
            }
            return '';
          });
          ListOfAppointments.map((values) => {
            if (contains(row.values.type_appointment, values)) {
              valueTypeOfAppointment.push(values);
            } else {
              valueTypeOfAppointment.push(ListOfAppointments[0]);
            }
            return '';
          });
          if (contains(row.values.modality.toLowerCase(), 'telemedic')) {
            valueModality.push(eventsListServiceRoots?.data?.[2].id);
          } else {
            valueModality.push(eventsListServiceRoots?.data?.[4].id);
          }
        } else if (contains(DOB, '-')) {
          listPatient.push('Invalid DOB format');
        } else {
          listPatient.push('Invalid date');
        }
        return {
          listPatient,
          listMedic,
          valuePatient,
          valueMedic,
          valueRequester,
          valueLanguage,
          valueTime,
          valueTimeEnd,
          valueOperator,
          valueCompanies,
          valueTypeOfAppointment,
          valueModality,
        };
      });
      setDataStatus(dataStat);
      setDataStatusUpdated(true);
    }
  }, [eventsListUser]);

  useEffect(() => {
    handleData();
  }, [handleData]);

  const PayloadBulk = dataStatus
    ?.map((dataStat) => {
      if (dataStat.listPatient.includes('Exact Match ✅') && dataStat.listMedic.includes('Exact Match ✅')) {
        return {
          companies: dataStat.valueCompanies[0],
          created_by: userId,
          notes: [],
          operators: dataStat.valueOperator[0],
          parent: undefined,
          reminder_targets: 'all',
          requester: dataStat.valueRequester[0],
          requester_company_source: 'clinic',
          service_root: dataStat.valueModality[0],
          services: [],
          target_language_alpha3: dataStat.valueLanguage[0],
          _event_datalist: [
            {
              affiliates: dataStat.valuePatient[0],
              agents: dataStat.valueMedic[0],
              arrive_at: dataStat.valueTime[0],
              authorizations: undefined,
              description: dataStat.valueTypeOfAppointment[0],
              end_at: dataStat.valueTimeEnd[0],
              payer: null,
              payer_company: null,
              payer_company_type: 'insurance',
              requester: dataStat.valueRequester[0],
              start_at: dataStat.valueTime[0],
            },
          ],
          _offer_datalist: [],
        };
      }
      return undefined;
    })
    .filter((payload) => {
      return payload !== undefined;
    });
  const rows = data.rows.map((row: { [x: string]: any }) => ({
    id: row.index,
    first_name: row.values.first_name,
    last_name: row.values.last_name,
    language: row.values.language,
    dob: numberToDate(row.values.dob),
    date: numberToDate(row.values.date),
    hour: decimalToTime(row.values.hour),
    type_appointment: row.values.type_appointment,
    modality: row.values.modality,
    medic_first_name: row.values.medic_first_name,
    medic_last_name: row.values.medic_last_name,
    clinic: row.values.clinic,
    status: contains(dataStatus?.[row.index].listPatient[0], 'Invalid')
      ? dataStatus?.[row.index].listPatient[0]
      : dataStatus?.[row.index].listPatient.includes('Exact Match ✅') &&
        dataStatus?.[row.index].listMedic.includes('Exact Match ✅')
      ? 'All Ok'
      : 'Warning',
  }));
  const columns: GridColDef[] = data.columns.map((column: { key: string }) => ({
    id: 0,
    field: column.key,
    headerName: t(`invoicing.${column.key}`),
    minWidth: 100,
    flex: 0.5,
    renderCell: (params: {
      value:
        | string
        | number
        | boolean
        | ReactElement<any, string | JSXElementConstructor<any>>
        | ReactFragment
        | ReactPortal
        | null
        | undefined;
    }) => {
      return <div className="booking-cell">{dataStatusUpdated === false ? <Skeleton /> : params.value}</div>;
    },
  }));

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
  };

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries([MUTATION_KEYS.BOOKINGS]).then();
      queryClient.invalidateQueries([MUTATION_KEYS.EVENTS]).then();
    },
  };

  const { mutateAsync: mutateAsyncCreateBooking } = useMutation<
    BookingPostResponseComplex,
    BookingPostErrorComplex,
    BookingPostPayloadComplex
  >(createBookingComplex, mutationOptions);

  const createBookings = () => {
    const processNextBooking = (index: number) => {
      if (PayloadBulk !== undefined) {
        if (index >= PayloadBulk.length) {
          return;
        }

        const dataItem: any = PayloadBulk[index];
        mutateAsyncCreateBooking(dataItem)
          .then(() => {
            toast.success(`${t('progress.bookingCreate.started')}`);
            processNextBooking(index + 1);
          })
          .catch(() => {
            toast.error(`${t('progress.bookingCreate.error')}`);
            processNextBooking(index + 1);
          });
      }
    };

    processNextBooking(0);
  };

  return (
    <>
      <Button onClick={createBookings}>Send</Button>
      <Box className="w-100 h-100 mw-100 p-0 container" sx={{ display: 'flex' }}>
        <DataGrid
          pageSizeOptions={[10, 25, 50, 75, 100]}
          sx={{
            height: '100%',
            width: '100%',
            [`.${gridClasses.cell}.err`]: {
              backgroundColor: '#fc6060',
              color: '#000000',
            },
            [`.${gridClasses.cell}.ok`]: {
              backgroundColor: '#75f375',
              color: '#000000',
            },
            [`.${gridClasses.cell}.warn`]: {
              backgroundColor: '#FFFF00',
              color: '#000000',
            },
            '& .MuiDataGrid-columnHeaders': {
              textTransform: 'uppercase',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
              borderBottom: '3px solid',
              borderColor: 'primary.main',
              marginBottom: '-3px',
            },
            '& .MuiDataGrid-sortIcon': {
              color: 'primary.main',
            },
            '& .MuiDataGrid-menuIconButton': {
              color: 'primary.main',
            },
            '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
              'margin-top': '1em',
              'margin-bottom': '1em',
            },
          }}
          onRowClick={(params) => handleOpen(params.row.id)}
          rows={rows}
          columns={columns}
          getCellClassName={(params) => {
            if (!dataStatusUpdated) return '';
            if (params.value === 'All Ok') return 'ok';
            if (params.value === 'Warning') return 'warn';
            if (contains(params.value, 'Invalid')) return 'err';
            return '';
          }}
          disableRowSelectionOnClick
        />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRowIndex !== null && dataStatus?.[selectedRowIndex].listPatient.length === 0
              ? t`progress.bookingUpload.patientNotFound`
              : selectedRowIndex !== null && dataStatus?.[selectedRowIndex].listPatient.includes('Exact Match ✅')
              ? ''
              : selectedRowIndex !== null && !contains(dataStatus?.[selectedRowIndex].listPatient[0], 'Invalid')
              ? t`progress.bookingUpload.patientSimilar`
              : t`progress.bookingUpload.error`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ul>
              {selectedRowIndex !== null && dataStatus?.[selectedRowIndex].listPatient.includes('Exact Match ✅')
                ? ''
                : selectedRowIndex !== null && dataStatus?.[selectedRowIndex]
                ? dataStatus[selectedRowIndex].listPatient
                    .filter((item: any, index: any) => dataStatus[selectedRowIndex].listPatient.indexOf(item) === index)
                    .map((row: any) => <li>{row}</li>)
                : ''}
            </ul>
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedRowIndex !== null && dataStatus?.[selectedRowIndex].listMedic.includes('Exact Match ✅')
              ? ''
              : selectedRowIndex !== null && dataStatus?.[selectedRowIndex].listMedic.length === 0
              ? ''
              : selectedRowIndex !== null && !contains(dataStatus?.[selectedRowIndex].listMedic[0], 'Invalid')
              ? t`progress.bookingUpload.medicSimilar`
              : t`progress.bookingUpload.error`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ul>
              {selectedRowIndex !== null && dataStatus?.[selectedRowIndex].listMedic.includes('Exact Match ✅')
                ? ''
                : selectedRowIndex !== null && dataStatus?.[selectedRowIndex]
                ? dataStatus[selectedRowIndex].listMedic
                    .filter((item: any, index: any) => dataStatus[selectedRowIndex].listMedic.indexOf(item) === index)
                    .map((row: any) => <li>{row}</li>)
                : ''}
            </ul>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
