import { Collapse, FormFeedback } from 'reactstrap';
import { FormFeedbackProps } from 'reactstrap/types/lib/FormFeedback';
import './InputMessages.scss';

interface InputMessagesProps extends FormFeedbackProps {
  messages?: string[];
}

export default function InputMessages({ messages, ...props }: InputMessagesProps) {
  return (
    <FormFeedback {...props}>
      <Collapse isOpen={!!messages}>
        <ul className="text-start">
          {messages?.map((m, i) => (
            <li key={i}>{m}</li>
          ))}
        </ul>
      </Collapse>
    </FormFeedback>
  );
}
