import { Component, ErrorInfo, ReactNode } from 'react';
import { Button } from 'reactstrap';
import MiddleCenterAlignedContainer from '../MiddleCenterAlignedContainer';

interface Props {
  children: ReactNode;
  location: string;
}

interface State {
  error: Error | null;
  info?: string;
}

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState((prev) => ({ ...prev, info: errorInfo.componentStack }));
    console.error(`${error}${errorInfo.componentStack}`);
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public render() {
    return this.state.error ? (
      <MiddleCenterAlignedContainer maxWidth>
        <h2 style={{ color: 'red' }}>Something went wrong!</h2>
        <p>
          <Button color="primary" onClick={() => window.location.reload()}>
            Reload
          </Button>
        </p>
        <p>
          Error at <code>{this.props.location}</code>:
        </p>
        <code>{String(this.state.error)}</code>
        <code style={{ whiteSpace: 'pre-wrap' }}>{this.state.info ?? null}</code>
      </MiddleCenterAlignedContainer>
    ) : (
      this.props.children
    );
  }
}
