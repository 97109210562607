import { Col, Row } from 'reactstrap';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import { TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { StateUpdater } from 'src/app/types/Components';
import FormSection from '../../../../components/Form/FormSection';
import FormSelect from '../../../../components/Form/FormSelect/FormSelect';
import ParkingTicketIcon from '../../../../assets/icons/ParkingTicketIcon';
import MileageExtraIcon from '../../../../assets/icons/MileageExtraIcon';
import { findInOptions } from '../../../../app/helpers/manipulation';
import { ServiceTermsState } from './localTypes';
import useStateWithCallback from '../../../../app/hooks/useStateWithCallback';

export interface ServiceTermsFieldProps {
  initialState: ServiceTermsState;
  stateUpdater: StateUpdater<ServiceTermsState>;
  // TODO add errors
}

const translationArray = ['onboardings'];

function ServiceTermsField(props: ServiceTermsFieldProps) {
  const { initialState, stateUpdater } = props;

  // Utils
  const { t } = useTranslation(translationArray);

  // Form data
  const [minWklyAssignments, setMinWklyAssignments] = useStateWithCallback(
    initialState.minWklyAssignments,
    stateUpdater('minWklyAssignments'),
  );
  const [parkingExtra, setParkingExtra] = useStateWithCallback(initialState.parkingExtra, stateUpdater('parkingExtra'));
  const [parkingTicket, setParkingTicket] = useStateWithCallback(
    initialState.parkingTicket,
    stateUpdater('parkingTicket'),
  );
  const [bonusExtra, setBonusExtra] = useStateWithCallback(initialState.bonusExtra, stateUpdater('bonusExtra'));
  const [mileageExtra, setMileageExtra] = useStateWithCallback(initialState.mileageExtra, stateUpdater('mileageExtra'));
  const [mileageRate, setMileageRate] = useStateWithCallback(initialState.mileageRate, stateUpdater('mileageRate'));

  // Select options
  const minWklyAssignmentOptions = Array.from({ length: 11 }, (_, i) => i);
  const booleanOptions = [
    {
      value: true,
      label: t`yes`,
    },
    {
      value: false,
      label: t`no`,
    },
  ];

  return (
    <FormSection label="Service terms">
      <Row className="mb-2">
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <Typography color="textSecondary" fontSize="small">
                {t`minWeeklyAssignments`}
              </Typography>
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <FormSelect
                noMarginBottom
                disableClearable
                options={minWklyAssignmentOptions}
                value={minWklyAssignments}
                onChange={(_, value) => setMinWklyAssignments(value)}
                getOptionLabel={String}
                renderInput={(inputProps) => <TextField {...inputProps} variant="standard" />}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <DirectionsCarFilledOutlinedIcon />
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <Typography color="textSecondary" fontSize="small">
                {t`parkingExtra`}
              </Typography>
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <FormSelect
                noMarginBottom
                disableClearable
                options={booleanOptions}
                value={findInOptions(booleanOptions, parkingExtra)}
                onChange={(_, option) => setParkingExtra(option.value)}
                renderInput={(inputProps) => <TextField {...inputProps} variant="standard" />}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <ParkingTicketIcon />
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <Typography color="textSecondary" fontSize="small">
                {t`parkingTicket`}
              </Typography>
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <FormSelect
                noMarginBottom
                disableClearable
                options={booleanOptions}
                value={findInOptions(booleanOptions, parkingTicket)}
                onChange={(_, option) => setParkingTicket(option.value)}
                renderInput={(inputProps) => <TextField {...inputProps} variant="standard" />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <Typography color="textSecondary" fontSize="small">
                {t`bonusExtra`}
              </Typography>
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <FormSelect
                noMarginBottom
                disableClearable
                options={booleanOptions}
                value={findInOptions(booleanOptions, bonusExtra)}
                onChange={(_, option) => setBonusExtra(option.value)}
                renderInput={(inputProps) => <TextField {...inputProps} variant="standard" />}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <MileageExtraIcon />
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <Typography color="textSecondary" fontSize="small">
                {t`mileageExtra`}
              </Typography>
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <FormSelect
                noMarginBottom
                disableClearable
                options={booleanOptions}
                value={findInOptions(booleanOptions, mileageExtra)}
                onChange={(_, option) => setMileageExtra(option.value)}
                renderInput={(inputProps) => <TextField {...inputProps} variant="standard" />}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4}>
          <Row className="gx-2 h-100">
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <MonetizationOnOutlinedIcon />
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <Typography color="textSecondary" fontSize="small">
                {t`mileageRate`}
              </Typography>
            </Col>
            <Col xs="auto" className="flex-grow-0 d-flex align-items-center">
              <TextField
                variant="standard"
                value={mileageRate}
                onChange={(e) => setMileageRate(e.target.value)}
                InputProps={{ startAdornment: '$', inputProps: { maxLength: 6 } }}
                sx={{ maxWidth: '4em' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </FormSection>
  );
}

export default ServiceTermsField;
