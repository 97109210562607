import { Button, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { HTMLAttributes, useEffect, useState } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { Box, TextField } from '@mui/material';
import FormWrapper from '../../../../../../components/Form/FormWrapper';
import { Authorizer } from '../localTypes';
import { AUTHORIZATION_STATUSES, COMPANY_TYPES, PayerType, SendMethod } from '../../../../../../app/helpers/enum';
import { flattenContacts, getDataFromFlatContact, userToString } from '../../../../../../app/helpers/mappers';
import { useForm, useStateDict } from '../../../../../../app/hooks';
import { Authorization, FlatContact } from '../../../../../../app/types/Entities';
import {
  AuthorizationsPostError,
  AuthorizationsPostPayload,
  AuthorizationsPostResponse,
  AuthorizationsPutError,
  AuthorizationsPutPayload,
  AuthorizationsPutResponse,
  createAuthorization,
  getCompanyWithRoles,
  getPayers,
  MUTATION_KEYS,
  updateAuthorization,
} from '../../../../../../app/api';
import AuthorizationRequestsList from './AuthorizationRequestsList';
import './AuthorizationsSection.scss';
import { DatabaseId, Errors } from '../../../../../../app/types/DataStructures';
import { ErrorsAlert } from '../../../../../../components/ErrorsAlert';
import { Claim } from '../../CaseField/localTypes';

export interface AuthorizationsSectionProps {
  formPrefix: string;
  claim: Claim | null;
  payerType: PayerType;
  payerCompanyId: DatabaseId | null;
  toggleShowPayerField: (isOpen: boolean) => void;
  onAuthorizationCreated: (authorizationId: DatabaseId) => void;
  authorizations?: Authorization[];
  refetchAuthorizations: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<Authorization[], unknown>>;
  existsApprovedAuthorization: boolean;
}

const translationArray = ['booking', 'collections'];

function AuthorizationsSection(props: AuthorizationsSectionProps) {
  const {
    formPrefix,
    claim,
    payerType,
    payerCompanyId,
    toggleShowPayerField,
    onAuthorizationCreated,
    authorizations,
    refetchAuthorizations,
    existsApprovedAuthorization,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);
  const translateSendMethod = (sendMethod?: SendMethod) => t(`collections:sendMethods.${sendMethod}`);

  const getAuthorizerLabel = (authorizer: Authorizer) =>
    !authorizer._isOverrider
      ? `${userToString(authorizer)} — ${payerType === COMPANY_TYPES.INSURANCE ? 'Adjuster' : 'Agent'}`
      : 'OVERRIDE (Carlos)';

  const formatAuthorizer = (authorizer: Authorizer, boxProps: HTMLAttributes<HTMLLIElement>) => (
    <Box component="li" style={{ fontWeight: authorizer._isOverrider ? 'bold' : undefined }} {...boxProps}>
      {getAuthorizerLabel(authorizer)}
    </Box>
  );

  const formatFlatContact = (flatContact: FlatContact) =>
    t('formatContactVia', {
      contactMethod: translateSendMethod(flatContact.type),
      contactData: getDataFromFlatContact(flatContact),
    });

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<Errors<AuthorizationsPostError | AuthorizationsPutError>>({});

  const [authorizer, setAuthorizer] = useState<Authorizer | null>(null);
  const [contactVia, setContactVia] = useState<FlatContact | null>(null);

  const payerFlatContacts = flattenContacts(authorizer?.contacts);

  const [checkedAuthorizationsIds, setCheckedAuthorizationsIds] = useState<DatabaseId[]>([]);

  // Query
  const [payerCompanyRefetchEnabled, setPayerCompanyRefetchEnabled] = useState<boolean>(true);
  const { data: company, isLoading: isCompanyLoading } = useQuery(
    [MUTATION_KEYS.COMPANIES, payerCompanyId],
    () => (payerCompanyId ? getCompanyWithRoles(payerCompanyId) : Promise.reject()),
    { enabled: payerCompanyRefetchEnabled },
  );

  useEffect(() => {
    if (company) {
      setPayerCompanyRefetchEnabled(false);
    }
  }, [company, payerCompanyId]);

  const overriderFilters = { username: process.env.REACT_APP_AUTHORIZATION_OVERRIDER_USERNAME ?? '' };
  const [overriderRefetchEnabled, setOverriderRefetchEnabled] = useState<boolean>(true);
  const { data: overrider } = useQuery(
    [MUTATION_KEYS.PAYERS, overriderFilters],
    () =>
      getPayers(overriderFilters).then(
        (payers) =>
          payers[0] ??
          Promise.reject(
            new Error(
              `Authorization overrider user not found: ${process.env.REACT_APP_AUTHORIZATION_OVERRIDER_USERNAME}`,
            ),
          ),
      ),
    { enabled: overriderRefetchEnabled },
  );

  useEffect(() => {
    if (overrider) {
      setOverriderRefetchEnabled(false);
    }
  }, [overrider]);

  const authorizers = () => {
    const ret: Authorizer[] = [...(company?.payers ?? [])];
    if (overrider) {
      ret.push({ ...overrider, _isOverrider: true });
    }
    return ret;
  };

  // Mutations
  const { mutateAsync: mutateAsyncCreateAuthorization } = useMutation<
    AuthorizationsPostResponse,
    AuthorizationsPostError,
    AuthorizationsPostPayload
  >(createAuthorization);

  const { mutateAsync: mutateAsyncUpdateAuthorization } = useMutation<
    AuthorizationsPutResponse,
    AuthorizationsPutError,
    Parameters<typeof updateAuthorization>
  >((args) => updateAuthorization(...args));

  // Form functions
  // Validate
  const validate = () => {
    const validationErrors = [];

    if (!claim || claim._newlyCreated) {
      validationErrors.push(t`errors.noClaim`);
    } else if (!payerCompanyId || !claim.eventId) {
      validationErrors.push(t`errors.incompleteClaim`);
    }

    if (!authorizer) {
      validationErrors.push(t`errors.noAdjuster`);
    }
    if (!authorizer?._isOverrider && !contactVia) {
      validationErrors.push(t`errors.noContactVia`);
    }

    if (validationErrors) {
      updateErrors({ validation_errors: validationErrors });
    }

    return validationErrors.length === 0;
  };

  // Send authorization request
  const createAuthorizationRequest = (isAccepted: boolean) => {
    if (!isAccepted && !validate()) {
      return;
    }

    const payload: AuthorizationsPostPayload = {
      authorizer: authorizer!.payer_id!,
      company: payerCompanyId!,
      contact: contactVia?.sourceContactId,
      contact_via: contactVia?.type,
      _events_query: { claim_number: claim!.claimNumber! },
      status: isAccepted ? AUTHORIZATION_STATUSES.ACCEPTED : AUTHORIZATION_STATUSES.PENDING,
    };

    toast
      .promise(mutateAsyncCreateAuthorization(payload), {
        pending: isAccepted
          ? (t`progress.authorizationAccepted.started` as string)
          : (t`progress.authorizationCreate.started` as string),
        error: isAccepted
          ? (t`progress.authorizationAccepted.error` as string)
          : (t`progress.authorizationCreate.error` as string),
        success: isAccepted
          ? (t`progress.authorizationAccepted.success` as string)
          : (t`progress.authorizationCreate.success` as string),
      })
      .catch((err) => {
        // Authorization request creation failed
        setErrors(err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then((authorizationId) => {
        onAuthorizationCreated(authorizationId);
        return refetchAuthorizations();
      });
  };

  // Create authorization override
  const createAuthorizationOverride = () => {
    if (!validate()) {
      return;
    }

    const payload: AuthorizationsPostPayload = {
      authorizer: authorizer!.payer_id!,
      company: payerCompanyId!,
      _events_query: { claim_number: claim!.claimNumber! },
      status: AUTHORIZATION_STATUSES.OVERRIDE,
    };

    toast
      .promise(mutateAsyncCreateAuthorization(payload), {
        pending: t`progress.authorizationOverride.started` as string,
        error: t`progress.authorizationOverride.error` as string,
        success: t`progress.authorizationOverride.success` as string,
      })
      .catch((err) => {
        // Authorization request creation failed
        setErrors(err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then((authorizationId) => {
        onAuthorizationCreated(authorizationId);
        return refetchAuthorizations();
      });
  };

  // Update authorization request
  const updateAuthorizationRequest = (
    authorization: Authorization,
    toastMessages: { pending: string; error: string; success: string } = {
      pending: t`progress.authorizationUpdate.started` as string,
      error: t`progress.authorizationUpdate.error` as string,
      success: t`progress.authorizationUpdate.success` as string,
    },
  ) => {
    if (!authorization.id) {
      const errorMsg = 'This authorization has no ID. Please contact a dev';
      console.error(errorMsg);
      toast.error(errorMsg);
      return;
    }

    const payload: AuthorizationsPutPayload = {
      authorizer: authorization!.authorizer.payer_id!,
      company: authorization!.company.id!,
      contact: authorization!.contact?.id!,
      contact_via: authorization!.contact_via,
      events: authorization!.events.map(({ id }) => id!),
      status: authorization!.status,
    };

    toast
      .promise(mutateAsyncUpdateAuthorization([authorization.id, payload]), toastMessages)
      .catch((err) => {
        // Authorization request update failed
        setErrors(err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => {
        setCheckedAuthorizationsIds([]);
        return refetchAuthorizations();
      });
  };

  // Resend authorization requests
  const resendAuthorizationRequests = () => {
    checkedAuthorizationsIds.forEach((authorizationId) => {
      const currentAuthorization = authorizations?.find((authorization) => authorization.id === authorizationId);

      if (!currentAuthorization) {
        const errorMsg = `Checked authorization with id ${authorizationId} not found. Please contact a dev`;
        console.error(errorMsg);
        toast.error(errorMsg);
        return;
      }

      updateAuthorizationRequest(
        { ...currentAuthorization, status: AUTHORIZATION_STATUSES.PENDING },
        {
          pending: t`progress.authorizationResend.started` as string,
          error: t`progress.authorizationResend.error` as string,
          success: t`progress.authorizationResend.success` as string,
        },
      );
    });
  };

  return (
    <Container className="p-0">
      <Row>
        <Col>
          <h5>{t`authorizer`}</h5>
        </Col>
      </Row>
      <Row className="gx-2">
        <Col xs="12" xxl="6">
          <FormSelect
            id={p`payer-select`}
            options={authorizers()}
            loading={isCompanyLoading}
            value={authorizer}
            onChange={(_event, authorizerOption) => {
              setAuthorizer(authorizerOption);
              if (!authorizerOption || authorizerOption._isOverrider) {
                setContactVia(null);
              }
            }}
            renderInput={(params) => (
              <TextField
                placeholder={payerType === COMPANY_TYPES.INSURANCE ? t`selectAdjuster` : t`selectAgent`}
                {...params}
              />
            )}
            renderOption={(renderOptionProps, authorizerOption) =>
              formatAuthorizer(authorizerOption as Authorizer, renderOptionProps)
            }
            getOptionLabel={(authorizerOption) => getAuthorizerLabel(authorizerOption)}
          />
        </Col>
        <Col xs="12" xxl="6">
          <FormSelect
            id={p`payer-contact-select`}
            options={payerFlatContacts}
            loading={isCompanyLoading}
            value={contactVia}
            onChange={(_event, payerFlatContactOption) => {
              setContactVia(payerFlatContactOption);
            }}
            renderInput={(params) => <TextField placeholder={t`selectContact`} {...params} />}
            getOptionLabel={(payerFlatContactOption) => formatFlatContact(payerFlatContactOption)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormWrapper label={t`authorizationRequests`}>
            <AuthorizationRequestsList
              authorizations={authorizations ?? []}
              checkedAuthorizationsIds={checkedAuthorizationsIds}
              setCheckedAuthorizationsIds={setCheckedAuthorizationsIds}
              updateAuthorization={updateAuthorizationRequest}
            />
          </FormWrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <ErrorsAlert errorsArray={errors.non_field_errors} />
          <ErrorsAlert errorsArray={errors.validation_errors} />
        </Col>
      </Row>

      {existsApprovedAuthorization ? (
        <Row className="mt-4">
          <Col className="text-center">
            <Button className="action-button" color="cancel" onClick={() => toggleShowPayerField(false)}>
              {t`close`}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="mt-4">
          <Col xs={4} className="text-end">
            {checkedAuthorizationsIds.length === 0 ? (
              !authorizer?._isOverrider ? (
                <Button
                  className="action-button"
                  color="submit"
                  onClick={() => createAuthorizationRequest(false)}
                  disabled={!authorizer || !contactVia}
                >
                  {t`sendAuthorizationRequest`}
                </Button>
              ) : (
                <Button className="action-button" color="submit" onClick={createAuthorizationOverride}>
                  {t`createAuthorizationOverride`}
                </Button>
              )
            ) : (
              <Button className="action-button" color="submit" onClick={resendAuthorizationRequests}>
                {t('resendAuthorizationRequest', { count: checkedAuthorizationsIds.length })}
              </Button>
            )}
          </Col>
          <Col className="text-center">
            <Button
              className="action-button"
              color="submit"
              onClick={() => createAuthorizationRequest(true)}
              disabled={!authorizer}
            >
              {t`authorize`}
            </Button>
          </Col>
          <Col>
            <Button className="action-button" color="cancel" onClick={() => toggleShowPayerField(false)}>
              {t`cancel`}
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default AuthorizationsSection;
