import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import FormSelectCreatable from '../../../../../../components/Form/FormSelectCreatable/FormSelectCreatable';
import { Agent } from '../../../../../../app/types/Entities';

export interface CompanyStaffListItemDisplaySelectProps {
  id: string;
  agent: Agent | null;
  agents: Agent[] | undefined | null;
  formatAgentToLabel: (agent: Agent) => string;
  setAgent: (agent: Agent | null) => void;
  setShowStaffInfo: (showStaffInfo: boolean) => void;
  isAgentsLoading: boolean;
  createMode: boolean;
  setCreateMode: (createMode: boolean) => void;
  setFirstName: (firstName: string) => void;
  setLastName: (lastName: string) => void;
}

const translationArray = ['onboardings'];

function CompanyStaffListItemDisplaySelect(props: CompanyStaffListItemDisplaySelectProps) {
  const {
    id,
    agent,
    agents,
    formatAgentToLabel,
    setAgent,
    setShowStaffInfo,
    isAgentsLoading,
    createMode,
    setCreateMode,
    setFirstName,
    setLastName,
  } = props;

  const { t } = useTranslation(translationArray);

  const [shouldUpdateLastName, setShouldUpdateLastName] = useState('');

  const options = agents ?? [];

  const renderInput = (params: any) => <TextField {...params} label={createMode ? t`newStaff` : t`selectStaff`} />;

  const onCreateOption = (newValue: string) => {
    setShowStaffInfo(!createMode);
    setCreateMode(!createMode);

    const spaceIndex = newValue.indexOf(' ');
    const firstWord = newValue.substring(0, spaceIndex);
    const restOfString = newValue.substring(spaceIndex + 1);

    setFirstName(firstWord);
    setShouldUpdateLastName(restOfString);
  };

  useEffect(() => {
    if (shouldUpdateLastName) {
      setLastName(shouldUpdateLastName);
      setShouldUpdateLastName('');
    }
  }, [shouldUpdateLastName, setLastName]);

  const onSelectOption = (option: Agent | null) => setAgent(option);

  const getOptionValue = (option: Agent) => option.id;

  return (
    <FormSelectCreatable<Agent>
      fullWidth
      id={id}
      options={options}
      value={createMode ? null : agent}
      loading={isAgentsLoading}
      disabled={createMode}
      clearOnBlur
      renderInput={renderInput}
      getCreatedOptionLabel={() => t`tooltip.addStaff`}
      getSelectedOptionLabel={formatAgentToLabel}
      onCreateOption={onCreateOption}
      onSelectOption={onSelectOption}
      getOptionValue={getOptionValue}
    />
  );
}

export default CompanyStaffListItemDisplaySelect;
