import { Booking, BookingLight } from '../types/Entities';
import { apiClient, ApiDao, ApiQuery, SEARCH_ENDPOINT } from './index';
import { sanitizeBooking, sanitizeBookingLight } from './config/sanitizers';
import { hydrateBooking } from './config/hydrators';

export interface SearchGetParams {
  first_name?: string;
  last_name?: string;
  date?: string;
  booking_id?: string;
}
export const searchBookingsDao: ApiDao<SearchGetParams, any[]> = (params) => apiClient.get(SEARCH_ENDPOINT, { params });

export const searchBookings: ApiQuery<SearchGetParams, Booking[]> = (params) =>
  searchBookingsDao(params)
    .then((res) => res.data.map((v) => sanitizeBooking(v)))
    .then((dehydratedBookings) => Promise.all(dehydratedBookings.map(hydrateBooking)));

export const searchInterpretations: ApiQuery<SearchGetParams, BookingLight[]> = (params) =>
  searchBookingsDao(params).then((res) => res.data.map((v) => sanitizeBookingLight(v)));
