/* eslint-disable @typescript-eslint/no-use-before-define */
import { DateInput } from '@fullcalendar/react';
import moment from 'moment-timezone';
import { isEmpty, isNil } from 'lodash';
import { Dictionary } from '../types/DataStructures';
import {
  DEFAULT_TIMEZONE,
  ISO8601_DATE_FORMAT_MOMENT,
  PREFERRED_TIME_FORMAT_MOMENT,
  QUERY_PARAM_KEY_SEP,
  SORTER_PICK_LEFT,
  SORTER_PICK_RIGHT,
} from './constants';
import { ValuesOf } from '../types/TypeMappers';
import { FormSelectEntry } from '../types/Components';
import { Category } from '../types/Entities';
import { CategoryPrefix } from './enum';
import { hasCategoryPrefix } from './sanitizers';

/**
 * Functional implementation of assert
 * @param cond
 * @param msg
 * @param Err error constructor
 * @return true if condition was met, raise exception otherwise
 */
export const assert = (cond: boolean, msg?: any, Err: new (message?: string) => Error = Error): true | never => {
  if (!cond) {
    throw new Err(msg);
  }
  return true;
};

/**
 * Function to be fed to Array.filter(), it will all but one entries that coincide in the key
 * @param keyToFilter
 */
export const arrayFilterDuplicates =
  <T>(keyToFilter: keyof T) =>
  (obj: T, index: number, self: T[]) =>
    index === self.findIndex((o) => o[keyToFilter] === obj[keyToFilter]);

/**
 * Removes the element in position i
 * @param array
 * @param i
 * @return copy of array
 */
export const arrayRemoveAt = <T>(array: Array<T>, i: number) => [...array.slice(0, i), ...array.slice(i + 1)];

/**
 * Replaces the element in position i with item
 * @param array
 * @param i
 * @param item
 * @return copy of array
 */
export const arrayReplaceAt = <T>(array: Array<T>, i: number, item: T) => {
  const arr = [...array];
  arr[i] = item;
  return arr;
};

/**
 * Updates the element in position i with the fields provided
 * @param array
 * @param i
 * @param fields
 * @return copy of array
 */
export const arrayUpdateAt = <T>(array: Array<T>, i: number, fields: Partial<T>) =>
  arrayReplaceAt(array, i, { ...array[i], ...fields });

/**
 * Turns a dict of type T to type R, without any type checking (it type asserts to R forcefully)
 * @param dict
 * @param mappedFields dict describing the transformations to be done on the fields:
 * from is the source field, to is the target field to rename from to, conversion is a mapper to convert from's value
 * @param newFields fields to be appended to the resulting dict
 */
export const dictMap = <T extends object = object, R extends object = object>(
  dict: T,
  mappedFields: DictMapFields<T, R>[],
  newFields?: Partial<R>,
): R => {
  let ret: Partial<T & R> = dict;
  mappedFields.forEach(({ from, to = from, conversion = (v) => v }) => {
    const value = conversion(dict[from]);
    ret = {
      ...ret,
      [to]: value,
    };
    if (from !== to) delete ret[from];
  });
  return {
    ...ret,
    ...newFields,
  } as R;
};
export type DictMapFields<T, R> = {
  from: keyof T;
  to?: keyof R;
  conversion?: (value: any) => ValuesOf<R>;
};

/**
 * Filters categories by prefix
 * @param categories categories to filter
 * @param prefix prefix to filter by
 */
export const filterCategoriesByPrefix = (categories: Category[] | undefined, prefix: CategoryPrefix) =>
  categories?.filter((category) => hasCategoryPrefix(category, prefix));

/**
 * Filter tool to filter out duplicates
 * @param comp
 */
export const filterDistinct =
  <T>(comp: (value: T, other: T) => boolean) =>
  (value: T, _: number, arr: T[]) =>
    arr.find((other) => comp(value, other)) === value;

/**
 * Checks whether something is an array
 */
export const isArray = (a: unknown): a is Array<unknown> => a instanceof Array;

/**
 * Checks whether something is a function
 */
export const isCallable = (o: unknown): o is Function => o instanceof Function;

/**
 * Checks if something is an object (and not an array)
 */
export const isObject = (o: unknown): o is Object & Dictionary => !isArray(o) && o instanceof Object;

/**
 * Checks if something is an object and empty
 */
export const isEmptyObject = (o: unknown) => isObject(o) && isEmpty(o);

/**
 * Turns a dictionary into a query params string
 * @param dict
 * @param exactMatch whether the keys are meant to be an exact match
 * @param keyPrefix prefix to prepend to every key
 * @param recursive whether this is a recursive call of the function
 */
export const dictToParams = (
  dict: Dictionary,
  exactMatch?: boolean,
  keyPrefix?: string,
  recursive?: boolean,
): string => {
  const prefix = keyPrefix ? keyPrefix + QUERY_PARAM_KEY_SEP : '';
  const d: Dictionary = !recursive
    ? {
        ...dict,
        _exact_match: !!exactMatch,
      }
    : dict;
  const params = Object.keys(d)
    .filter((k) => d[k] !== null && d[k] !== undefined && d[k] !== '' && !isEmptyObject(d[k]))
    .map((k) => {
      const queryKey = prefix + encodeURIComponent(k);
      if (isObject(d[k])) {
        return dictToParams(d[k], exactMatch, queryKey, true);
      }
      return `${queryKey}=${encodeURIComponent(d[k])}`;
    })
    .join('&');
  return params ? (!recursive ? '?' : '') + params : '';
};

export const findInOptions = <T, Entry extends FormSelectEntry<T> = FormSelectEntry<T>>(
  options: Array<Entry>,
  value: T,
): Entry | undefined => options.find((entry) => entry.value === value);

/**
 * Flattens the values from a dict into an array
 */
export const flattenValues = <T = any>(d: Dictionary<T>): T[] =>
  Object.values(d)
    .map((v) => (isObject(v) ? flattenValues(v) : v))
    .flat();

/**
 * Returns the order corresponding to the values v1 and v2
 * @param v1
 * @param v2
 */
export const orderObjs = (v1: any, v2: any): number =>
  !v2 ? SORTER_PICK_LEFT : !v1 ? SORTER_PICK_RIGHT : String(v1).localeCompare(String(v2), 'en', { numeric: true });

/**
 * Returns the order corresponding to the values v1 and v2
 * @param n1
 * @param n2
 * @param preferenceOnNil where to place nil values
 */
export const orderNumbers = (
  n1: number | undefined | null,
  n2: number | undefined | null,
  preferenceOnNil: typeof SORTER_PICK_LEFT | typeof SORTER_PICK_RIGHT = SORTER_PICK_LEFT,
): number =>
  isNil(n2) ? SORTER_PICK_LEFT * preferenceOnNil : isNil(n1) ? SORTER_PICK_RIGHT * preferenceOnNil : n1 - n2;

/**
 * @param datetimeString ISO8601 UTC date time string
 */
export const parseDateTime = (datetimeString: string) => moment.utc(datetimeString).toDate();

/**
 * @param dateString ISO8601 UTC date string
 */
export const parseDate = (dateString: string) => moment(dateString).toDate();

/**
 * Calls a function asynchronously
 * @param fn
 */
export const runAsync = async <T>(fn: () => T) => fn();

/**
 * @param date
 * @returns ISO8601 UTC datetime string
 */
export const stringifyDateTime = (date: DateInput) => moment(date).utc().toISOString();

/**
 * @param date
 * @returns ISO8601 UTC date string
 */
export const stringifyDate = (date: DateInput) => moment(date).format(ISO8601_DATE_FORMAT_MOMENT);

/**
 * Checks if a date string is in ISO8601 UTC format
 * @param date
 */
export const isDateStringValid = (date: string): boolean => moment(date, moment.ISO_8601, true).isValid();

/**
 * Formats a date to local time in 12 or 24h format
 * @param date
 * @param boolean
 * @returns Local time string
 */
export const formatTime = (date: Date): string => {
  return moment(date).format(PREFERRED_TIME_FORMAT_MOMENT);
};

/**
 * Formats Date to PST
 *  @param date
 *  @param format
 *  @returns PST Date object
 */
export const formatDatePST = (date: Date | undefined, format: string = 'YYYY-MM-DD HH:mm'): Date => {
  return new Date(moment(date).tz(DEFAULT_TIMEZONE).format(format));
};

/**
 * Formats Date to Moment PST
 * @param date
 * @returns PST Moment object
 */
export const formatMomentPST = (date: Date | undefined): moment.Moment => {
  return moment(date).tz(DEFAULT_TIMEZONE);
};

/**
 * Throw an error as expression
 * @param err
 */
export const throwErr = (err: any): never => {
  throw err;
};
