import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getInterpretations, MUTATION_KEYS } from 'src/app/api';
import { DatabaseId } from 'src/app/types/DataStructures';
import {
  createOffer,
  OffersPostError,
  OffersPostPayload,
  OffersPostResponse,
  OffersPutError,
  OffersPutResponse,
  updateOffer,
} from 'src/app/api/offers';
import { TextField } from '@mui/material';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import FormSpan from '../../../../../components/Form/FormSpan';
import { BUTTON_STATE, LANGUAGE_ALPHA3, OFFER_STATUS, OfferStatus } from '../../../../../app/helpers/enum';
import { userToString } from '../../../../../app/helpers/mappers';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { Interpretation, Language, Offer, ServiceRoot } from '../../../../../app/types/Entities';
import { useForm, useStateArray } from '../../../../../app/hooks';
import ButtonWithTooltip from '../../../../../components/ButtonWithTooltip';
import UserDataSection from './UserDataSection';
import { FieldInterpreter } from './localTypes';
import OffersSection from './OffersSection';
import './InterpreterField.scss';
import { ValuesOf } from '../../../../../app/types/TypeMappers';
import { LocalOffer } from './OfferRow';
import { useCacheLanguages } from '../../../../../app/api/cache/hooks';

export interface InterpreterFieldProps {
  showInterpreter: boolean;
  setShowInterpreter: (display: boolean) => void;
  setGetInterpreterSectionOpened?: Dispatch<SetStateAction<() => number>>;
  service: Interpretation | null;
  setService: (service: Interpretation | null) => void;
  targetLanguage: Language | null;
  serviceRoot: ServiceRoot<Interpretation> | null;
  offers: LocalOffer[];
  setOffers: (offers: LocalOffer[]) => void;
  isFromFollowUp?: boolean;
  preloadBookingId?: DatabaseId;

  refetchOffers?: () => void;
  onUpdateSuccess?: (updatedService?: Interpretation) => void;
}

const SECTION_DISPLAY = {
  INTERPRETER_DATA: 0,
  OFFERS: 1,
} as const;

const formPrefix = 'interpreter';
const translationArray = ['booking', 'forms', 'languages'];

function InterpreterField(props: InterpreterFieldProps) {
  const {
    showInterpreter,
    setShowInterpreter,
    setGetInterpreterSectionOpened,
    service,
    setService,
    targetLanguage,
    serviceRoot,
    offers,
    setOffers,
    isFromFollowUp = false,
    preloadBookingId,
    refetchOffers,
    onUpdateSuccess,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [openSection, setOpenSection] = useState<ValuesOf<typeof SECTION_DISPLAY>>(SECTION_DISPLAY.INTERPRETER_DATA);

  const englishLanguage = useCacheLanguages()?.find(({ alpha3 }) => alpha3 === LANGUAGE_ALPHA3.ENGLISH);

  const serviceFilters = {
    source_language_alpha3: englishLanguage?.alpha3 ?? 'fail match',
    target_language_alpha3: targetLanguage?.alpha3 ?? undefined,
    'root.id': serviceRoot?.id,
  };

  const { data: services, refetch: refetchServices } = useQuery(
    [MUTATION_KEYS.SERVICES, serviceFilters],
    async () =>
      (await getInterpretations(serviceFilters)).map((iterService) => ({
        ...iterService,
        provider: {
          ...iterService.provider,
          source: iterService.root,
        },
      })),
    { staleTime: Infinity, enabled: !!targetLanguage && !!serviceRoot },
  );

  const { values: interpreters, set: setInterpreters } = useStateArray<FieldInterpreter>([]);
  const [canSendOffers, setCanSendOffers] = useState<boolean>(true);

  const {
    values: newOffers,
    push: pushNewOffer,
    set: setNewOffers,
    replaceAt: replaceNewOfferAt,
  } = useStateArray<LocalOffer>([]);

  useEffect(() => {
    setGetInterpreterSectionOpened?.(() => () => openSection);
  }, [openSection, setGetInterpreterSectionOpened]);

  useEffect(() => {
    setInterpreters(
      services?.map((iterService) => ({
        ...iterService.provider,
        source: iterService,
      })) ?? [],
    );

    setNewOffers(offers);
  }, [offers, services, setInterpreters, setNewOffers]);

  useEffect(() => {
    services?.forEach((iterService) => {
      if (
        !newOffers.find(
          (newOffer) =>
            newOffer.service.id === iterService.id && newOffer.service.target_language === iterService.target_language,
        )
      ) {
        pushNewOffer({
          status: OFFER_STATUS.NOT_REQUESTED,
          service: iterService,
          checked: false,
        });
      }
    });
  }, [newOffers, pushNewOffer, services]);

  const interpreter = service && {
    ...service.provider,
    source: service,
  };

  const { mutateAsync: mutateAsyncCreateOffer } = useMutation<OffersPostResponse, OffersPostError, OffersPostPayload>(
    createOffer,
  );

  const { mutateAsync: mutateAsyncUpdateOffer } = useMutation<
    OffersPutResponse,
    OffersPutError,
    Parameters<typeof updateOffer>
  >((args) => updateOffer(...args));

  const sendOffers = () => {
    setCanSendOffers(false);

    const offersToSend = newOffers
      .filter(
        (offer) =>
          offer.checked && (offer.status === OFFER_STATUS.NOT_REQUESTED || offer.status === OFFER_STATUS.AVAILABLE),
      )
      .map((offer) => {
        if (offer.status === OFFER_STATUS.NOT_REQUESTED) {
          return {
            ...offer,
            status: OFFER_STATUS.REQUESTED,
            checked: false,
            last_updated_at: new Date(),
          };
        }

        if (offer.status === OFFER_STATUS.AVAILABLE) {
          return {
            ...offer,
            status: OFFER_STATUS.PENDING_OFFER,
            last_updated_at: new Date(),
            checked: false,
          };
        }

        if (!offer.last_updated_at) {
          return {
            ...offer,
            last_updated_at: new Date(),
            checked: false,
          };
        }

        return offer;
      })
      .sort((a, b) => {
        return b.last_updated_at!.getTime() - a.last_updated_at!.getTime();
      });

    if (offersToSend.length > 0) {
      if (preloadBookingId) {
        const offerPetitions = offersToSend.map((offer) => {
          if (!offer.id) {
            return mutateAsyncCreateOffer({
              booking: preloadBookingId,
              service: offer.service.id!,
              status: offer.status!,
            });
          }

          return mutateAsyncUpdateOffer([offer.id, { status: offer.status! }]);
        });

        Promise.allSettled(offerPetitions).then(refetchOffers);
      } else {
        setOffers(offersToSend);
      }
    }

    setCanSendOffers(true);
  };

  const onOfferChange = async (index: number, newOffer: LocalOffer, hasCheckboxChanged: boolean) => {
    if (!preloadBookingId || hasCheckboxChanged) {
      replaceNewOfferAt(index, newOffer);
    } else {
      if (newOffer.id) {
        await mutateAsyncUpdateOffer([newOffer.id, { status: newOffer.status! }]);
      } else {
        await mutateAsyncCreateOffer({
          booking: preloadBookingId,
          service: newOffer.service.id!,
          status: newOffer.status!,
        });
      }
      refetchOffers?.();
    }
  };

  // Formatting
  const formatInterpreterToLabel = (interpreterToFormat: FieldInterpreter) =>
    `${userToString(interpreterToFormat)} - ${serviceRoot?.name} - $${interpreterToFormat.source.bill_amount}`;

  const formatOfferToLabel = (offerToFormat: Offer) => (
    <FormSpan>
      <b>{userToString(offerToFormat.service.provider)} </b> - {offerToFormat.service.target_language.name} -{' '}
      {serviceRoot?.name} - ${offerToFormat.service.bill_amount}
    </FormSpan>
  );

  const translateState = (state: OfferStatus) => {
    switch (state) {
      case OFFER_STATUS.ACCEPTED:
        return t`buttons.accepted`;

      case OFFER_STATUS.REJECTED:
        return t`buttons.rejected`;

      case OFFER_STATUS.PENDING_OFFER:
        return t`buttons.pendingOffer`;

      case OFFER_STATUS.AVAILABLE:
        return t`buttons.available`;

      case OFFER_STATUS.NOT_AVAILABLE:
        return t`buttons.notAvailable`;

      case OFFER_STATUS.REQUESTED:
        return t`buttons.requested`;

      case OFFER_STATUS.CANCELED:
        return t`buttons.canceled`;

      default:
        return t`buttons.notOffered`;
    }
  };

  const calculateState = () => {
    let state: OfferStatus = OFFER_STATUS.NOT_REQUESTED;
    let priority = 0;

    newOffers.forEach((offer) => {
      if (offer.status === OFFER_STATUS.ACCEPTED) {
        state = OFFER_STATUS.ACCEPTED;
        priority = 7;
      } else if (offer.status === OFFER_STATUS.REJECTED && priority < 6) {
        state = OFFER_STATUS.REJECTED;
        priority = 6;
      } else if (offer.status === OFFER_STATUS.PENDING_OFFER && priority < 5) {
        state = OFFER_STATUS.PENDING_OFFER;
        priority = 5;
      } else if (offer.status === OFFER_STATUS.AVAILABLE && priority < 4) {
        state = OFFER_STATUS.AVAILABLE;
        priority = 4;
      } else if (offer.status === OFFER_STATUS.NOT_AVAILABLE && priority < 3) {
        state = OFFER_STATUS.NOT_AVAILABLE;
        priority = 3;
      } else if (offer.status === OFFER_STATUS.REQUESTED && priority < 2) {
        state = OFFER_STATUS.REQUESTED;
        priority = 2;
      } else if (offer.status === OFFER_STATUS.NOT_REQUESTED && priority < 1) {
        state = OFFER_STATUS.NOT_REQUESTED;
        priority = 1;
      } else if (offer.status === OFFER_STATUS.CANCELED && priority <= 0) {
        state = OFFER_STATUS.CANCELED;
        priority = 0;
      }
    });

    return translateState(state);
  };

  // Display
  const label = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: '0 0 auto' }}>
        <h5>{t`interpreter`}</h5>
      </div>
      <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start', paddingLeft: '10px' }}>
        {service === null ? <PersonOffIcon className="list-icon" /> : null}
      </div>
    </div>
  );

  const display = (
    <Row className="gx-2">
      {isFromFollowUp ? (
        <Col>
          <FormSelect
            id={p`interpreter`}
            options={interpreters}
            disabled={!targetLanguage || !serviceRoot}
            value={interpreter}
            onChange={(_event, selectedInterpreterOption) => setService(selectedInterpreterOption?.source ?? null)}
            renderInput={(params) => <TextField placeholder={t`noInterpreterAssigned`} {...params} />}
            getOptionLabel={(selectedInterpreter) => formatInterpreterToLabel(selectedInterpreter)}
          />
        </Col>
      ) : (
        <>
          <Col xs={12} lg={6} xl={12} xxl={6}>
            <FormSelect
              id={p`interpreter`}
              options={interpreters}
              disabled={!targetLanguage || !serviceRoot}
              value={interpreter}
              onChange={(_event, selectedInterpreterOption) => setService(selectedInterpreterOption?.source ?? null)}
              renderInput={(params) => <TextField placeholder={t`noInterpreterAssigned`} {...params} />}
              getOptionLabel={(selectedInterpreter) => formatInterpreterToLabel(selectedInterpreter)}
            />
          </Col>
          <Col xs={4} lg={2} xl={4} xxl={2}>
            <ButtonWithTooltip
              disabled
              id={p`filter-button`}
              className="filter-button"
              sx={{ height: '58px', width: '100%' }}
              color="primary"
              tooltip="Filter button"
              onClick={() => null}
              size="small"
              isIconButton
            >
              <FilterAltIcon fontSize="large" />
            </ButtonWithTooltip>
          </Col>
          <Col xs={8} lg={4} xl={8} xxl={4}>
            <ButtonWithTooltip
              id={p`offer-status-button`}
              fullWidth
              sx={{ height: '58px' }}
              color="primary"
              disabled={!targetLanguage || !serviceRoot || (showInterpreter && openSection === SECTION_DISPLAY.OFFERS)}
              tooltip={t`tooltip.viewInterpreterOffers`}
              onClick={() => {
                setOpenSection(SECTION_DISPLAY.OFFERS);
                setShowInterpreter(true);
              }}
              variant="outlined"
            >
              {calculateState()}
            </ButtonWithTooltip>
          </Col>
        </>
      )}
    </Row>
  );

  const toggleOpen = (isOpen: boolean) => {
    if (!showInterpreter) {
      // View was closed, reset sections
      setOpenSection(SECTION_DISPLAY.INTERPRETER_DATA);
    }
    setShowInterpreter(isOpen);
  };

  const triggerClose = () => setShowInterpreter(false);

  return (
    <FormExpandableField // Interpreter
      formPrefix={p`interpreter`}
      isOpen={showInterpreter}
      toggleOpen={toggleOpen}
      openButtonState={!interpreter && !showInterpreter ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showInterpreter ? t`tooltip.hideInterpreter` : t`tooltip.viewInterpreter`}
      label={label}
      display={display}
      onCancel={triggerClose}
    >
      {openSection === SECTION_DISPLAY.INTERPRETER_DATA ? (
        <UserDataSection
          showInterpreter={showInterpreter}
          triggerClose={triggerClose}
          interpreter={interpreter}
          refetchServices={refetchServices}
          onUpdateSuccess={onUpdateSuccess}
        />
      ) : openSection === SECTION_DISPLAY.OFFERS ? (
        <OffersSection
          offers={newOffers}
          formatOffer={formatOfferToLabel}
          onOfferChange={onOfferChange}
          sendOffers={sendOffers}
          canSendOffers={canSendOffers}
          triggerClose={triggerClose}
          setInterpreter={setService}
          targetLanguage={targetLanguage}
        />
      ) : null}
    </FormExpandableField>
  );
}

export default InterpreterField;
