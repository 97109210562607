import { Autocomplete, AutocompleteProps, FormControl } from '@mui/material';
import { ElementType } from 'react';
import { ChipTypeMap } from '@mui/material/Chip';

export type FormSelectProps<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, false, ChipComponent>, 'options'> & {
  noMarginBottom?: boolean;
  options: readonly T[] | undefined;
};

function FormSelect<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({ noMarginBottom = false, options, value, ...props }: FormSelectProps<T, Multiple, DisableClearable, ChipComponent>) {
  const sanitizedOptions = options ?? [];

  return (
    <FormControl fullWidth sx={{ marginBottom: noMarginBottom ? undefined : 2 }}>
      <Autocomplete autoHighlight options={sanitizedOptions} value={value ?? (null as never)} {...props} />
    </FormControl>
  );
}

export default FormSelect;
