import { EventInput } from '@fullcalendar/react';
import { DEFAULT_BOOKING_STAGE_COLOR } from './constants';
import { COMPANY_TYPES } from './enum';
import { extractBookingFromLightEvent } from './services';
import { LightBEvent, LightService, LightUser } from '../api/config/light_entities';

export const mapLightUserToName = (u: LightUser) => `${u.first_name} ${u.last_name} (${u.username})`;

export const mapLightEventToCalendar = (event: LightBEvent): EventInput => {
  const patientInfo = event.affiliates?.map((a) => mapLightUserToName(a.recipient)).join(', ');
  const clinicInfo = event.booking.companies.find((c) => c.type === COMPANY_TYPES.CLINIC)?.name;
  const service: LightService | undefined = event.booking.services[0];
  const interpreterInfo = service ? mapLightUserToName(service.provider) : 'None';
  const { booking, ...eventWithoutBooking } = event;

  return {
    id: String(event.id),
    start: event.start_at,
    end: event.end_at,
    title: `[Patient] ${patientInfo} - [Clinic] ${clinicInfo} - [Interpreter] ${interpreterInfo}`,
    color: event.booking.cCurrentStatus?.color ?? DEFAULT_BOOKING_STAGE_COLOR,
    // Extended props
    booking: extractBookingFromLightEvent(event),
    eventWithoutBooking,
  };
};
