import { AxiosError } from 'axios';
import { DEFAULT_USERS_LOOKUPS, UsersGetParams } from './users';
import { Recipient } from '../types/Entities';
import { DatabaseId, Errors } from '../types/DataStructures';
import { sanitizeRecipient } from './config/sanitizers';
import { apiClient } from './config/clients';
import { RECIPIENTS_ENDPOINT } from './config/endpoints';
import { ApiQuery, applyLookup, BUSINESS_NAME, businessParam, LookupsMap, pathParam } from './config/helpers';
import { NotePayload } from './payloads';

export type RecipientsGetParams = UsersGetParams;
export const DEFAULT_RECIPIENT_LOOKUPS: LookupsMap<RecipientsGetParams> = DEFAULT_USERS_LOOKUPS;

export const getRecipients: ApiQuery<RecipientsGetParams, Recipient[]> = (
  params,
  lookups = DEFAULT_RECIPIENT_LOOKUPS,
) =>
  apiClient
    .get(RECIPIENTS_ENDPOINT, { params: applyLookup(params, lookups) })
    .then((res) => res.data.map(sanitizeRecipient));

// Patients
export type PatientGetParams = RecipientsGetParams;

// POST
export interface PatientPostPayload {
  user: DatabaseId;
  companies: DatabaseId[];
  notes?: NotePayload[];
}

export type PatientPostResponse = DatabaseId;
export type PatientPostError = Errors<PatientPostPayload>;
export const createPatient = (payload: PatientPostPayload) =>
  apiClient
    .post<PatientPostResponse>(businessParam(RECIPIENTS_ENDPOINT), { ...payload, _business: BUSINESS_NAME })
    .then((res) => res.data)
    .catch((err: AxiosError<PatientPostError>) => Promise.reject(err.response?.data));

// PUT
export type PatientPutPayload = PatientPostPayload;
export type PatientPutResponse = unknown;
export type PatientPutError = Errors<PatientPutPayload>;
export const updatePatient = (patientId: DatabaseId, payload: PatientPutPayload) =>
  apiClient
    .put<PatientPutResponse>(pathParam(RECIPIENTS_ENDPOINT, patientId), {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .catch((err: AxiosError<PatientPutError>) => Promise.reject(err.response?.data));
