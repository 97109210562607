import { useTranslation } from 'react-i18next';
import FormSelect from 'src/components/Form/FormSelectLegacy';
import { FormSelectProps } from 'src/components/Form/FormSelectLegacy/FormSelectLegacy';
import { Category } from 'src/app/types/Entities';
import { useForm } from '../../../../app/hooks';
import { FormGroupProps } from '../../../../app/types/Components';
import { FormInput, FormInputProps } from '../../../../components/Form/FormInput';

export interface ServiceRootFormGroupProps extends FormGroupProps {
  descriptionProps: Partial<FormInputProps>;
  nameProps: Partial<FormInputProps>;
  certificationProps: Partial<FormSelectProps<Category>>;
  modalityProps: Partial<FormSelectProps<Category>>;
}

export function ServiceRootFormGroup({
  formPrefix = 'ServiceRoot',
  descriptionProps,
  nameProps,
  certificationProps,
  modalityProps,
}: ServiceRootFormGroupProps) {
  const { t } = useTranslation('forms');
  const { p } = useForm(formPrefix);

  return (
    <>
      <FormInput id={p`name`} type="text" label={t`name`} required {...nameProps} />
      <FormSelect id={p`certification`} placeholder={t`certification`} {...certificationProps} />
      <FormSelect id={p`modality`} placeholder={t`modality`} {...modalityProps} />
      <FormInput id={p`description`} type="textarea" label={t`description`} required {...descriptionProps} />
    </>
  );
}
