import { Col, Form, Row } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Alert, AlertTitle, Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { flattenContacts, getDataFromFlatContact, unflattenContacts } from 'src/app/helpers/mappers';
import { CompanyType } from 'src/app/helpers/enum';
import currency from 'currency.js';
import { SERVICE_BILL_RATE, SERVICE_BILL_RATE_TYPE } from 'src/app/helpers/constants';
import {
  CompaniesPostPayloadComplex,
  CompaniesPutErrorComplex,
  CompaniesPutPayloadComplex,
  CompaniesPutResponseComplex,
  updateCompanyComplex,
} from 'src/app/api/complex';
import {
  CompaniesPostErrorComplex,
  CompaniesPostResponseComplex,
  MUTATION_KEYS,
  createCompanyComplex,
  getInterpretationRoots,
} from 'src/app/api';
import { RatesPostPayload, RatesPutPayload } from 'src/app/api/rates';
import { useForm, useStateDict } from '../../../../app/hooks';
import { Company, EmailFlatContact, PhoneFlatContact } from '../../../../app/types/Entities';
import { DatabaseId, Errors } from '../../../../app/types/DataStructures';
import useStateWithCallback from '../../../../app/hooks/useStateWithCallback';
import {
  AuthorizationPriorityState,
  CompanyInfoState,
  CompanyNotesState,
  CompanyOnboardingState,
  CompanyRateItem,
  CompanyRateState,
  CompanyStaffItem,
  CompanyStaffState,
  CompanyTermsState,
  InvoicingState,
  PreferredAgencyItem,
  PreferredAgencyState,
} from './localTypes';
import { FormProps, FormSubmitEvent, FormSubmitProps, StateUpdater } from '../../../../app/types/Components';
import { CompaniesPostError } from '../../../../app/api/companies';
import CompanyInfoField from './InfoField/InfoField';
import CompanyNotesField from './NotesField';
import CompanyRateField from './RateField/RateField';
import CompanyStaffField from './StaffField/StaffField';
import CompanyTermsField from './TermsField';
import PreferredAgencyField from './PreferredAgencyField/PreferredAgencyField';
import AuthorizationPriorityField from './AuthorizationPriorityField/AuthorizationPriorityField';
import InvoicingField from './InvoicingField/InvoicingField';

export interface CompanyOnboardingProps extends FormProps, FormSubmitProps<unknown, unknown, unknown> {
  preloadCompany?: Company;
}

const translationArray = ['onboardings'];

export default function CompanyOnboardingForm(props: CompanyOnboardingProps) {
  const { formPrefix, preSubmit, onSubmitSuccess, onSubmitFailure, postSubmit, id, preloadCompany } = props;

  const { t } = useTranslation(translationArray);
  const { p } = useForm(formPrefix ?? '');

  // Data
  const [errors, setErrors] = useStateDict<CompaniesPostError>({});

  // Queries
  const { data: serviceRoots, isLoading: areServiceRootsLoading } = useQuery([MUTATION_KEYS.SERVICE_ROOTS], () =>
    getInterpretationRoots({}),
  );

  // State
  const defaultState: CompanyOnboardingState & { id?: DatabaseId } = {
    id: undefined,
    type: '',
    name: '',
    parentCompany: undefined,
    location: undefined,
    flatContacts: [],
    rates: [],
    preferredAgencies: [],
    notes: [],
    // Staff
    staff: [],
    // Service terms
    minWklyAssignments: 1,
    parkingExtra: false,
    parkingTicket: false,
    bonusExtra: false,
    mileageExtra: false,
    mileageRate: '0.00',
    authorizationPriority: '',
    // Invoicing
    sendInvoiceMethod: '',
    invoiceFormat: '',
    invoiceFrequency: '',
    invoiceGrouping: '',
    invoiceBatchSize: '',
    invoiceDeadline: '',
    invoiceSpecialField: '',
  };

  const formState = useRef<CompanyOnboardingState>(defaultState);
  const formStateUpdater: StateUpdater<CompanyOnboardingState> =
    <K extends keyof CompanyOnboardingState>(key: K) =>
    (value: CompanyOnboardingState[K]) => {
      formState.current[key] = value;
    };

  const [initialState, setInitialState] = useStateWithCallback(defaultState, (state) => {
    formState.current = state;
  });

  const resetState = () => {
    if (preloadCompany === undefined) {
      setInitialState(defaultState);
      return;
    }

    const rates = preloadCompany.rates ?? [];
    const preloadRates: CompanyRateItem[] = rates.map((rate) => ({
      sourceId: rate.id,
      uuid: uuidv4(),
      language: rate.language,
      serviceRoot: rate.root,
      hourRate: rate.bill_amount,
      minHours: Number(currency(rate.bill_min_payment).divide(rate.bill_amount).toString()),
      minMinutes: rate.bill_rate_minutes_threshold ?? 0,
      noShowingFee: rate.bill_no_show_fee,
    }));

    const staff = preloadCompany.agents?.map((agent) => {
      return {
        uuid: agent.id?.toString(),
        agent,
        role: agent.role,
        title: agent.title,
        firstName: agent.first_name,
        lastName: agent.last_name,
        suffix: agent.suffix,
        flatContacts: flattenContacts(agent.contacts),
      } as CompanyStaffItem;
    });

    const state: CompanyOnboardingState & { id?: DatabaseId } = {
      ...defaultState,
      id: preloadCompany.id,
      type: preloadCompany.type,
      name: preloadCompany.name,
      parentCompany: preloadCompany.parent_company,
      location: preloadCompany.locations[0],
      flatContacts: flattenContacts(preloadCompany.contacts),
      rates: preloadRates ?? [],
      notes: preloadCompany.notes ?? [],
      staff: staff ?? [],

      preferredAgencies: preloadCompany.preferred_agencies ?? [],
      authorizationPriority: preloadCompany.authorization_priority ?? defaultState.authorizationPriority,
      // Company Terms
      minWklyAssignments: preloadCompany.min_weekly_assignments ?? defaultState.minWklyAssignments,
      parkingExtra: !!preloadCompany.parking_extra,
      parkingTicket: !!preloadCompany.parking_ticket,
      bonusExtra: !!preloadCompany.bonus_extra,
      mileageExtra: !!preloadCompany.mileage_extra,
      mileageRate: preloadCompany.mileage_rate ?? defaultState.mileageRate,
      // Invoicing
      sendInvoiceMethod: preloadCompany.send_invoice_method ?? defaultState.sendInvoiceMethod,
      invoiceFormat: preloadCompany.invoice_format ?? defaultState.invoiceFormat,
      invoiceGrouping: preloadCompany.invoice_grouping ?? defaultState.invoiceGrouping,
      invoiceBatchSize: preloadCompany.invoice_batch_size ?? defaultState.invoiceBatchSize,
      invoiceDeadline: preloadCompany.invoice_deadline ?? defaultState.invoiceDeadline,
      invoiceFrequency: preloadCompany.invoice_frequency ?? defaultState.invoiceFrequency,
      invoiceSpecialField: preloadCompany.invoice_special_field ?? defaultState.invoiceSpecialField,
    };

    setInitialState(state);
  };

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadCompany]);

  // Fields display
  const [showCompanyInfo, setShowCompanyInfo] = useState<boolean>(false);
  const [showCompanyRateField, setShowCompanyRateField] = useState<boolean>(false);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [showCompanyStaff, setShowCompanyStaff] = useState<boolean>(false);
  const [showPreferredAgencyField, setShowPreferredAgencyField] = useState<boolean>(false);
  const [isInsuranceType, setIsInsuranceType] = useState<boolean>(false);
  const [isEmployerType, setIsEmployerType] = useState<boolean>(false);

  // Mutations
  const { mutateAsync: mutateAsyncCreateCompanyComplex } = useMutation<
    CompaniesPostResponseComplex,
    CompaniesPostErrorComplex,
    CompaniesPostPayloadComplex
  >(createCompanyComplex);

  const { mutateAsync: mutateAsyncUpdateCompanyComplex } = useMutation<
    CompaniesPutResponseComplex,
    CompaniesPutErrorComplex,
    Parameters<typeof updateCompanyComplex>
  >((args) => updateCompanyComplex(...args));

  // Validation
  const getPreferredAgenciesErrors = (
    preferredAgencies: PreferredAgencyItem[],
  ): Errors<PreferredAgencyItem>[] | null => {
    let hasErrors = false;

    const preferredAgencyErrors: Errors<PreferredAgencyItem>[] = preferredAgencies?.map((preferredAgency) => {
      const preferredAgencyItemErrors: Errors<PreferredAgencyItem> = {};

      if (!preferredAgency.company_to) {
        preferredAgencyItemErrors.company_to = t`errors.required`;
      }
      if (!preferredAgency.relationship) {
        preferredAgencyItemErrors.relationship = t`errors.required`;
      }
      if (!isEmpty(preferredAgencyItemErrors)) {
        hasErrors = true;
      }

      return preferredAgencyItemErrors;
    });

    return hasErrors ? preferredAgencyErrors : null;
  };

  const getRatesErrors = (rates: CompanyRateItem[]): Errors<CompanyRateItem>[] | null => {
    let hasErrors = false;

    const rateErrors: Errors<CompanyRateItem>[] = rates?.map((rate) => {
      const rateItemErrors: Errors<CompanyRateItem> = {};

      if (!rate.language) {
        rateItemErrors.language = t`errors.required`;
      }
      if (!rate.serviceRoot) {
        rateItemErrors.serviceRoot = t`errors.required`;
      }
      if (!rate.hourRate) {
        rateItemErrors.hourRate = t`errors.required`;
      }
      if (rate.minHours === '') {
        rateItemErrors.minHours = t`errors.required`;
      }
      if (rate.minMinutes === '') {
        rateItemErrors.minMinutes = t`errors.required`;
      }
      if (!rate.noShowingFee) {
        rateItemErrors.noShowingFee = t`errors.required`;
      }

      if (!isEmpty(rateItemErrors)) {
        hasErrors = true;
      }

      return rateItemErrors;
    });

    return hasErrors ? rateErrors : null;
  };

  const validate = (formData: CompanyOnboardingState, email: string, phone: string) => {
    const newErrors: Errors<CompanyOnboardingState> = {};
    const validationErrors: string[] = [];

    // Flat fields
    if (!formData.name) {
      newErrors.name = t`errors.required`;
    }

    if (!formData.type) {
      newErrors.type = t`errors.required`;
    }

    if (!email && !phone) {
      validationErrors.push(t`errors.emailOrPhoneRequired`);
    }

    // Company rates
    const rateErrors = getRatesErrors(formData.rates);

    if (rateErrors !== null) {
      newErrors.rates = rateErrors;
    }

    // Preferred Agencies
    const preferredAgenciesErrors = getPreferredAgenciesErrors(formData.preferredAgencies);

    if (preferredAgenciesErrors !== null) {
      newErrors.preferredAgencies = preferredAgenciesErrors;
    }

    // Wrap up
    if (validationErrors.length !== 0) {
      newErrors.validation_errors = validationErrors;
    }

    setErrors(newErrors);
    return isEmpty(newErrors);
  };

  // Submission
  const getFormData = () => {
    const formData = formState.current;

    const emailFlatContact = formData.flatContacts.find(({ type, data }) => type === 'email' && data) as
      | EmailFlatContact
      | undefined;

    const email: string = emailFlatContact ? getDataFromFlatContact(emailFlatContact) : '';

    const phoneFlatContact = formData.flatContacts.find(({ type, data }) => type === 'phone' && data) as
      | PhoneFlatContact
      | undefined;

    const phone: string = phoneFlatContact ? getDataFromFlatContact(phoneFlatContact) : '';

    const contacts = unflattenContacts(formData.flatContacts);

    return { formData, email, phone, contacts };
  };

  // Create
  const submitForCreation = (event: FormSubmitEvent) => {
    event.preventDefault();

    if (preloadCompany?.id !== undefined) {
      const msg = 'Tried creating a new company when an ID was provided, please contact a dev';
      toast.error(msg);
      return;
    }

    const { formData, email, phone, contacts } = getFormData();

    if (!validate(formData, email, phone)) {
      return;
    }

    formData.staff = formData.staff.map((staff) => {
      return {
        ...staff,
        firstName: staff.firstName ? staff.firstName : staff!.agent?.first_name ? staff!.agent?.first_name : '',
        lastName: staff.lastName ? staff.lastName : staff!.agent?.last_name ? staff!.agent?.last_name : '',
        suffix: staff.suffix ? staff.suffix : staff!.agent?.suffix ? staff!.agent?.suffix : '',
        title: staff.title ? staff.title : staff!.agent?.title ? staff!.agent?.title : '',
        agent_id: staff.agent?.id || null,
        contacts: unflattenContacts(staff.flatContacts),
      };
    });

    const ratesPayload: RatesPostPayload[] = formData.rates!.map((rate) => ({
      bill_amount: rate.hourRate,
      bill_min_payment: currency(rate.hourRate).multiply(rate.minHours).toString(),
      bill_no_show_fee: rate.noShowingFee,
      bill_rate: SERVICE_BILL_RATE,
      bill_rate_type: SERVICE_BILL_RATE_TYPE,
      bill_rate_minutes_threshold: rate.minMinutes || 0,
      root: rate.serviceRoot!.id,
      language: rate.language,
    }));

    const payload: CompaniesPostPayloadComplex = {
      name: formData.name,
      type: formData.type as CompanyType,
      parent_company: formData.parentCompany?.id ?? null,
      locations: formData.location ? [formData.location] : [],
      contacts,
      notes: formData.notes,
      send_method: 'email',
      on_hold: false,
      _agents_data: formData.staff,
      _rates_datalist: ratesPayload,

      // Company Terms
      min_weekly_assignments: formData.minWklyAssignments,
      parking_extra: formData.parkingExtra,
      parking_ticket: formData.parkingTicket,
      bonus_extra: formData.bonusExtra,
      mileage_extra: formData.mileageExtra,
      mileage_rate: formData.mileageRate,
      _preferred_agency_data: formData.preferredAgencies,

      authorization_priority: formData.authorizationPriority,

      // Invoicing
      send_invoice_method: formData.sendInvoiceMethod,
      invoice_format: formData.invoiceFormat,
      invoice_grouping: formData.invoiceGrouping,
      invoice_batch_size: formData.invoiceBatchSize,
      invoice_deadline: formData.invoiceDeadline,
      invoice_frequency: formData.invoiceFrequency,
      invoice_special_field: formData.invoiceSpecialField,
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    // Display toast for the creation process
    toast
      .promise(mutateAsyncCreateCompanyComplex(payload), {
        pending: t`progress.companyCreate.started` as string,
        success: t`progress.companyCreate.success` as string,
        error: t`progress.companyCreate.error` as string,
      })
      .catch((err: CompaniesPostError) => {
        console.error(`Errors when creating company "${formData.name}"`, err);
        setErrors(err);
        onSubmitFailure?.(err, payload);
        return Promise.reject(); // Stop promise chain execution
      })
      .then((company_id) => onSubmitSuccess?.(company_id, payload))
      .finally(postSubmit);
  };

  // Update
  const submitForUpdate = (event: FormSubmitEvent) => {
    event.preventDefault();

    if (preloadCompany === undefined || preloadCompany.id === undefined) {
      const msg = 'Tried updating an company but no ID was provided, please contact a dev';
      console.error(msg);
      toast.error(msg);
      return;
    }

    const { formData, email, phone, contacts } = getFormData();

    if (!validate(formData, email, phone)) {
      return;
    }

    formData.staff = formData.staff.map((staff) => {
      return {
        ...staff,
        firstName: staff.firstName ? staff.firstName : staff!.agent?.first_name ? staff!.agent?.first_name : '',
        lastName: staff.lastName ? staff.lastName : staff!.agent?.last_name ? staff!.agent?.last_name : '',
        suffix: staff.suffix ? staff.suffix : staff!.agent?.suffix ? staff!.agent?.suffix : '',
        title: staff.title ? staff.title : staff!.agent?.title ? staff!.agent?.title : '',
        agent_id: staff.agent?.id || null,
        contacts: unflattenContacts(staff.flatContacts),
      };
    });

    const ratesPayload: RatesPutPayload[] = formData.rates!.map((rate) => ({
      id: rate.sourceId,
      bill_amount: rate.hourRate,
      bill_min_payment: currency(rate.hourRate).multiply(rate.minHours).toString(),
      bill_no_show_fee: rate.noShowingFee,
      bill_rate: SERVICE_BILL_RATE,
      bill_rate_type: SERVICE_BILL_RATE_TYPE,
      bill_rate_minutes_threshold: rate.minMinutes || 0,
      root: rate.serviceRoot!.id,
      language: rate.language,
    }));

    const payload: CompaniesPutPayloadComplex = {
      name: formData.name,
      type: formData.type as CompanyType,
      parent_company: formData.parentCompany?.id ?? null,
      locations: formData.location ? [formData.location] : [],
      contacts,
      notes: formData.notes,
      send_method: 'email',
      on_hold: false,
      min_weekly_assignments: formData.minWklyAssignments,
      parking_extra: formData.parkingExtra,
      parking_ticket: formData.parkingTicket,
      bonus_extra: formData.bonusExtra,
      mileage_extra: formData.mileageExtra,
      mileage_rate: formData.mileageRate,

      _agents_data: formData.staff,
      _rates_datalist: ratesPayload,
      _preferred_agency_data: formData.preferredAgencies,

      authorization_priority: formData.authorizationPriority,

      // Invoicing
      send_invoice_method: formData.sendInvoiceMethod,
      invoice_format: formData.invoiceFormat,
      invoice_grouping: formData.invoiceGrouping,
      invoice_batch_size: formData.invoiceBatchSize,
      invoice_deadline: formData.invoiceDeadline,
      invoice_frequency: formData.invoiceFrequency,
      invoice_special_field: formData.invoiceSpecialField,
    };

    if (preSubmit && !preSubmit(payload)) {
      return;
    }

    // Display toast for the update process
    toast
      .promise(mutateAsyncUpdateCompanyComplex([preloadCompany.id, payload]), {
        pending: t`progress.companyUpdate.started` as string,
        success: t`progress.companyUpdate.success` as string,
        error: t`progress.companyUpdate.error` as string,
      })
      .catch((err: CompaniesPostError) => {
        console.error(`Errors when updating company "${preloadCompany.name}"`, err);
        setErrors(err);
        onSubmitFailure?.(err, payload);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => onSubmitSuccess?.(preloadCompany, payload))
      .finally(postSubmit);
  };

  const onSubmit = (event: FormSubmitEvent) =>
    preloadCompany === undefined ? submitForCreation(event) : submitForUpdate(event);

  return (
    <Form id={id} key={initialState.id} onSubmit={onSubmit}>
      <Row>
        {!isEmpty(errors) ? (
          <Alert severity="error">
            <AlertTitle>
              There's errors{' '}
              <Button variant="text" onClick={() => setErrors({})}>
                Clear
              </Button>
            </AlertTitle>
            <ul>
              {Object.entries(errors).map(([k, v]) => (
                <li key={k}>
                  {k}: {JSON.stringify(v)}
                </li>
              ))}
            </ul>
          </Alert>
        ) : null}
      </Row>

      <Row>
        <Col xl={6}>
          <CompanyInfoField
            formPrefix={p`company-info`}
            showCompanyInfo={showCompanyInfo}
            setShowCompanyInfo={setShowCompanyInfo}
            setIsInsuranceType={setIsInsuranceType}
            setIsEmployerType={setIsEmployerType}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<CompanyInfoState>}
            errors={errors}
          />

          <CompanyRateField
            formPrefix={p`companyRate`}
            showCompanyRateField={showCompanyRateField}
            setShowCompanyRateField={setShowCompanyRateField}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<CompanyRateState>}
            serviceRoots={serviceRoots}
            areServiceRootsLoading={areServiceRootsLoading}
            showAllLanguages
          />

          <CompanyTermsField
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<CompanyTermsState>}
          />

          {!isEmployerType && (
            <InvoicingField
              initialState={initialState}
              stateUpdater={formStateUpdater as StateUpdater<InvoicingState>}
            />
          )}
          {isInsuranceType && (
            <>
              <AuthorizationPriorityField
                initialState={initialState}
                stateUpdater={formStateUpdater as StateUpdater<AuthorizationPriorityState>}
              />
              <PreferredAgencyField
                formPrefix={p`preferredAgency`}
                showPreferredAgencyField={showPreferredAgencyField}
                setShowPreferredAgencyField={setShowPreferredAgencyField}
                initialState={initialState}
                stateUpdater={formStateUpdater as StateUpdater<PreferredAgencyState>}
              />
            </>
          )}

          <CompanyNotesField
            formPrefix={p`notes`}
            showNotesField={showNotes}
            setShowNotesField={setShowNotes}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<CompanyNotesState>}
          />
        </Col>

        <Col xl={6}>
          <CompanyStaffField
            formPrefix={p`staff`}
            showCompanyStaff={showCompanyStaff}
            setShowCompanyStaff={setShowCompanyStaff}
            companyInfoState={initialState}
            initialState={initialState}
            stateUpdater={formStateUpdater as StateUpdater<CompanyStaffState>}
          />
        </Col>
      </Row>
    </Form>
  );
}
