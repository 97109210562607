import { useTranslation } from 'react-i18next';
import { Lawfirm, Payer } from '../../../../../../app/types/Entities';
import GenericPayerField, { GenericPayerFieldTranslationProps } from '../GenericPayerField';

export interface LawyerFieldProps {
  formPrefix: string;
  optional?: boolean;

  isDisabled: boolean;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;

  lawyer: Payer | null;
  setLawyer: (agent: Payer | null) => void;

  lawyerList: Payer[] | undefined;
  isLoading: boolean;
  refetchLawyers: () => Promise<{ data: Payer[] | undefined }>;

  lawfirm: Lawfirm | null;

  onUpdateSuccess?: (updatedLawyer: Payer | null) => void;
}

export default function LawyerField(props: LawyerFieldProps) {
  const {
    formPrefix,
    optional,
    isDisabled,
    isOpen,
    toggleOpen,
    lawyer,
    setLawyer,
    lawyerList,
    isLoading,
    refetchLawyers,
    lawfirm,
    onUpdateSuccess,
  } = props;

  const { t } = useTranslation('booking');

  const translations: GenericPayerFieldTranslationProps = {
    firstName: t`firstName`,
    lastName: t`lastName`,
    suffix: t`suffix`,
    title: t`title`,
    errors: {
      noFirstName: t`errors.noFirstName`,
      noLastName: t`errors.noLastName`,
      expectedPayerId: t`errors.expectedPayerId`,
      expectedCompanyId: t`errors.expectedCompanyId`,
    },
    progress: {
      payerCreate: {
        started: t`progress.lawyerCreate.started`,
        success: t`progress.lawyerCreate.success`,
        error: t`progress.lawyerCreate.error`,
      },
      payerUpdate: {
        started: t`progress.lawyerUpdate.started`,
        success: t`progress.lawyerUpdate.success`,
        error: t`progress.lawyerUpdate.error`,
      },
    },
    tooltip: {
      cancelAddPayer: t`tooltip.cancelAddLawyer`,
      addPayer: t`tooltip.addLawyer`,
      hidePayer: t`tooltip.hideLawyer`,
      viewPayer: t`tooltip.viewLawyer`,
    },
    selectPayer: t`selectLawyer`,
    savePayer: t`saveLawyer`,
    cancel: t`cancel`,
    requiredFieldsHelp: t`requiredFieldsHelp`,
    payer: t`lawyer`,
    contactInformation: t`contactInformation`,
  };

  return (
    <GenericPayerField
      formPrefix={formPrefix}
      optional={optional}
      isDisabled={isDisabled}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      payer={lawyer}
      setPayer={setLawyer}
      payerList={lawyerList}
      isLoading={isLoading}
      refetchPayers={refetchLawyers}
      company={lawfirm}
      onUpdateSuccess={onUpdateSuccess}
      translations={translations}
    />
  );
}
