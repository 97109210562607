import './FormInput.scss';
import { inputLabelClasses, TextField, useTheme } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import classNames from 'classnames';
import * as React from 'react';

export interface FormInputProps extends Omit<TextFieldProps, 'variant'> {
  help?: string;
  hidden?: boolean;
  errors?: string[];
  maxLength?: number;
  invalid?: boolean;
  readOnly?: boolean;
  min?: string | number;
  max?: string | number;
  pattern?: string;
  startAdornment?: React.ReactNode;
}

export function FormInput({
  disabled,
  help,
  hidden = false,
  readOnly,
  maxLength,
  invalid,
  min,
  max,
  errors,
  inputMode,
  pattern,
  startAdornment,
  ...props
}: FormInputProps) {
  const theme = useTheme();

  const hasErrors = (errors && (errors.length ?? 0) > 0) || invalid;
  const errorsText = errors?.join(', ');
  const helperText = (hasErrors && errorsText) || help;

  return !hidden ? (
    <TextField
      fullWidth
      className={classNames('form-input', { 'form-input-disabled': disabled })}
      disabled={disabled}
      variant="filled"
      helperText={helperText}
      error={hasErrors}
      InputProps={{
        startAdornment,
        sx: {
          backgroundColor: 'transparent',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',

          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },

          '&.Mui-disabled': {
            backgroundColor: '#e9ecef',
            borderColor: '#ced4da',
            color: '#ced4da',
          },

          '&.Mui-error': {
            borderColor: theme.palette.error.main,
          },

          '&.Mui-focused': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.primary.main,
          },

          '&:before, &:after': {
            display: 'none',
          },
        },
        inputProps: {
          readOnly,
          maxLength,
          min,
          max,
          inputMode,
          pattern,
        },
      }}
      InputLabelProps={{
        sx: {
          [`&.${inputLabelClasses.shrink}:not(.Mui-disabled)`]: {
            // set the color of the label when shrunk (usually when the TextField is focused)
            color: theme.palette.primary.light,
          },
        },
      }}
      {...props}
    />
  ) : null;
}
