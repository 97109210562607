import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Container, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { GridColDef, GridRowParams, GridRowsProp } from '@mui/x-data-grid';
import { CircularProgress, Typography } from '@mui/material';
import { Company } from 'src/app/types/Entities';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import 'src/assets/styles/Tables.scss';
import { isEmpty } from 'lodash';
import { FormTable } from '../../../components/Form/FormTable/FormTable';
import { MUTATION_KEYS, CompaniesGetParams, getCompaniesPaginated } from '../../../app/api';
import { useStateDict } from '../../../app/hooks';
import CompanyOnboardingForm from './Onboarding/Form';
import TableSearchBar from '../TableSearchBar/TableSearchBar';

const translationArray = ['tables', 'collections'];

export default function CompaniesTable() {
  const { t } = useTranslation(translationArray);

  // Pagination

  const [paginationModel, setPaginationModel] = useState<{ page: number; pageSize: number }>({ page: 0, pageSize: 25 });

  const [queryFilters, setQueryFilters] = useStateDict<CompaniesGetParams>({
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    _include_roles: true,
  });
  useEffect(() => {
    setQueryFilters({
      ...queryFilters,
      page: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel, setQueryFilters]);

  // SearchBar
  const [companiesSearchParams, setCompaniesSearchParams, updateCompaniesSearchParams] =
    useStateDict<CompaniesGetParams>({});
  const [results, setResults] = useState<Company[] | null>(null);

  const handleResultsChange = (data: any) => {
    setResults(data);
  };

  const transformedResults = () =>
    results?.map((company): any => {
      const phone = company.contacts?.find((contact) => contact.phone)?.phone;
      const email = company.contacts?.find((contact) => contact.email)?.email;

      return {
        ...company,
        phone,
        email,
      };
    }) ?? [];

  const noSearchParams = () => {
    return isEmpty(companiesSearchParams.name);
  };

  // Query
  const {
    data: companiesPaginatedData,
    isLoading,
    refetch: refetchCompaniesPaginatedData,
  } = useQuery([MUTATION_KEYS.COMPANIES, queryFilters], () => getCompaniesPaginated(queryFilters));
  const filteredCompanies =
    companiesPaginatedData?.results?.map((company): any => {
      const phone = company.contacts?.find((contact) => contact.phone)?.phone;
      const email = company.contacts?.find((contact) => contact.email)?.email;

      return {
        ...company,
        phone,
        email,
      };
    }) ?? [];

  const [rowCountState, setRowCountState] = useState(companiesPaginatedData?.count || 0);
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      companiesPaginatedData?.count !== undefined ? companiesPaginatedData.count : prevRowCountState,
    );
  }, [companiesPaginatedData?.count, setRowCountState]);

  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>();
  const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false);
  const [companyPreloadStatus, setPreloadStatus] = useState<'loading' | 'success' | 'error' | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const hideModal = () => {
    setShowCompanyModal(false);
    setSelectedCompany(undefined);
    setPreloadStatus(undefined);
  };

  const rows: GridRowsProp = (noSearchParams() ? filteredCompanies : transformedResults()).map((company) => {
    return {
      id: company.id,
      name: company.name,
      type: t(`collections:companyTypes.${company.type}`),
      phone: company.phone,
      email: company.email,
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t`company.name`,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t`company.type`,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: t`company.phone`,
      flex: 1,
    },
    {
      field: 'email',
      headerName: t`company.email`,
      flex: 1,
    },
  ];

  return (
    <>
      <Container className="w-100 h-100 mw-100">
        <Row className="w-100">
          <Col className="d-flex align-items-center">
            <TableSearchBar
              onResultsChange={handleResultsChange}
              placeholderOne={t('company.filterName')}
              queryType="companies"
              companiesSearchParams={companiesSearchParams}
              setCompaniesSearchParams={setCompaniesSearchParams}
              updateCompaniesSearchParams={updateCompaniesSearchParams}
            />
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <Typography className="me-4">
              {t('company.totalCompanies', { companies: companiesPaginatedData?.count })}
            </Typography>

            <Button
              color="light"
              className="my-3 Table-Add-Button"
              onClick={() => {
                setSelectedCompany(undefined);
                setShowCompanyModal(true);
                setPreloadStatus(undefined);
              }}
            >
              <AddIcon fontSize="large" />
            </Button>
          </Col>
        </Row>

        <Row className="w-100 h-100">
          <Col className="d-flex flex-column">
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  flexDirection: 'column',
                }}
              >
                <CircularProgress size="4rem" sx={{ color: '#00A2B8' }} />
                <span>
                  <b>{t`loadingTable`}</b>
                </span>
              </div>
            ) : (
              <FormTable
                rows={rows}
                rowCount={rowCountState}
                columns={columns}
                sx={{ backgroundColor: 'white', minHeight: '200px' }}
                onRowClick={(params: GridRowParams) => {
                  const companyId = params.row.id;
                  const company = companiesPaginatedData?.results.find(({ id }) => id === companyId);
                  setSelectedCompany(company);
                  setShowCompanyModal(true);
                  if (companyPreloadStatus === undefined) {
                    setPreloadStatus('loading');
                  }
                }}
                paginationModel={paginationModel}
                paginationMode="server"
                setPaginationModel={setPaginationModel}
              />
            )}
          </Col>
        </Row>
      </Container>

      <Modal fullscreen className="admin-modal" isOpen={showCompanyModal} toggle={hideModal}>
        <ModalHeader className="admin-modal-header">
          <Row className="align-items-center">
            <Col>
              <Button
                className="action-button"
                color="submit"
                disabled={isSubmitting}
                form="interpreter-onboarding-form"
              >
                {isSubmitting ? <Spinner className="me-2" size="sm" type="border" color="primary" /> : t`save`}
              </Button>
            </Col>

            <Col xs="auto">
              <Button color="transparent" disabled={isSubmitting} onClick={hideModal}>
                <CloseIcon fontSize="large" style={{ fill: 'white' }} />
              </Button>
            </Col>
          </Row>
        </ModalHeader>

        <ModalBody className="admin-modal-body">
          <div className="admin-modal-content-header">
            <Row className="align-items-center">
              <Col xs="auto">{selectedCompany !== undefined ? selectedCompany.name : t('company.modalTitleNew')}</Col>
            </Row>
          </div>
          <CompanyOnboardingForm
            id="interpreter-onboarding-form"
            preloadCompany={selectedCompany ?? undefined}
            preSubmit={() => {
              setIsSubmitting(true);
              return true;
            }}
            onSubmitSuccess={() => {
              refetchCompaniesPaginatedData().then(hideModal);
            }}
            postSubmit={() => {
              setIsSubmitting(false);
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
