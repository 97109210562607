import { Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm } from '../../../../../../app/hooks';
import AgencyField from './AgencyField';
import { getAgenciesWithRoles, MUTATION_KEYS } from '../../../../../../app/api';
import { COMPANY_TYPES } from '../../../../../../app/helpers/enum';
import { Agency } from '../../../../../../app/types/Entities';
import AgentField from './AgentField';
import { PayerSectionProps } from '../localTypes';

export type AgencySectionProps = PayerSectionProps;

function AgencySection(props: AgencySectionProps) {
  const { formPrefix, payer, setPayer, payerCompany, setPayerCompany, initialPayerFieldState } = props;

  // Utils
  const { p } = useForm(formPrefix);

  // Form data
  const [showAgency, setShowAgency] = useState<boolean>(false);
  const [showAgent, setShowAgent] = useState<boolean>(false);

  // Query
  const [agenciesRefetchEnabled, setAgenciesRefetchEnabled] = useState<boolean>(true);
  const {
    data: agencies,
    isLoading: isAgencyLoading,
    refetch: refetchAgencies,
  } = useQuery([MUTATION_KEYS.COMPANIES, { type: COMPANY_TYPES.AGENCY }], () => getAgenciesWithRoles({}), {
    enabled: agenciesRefetchEnabled,
  });

  useEffect(() => {
    if (agencies) {
      setAgenciesRefetchEnabled(false);
    }
  }, [agencies]);

  const agents = payerCompany?.payers ?? [];

  // Handle fields visibility
  useEffect(() => {
    if (!payerCompany && initialPayerFieldState && payerCompany !== initialPayerFieldState.payerCompany) {
      setPayer(null);
      setShowAgent(false);
    }
  }, [initialPayerFieldState, payerCompany, setPayer]);

  return (
    <Container className="p-0">
      <Row>
        <Col>
          <AgencyField
            formPrefix={p`agency`}
            isOpen={showAgency}
            toggleOpen={setShowAgency}
            agency={payerCompany as Agency | null}
            setAgency={setPayerCompany}
            agencyList={agencies}
            isLoading={isAgencyLoading}
            refetchAgencies={refetchAgencies}
            onUpdateSuccess={(newAgency) => {
              setPayerCompany(newAgency);
              setShowAgency(false);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AgentField
            formPrefix={p`agent`}
            isDisabled={!payerCompany}
            isOpen={showAgent}
            toggleOpen={setShowAgent}
            agent={payer}
            setAgent={setPayer}
            agentList={agents}
            isLoading={isAgencyLoading}
            refetchAgents={() =>
              refetchAgencies().then(({ data: newAgencies = [] }) => ({
                data: newAgencies.flatMap(({ payers }) => payers ?? []),
              }))
            }
            agency={payerCompany as Agency | null}
            onUpdateSuccess={(newAgent) => {
              setPayer(newAgent);
              setShowAgent(false);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AgencySection;
