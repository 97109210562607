import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FormGroupProps } from '../../../app/types/Components';
import { useForm } from '../../../app/hooks';
import { FormInput, FormInputProps } from '../FormInput';

export interface LocationFormGroupProps extends FormGroupProps {
  addressProps: Partial<FormInputProps>;
  cityProps: Partial<FormInputProps>;
  stateProps: Partial<FormInputProps>;
  countryProps: Partial<FormInputProps>;
  zipProps: Partial<FormInputProps>;
}

export default function LocationFormGroup(props: LocationFormGroupProps) {
  const { formPrefix = 'location', addressProps, cityProps, stateProps, countryProps, zipProps } = props;

  const { t } = useTranslation('forms');
  const { p } = useForm(formPrefix);

  return (
    <>
      <FormInput id={p`address`} name={p`address`} type="text" label={t`address`} {...addressProps} />
      <Row>
        <Col>
          <FormInput id={p`city`} name={p`city`} type="text" label={t`city`} {...cityProps} />
        </Col>
        <Col>
          <FormInput id={p`state`} name={p`state`} type="text" label={t`state`} {...stateProps} />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput id={p`country`} name={p`country`} type="text" label={t`country`} {...countryProps} />
        </Col>
        <Col>
          <FormInput id={p`zip`} name={p`zip`} type="text" label={t`zipCode`} maxLength={12} {...zipProps} />
        </Col>
      </Row>
    </>
  );
}
