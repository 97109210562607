import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FormGroupProps } from '../../../app/types/Components';
import { useForm } from '../../../app/hooks';
import { FormInput, FormInputProps } from '../FormInput';

export interface PersonFormGroupProps extends FormGroupProps {
  firstNameProps: Partial<FormInputProps>;
  lastNameProps: Partial<FormInputProps>;
}

export default function PersonFormGroup(props: PersonFormGroupProps) {
  const { formPrefix = 'person', firstNameProps, lastNameProps } = props;

  const { t } = useTranslation('forms');
  const { p } = useForm(formPrefix);

  return (
    <Row>
      <Col>
        <FormInput id={p`firstName`} name={p`firstName`} type="text" label={t`firstName`} {...firstNameProps} />
      </Col>
      <Col>
        <FormInput id={p`lastName`} name={p`lastName`} type="text" label={t`lastName`} {...lastNameProps} />
      </Col>
    </Row>
  );
}
