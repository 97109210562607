import moment from 'moment-timezone';
import './NowIndicator.scss';
import { DEFAULT_TIMEZONE } from 'src/app/helpers/constants';

function NowIndicator() {
  const now = new Date();
  const currentTime = moment(now).tz(DEFAULT_TIMEZONE).format('h:mm A');

  return (
    <div>
      <div className="now-indicator" />
      <div className="time-text">{currentTime}</div>
    </div>
  );
}
export default NowIndicator;
