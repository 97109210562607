/* eslint-disable no-console */
import { isObject, isArray, isNil } from 'lodash';
import { Company, ParentCompany, User } from 'src/app/types/Entities';
import { parseDate, isDateStringValid, parseDateTime } from 'src/app/helpers/manipulation';
import validatorStatus from 'src/app/helpers/statusStructure';
import {
  LightAffiliation,
  LightAgent,
  LightBEvent,
  LightBooking,
  LightRecipient,
  LightRequester,
  LightService,
  LightUser,
} from './light_entities';
import {
  Sanitizer,
  SanitizerWithArgs,
  sanitizeContact,
  sanitizeLocation,
  sanitizeNote,
  sanitizeShallowAuthorization,
} from './sanitizers';
import {
  invokeMap,
  invokeOnNotNil,
  isFromEnum,
  isNotNil,
  isNumeric,
  sanitize,
  sanitizeId,
  sanitizeOrOmit,
} from '../../helpers/sanitizers';
import {
  TypesOfAppointments,
  TYPES_OF_APPOINTMENTS,
  PayerType,
  PAYER_TYPES,
  CompanyType,
  SendMethod,
} from '../../helpers/enum';
import { DehydratedLightEvent } from './dehydrated_light_entities';

export const sanitizeLightUser: SanitizerWithArgs<LightUser> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('User: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    user_id: sanitizeOrOmit(data, omit, 'user_id', Number),
    username: sanitizeOrOmit(data, omit, 'username', String, isNotNil),
    first_name: sanitizeOrOmit(data, omit, 'first_name', String),
    last_name: sanitizeOrOmit(data, omit, 'last_name', String),
    date_of_birth: sanitizeOrOmit(
      data,
      omit,
      'date_of_birth',
      invokeOnNotNil(parseDate),
      (v) => isNil(v) || isDateStringValid(v),
    ),
    is_operator: sanitizeOrOmit(data, omit, 'is_operator', Boolean),
    is_provider: sanitizeOrOmit(data, omit, 'is_provider', Boolean),
    is_recipient: sanitizeOrOmit(data, omit, 'is_recipient', Boolean),
    is_requester: sanitizeOrOmit(data, omit, 'is_requester', Boolean),
    is_payer: sanitizeOrOmit(data, omit, 'is_payer', Boolean),
    is_admin: sanitizeOrOmit(data, omit, 'is_admin', Boolean),
  };
};

export const sanitizeLightBaseCompany: Sanitizer<ParentCompany> = (data) => {
  if (!isObject(data)) {
    console.error('Company: invalid data type');
  }
  return {
    contacts: sanitize(data, 'contacts', invokeOnNotNil(invokeMap(sanitizeContact))),
    id: sanitizeId(data),
    locations: sanitize(data, 'locations', invokeOnNotNil(invokeMap(sanitizeLocation))),
    name: sanitize(data, 'name', String, isNotNil),
    notes: sanitize(data, 'notes', invokeOnNotNil(invokeMap(sanitizeNote))),
    on_hold: sanitize(data, 'on_hold', Boolean),
    send_method: sanitize(data, 'send_method', (v) => String(v) as SendMethod),
    type: sanitize(data, 'type', (v) => String(v) as CompanyType),
  };
};

export const sanitizeLightCompany: Sanitizer<Company> = (data) => {
  if (!isObject(data)) {
    console.error('Company: invalid data type');
  }
  return {
    ...sanitizeLightBaseCompany(data),
    parent_company: sanitize(data, 'parent_company', invokeOnNotNil(sanitizeLightBaseCompany)),
  };
};

export const sanitizeLightProvider: Sanitizer<LightUser> = (data) => {
  if (!isObject(data)) {
    console.error('Provider: invalid data type');
  }
  return {
    ...sanitizeLightUser(data),
  };
};

export const sanitizeLightService: SanitizerWithArgs<LightService> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Service: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    provider: sanitizeOrOmit(data, omit, 'provider', sanitizeLightProvider),
  };
};

export const sanitizeLightBooking: SanitizerWithArgs<LightBooking> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Booking: invalid data type');
  }

  const booking: Omit<LightBooking, 'cCurrentStatus'> = {
    id: sanitizeId(data, omit),
    companies: sanitizeOrOmit(data, omit, 'companies', invokeMap(sanitizeLightCompany), isArray),
    services: sanitizeOrOmit(
      data,
      omit,
      'services',
      invokeMap((v) => sanitizeLightService(v)),
      isArray,
    ),
    public_id: sanitizeOrOmit(data, omit, 'public_id', invokeOnNotNil(String)),
    status: sanitize(data, 'status', String, isNotNil),
  };

  return { ...booking, cCurrentStatus: validatorStatus(booking) };
};

export const sanitizeLightRecipient: SanitizerWithArgs<LightRecipient> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Recipient: invalid data type');
  }
  return {
    ...(sanitizeLightUser(data) as User),
    companies: sanitizeOrOmit(data, omit, 'companies', invokeMap(Number), isArray),
  };
};

export const sanitizeLightAffiliation: SanitizerWithArgs<LightAffiliation> = (data, { omit } = {}) => {
  if (!isObject(data)) {
    console.error('Affiliation: invalid data type');
  }
  return {
    id: sanitizeId(data, omit),
    company: sanitizeOrOmit(
      data,
      omit,
      'company',
      invokeOnNotNil((v) => sanitizeLightCompany(v)),
    ),
    recipient: sanitizeOrOmit(data, omit, 'recipient', sanitizeLightRecipient),
  };
};

export const sanitizeLightAgent: Sanitizer<LightAgent> = (data) => {
  if (!isObject(data)) {
    console.error('Agent: invalid data type');
  }
  return {
    ...sanitizeLightUser(data),
    companies: sanitize(data, 'companies', invokeMap(sanitizeLightCompany), isArray),
    role: sanitize(data, 'role', String, isNotNil),
  };
};

export const sanitizeLightRequester: Sanitizer<LightRequester> = (data) => {
  if (!isObject(data)) {
    console.error('Requester: invalid data type');
  }
  return {
    ...sanitizeLightUser(data),
    companies: sanitize(data, 'companies', invokeMap(sanitizeLightCompany), isArray),
  };
};

export const sanitizeLightBaseEvent: Sanitizer<Omit<LightBEvent, 'booking'>> = (data) => {
  if (!isObject(data)) {
    console.error('Event: invalid data type');
  }
  return {
    affiliates: sanitize(
      data,
      'affiliates',
      invokeOnNotNil(invokeMap(sanitizeLightAffiliation)),
      (v) => isNil(v) || isArray(v),
    ),
    agents: sanitize(data, 'agents', invokeOnNotNil(invokeMap(sanitizeLightAgent)), (v) => isNil(v) || isArray(v)),
    arrive_at: sanitize(data, 'arrive_at', invokeOnNotNil(parseDate), (v) => isNil(v) || isDateStringValid(v)),
    authorizations: sanitize(data, 'authorizations', invokeMap(sanitizeShallowAuthorization), isArray),
    description: sanitize(
      data,
      'description',
      invokeOnNotNil((v) => String(v) as TypesOfAppointments),
      (v) => isNil(v) || isFromEnum(TYPES_OF_APPOINTMENTS)(v),
    ),
    end_at: sanitize(data, 'end_at', parseDateTime, isDateStringValid),
    id: sanitizeId(data),
    location: sanitize(data, 'location', invokeOnNotNil(sanitizeLocation)),
    meeting_url: sanitize(data, 'meeting_url', invokeOnNotNil(String)),
    payer: sanitize(data, 'payer', invokeOnNotNil(Number)),
    payer_company: sanitize(data, 'payer_company', invokeOnNotNil(Number)),
    payer_company_type: sanitize(
      data,
      'payer_company_type',
      invokeOnNotNil((v) => String(v) as PayerType),
      (v) => isNil(v) || isFromEnum(PAYER_TYPES)(v),
    ),
    requester: sanitize(data, 'requester', sanitizeLightRequester, isNotNil),
    start_at: sanitize(data, 'start_at', parseDateTime, isDateStringValid),
  };
};

export const sanitizeLightEvent: Sanitizer<DehydratedLightEvent> = (data) => {
  if (!isObject(data)) {
    console.error('Event: invalid data type');
  }

  const baseEvent = sanitizeLightBaseEvent(data);

  return {
    ...baseEvent,
    booking: sanitize(
      data,
      'booking',
      invokeOnNotNil((v) => sanitizeLightBooking(v)),
    ),
  };
};

export const sanitizeLightShallowEvent: Sanitizer<LightBEvent> = (data) => {
  if (!isObject(data)) {
    console.error('Event (from Booking): invalid data type');
  }
  return {
    ...sanitizeLightBaseEvent(data),
    booking: sanitize(data, 'booking', invokeOnNotNil(Number), (v) => isNil(v) || isNumeric(v)),
  };
};
