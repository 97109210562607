import { PropsWithChildren, ReactNode } from 'react';
import { Col, Container, Row, RowProps } from 'reactstrap';
import './FormListRowNoAdd.scss';
import RemoveIcon from '@mui/icons-material/Remove';
import classNames from 'classnames';
import { ContainerProps } from 'reactstrap/types/lib/Container';
import { BUTTON_STATE, ButtonState } from '../../../app/helpers/enum';
import ButtonWithTooltip from '../../ButtonWithTooltip';
import { useForm } from '../../../app/hooks';

export interface FormListRowItemProps extends PropsWithChildren, RowProps {
  formPrefix?: string;
  smallGutters?: boolean;

  removeButtonState?: ButtonState;
  onRemove?: () => void;
  removeButtonTooltip?: ReactNode;
}

export function FormListRowItemNoAdd({
  className = '',
  formPrefix = 'form-list-row-no-add',
  smallGutters = true,
  children,
  onRemove,
  removeButtonState = onRemove ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.DISABLED,
  removeButtonTooltip,
  ...props
}: FormListRowItemProps) {
  const { p } = useForm(formPrefix);

  return (
    <Row
      className={classNames('form-list-row-item', {
        'gx-2': smallGutters,
        [className]: !!className,
      })}
      {...props}
    >
      <Col>{children}</Col>
      {removeButtonState !== BUTTON_STATE.HIDDEN ? (
        <Col xs="auto" className="flex-grow-0">
          <ButtonWithTooltip
            id={p`remove-button`}
            className={classNames('form-list-row-remove-button', {
              invisible: removeButtonState === BUTTON_STATE.INVISIBLE,
            })}
            color="primary"
            disabled={removeButtonState === BUTTON_STATE.DISABLED}
            tooltip={removeButtonTooltip}
            onClick={() => onRemove?.()}
            isIconButton
          >
            <RemoveIcon fontSize="large" />
          </ButtonWithTooltip>
        </Col>
      ) : null}
    </Row>
  );
}

export interface FormListRowProps extends ContainerProps {
  className?: string;
  displaySeparator?: boolean;
}

export default function FormListRowNoAdd({
  children,
  className = '',
  displaySeparator = false,
  ...props
}: FormListRowProps) {
  return (
    <Container
      className={classNames('form-list-row', { 'display-separator': displaySeparator, [className]: !!className })}
      {...props}
    >
      {children}
    </Container>
  );
}
