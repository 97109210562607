import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import { BrowserRouter } from 'react-router-dom';
import { store } from './app/stores/store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './themes.scss';
import './index.scss';
import './app/i18n/config';
import 'react-datepicker/dist/react-datepicker.css';

// Set the default timezone to the browser's timezone
moment.tz.setDefault('America/Los_Angeles');

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
