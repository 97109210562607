import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import { Col, Row } from 'reactstrap';
import FormSection from 'src/components/Form/FormSection';
import { TextField } from '@mui/material';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { AUTHORIZATION_PRIORITY } from 'src/app/helpers/enum';
import { mapObjectForSelect } from 'src/app/helpers/mappers';
import { findInOptions } from 'src/app/helpers/manipulation';
import useStateWithCallback from 'src/app/hooks/useStateWithCallback';
import { AuthorizationPriorityState } from '../localTypes';

export interface AuthorizationPriorityFieldProps {
  initialState: AuthorizationPriorityState;
  stateUpdater: StateUpdater<AuthorizationPriorityState>;
}

const translationArray = ['collections'];

function AuthorizationPriorityField(props: AuthorizationPriorityFieldProps) {
  const { initialState, stateUpdater } = props;

  // Utils

  const { t } = useTranslation(translationArray);

  // Form data
  const [authPriority, setAuthPriority] = useStateWithCallback(
    initialState.authorizationPriority,
    stateUpdater('authorizationPriority'),
  );

  // Options
  const authPriorityOptions = mapObjectForSelect(AUTHORIZATION_PRIORITY, (typeKey) =>
    t(`collections:authPriorityOptions.${typeKey.toLowerCase()}`),
  );

  return (
    <FormSection label="Authorization Priority">
      <Row className="mb-2">
        <Col xl={4}>
          <Row className="gx-2">
            <Col md={2} xl={6} xxl={12}>
              <FormSelect
                options={authPriorityOptions}
                value={findInOptions(authPriorityOptions, authPriority) ?? null}
                onChange={(_, option) => setAuthPriority(option?.value!)}
                renderInput={(params) => <TextField placeholder={`${t`Priority`} `} {...params} />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </FormSection>
  );
}

export default AuthorizationPriorityField;
