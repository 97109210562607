import { Col, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { StateUpdater } from 'src/app/types/Components';
import { SUFFIXES } from 'src/app/helpers/collections';
import {
  AGENCY_AGENT_ROLES,
  BUTTON_STATE,
  CLINIC_AGENT_ROLES,
  INSURANCE_AGENT_ROLES,
  LAW_FIRM_AGENT_ROLES,
  TITLES,
} from 'src/app/helpers/enum';
import { mapObjectForSelect, mapArrayForSelect, userToString, flattenContacts } from 'src/app/helpers/mappers';
import { arrayRemoveAt, arrayReplaceAt, findInOptions } from 'src/app/helpers/manipulation';
import { FormInput } from 'src/components/Form/FormInput';
import FormWrapper from 'src/components/Form/FormWrapper';
import FlatContactList from 'src/pages/BookingsPage/Onboarding/Fields/FlatContactList/FlatContactList';
import FormExpandableField from 'src/components/Form/FormExpandableField';
import { useForm, useStateDict } from 'src/app/hooks';
import { useQuery } from '@tanstack/react-query';
import { AgentsGetParams, MUTATION_KEYS, getAgents } from 'src/app/api';
import useStateWithCallback from '../../../../../../app/hooks/useStateWithCallback';
import { CompanyInfoState, CompanyStaffItem } from '../../localTypes';
import FormSelect from '../../../../../../components/Form/FormSelect/FormSelect';
import CompanyStaffListItemDisplaySelect from './DisplaySelect';

export interface CompanyStaffListItemProps {
  formPrefix: string;
  removeCompanyStaff: () => void;
  companyInfoState: CompanyInfoState;
  initialState: CompanyStaffItem;
  stateUpdater: StateUpdater<CompanyStaffItem>;
  // TODO add errors
}

const translationArray = ['onboardings', 'collections'];

export default function CompanyStaffListItem(props: CompanyStaffListItemProps) {
  const { initialState, formPrefix, companyInfoState, stateUpdater, removeCompanyStaff } = props;

  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  const [showStaffInfo, setShowStaffInfo] = useState<boolean>(false);
  const [createMode, setCreateMode] = useState<boolean>(false);

  const [agent, setAgent] = useStateWithCallback(initialState.agent, stateUpdater('agent'), true);
  const [role, setRole] = useStateWithCallback(initialState.role, stateUpdater('role'), true);
  const [title, setTitle] = useStateWithCallback(initialState.title, stateUpdater('title'), true);
  const [firstName, setFirstName] = useStateWithCallback(initialState.firstName, stateUpdater('firstName'), true);
  const [lastName, setLastName] = useStateWithCallback(initialState.lastName, stateUpdater('lastName'), true);
  const [suffix, setSuffix] = useStateWithCallback(initialState.suffix, stateUpdater('suffix'), true);
  const [flatContacts, setFlatContacts] = useStateWithCallback(
    initialState.flatContacts,
    stateUpdater('flatContacts'),
    true,
  );

  const addNewFlatContact = () =>
    setFlatContacts([...flatContacts, { uuid: uuidv4(), type: 'phone', data: {}, context: 'personal' }]);

  const replaceFlatContactAt = (index: number, contact: any) =>
    setFlatContacts(arrayReplaceAt(flatContacts, index, contact));

  const removeFlatContactAt = (index: number) => setFlatContacts(arrayRemoveAt(flatContacts, index));

  useEffect(() => {
    // Ensure there is always at least one (flat) contact available to fill
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  useEffect(() => {
    setTitle(agent?.title);
    setFirstName(agent?.first_name ?? '');
    setLastName(agent?.last_name ?? '');
    setSuffix(agent?.suffix);
    setFlatContacts(flattenContacts(agent?.contacts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  // Options
  const roleOptions = () => {
    let options: any[];

    switch (companyInfoState.type) {
      case 'clinic':
        options = Object.values(CLINIC_AGENT_ROLES);
        options = mapArrayForSelect(options, (roleKey) => t(`collections:agentRoles.${roleKey}`));
        break;
      case 'insurance':
        options = Object.values(INSURANCE_AGENT_ROLES);
        options = mapArrayForSelect(options, (roleKey) => t(`collections:agentRoles.${roleKey}`));
        break;
      case 'lawfirm':
        options = Object.values(LAW_FIRM_AGENT_ROLES);
        options = mapArrayForSelect(options, (roleKey) => t(`collections:agentRoles.${roleKey}`));
        break;
      case 'agency':
        options = Object.values(AGENCY_AGENT_ROLES);
        options = mapArrayForSelect(options, (roleKey) => t(`collections:agentRoles.${roleKey}`));
        break;
      default:
        options = [];
    }

    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const titleOptions = mapObjectForSelect(TITLES, (titleKey) => t(`collections:titles.${titleKey}`));
  const suffixOptions = mapArrayForSelect(SUFFIXES, (suffixKey) => t(`collections:suffixes.${suffixKey}`));

  const [agentFilters] = useStateDict<AgentsGetParams>({});
  const { data: agents, isLoading: isAgentsLoading } = useQuery([MUTATION_KEYS.AGENTS, agentFilters], () =>
    getAgents(agentFilters),
  );

  // Display
  const display = (
    <CompanyStaffListItemDisplaySelect
      id={p`staff`}
      agent={agent}
      agents={agents}
      formatAgentToLabel={userToString}
      setAgent={setAgent}
      setShowStaffInfo={setShowStaffInfo}
      isAgentsLoading={isAgentsLoading}
      createMode={createMode}
      setCreateMode={setCreateMode}
      setFirstName={setFirstName}
      setLastName={setLastName}
    />
  );

  return (
    <FormExpandableField // Personal info
      showDisplaySeparator
      formPrefix={p`personal-info`}
      display={display}
      isOpen={showStaffInfo}
      toggleOpen={setShowStaffInfo}
      openButtonState={!agent || createMode ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      onCancel={() => setShowStaffInfo(false)}
      className={showStaffInfo ? '' : 'pt-0'}
      canClose={!createMode}
    >
      <Row>
        <Col>
          <Row className="gx-2">
            <Col md={12} xl={6} xxl={2}>
              <FormSelect
                options={roleOptions()}
                value={findInOptions(roleOptions(), role) ?? null}
                onChange={(_, option) => setRole(option?.value)}
                renderInput={(inputProps) => <TextField {...inputProps} placeholder={t`role`} />}
              />
            </Col>

            <Col md={12} xl={6} xxl={2}>
              <FormSelect
                options={titleOptions}
                value={findInOptions(titleOptions, title) ?? null}
                onChange={(_, option) => setTitle(option?.value)}
                renderInput={(inputProps) => <TextField {...inputProps} placeholder={t`title`} />}
              />
            </Col>

            <Col md={12} xl={6} xxl={3}>
              <FormInput label={`${t`firstName`} *`} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </Col>

            <Col md={12} xl={6} xxl={3}>
              <FormInput label={`${t`lastName`} *`} value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </Col>

            <Col md={12} xl={6} xxl={2}>
              <FormSelect
                options={suffixOptions}
                value={findInOptions(suffixOptions, suffix) ?? null}
                onChange={(_, option) => setSuffix(option?.value)}
                renderInput={(inputProps) => <TextField {...inputProps} placeholder={t`suffix`} />}
              />
            </Col>
          </Row>
        </Col>

        <Col xs="auto" className="flex-grow-0 align-self-center">
          <IconButton onClick={removeCompanyStaff}>
            <DeleteIcon fontSize="medium" />
          </IconButton>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormWrapper label={t`contactInfo`}>
            <FlatContactList
              items={flatContacts}
              add={addNewFlatContact}
              removeAt={removeFlatContactAt}
              replaceAt={replaceFlatContactAt}
            />
          </FormWrapper>
        </Col>
      </Row>
    </FormExpandableField>
  );
}
