import { ReactNode } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Interpretation, Language } from 'src/app/types/Entities';
import FormWrapper from '../../../../../components/Form/FormWrapper';
import OfferRow, { LocalOffer } from './OfferRow';

export interface InterpreterFieldOffersSectionProps {
  offers: LocalOffer[];
  formatOffer: (offer: LocalOffer) => ReactNode;
  onOfferChange: (index: number, offer: LocalOffer, hasCheckboxChanged: boolean) => Promise<void>;
  sendOffers: () => void;
  canSendOffers: boolean;
  triggerClose: () => void;
  setInterpreter: (interpreter: Interpretation) => void;
  targetLanguage: Language | null;
}

const translationArray = ['booking', 'forms', 'languages'];

function InterpreterFieldOffersSection(props: InterpreterFieldOffersSectionProps) {
  const {
    offers,
    formatOffer,
    onOfferChange,
    sendOffers,
    canSendOffers,
    triggerClose,
    setInterpreter,
    targetLanguage,
  } = props;

  const { t } = useTranslation(translationArray);

  return (
    <Container className="p-0">
      <Row>
        <Col>
          <FormWrapper label="Matched interpreters">
            {offers.map((offer, index) => (
              <OfferRow
                key={`
                  ${offer.service.provider.id}PRO
                  ${offer.service.target_language}LAN
                  ${offer.service.root!.id}ROOT
                  ${offer.id ?? NaN}OFF
                `}
                formatOffer={formatOffer}
                offer={offer}
                onOfferChange={(newOffer: LocalOffer, hasCheckboxChanged: boolean) =>
                  onOfferChange(index, newOffer, hasCheckboxChanged)
                }
                setInterpreter={setInterpreter}
                targetLanguage={targetLanguage}
              />
            ))}
          </FormWrapper>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <Button className="action-button" color="submit" onClick={sendOffers} disabled={!canSendOffers}>
            {t`sendOffers`}
          </Button>
        </Col>

        <Col className="d-flex justify-content-center">
          <Button className="action-button" color="cancel" onClick={triggerClose}>
            {t`cancel`}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default InterpreterFieldOffersSection;
