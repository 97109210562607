import { Button, Col, FormText, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import FormDatePicker from 'src/components/DatePicker/FormDatePicker';
import FormSelectCreatable from 'src/components/Form/FormSelectCreatable/FormSelectCreatable';
import { TextField } from '@mui/material';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import FormExpandableField from '../../../../components/Form/FormExpandableField';
import {
  BUTTON_STATE,
  NOTIFICATION_BOOKING_REMINDER_TARGETS,
  NotificationBookingReminderTarget,
  TITLES,
} from '../../../../app/helpers/enum';
import {
  createUserComplex,
  getOperators,
  MUTATION_KEYS,
  updateUser,
  UsersPostErrorComplex,
  UsersPostPayloadComplex,
  UsersPostResponseComplex,
  UsersPutError,
  UsersPutErrorComplex,
  UsersPutPayload,
  UsersPutResponse,
} from '../../../../app/api';
import { FlatContact, Location, Operator } from '../../../../app/types/Entities';
import {
  buildUsername,
  flattenContacts,
  mapArrayForSelect,
  mapObjectForSelect,
  unflattenContacts,
  userToString,
} from '../../../../app/helpers/mappers';
import { findInOptions, stringifyDate } from '../../../../app/helpers/manipulation';
import { FormInput } from '../../../../components/Form/FormInput';
import { DEFAULT_COUNTRY_FOR_LOCATION, PREFERRED_DATE_FORMAT_MOMENT } from '../../../../app/helpers/constants';
import FormWrapper from '../../../../components/Form/FormWrapper';
import LocationSection from './LocationSection/LocationSection';
import FlatContactList from './FlatContactList/FlatContactList';
import { ErrorsAlert } from '../../../../components/ErrorsAlert';
import { useForm, useStateArray, useStateDict } from '../../../../app/hooks';
import { SUFFIXES } from '../../../../app/helpers/collections';

export interface SupportOperatorFieldProps {
  showSupportOperator: boolean;
  setShowSupportOperator: (isOpen: boolean) => void;
  operator: Operator | null;
  setOperator: (operator: Operator | null) => void;
  sendReminderTarget: NotificationBookingReminderTarget | null;
  setSendReminderTarget: (target: NotificationBookingReminderTarget | null) => void;

  onUpdateSuccess?: (updatedOperator?: Operator) => void;
}

const formPrefix = 'operator';
const translationArray = ['booking', 'collections'];

function SupportOperatorField(props: SupportOperatorFieldProps) {
  const {
    showSupportOperator,
    setShowSupportOperator,
    operator,
    setOperator,
    sendReminderTarget,
    setSendReminderTarget,
    onUpdateSuccess,
  } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [errors, setErrors, updateErrors] = useStateDict<UsersPostErrorComplex & UsersPutErrorComplex>({});
  const [createMode, setCreateMode] = useState<boolean>(false);

  const [title, setTitle] = useState<string | undefined>();
  const titleOptions = mapObjectForSelect(TITLES, (titleKey) => t(`collections:titles.${titleKey}`));

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();

  const [suffix, setSuffix] = useState<string | undefined>();
  const suffixOptions = mapArrayForSelect(SUFFIXES, (suffixKey) => t(`collections:suffixes.${suffixKey}`));

  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();

  const [location, setLocation] = useState<Location | undefined>();
  const updateLocation = (fields: Partial<Location>) => setLocation((prev) => ({ ...prev, ...fields }));

  const {
    values: flatContacts,
    set: setFlatContacts,
    push: pushFlatContact,
    replaceAt: replaceFlatContactAt,
    removeAt: removeFlatContactAt,
  } = useStateArray<FlatContact>([]);

  const addNewFlatContact = () =>
    pushFlatContact({
      type: 'email',
      data: {},
      context: 'work',
      uuid: uuidv4(),
    });

  const prefillForm = () => {
    setTitle(operator?.title);
    setFirstName(operator?.first_name);
    setLastName(operator?.last_name);
    setSuffix(operator?.suffix);
    setDateOfBirth(operator?.date_of_birth);
    setLocation(operator?.location ?? undefined);
    setFlatContacts(flattenContacts(operator?.contacts) ?? []);
  };

  useEffect(() => {
    prefillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator, setFlatContacts]);

  useEffect(() => {
    // Ensure there is always at least one (flat) contact available to fill
    if (flatContacts.length <= 0) {
      addNewFlatContact();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatContacts.length]);

  const sendReminderOptions = mapObjectForSelect(NOTIFICATION_BOOKING_REMINDER_TARGETS, (_, value) =>
    t(`collections:notificationBookingReminderTarget.${value}`),
  ).filter(
    ({ value }) => value === NOTIFICATION_BOOKING_REMINDER_TARGETS.ALL, // As per requirement, only show ALL option
  );

  // Query
  const [operatorsRefetchEnabled, setOperatorsRefetchEnabled] = useState<boolean>(true);
  const {
    data: operators,
    isLoading: areOperatorsLoading,
    refetch: refetchOperators,
  } = useQuery([MUTATION_KEYS.OPERATORS], () => getOperators({}), { enabled: operatorsRefetchEnabled });

  useEffect(() => {
    if (operators) {
      setOperatorsRefetchEnabled(false);
    }
  }, [operators]);

  // On saving
  const { mutateAsync: mutateAsyncCreateUserComplex } = useMutation<
    UsersPostResponseComplex,
    UsersPostErrorComplex,
    UsersPostPayloadComplex
  >(createUserComplex);

  const { mutateAsync: mutateAsyncUpdateUser } = useMutation<
    UsersPutResponse,
    UsersPutError,
    Parameters<typeof updateUser>
  >((args) => updateUser(...args));

  // On save functions
  // Validate
  const validate = () => {
    const validationErrors = [];

    if (!dateOfBirth) {
      validationErrors.push(t`errors.noDateOfBirth`);
    }
    if (!firstName) {
      validationErrors.push(t`errors.noFirstName`);
    }
    if (!lastName) {
      validationErrors.push(t`errors.noLastName`);
    }

    if (!createMode && !operator?.id) {
      validationErrors.push(t`errors.expectedOperatorId`);
    }

    if (validationErrors.length !== 0) {
      updateErrors({ validation_errors: validationErrors });
      return false;
    }
    return true;
  };

  // On create
  const createOperator = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? {
            ...location,
            country: DEFAULT_COUNTRY_FOR_LOCATION,
          }
        : undefined;

    const payloadEmailFlatContact = flatContacts.find(({ type, data }) => type !== 'email' && data) as Extract<
      FlatContact,
      { type?: 'email' }
    >;

    const payloadUsername = buildUsername(firstName!, lastName!, dateOfBirth!);

    const payload: UsersPostPayloadComplex = {
      contacts: unflattenContacts(flatContacts),
      date_of_birth: stringifyDate(dateOfBirth!),
      email: payloadEmailFlatContact?.data.address ?? '',
      first_name: firstName!,
      last_name: lastName!,
      location: payloadLocation,
      suffix: suffix ?? '',
      title: title ?? '',
      username: payloadUsername,

      _recipient_data: {},
      _operator_data: {
        // TODO properly create operator data when mocks are available
        companies: [],
        hiring_date: stringifyDate(new Date()),
      },
    };

    // Display toast for the creation process
    toast
      .promise(mutateAsyncCreateUserComplex(payload), {
        pending: t`progress.operatorCreate.started` as string,
        success: t`progress.operatorCreate.success` as string,
        error: t`progress.operatorCreate.error` as string,
      })
      .catch((err: UsersPostErrorComplex) => {
        setErrors(err);
        console.error(`Errors when creating operator "${payloadUsername}"`, err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(async (idsCollection) => {
        // Refetch affiliations without losing the created affiliation's ID
        const { data: newOperators } = await refetchOperators();
        const { operator_id } = idsCollection;
        const createdOperator = newOperators?.find(({ id }) => id === operator_id);
        setShowSupportOperator(false);
        setCreateMode(false);
        onUpdateSuccess?.(createdOperator);
      });
  };

  // On update
  const updateOperator = () => {
    setErrors({});
    if (!validate()) {
      return;
    }

    const payloadLocation =
      location && Object.values(location).some(Boolean)
        ? {
            ...location,
            country: DEFAULT_COUNTRY_FOR_LOCATION,
          }
        : undefined;

    const payload: UsersPutPayload = {
      contacts: unflattenContacts(flatContacts),
      date_of_birth: stringifyDate(dateOfBirth!),
      email: operator!.email,
      first_name: firstName!,
      last_name: lastName!,
      location: payloadLocation,
      suffix: suffix ?? '',
      title: title ?? '',
    };

    toast
      .promise(mutateAsyncUpdateUser([operator!.user_id!, payload]), {
        pending: t`progress.operatorUpdate.started` as string,
        success: t`progress.operatorUpdate.success` as string,
        error: t`progress.operatorUpdate.error` as string,
      })
      .catch((err) => {
        if (err) setErrors(err);
        console.error('Errors when updating operator', err);
        return Promise.reject(); // Stop promise chain execution
      })
      .then(() => refetchOperators())
      .then((refetchData) => {
        const { data: newOperators } = refetchData;
        const updatedOperator = newOperators?.find(({ id }) => id === operator?.operator_id);
        onUpdateSuccess?.(updatedOperator);
      });
  };

  const onSave = () => (createMode ? createOperator() : updateOperator());

  // Display
  const label = <h5>{t`supportOperator`}</h5>;

  const display = (
    <Row className="gx-2">
      <Col>
        <FormSelectCreatable<Operator>
          fullWidth
          id={p`operator`}
          options={operators ?? []}
          value={createMode ? null : operator}
          loading={areOperatorsLoading}
          disabled={createMode}
          clearOnBlur
          renderInput={(params) => <TextField {...params} label={createMode ? t`newOperator` : t`selectOperator`} />}
          getCreatedOptionLabel={() => t`tooltip.addOperator`}
          getSelectedOptionLabel={(optionOperator) => userToString(optionOperator)}
          onCreateOption={(newValue) => {
            setShowSupportOperator(!createMode);
            setCreateMode(!createMode);

            const [newFirstName, newLastName] = newValue.split(' ', 2);
            setFirstName(newFirstName);
            setLastName(newLastName);
          }}
          onSelectOption={(option) => setOperator(option as Operator)}
          getOptionValue={(option) => option.id}
        />
      </Col>
      <Col>
        <FormSelect
          options={sendReminderOptions}
          value={findInOptions(sendReminderOptions, sendReminderTarget)}
          onChange={(_event, reminderOption) => reminderOption && setSendReminderTarget(reminderOption.value)}
          renderInput={(params) => <TextField {...params} />}
          disableClearable
        />
      </Col>
    </Row>
  );

  const onCancel = () => {
    setShowSupportOperator(false);
    setCreateMode(false);
    prefillForm();
  };

  const footer = (
    <Row>
      <Col>
        <Button className="action-button me-4" color="submit" onClick={onSave} block>
          {t`saveOperator`}
        </Button>
      </Col>
      <Col>
        <Button className="action-button" color="cancel" onClick={onCancel} block>
          {t`cancel`}
        </Button>
      </Col>
      <Col>
        <FormText color="required-fields-help">{t`requiredFieldsHelp`}</FormText>
      </Col>
    </Row>
  );

  const onAdd = () => {
    setOperator(null);
    setShowSupportOperator(!createMode);
    setCreateMode(!createMode);
  };

  return (
    <FormExpandableField
      formPrefix={formPrefix}
      isOpen={showSupportOperator}
      toggleOpen={setShowSupportOperator}
      canClose={!createMode}
      onAdd={onAdd}
      openButtonState={!operator || createMode ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showSupportOperator ? t`tooltip.hideOperator` : t`tooltip.viewOperator`}
      label={label}
      display={display}
      footer={footer}
      onCancel={onCancel}
    >
      <Row className="gx-2">
        <Col md={12} xl={12} xxl={4}>
          <FormSelect
            id={p`title`}
            options={titleOptions}
            value={titleOptions.find((o) => o.value === title)}
            onChange={(_event, titleOption) => setTitle(titleOption.value)}
            renderInput={(params) => <TextField placeholder={t`title`} {...params} />}
            disableClearable
          />
        </Col>
        <Col md={12} xl={6} xxl={4}>
          <FormInput
            label={`${t`firstName`} *`}
            value={firstName ?? ''}
            onChange={(e) => setFirstName(e.target.value)}
            errors={errors.first_name}
          />
        </Col>
        <Col md={12} xl={6} xxl={4}>
          <FormInput
            label={`${t`lastName`} *`}
            value={lastName ?? ''}
            onChange={(e) => setLastName(e.target.value)}
            errors={errors.last_name}
          />
        </Col>
        <Col md={12} xl={12} xxl={6}>
          <FormSelect
            id={p`suffix`}
            options={suffixOptions}
            value={suffixOptions.find((o) => o.value === suffix)}
            onChange={(_event, suffixOption) => setSuffix(suffixOption.value)}
            renderInput={(params) => <TextField placeholder={t`suffix`} {...params} />}
            disableClearable
          />
        </Col>
        <Col md={12} xl={12} xxl={6}>
          <FormDatePicker
            label={`${t`dob`} *`}
            dateFormat={PREFERRED_DATE_FORMAT_MOMENT}
            maxDate={new Date()}
            value={dateOfBirth}
            onChange={(date) => date && setDateOfBirth(date.toDate())}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label="Address" className="pb-2">
            <LocationSection errors={errors.location} location={location} update={updateLocation} />
          </FormWrapper>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormWrapper label={t`contactInformation`}>
            <FlatContactList
              items={flatContacts}
              add={addNewFlatContact}
              removeAt={removeFlatContactAt}
              replaceAt={replaceFlatContactAt}
            />
          </FormWrapper>
        </Col>
      </Row>

      <ErrorsAlert errorsArray={errors.non_field_errors} />
      <ErrorsAlert errorsArray={errors.validation_errors} />
    </FormExpandableField>
  );
}

export default SupportOperatorField;
