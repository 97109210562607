import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Insurance } from '../../../../../../app/types/Entities';
import GenericCompanyField, { GenericCompanyFieldTranslationProps } from '../GenericCompanyField';
import { COMPANY_TYPES } from '../../../../../../app/helpers/enum';
import { getCompanies, MUTATION_KEYS } from '../../../../../../app/api';

export interface InsuranceFieldProps {
  formPrefix: string;
  optional?: boolean;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  insurance: Insurance | null;
  setInsurance: (insurance: Insurance | null) => void;
  insuranceList: Insurance[] | undefined;
  isLoading: boolean;
  refetchInsurances: () => Promise<{ data: Insurance[] | undefined }>;
  onUpdateSuccess: (insurance: Insurance | null) => void;
}

export default function InsuranceField(props: InsuranceFieldProps) {
  const {
    formPrefix,
    optional,
    isOpen,
    toggleOpen,
    insurance,
    setInsurance,
    insuranceList,
    isLoading,
    refetchInsurances,
  } = props;

  // Utils
  const { t } = useTranslation('booking');

  // Query
  const [parentCompaniesRefetchEnabled, setParentCompaniesRefetchEnabled] = useState<boolean>(true);
  const { data: parentCompanies, isLoading: isParentCompaniesLoading } = useQuery(
    [MUTATION_KEYS.COMPANIES, {}],
    () => getCompanies({}),
    {
      enabled: parentCompaniesRefetchEnabled,
    },
  );

  useEffect(() => {
    if (parentCompanies) {
      setParentCompaniesRefetchEnabled(false);
    }
  }, [parentCompanies]);

  const onUpdateSuccess = (company: Insurance | undefined) => props.onUpdateSuccess(company ?? null);

  const translations: GenericCompanyFieldTranslationProps = {
    company: t`insurance`,
    companyName: t`insuranceName`,
    newCompany: t`newInsurance`,
    selectCompany: t`selectInsurance`,
    parentCompany: t`parentCompany`,
    saveCompany: t`saveInsurance`,
    cancel: t`cancel`,
    requiredFieldsHelp: t`requiredFieldsHelp`,
    contactInformation: t`contactInformation`,
    companyNotes: t`insuranceNotes`,
    errors: {
      noCompanyName: t`errors.noInsuranceName`,
      noParentCompany: t`errors.noParentCompany`,
    },
    tooltip: {
      cancelAddCompany: t`tooltip.cancelAddInsurance`,
      addCompany: t`tooltip.addInsurance`,
      hideCompany: t`tooltip.hideInsurance`,
      viewCompany: t`tooltip.viewInsurance`,
    },
    progress: {
      companyCreate: {
        started: t`progress.insuranceCreate.started`,
        success: t`progress.insuranceCreate.success`,
        error: t`progress.insuranceCreate.error`,
      },
      companyUpdate: {
        started: t`progress.insuranceUpdate.started`,
        success: t`progress.insuranceUpdate.success`,
        error: t`progress.insuranceUpdate.error`,
      },
    },
  };

  return (
    <GenericCompanyField
      formPrefix={formPrefix}
      optional={optional}
      showCompanyField={isOpen}
      setShowCompanyField={toggleOpen}
      company={insurance}
      setCompany={setInsurance}
      companyType={COMPANY_TYPES.INSURANCE}
      companies={insuranceList}
      isCompanyLoading={isLoading}
      refetchCompanies={refetchInsurances}
      parentCompanies={parentCompanies}
      isParentCompaniesLoading={isParentCompaniesLoading}
      onUpdateSuccess={onUpdateSuccess}
      translations={translations}
    />
  );
}
