import { ReactNode } from 'react';
import { Alert } from 'reactstrap';
import { Stringable } from '../types/DataStructures';

export default function arrayToUnorderedList<T = Stringable>(
  array: Array<T> | undefined | null,
  mapper: (item: T, index: number) => ReactNode = String,
) {
  return (
    <ul>
      {array?.map((item, i) => (
        <li key={i}>{mapper(item, i)}</li>
      ))}
    </ul>
  );
}

export function displayErrorsAlert(errorsArray: Array<string> | undefined | null) {
  return errorsArray && <Alert color="danger">{arrayToUnorderedList(errorsArray)}</Alert>;
}
