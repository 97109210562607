import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useTranslation } from 'react-i18next';
import { useState, Dispatch, SetStateAction } from 'react';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import contains, { onlyContainsStatus } from 'src/app/stores/contains';
import './BookingStatus.scss';

const translationArray = ['calendar'];

function BookingStageCheck({
  color,
  label,
  status,
  setStatus,
  value,
}: {
  color: string;
  label: string;
  status: string;
  setStatus: Dispatch<SetStateAction<string>>;
  value: string;
}) {
  const [checked, setChecked] = useState([true, true, true]);

  const { t } = useTranslation(translationArray);

  const handleChangeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked;
    setChecked([newChecked, newChecked, newChecked]);

    if (contains(status, value.toLowerCase()) && newChecked === false) {
      const removed = status.replace(value.toLowerCase(), '');
      setStatus(removed);
    } else if (!contains(status, value.toLowerCase()) && newChecked === true) {
      const added = status.concat('-', value.toLowerCase());
      setStatus(added);
    }
    setChecked([event.target.checked, event.target.checked, event.target.checked]);
  };

  const allChecked = checked.every(Boolean);
  const someChecked = checked.some(Boolean);

  return (
    <div>
      <FormControlLabel
        control={
          onlyContainsStatus(status) && allChecked === true ? (
            <Checkbox
              icon={allChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              checkedIcon={allChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              indeterminateIcon={<CheckCircleIcon />}
              sx={{
                color,
                '&.Mui-checked': {
                  color,
                },
              }}
            />
          ) : (
            <Checkbox
              icon={allChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              checkedIcon={allChecked ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              indeterminateIcon={<CheckCircleIcon />}
              checked={someChecked}
              indeterminate={!allChecked && someChecked}
              value={value}
              onChange={handleChangeOne}
              sx={{
                color,
                '&.Mui-checked': {
                  color,
                },
              }}
            />
          )
        }
        label={label}
      />
      {label === t`sidebar.bookingStatus.noCase` ? <WorkOffIcon className="icon" /> : null}
      {label === t`sidebar.bookingStatus.noPayer` ? <MoneyOffIcon className="icon" /> : null}
      {label === t`sidebar.bookingStatus.noInterpreter` ? <PersonOffIcon className="icon" /> : null}
      {label === t`sidebar.bookingStatus.noFollowUp` ? <EventBusyIcon className="icon" /> : null}
      {label === t`sidebar.bookingStatus.noReport` ? <ContentPasteOffIcon className="icon" /> : null}
    </div>
  );
}

function BookingStatus({
  status,
  setStatus,
  hidePendingItems,
}: {
  status: string;
  setStatus: Dispatch<SetStateAction<string>>;
  hidePendingItems?: boolean;
}) {
  const { t } = useTranslation(translationArray);

  return (
    <Box>
      <Typography sx={{ whiteSpace: 'nowrap', marginBottom: '1.7rem', fontWeight: 'bold', textTransform: 'uppercase' }}>
        {t`sidebar.bookingStatus.title`}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <BookingStageCheck
          color="#ff7976"
          label={t`sidebar.bookingStatus.pending`}
          status={status}
          setStatus={setStatus}
          value="pending"
        />
        <BookingStageCheck
          color="#ffc55f"
          label={t`sidebar.bookingStatus.booked`}
          status={status}
          setStatus={setStatus}
          value="booked"
        />
        <BookingStageCheck
          color="#eda93e"
          label={t`sidebar.bookingStatus.override`}
          status={status}
          setStatus={setStatus}
          value="override"
        />
        <BookingStageCheck
          color="#49dcbb"
          label={t`sidebar.bookingStatus.authorized`}
          status={status}
          setStatus={setStatus}
          value="authorized"
        />
        <BookingStageCheck
          color="#538fff"
          label={t`sidebar.bookingStatus.delivered`}
          status={status}
          setStatus={setStatus}
          value="delivered"
        />
      </Box>
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          marginBottom: '1.7rem',
          marginTop: '1.7rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          display: hidePendingItems ? 'none' : 'block',
        }}
      >
        {t`sidebar.bookingStatus.titlePending`}
      </Typography>
      <Box sx={{ display: hidePendingItems ? 'none' : 'flex', flexDirection: 'column' }}>
        <BookingStageCheck
          color="#ff7976"
          label={t`sidebar.bookingStatus.noCase`}
          status={status}
          setStatus={setStatus}
          value="no_case"
        />
        <BookingStageCheck
          color="#ff7976"
          label={t`sidebar.bookingStatus.noPayer`}
          status={status}
          setStatus={setStatus}
          value="no_payer"
        />
        <BookingStageCheck
          color="#ff7976"
          label={t`sidebar.bookingStatus.noInterpreter`}
          status={status}
          setStatus={setStatus}
          value="no_interpreter"
        />
        <BookingStageCheck
          color="#538fff"
          label={t`sidebar.bookingStatus.noFollowUp`}
          status={status}
          setStatus={setStatus}
          value="no_followup"
        />
        <BookingStageCheck
          color="#538fff"
          label={t`sidebar.bookingStatus.noReport`}
          status={status}
          setStatus={setStatus}
          value="no_report"
        />
      </Box>
    </Box>
  );
}

export default BookingStatus;
