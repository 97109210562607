import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as ParkingTicketSvg } from '../images/parking-ticket-icon.svg';

export default function ParkingTicketIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 17 18" {...props}>
      <ParkingTicketSvg />
    </SvgIcon>
  );
}
