import { map } from 'lodash';
import { format } from 'date-fns';
import { STATUS } from '../helpers/enum';

function contains(data: string | undefined, search: string | undefined) {
  if (!data || !search) {
    return false;
  }

  return data?.includes(search);
}

export default contains;

export function onlyContainsStatus(str: string): boolean {
  const elements = map(STATUS, (statusItem) => statusItem);

  const regex = new RegExp(`^(?:-*(${elements.join('|')})-*|-${elements.join('|')}|-+(${elements.join('|')}))$`);
  return regex.test(str);
}

export function formatDate(date: Date): string {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // Return the formatted date
  return `${day}/${month}/${year}`;
}

export function toMilitaryTime(time: string) {
  const [hours, minutes, ampm] = time.split(/:| /);

  let hoursIn24Format = hours;

  if (ampm === 'PM' && hours !== '12') {
    hoursIn24Format = String(Number(hours) + 12);
  }

  if (ampm === 'AM' && hours === '12') {
    hoursIn24Format = '00';
  }

  return `${hoursIn24Format}:${minutes}`;
}

export function dateFormatted(dateParts: any) {
  let year = dateParts[2];

  if (year !== undefined && year.length === 2) {
    year = `20${year}`;
  }

  const date = new Date(
    year, // year
    dateParts[0] - 1, // month (starts from 0)
    dateParts[1], // day
  );

  // const formatted = date.toLocaleDateString('en-US', {
  //   weekday: 'short',
  //   month: 'short',
  //   day: 'numeric',
  //   year: 'numeric',
  // });

  const formatted = format(date, 'MMM eee d yyyy');

  return formatted;
}

export function dateFormattedList(dateParts: any) {
  let year = dateParts[2];
  let month = dateParts[0];
  let day = dateParts[1];

  if (year !== undefined && year.length === 2) {
    year = `20${year}`;
  }

  if (month !== undefined && month.length === 1) {
    month = `0${month}`;
  }

  if (day !== undefined && day.length === 1) {
    day = `0${day}`;
  }

  // const date = new Date(
  //   year, // year
  //   dateParts[0] - 1, // month (starts from 0)
  //   dateParts[1], // day
  // );

  // const formatted = date.toLocaleDateString('en-US', {
  //   weekday: 'short',
  //   month: 'short',
  //   day: 'numeric',
  //   year: 'numeric',
  // });

  const formatted = `${year}-${month}-${day}`;

  return [formatted];
}

export function formatDateTime(date: string, hour: string) {
  const formattedDate = dateFormatted(date.split('/'));
  const militaryTime = hour; // toMilitaryTime(hour);

  return `${formattedDate} ${militaryTime}`;
}

export function decimalToTime(decimalHours: any) {
  const totalHours = decimalHours * 24; // 25.35;
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);
  const finalHours = hours.toString().padStart(2, '0');
  const finalminutes = minutes.toString().padStart(2, '0');
  return `${finalHours}:${finalminutes}`;
}

export function decimalToTimeEnd(decimalHours: any) {
  const [hours, minutes] = decimalHours.split(':').map(Number);

  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);

  date.setMinutes(date.getMinutes() + 45);

  const newHours = date.getHours();
  const newMinutes = date.getMinutes();

  const newTimeString = `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
  return newTimeString;
}

export function numberToDate(value: number) {
  const baseDate = new Date(1899, 11, 30);
  const date = new Date(baseDate.getTime() + value * 24 * 60 * 60 * 1000);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}

export function ExtractLastWord(words: string) {
  const wordArray = words.split(' ');
  const wordsExceptLast = wordArray.slice(0, -1);
  return wordsExceptLast.join(' ');
}

export function transformDateString(dateString: string | undefined): string {
  let returnFormatted = '';
  if (dateString?.length !== 0 && dateString !== undefined) {
    const date = new Date(dateString);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date string');
    }

    const formattedDate = date.toISOString();
    const timezoneOffset = dateString.slice(-6);

    returnFormatted = formattedDate.replace('Z', timezoneOffset);
  }
  return returnFormatted;
}
