import { useTranslation } from 'react-i18next';
import { Badge, Typography } from '@mui/material';
import { StateUpdater } from 'src/app/types/Components';
import FormExpandableListField from '../../../../../components/Form/FormExpandableListField';
import { BUTTON_STATE } from '../../../../../app/helpers/enum';
import { useForm } from '../../../../../app/hooks';
import { PreferredAgencyItem, PreferredAgencyState } from '../localTypes';
import useStateWithCallback from '../../../../../app/hooks/useStateWithCallback';
import { arrayRemoveAt, arrayReplaceAt } from '../../../../../app/helpers/manipulation';
import PreferredAgencyListItem from './ListItem/ListItem';

export interface PreferredAgencyFieldProps {
  formPrefix: string;
  showPreferredAgencyField: boolean;
  setShowPreferredAgencyField: (display: boolean) => void;
  initialState: PreferredAgencyState;
  stateUpdater: StateUpdater<PreferredAgencyState>;
}

const translationArray = ['onboardings'];

function PreferredAgencyField(props: PreferredAgencyFieldProps) {
  const { formPrefix, showPreferredAgencyField, setShowPreferredAgencyField, initialState, stateUpdater } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [preferredAgencies, setPreferredAgencies] = useStateWithCallback(
    initialState.preferredAgencies,
    stateUpdater('preferredAgencies'),
  );

  const pushNewPreferredAgency = () =>
    setPreferredAgencies([
      ...preferredAgencies,
      {
        id: undefined,
        _deleted: false,
        company_from: undefined,
        company_to: undefined,
        relationship: '',
      },
    ]);

  const replacePreferredAgencyAt = (index: number, preferredAgency: PreferredAgencyItem) =>
    setPreferredAgencies(arrayReplaceAt(preferredAgencies, index, preferredAgency));

  const removePreferredAgencyAt = (index: number) => {
    const preferredAgency = preferredAgencies[index];
    if (preferredAgency.id !== undefined) {
      replacePreferredAgencyAt(index, { ...preferredAgency, _deleted: true });
      return;
    }
    setPreferredAgencies(arrayRemoveAt(preferredAgencies, index));
  };

  // Display
  const label = (
    <Badge
      showZero
      variant="counterBadge"
      color="primary"
      badgeContent={preferredAgencies.filter(({ _deleted }) => !_deleted).length}
      sx={{ marginBottom: 2 }}
    >
      <Typography variant="formLabel">{t`preferredAgency`}</Typography>
    </Badge>
  );

  const onAdd = () => {
    pushNewPreferredAgency();
    setShowPreferredAgencyField(true);
  };

  return (
    <FormExpandableListField
      showSeparator
      formPrefix={p`preferredAgencies`}
      isOpen={showPreferredAgencyField}
      toggleOpen={setShowPreferredAgencyField}
      onAdd={onAdd}
      addLabel={t`addPreferredAgency`}
      openButtonState={preferredAgencies.length === 0 ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showPreferredAgencyField ? t`tooltip.hidePreferredAgencies` : t`tooltip.viewPreferredAgencies`}
      defaultDisplay={t`noPreferredAgencies`}
      label={label}
      onCancel={() => {
        setShowPreferredAgencyField(false);
      }}
    >
      {preferredAgencies.map((preferredAgency, index) => {
        return (
          !preferredAgency._deleted && (
            <PreferredAgencyListItem
              key={preferredAgency.id}
              removePreferredAgency={() => removePreferredAgencyAt(index)}
              initialState={preferredAgency}
              stateUpdater={(key: any) => (value: any) =>
                replacePreferredAgencyAt(index, { ...preferredAgency, [key]: value })}
            />
          )
        );
      })}
    </FormExpandableListField>
  );
}

export default PreferredAgencyField;
