import { Col, Collapse, Container, Row } from 'reactstrap';
import { PropsWithChildren, ReactNode, useRef } from 'react';
import classNames from 'classnames';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import './FormExpandableField.scss';
import { Button, Grow, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BUTTON_STATE, ButtonState } from '../../../app/helpers/enum';
import { useForm } from '../../../app/hooks';
import ButtonWithTooltip from '../../ButtonWithTooltip';

export interface FormExpandableFieldProps extends PropsWithChildren {
  formPrefix: string;
  className?: string;

  display: ReactNode;
  footer?: ReactNode;
  label?: ReactNode;
  showDisplaySeparator?: boolean;

  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;

  onAdd?: () => void;
  addButtonState?: ButtonState;
  addLabel?: string;

  canClose?: boolean;

  openButtonState?: ButtonState;
  openButtonTooltip?: ReactNode;

  alignButtonsBottom?: boolean;

  onCancel?: () => void;
}

function FormExpandableField(props: FormExpandableFieldProps) {
  const {
    formPrefix = 'expandable-field',
    className = '',
    display,
    footer,
    label,
    showDisplaySeparator = true,
    isOpen,
    toggleOpen,
    onAdd,
    addButtonState = onAdd ? BUTTON_STATE.INTERACTIVE : BUTTON_STATE.HIDDEN,
    addLabel,
    canClose = true,
    openButtonState = BUTTON_STATE.INTERACTIVE,
    openButtonTooltip,
    alignButtonsBottom = false,
    children,
    onCancel,
  } = props;

  const { p } = useForm(formPrefix);

  const buttonRef = useRef();

  const onClose = () => {
    if (onCancel !== undefined) {
      toggleOpen(!isOpen);
      onCancel();
    }
  };

  return (
    <Container
      className={classNames('expandable-field', {
        open: isOpen,
        [className]: className,
      })}
    >
      {(label || canClose) && (
        <Row className="align-items-center">
          <Col
            className={classNames('label-title', { 'flex-grow-1': addButtonState === BUTTON_STATE.HIDDEN })}
            xs="auto"
          >
            {label}
          </Col>

          {addButtonState !== BUTTON_STATE.HIDDEN ? (
            <Col>
              <Button
                variant="text"
                sx={{
                  padding: '0 4px',
                  margin: '0 0 8px 10px',
                  textTransform: 'capitalize',
                }}
                size="small"
                onClick={onAdd}
                disabled={addButtonState === BUTTON_STATE.DISABLED}
              >
                {addLabel}
              </Button>
            </Col>
          ) : null}

          <Col xs="auto">
            <Grow in={canClose && isOpen}>
              <IconButton
                size="small"
                edge="end"
                sx={{
                  width: '20px',
                  height: '20px',
                  padding: 1,
                  marginBottom: '8px',
                }}
                onClick={onClose}
                disabled={addButtonState === BUTTON_STATE.DISABLED}
              >
                <CloseIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                  }}
                />
              </IconButton>
            </Grow>
          </Col>
        </Row>
      )}

      <Row className="gx-2">
        <Col>{display}</Col>
        {openButtonState !== BUTTON_STATE.HIDDEN ? (
          <Col xs="auto" className={classNames('flex-grow-0', { 'mt-auto': alignButtonsBottom }, { 'p-0': isOpen })}>
            <Grow in={!isOpen} unmountOnExit mountOnEnter>
              <ButtonWithTooltip
                id={p`open-button`}
                className="open-button mb-3"
                color="primary"
                disabled={openButtonState === BUTTON_STATE.DISABLED}
                tooltip={openButtonTooltip}
                onClick={() => toggleOpen(!isOpen)}
                ref={buttonRef}
                isIconButton
              >
                <KeyboardArrowRightIcon fontSize="medium" />
              </ButtonWithTooltip>
            </Grow>
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col>
          <Collapse className="expandable-field-content" isOpen={isOpen}>
            {showDisplaySeparator ? (
              <Row className="separator-row">
                <hr />
              </Row>
            ) : null}
            <Row>
              <Col>{children}</Col>
            </Row>
            {footer && (
              <Row className="mt-4">
                <Col>{footer}</Col>
              </Row>
            )}
          </Collapse>
        </Col>
      </Row>
    </Container>
  );
}

export default FormExpandableField;
