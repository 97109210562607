import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as MileageExtraSvg } from '../images/mileage-extra-icon.svg';

export default function MileageExtraIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <MileageExtraSvg />
    </SvgIcon>
  );
}
