// State
import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';
import { CalendarEvent } from '../types/DataStructures';

export interface CalendarState {
  events: CalendarEvent[];
  eventGUID: number;
}

function loadState(): CalendarState {
  return {
    events: [],
    eventGUID: 0,
  };
}

export const selectCalendarStore: Selector<any, CalendarState> = (state) => state.calendar;

// Payloads
export type CreateEventPayload = Omit<CalendarEvent, 'id'>;
export type UpdateEventPayload = CalendarEvent;
export type DeleteEventPayload = CalendarEvent['id'];

// Thunks and slice
export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: loadState(),
  reducers: {
    createEvent: (state, action: PayloadAction<CreateEventPayload>) => {
      const event: CalendarEvent = {
        ...action.payload,
        id: `${(state.eventGUID += 1)}`,
      };
      state.events.push(event);
    },
    updateEvent: (state, action: PayloadAction<UpdateEventPayload>) => {
      const event = action.payload;
      const index = state.events.findIndex((e) => e.id === event.id);
      if (index >= 0) {
        state.events[index] = event;
      }
    },
    deleteEvent: (state, action: PayloadAction<DeleteEventPayload>) => {
      const id = action.payload;
      state.events = state.events.filter((e) => e.id !== id);
    },
  },
});

export const { createEvent, updateEvent, deleteEvent } = calendarSlice.actions;

export const calendarReducer = calendarSlice.reducer;
