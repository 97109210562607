import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { mapObjectForSelect } from 'src/app/helpers/mappers';
import { BEventShallow, Report } from 'src/app/types/Entities';
import moment, { Moment } from 'moment-timezone';
import { FormInput } from 'src/components/Form/FormInput';
import { useForm } from 'src/app/hooks';
import { TextField } from '@mui/material';
import { DEFAULT_TIMEZONE, PREFERRED_TIME_FORMAT_MOMENT } from 'src/app/helpers/constants';
import FormExpandableField from '../../../../../components/Form/FormExpandableField';
import { BUTTON_STATE, REPORT_STATUS } from '../../../../../app/helpers/enum';
import FormTimePicker from '../../../../../components/DatePicker/FormTimePicker';

export interface ReportFieldProps {
  formPrefix: string;
  showReport: boolean;
  setShowReport: (isOpen: boolean) => void;
  setRetrieveReport: Dispatch<SetStateAction<(() => any) | undefined>>;
  initialReport?: Report;
  disabled?: boolean;
  prefillEvent?: BEventShallow;
}

const translationArray = ['collections', 'booking'];

function ReportField(props: ReportFieldProps) {
  const { formPrefix, showReport, setShowReport, setRetrieveReport, initialReport, disabled, prefillEvent } = props;

  // Utils
  const { t } = useTranslation(translationArray);
  const { p } = useForm(formPrefix);

  const statusOptions = mapObjectForSelect(REPORT_STATUS, (_, value) => t(`reportStatus.${value}`));

  const [id, setId] = useState<Number>();
  const [event, setEvent] = useState<Number>();

  const [status, setStatus] = useState<string | undefined>(REPORT_STATUS.UNREPORTED);

  const [observations, setObservations] = useState<string>('');

  const [arriveAt, setArriveAt] = useState<Moment | null>(null);
  const [startAt, setStartAt] = useState<Moment | null>(null);
  const [endAt, setEndAt] = useState<Moment | null>(null);

  const defaultValues = {
    arriveAt: moment.utc(prefillEvent?.arrive_at, PREFERRED_TIME_FORMAT_MOMENT).tz(DEFAULT_TIMEZONE),
    startAt: moment.utc(prefillEvent?.start_at, PREFERRED_TIME_FORMAT_MOMENT).tz(DEFAULT_TIMEZONE),
    endAt: moment.utc(prefillEvent?.end_at, PREFERRED_TIME_FORMAT_MOMENT).tz(DEFAULT_TIMEZONE),
  };

  useEffect(() => {
    setRetrieveReport?.(() => () => {
      return {
        id,
        event,
        status,
        arrive_at: arriveAt?.toDate(),
        start_at: startAt?.toDate(),
        end_at: endAt?.toDate(),
        observations,
      };
    });
  }, [arriveAt, endAt, observations, id, setRetrieveReport, startAt, status, event]);

  useEffect(() => {
    if (initialReport) {
      setId(initialReport.id);
      setEvent(initialReport.event);
      setStatus(initialReport.status);
      setObservations(initialReport.observations);
      setArriveAt(moment.utc(initialReport.arrive_at, PREFERRED_TIME_FORMAT_MOMENT).tz(DEFAULT_TIMEZONE));
      setStartAt(moment.utc(initialReport.start_at, PREFERRED_TIME_FORMAT_MOMENT).tz(DEFAULT_TIMEZONE));
      setEndAt(moment.utc(initialReport.end_at, PREFERRED_TIME_FORMAT_MOMENT).tz(DEFAULT_TIMEZONE));
    } else {
      setArriveAt(defaultValues.arriveAt);
      setStartAt(defaultValues.startAt);
      setEndAt(defaultValues.endAt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialReport, prefillEvent]);

  // Display
  const label = <h5>{t`booking:report`}</h5>;

  const display = (
    <Row className="gx-2">
      <Col>
        <FormSelect
          id={p`eventReport`}
          options={statusOptions}
          value={statusOptions.find((o) => o.value === status)}
          onChange={(_, newValue) => {
            setStatus(newValue?.value);
          }}
          disabled={disabled}
          renderInput={(params) => <TextField label={t`booking:reportStatus`} {...params} />}
        />
      </Col>
    </Row>
  );

  const onCancel = () => {
    setShowReport(false);
  };

  return (
    <FormExpandableField
      formPrefix={formPrefix}
      isOpen={showReport}
      toggleOpen={setShowReport}
      openButtonState={disabled ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      addButtonState={BUTTON_STATE.HIDDEN}
      label={label}
      display={display}
      onCancel={onCancel}
    >
      <Row>
        <Col>
          <FormTimePicker label={t`booking:arrivalTime`} onChange={setArriveAt} value={arriveAt} />
        </Col>

        <Col>
          <FormTimePicker label={t`booking:startTime`} onChange={setStartAt} value={startAt} />
        </Col>

        <Col>
          <FormTimePicker label={t`booking:endTime`} onChange={setEndAt} value={endAt} />
        </Col>
      </Row>

      <Row>
        <Col>
          <FormInput
            value={observations}
            label={t`booking:observations`}
            onChange={(change) => setObservations(change.target.value)}
            className="mb-1 form-input"
          />
        </Col>
      </Row>
    </FormExpandableField>
  );
}

export default ReportField;
