import './HomePage.scss';
import { Button, FormText, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logoutStore, selectUser } from '../../app/stores/authSlice';
import MiddleCenterAlignedContainer from '../../components/MiddleCenterAlignedContainer';
import { getAPIVersion, getVersion } from '../../app/api/version';
import { MUTATION_KEYS } from '../../app/api';

export default function HomePage() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const { t } = useTranslation('common');

  const { data: apiVersion } = useQuery([MUTATION_KEYS.VERSION], getAPIVersion);

  return (
    <>
      <FormText color="muted" className="version-note">
        <p>{t('frontend-version', { version: getVersion() ?? t`unknown` })}</p>
        <p>{t('api-version', { version: apiVersion ?? t`unknown` })}</p>
      </FormText>
      <MiddleCenterAlignedContainer maxWidth>
        <Nav pills className="main-menu">
          <NavItem>
            {user ? (
              <section className="logged-user-info">
                <p>Logged in as {user.username}</p>
                <p>
                  Roles:{' '}
                  {user.roles
                    ? Object.entries(user.roles ?? {})
                        .filter(([, v]) => v)
                        .map(([k]) => k)
                        .join(', ') || t`none`
                    : 'N/A'}
                </p>
              </section>
            ) : (
              <NavLink tag={Link} to="/login">
                Login
              </NavLink>
            )}
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/register">
              Register
            </NavLink>
          </NavItem>
          <NavItem>
            <Button disabled={!user} onClick={() => dispatch(logoutStore())}>
              Log out
            </Button>
          </NavItem>
        </Nav>
      </MiddleCenterAlignedContainer>
    </>
  );
}
