import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import FormSelect from 'src/components/Form/FormSelect/FormSelect';
import { TextField } from '@mui/material';
import { FormInput } from '../../../../../components/Form/FormInput';
import { Location } from '../../../../../app/types/Entities';
import { US_STATES } from '../../../../../app/helpers/enum';
import { mapObjectForSelect } from '../../../../../app/helpers/mappers';
import { findInOptions } from '../../../../../app/helpers/manipulation';
import { Errors } from '../../../../../app/types/DataStructures';
import './LocationSection.scss';

export interface LocationSectionProps {
  errors?: Errors<Location>;
  location: Location | undefined;
  update: (location: Partial<Location>) => void;
}

const translationArray = ['booking', 'forms', 'languages'];

function LocationSection(props: LocationSectionProps) {
  const { errors, location, update } = props;

  const { t } = useTranslation(translationArray);
  const stateOptions = mapObjectForSelect(US_STATES, (_, value) => value);

  return (
    <Container className="p-0">
      <Row className="gx-2">
        <Col>
          <FormInput
            className="location-field"
            label={t`location.street`}
            value={location?.address ?? ''}
            onChange={(e) => update({ address: e.target.value })}
            errors={errors?.address}
          />
        </Col>
        <Col>
          <FormInput
            className="location-field"
            label={t`location.unitNumber`}
            value={location?.unit_number ?? ''}
            onChange={(e) => update({ unit_number: e.target.value })}
            errors={errors?.unit_number}
          />
        </Col>
      </Row>
      <Row className="gx-2">
        <Col lg={6} xl={12} xxl={6}>
          <FormInput
            className="location-field"
            label={t`location.city`}
            value={location?.city ?? ''}
            onChange={(e) => update({ city: e.target.value })}
            errors={errors?.city}
          />
        </Col>
        <Col lg={2} xl={6} xxl={3}>
          <FormSelect
            disableClearable
            options={stateOptions}
            value={findInOptions(stateOptions, location?.state)}
            onChange={(_event, stateOption) => stateOption && update({ state: stateOption.value })}
            renderInput={(params) => (
              <TextField label={t`location.state`} placeholder={t`location.state`} {...params} />
            )}
          />
        </Col>
        <Col lg={4} xl={6} xxl={3}>
          <FormInput
            label={t`location.zipCode`}
            value={location?.zip ?? ''}
            onChange={(e) => update({ zip: e.target.value })}
            errors={errors?.zip}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default LocationSection;
