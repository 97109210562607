import { useTranslation } from 'react-i18next';
import { ClaimsAdjuster, Insurance } from '../../../../../../app/types/Entities';
import GenericPayerField, { GenericPayerFieldTranslationProps } from '../GenericPayerField';

export interface AdjusterFieldProps {
  formPrefix: string;
  optional?: boolean;

  isDisabled: boolean;
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;

  adjuster: ClaimsAdjuster | null;
  setAdjuster: (adjuster: ClaimsAdjuster | null) => void;

  adjusterList: ClaimsAdjuster[] | undefined;
  isLoading: boolean;
  refetchAdjusters: () => Promise<{ data: ClaimsAdjuster[] | undefined }>;

  insurance: Insurance | null;

  onUpdateSuccess?: (updatedAdjuster: ClaimsAdjuster | null) => void;
}

const translationArray = ['booking', 'collections'];

export default function AdjusterField(props: AdjusterFieldProps) {
  const {
    formPrefix,
    optional,
    isDisabled,
    isOpen,
    toggleOpen,
    adjuster,
    setAdjuster,
    adjusterList,
    isLoading,
    refetchAdjusters,
    insurance,
    onUpdateSuccess,
  } = props;

  const { t } = useTranslation(translationArray);

  const translations: GenericPayerFieldTranslationProps = {
    firstName: t`firstName`,
    lastName: t`lastName`,
    suffix: t`suffix`,
    title: t`title`,
    errors: {
      noFirstName: t`errors.noFirstName`,
      noLastName: t`errors.noLastName`,
      expectedPayerId: t`errors.expectedPayerId`,
      expectedCompanyId: t`errors.expectedCompanyId`,
    },
    progress: {
      payerCreate: {
        started: t`progress.adjusterCreate.started`,
        success: t`progress.adjusterCreate.success`,
        error: t`progress.adjusterCreate.error`,
      },
      payerUpdate: {
        started: t`progress.adjusterUpdate.started`,
        success: t`progress.adjusterUpdate.success`,
        error: t`progress.adjusterUpdate.error`,
      },
    },
    tooltip: {
      cancelAddPayer: t`tooltip.cancelAddAdjuster`,
      addPayer: t`tooltip.addAdjuster`,
      hidePayer: t`tooltip.hideAdjuster`,
      viewPayer: t`tooltip.viewAdjuster`,
    },
    selectPayer: t`selectAdjuster`,
    savePayer: t`saveAdjuster`,
    cancel: t`cancel`,
    requiredFieldsHelp: t`requiredFieldsHelp`,
    payer: t`adjuster`,
    contactInformation: t`contactInformation`,
  };

  return (
    <GenericPayerField
      formPrefix={formPrefix}
      optional={optional}
      isDisabled={isDisabled}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      payer={adjuster}
      setPayer={setAdjuster}
      payerList={adjusterList}
      isLoading={isLoading}
      refetchPayers={refetchAdjusters}
      company={insurance}
      onUpdateSuccess={onUpdateSuccess}
      translations={translations}
    />
  );
}
