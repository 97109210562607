import { AxiosError } from 'axios';
import { Agency, Clinic, Company, Contact, Insurance, Lawfirm, Location } from '../types/Entities';
import { Currency, DatabaseId, Errors } from '../types/DataStructures';
import { sanitizeCompany, sanitizeCompanyWithRoles } from './config/sanitizers';
import { COMPANY_TYPES, CompanyType, SendMethod } from '../helpers/enum';
import { apiClient } from './config/clients';
import { COMPANIES_ENDPOINT } from './config/endpoints';
import { ApiDao, ApiQuery, applyLookup, BUSINESS_NAME, LookupsMap, pathParam } from './config/helpers';
import { NotePayload } from './payloads';

/* GET */
export interface CompaniesGetParams {
  id?: DatabaseId;
  name?: string;
  type?: CompanyType;
  send_method?: string;
  on_hold?: boolean;
  _include_roles?: boolean;

  page?: number;
  page_size?: number;
}

export const DEFAULT_COMPANIES_LOOKUPS: LookupsMap<CompaniesGetParams> = {
  name: 'nem',
};
export type CompaniesConcreteGetParams = Omit<CompaniesGetParams, 'type'>;

export const getCompaniesDao: ApiDao<CompaniesGetParams, any[]> = (params, lookups = DEFAULT_COMPANIES_LOOKUPS) =>
  apiClient.get<any[]>(COMPANIES_ENDPOINT, {
    params: applyLookup(params, lookups),
  });

export const getCompanies: ApiQuery<CompaniesGetParams, Company[]> = (
  params: CompaniesGetParams,
  lookups = DEFAULT_COMPANIES_LOOKUPS,
) => getCompaniesDao(params, lookups).then((res) => res.data.map((v) => sanitizeCompany(v)));

export const getCompaniesPaginated: ApiQuery<
  CompaniesGetParams,
  { count: number; next: string | null; previous: string | null; results: Company[] }
> = (params, lookups = DEFAULT_COMPANIES_LOOKUPS) =>
  apiClient
    .get<any>(COMPANIES_ENDPOINT, {
      params: applyLookup<CompaniesGetParams>(params, lookups),
    })
    .then((res) => res.data)
    .then((data) =>
      params._include_roles
        ? {
            ...data,
            results: data.results.map(sanitizeCompanyWithRoles),
          }
        : { ...data, results: data.results.map(sanitizeCompany) },
    );

export const getCompany = (companyId: DatabaseId) =>
  apiClient.get(pathParam(COMPANIES_ENDPOINT, companyId)).then((res) => sanitizeCompany(res.data));

export const getCompanyWithRoles = (companyId: DatabaseId) =>
  apiClient
    .get(pathParam(COMPANIES_ENDPOINT, companyId), { params: { _include_roles: true } })
    .then((res) => sanitizeCompanyWithRoles(res.data));

// Clinic
export type ClinicsGetParams = CompaniesConcreteGetParams;
export const getClinics: ApiQuery<ClinicsGetParams, Clinic[]> = (params, lookups = DEFAULT_COMPANIES_LOOKUPS) =>
  getCompaniesDao({ ...params, type: COMPANY_TYPES.CLINIC }, lookups).then((res) =>
    res.data.map((v) => sanitizeCompany(v) as Clinic),
  );

export const getClinic = (clinicId: DatabaseId) => getCompany(clinicId).then((res) => res as Clinic);

// Insurance
export type InsurancesGetParams = CompaniesConcreteGetParams;
export const getInsurancesWithRoles: ApiQuery<CompaniesGetParams, Insurance[]> = (
  params: InsurancesGetParams,
  lookups = DEFAULT_COMPANIES_LOOKUPS,
) =>
  getCompaniesDao({ ...params, type: COMPANY_TYPES.INSURANCE, _include_roles: true }, lookups).then((res) =>
    res.data.map((v) => sanitizeCompanyWithRoles(v) as Insurance),
  );

export const getInsuranceWithRoles = (insuranceId: DatabaseId) =>
  apiClient
    .get(pathParam(COMPANIES_ENDPOINT, insuranceId), { params: { _include_roles: true } })
    .then((res) => sanitizeCompanyWithRoles(res.data) as Insurance);

// Agency
export type AgenciesGetParams = CompaniesConcreteGetParams;
export const getAgenciesWithRoles: ApiQuery<CompaniesGetParams, Agency[]> = (
  params: AgenciesGetParams,
  lookups = DEFAULT_COMPANIES_LOOKUPS,
) =>
  getCompaniesDao({ ...params, type: COMPANY_TYPES.AGENCY, _include_roles: true }, lookups).then((res) =>
    res.data.map((v) => sanitizeCompanyWithRoles(v) as Agency),
  );

// Lawfirm
export type LawfirmsGetParams = CompaniesConcreteGetParams;
export const getLawfirmsWithRoles: ApiQuery<CompaniesGetParams, Lawfirm[]> = (
  params: LawfirmsGetParams,
  lookups = DEFAULT_COMPANIES_LOOKUPS,
) =>
  getCompaniesDao({ ...params, type: COMPANY_TYPES.LAWFIRM, _include_roles: true }, lookups).then((res) =>
    res.data.map((v) => sanitizeCompanyWithRoles(v) as Lawfirm),
  );

export const getLawfirmWithRoles = (lawfirmId: DatabaseId) =>
  apiClient
    .get(pathParam(COMPANIES_ENDPOINT, lawfirmId), { params: { _include_roles: true } })
    .then((res) => sanitizeCompanyWithRoles(res.data) as Lawfirm);

/* POST */
export interface CompaniesPostPayload {
  name: string;
  type: CompanyType;
  send_method: SendMethod;
  on_hold: boolean;
  contacts: Contact[];
  locations: Location[];
  parent_company: DatabaseId | null;
  agents?: number[];
  operators?: number[];
  payers?: number[];
  providers?: number[];
  recipients?: number[];
  requesters?: number[];
  notes?: NotePayload[];

  min_weekly_assignments?: number;
  parking_extra?: boolean;
  parking_ticket?: boolean;
  bonus_extra?: boolean;
  mileage_extra?: boolean;
  mileage_rate?: Currency;
  preferred_agencies?: number[];

  authorization_priority?: string;

  send_invoice_method?: string;
  invoice_format?: string;
  invoice_grouping?: string;
  invoice_batch_size?: string;
  invoice_deadline?: string;
  invoice_frequency?: string;
  invoice_special_field?: string;
}

export type CompaniesPostResponse = DatabaseId;
export type CompaniesPostError = Errors<CompaniesPostPayload>;
export const createCompany = (payload: CompaniesPostPayload) =>
  apiClient
    .post<CompaniesPostResponse>(COMPANIES_ENDPOINT, { ...payload, _business: BUSINESS_NAME })
    .then((res) => res.data)
    .catch((err: AxiosError<CompaniesPostError>) => Promise.reject(err.response?.data));

// Clinic
export type ClinicsPostResponse = DatabaseId;
export type ClinicsPostError = Errors<CompaniesPostPayload>;
export type ClinicsPostPayload = Partial<CompaniesPostPayload>;
export const createClinic = (payload: ClinicsPostPayload) =>
  apiClient
    .post<ClinicsPostResponse>(COMPANIES_ENDPOINT, {
      ...payload,
      _business: BUSINESS_NAME,
    })
    .then((res) => res.data)
    .catch((err: AxiosError<ClinicsPostError>) => Promise.reject(err.response?.data));

/* PUT */
export type CompaniesPutPayload = Partial<CompaniesPostPayload>;
export type CompaniesPutResponse = unknown;
export type CompaniesPutError = Errors<CompaniesPutPayload>;
export const updateCompany = (companyId: DatabaseId, payload: CompaniesPutPayload) =>
  apiClient
    .put<CompaniesPutResponse>(pathParam(COMPANIES_ENDPOINT, companyId), payload)
    .catch((err: AxiosError<CompaniesPutError>) => Promise.reject(err.response?.data));

// Clinic
export type ClinicsPutPayload = CompaniesPutPayload;
export type ClinicsPutResponse = unknown;
export type ClinicsPutError = Errors<ClinicsPutPayload>;
export const updateClinic = (clinicId: DatabaseId, payload: ClinicsPutPayload) =>
  apiClient
    .put<ClinicsPutResponse>(pathParam(COMPANIES_ENDPOINT, clinicId), payload)
    .catch((err: AxiosError<ClinicsPutError>) => Promise.reject(err.response?.data));

/* DELETE */
export type CompaniesDeleteResponse = unknown;
export type CompaniesDeleteError = string;
export const deleteCompany = (companyId: DatabaseId) =>
  apiClient
    .delete<CompaniesDeleteResponse>(pathParam(COMPANIES_ENDPOINT, companyId))
    .catch((err: AxiosError<CompaniesDeleteError>) => Promise.reject(err.response?.data));
