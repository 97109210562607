import { StateUpdater } from 'src/app/types/Components';
import { Badge, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { BUTTON_STATE } from 'src/app/helpers/enum';
import { arrayReplaceAt, arrayRemoveAt } from 'src/app/helpers/manipulation';
import { useForm } from 'src/app/hooks';
import useStateWithCallback from 'src/app/hooks/useStateWithCallback';
import FormExpandableListField from 'src/components/Form/FormExpandableListField';
import { CompanyInfoState, CompanyStaffItem, CompanyStaffState } from '../localTypes';
import CompanyStaffListItem from './ListItem/ListItem';

export interface CompanyStaffFieldProps {
  formPrefix: string;
  showCompanyStaff: boolean;
  setShowCompanyStaff: (display: boolean) => void;
  companyInfoState: CompanyInfoState;
  initialState: CompanyStaffState;
  stateUpdater: StateUpdater<CompanyStaffState>;
}

const translationArray = ['onboardings'];

function CompanyStaffField(props: CompanyStaffFieldProps) {
  const { formPrefix, showCompanyStaff, setShowCompanyStaff, companyInfoState, initialState, stateUpdater } = props;

  // Utils
  const { p } = useForm(formPrefix);
  const { t } = useTranslation(translationArray);

  // Form data
  const [staff, setStaff] = useStateWithCallback(initialState.staff, stateUpdater('staff'));

  const pushNewStaff = () =>
    setStaff([
      ...staff,
      {
        uuid: uuidv4(),
        role: null,
        agent: null,
        title: undefined,
        firstName: '',
        lastName: '',
        suffix: undefined,
        flatContacts: [],
        agent_id: null,
      },
    ]);

  const replaceStaffAt = (index: number, staffToReplace: CompanyStaffItem) =>
    setStaff(arrayReplaceAt(staff, index, staffToReplace));

  const removeStaffAt = (index: number) => {
    const staffItem = staff[index];
    if (staffItem.uuid !== undefined) {
      // Flag as deleted instead of removing
      replaceStaffAt(index, { ...staffItem, deleted: true });
      return;
    }
    setStaff(arrayRemoveAt(staff, index));
  };

  // Display
  const label = (
    <Badge showZero variant="counterBadge" color="primary" badgeContent={staff.length} sx={{ marginBottom: 2 }}>
      <Typography variant="formLabel">{t`staff`}</Typography>
    </Badge>
  );

  const onAdd = () => {
    pushNewStaff();
    setShowCompanyStaff(true);
  };

  return (
    <FormExpandableListField
      showSeparator
      formPrefix={p`staff`}
      isOpen={showCompanyStaff && staff.length > 0}
      toggleOpen={setShowCompanyStaff}
      onAdd={onAdd}
      addLabel={t`addStaff`}
      openButtonState={staff.length === 0 ? BUTTON_STATE.DISABLED : BUTTON_STATE.INTERACTIVE}
      openButtonTooltip={showCompanyStaff ? t`tooltip.hideStaff` : t`tooltip.viewStaff`}
      defaultDisplay={t`noStaff`}
      label={label}
      onCancel={() => {
        setShowCompanyStaff(false);
      }}
    >
      {staff.map((employee, index) => {
        return (
          !employee.deleted && (
            <CompanyStaffListItem
              formPrefix={p`staff`}
              key={employee.uuid}
              removeCompanyStaff={() => removeStaffAt(index)}
              companyInfoState={companyInfoState}
              initialState={employee}
              stateUpdater={(key: any) => (value: any) => replaceStaffAt(index, { ...employee, [key]: value })}
            />
          )
        );
      })}
    </FormExpandableListField>
  );
}

export default CompanyStaffField;
