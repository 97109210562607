import { AxiosResponse } from 'axios';
import { Dictionary, Primitive, Stringable } from '../../types/DataStructures';
import { isObject } from '../../helpers/manipulation';

// Constants
export const BUSINESS_NAME = 'interpretation' as const;

export const QUERY_LOOKUPS = ['em', 'nem', 'isnull', 'gt', 'lt', 'gte', 'lte'] as const;

export const MUTATION_KEYS = {
  AFFILIATIONS: 'affiliations',
  AGENTS: 'agents',
  AUTHORIZATION: 'authorization',
  BOOKINGS: 'bookings',
  CATEGORIES: 'categories',
  COMPANIES: 'companies',
  EVENTS: 'events',
  EVENTS_LIGHT: 'events_light',
  EXPENSES: 'expenses',
  GLOBAL_SETTINGS: 'global_settings',
  LANGUAGES: 'languages',
  OFFERS: 'offers',
  OPERATORS: 'operators',
  PAYERS: 'payers',
  PROVIDERS: 'providers',
  RECIPIENTS: 'recipients',
  REQUESTERS: 'requesters',
  SEARCH: 'search',
  SERVICES: 'services',
  SERVICE_ROOTS: 'service_roots',
  USERS: 'users',
  VERSION: 'version',
} as const;

// Functions
export const pathParam = (endpoint: string, param: Stringable) => `${endpoint}${param}/`;
export const businessParam = (endpoint: string) => pathParam(endpoint, BUSINESS_NAME);

export const applyLookup = <P extends Dictionary>(params: P, lookups: LookupsMap<P>) =>
  Object.keys(params).reduce((acc: Dictionary, key: keyof P & string) => {
    const lookup: string | object | undefined = lookups[key];
    const value = params[key];

    if (isObject(lookup)) {
      acc[key] = isObject(value) ? applyLookup(value, lookup as any) : value;
    } else {
      const newKey: string = lookup ? `${key}__${lookup}` : key;
      acc[newKey] = value;
    }
    return acc;
  }, {}) as ParamsWithLookup<P>;

export const offsetDateByMinutes = (timestamp: number, minutes: number) => new Date(timestamp + minutes * 60 * 1000);

// Types
export type QueryLookup = (typeof QUERY_LOOKUPS)[number];

export type LookupsMap<T> = {
  [K in keyof T]: T[K] extends Primitive ? QueryLookup : QueryLookup | LookupsMap<T[K]>;
};

export type ParamsWithLookup<T extends { [k: string]: any }> = {
  [K in keyof T & string as T[K] extends object ? K : K | `${K}__${QueryLookup}`]?: T[K] extends object
    ? ParamsWithLookup<T[K]>
    : T[K];
};

export type ApiDao<Params, R = any> = {
  (params: Params, lookups?: LookupsMap<Params>): Promise<AxiosResponse<R>>;
};

export type ApiQuery<Params, R> = {
  (params: Params, lookups?: LookupsMap<Params>): Promise<R>;
};
