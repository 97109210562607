import { IconButton, Input, Paper } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

export interface NoteInputProps {
  onDiscard: () => void;
  onSubmit: () => void;
  noteText: string;
  setNoteText: (text: string) => void;
}

export default function NoteInput({ onDiscard, onSubmit, noteText, setNoteText }: NoteInputProps) {
  const { t } = useTranslation('forms');
  return (
    <Paper elevation={0} sx={{ marginBottom: 1 }}>
      <Input
        placeholder={t`addNote`}
        multiline
        fullWidth
        endAdornment={
          <>
            <IconButton
              onClick={onDiscard}
              sx={{
                alignSelf: 'start',
              }}
            >
              <DeleteIcon fontSize="medium" />
            </IconButton>
            <IconButton
              onClick={onSubmit}
              color="primary"
              sx={{
                alignSelf: 'start',
              }}
            >
              <SaveIcon fontSize="medium" />
            </IconButton>
          </>
        }
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setNoteText(`${noteText}\n`);
            event.preventDefault();
          }
        }}
        sx={{
          borderRadius: '3px',
          padding: '5px 0 5px 8px;',
          '&:focus': {
            backgroundColor: '#f6f8fa',
          },
          '&.Mui-focused': {
            backgroundColor: '#f6f8fa',
          },
        }}
      />
    </Paper>
  );
}
