import { ReactNode } from 'react';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import './FormList.scss';
import { isCallable } from '../../../app/helpers/manipulation';

export interface FormListProps<T> {
  items: readonly T[];
  mapper: (element: T, index: number) => ReactNode;

  canAdd?: boolean | (() => boolean);
  onAdd?: () => void;

  canRemove?: boolean | ((index: number, element: T) => boolean);
  onRemove?: (index: number, element: T) => void;

  flush?: boolean;
}

export default function FormList<T>(props: FormListProps<T>) {
  const { items, mapper, canAdd, onAdd, canRemove, onRemove, flush } = props;

  const { t } = useTranslation('forms');

  return (
    <div className="form-list">
      <ListGroup flush={flush}>
        {items.map((e, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ListGroupItem key={i}>
            {(isCallable(canRemove) ? canRemove(i, e) : !!canRemove) && (
              <Button outline color="danger" className="remove mb-2" onClick={() => onRemove?.(i, e)}>
                {t`remove`}
              </Button>
            )}
            {mapper(e, i)}
          </ListGroupItem>
        ))}
      </ListGroup>
      {(isCallable(canAdd) ? canAdd() : !!canAdd) && (
        <Button outline color="primary" className="add" onClick={() => onAdd?.()}>
          {t`add`}
        </Button>
      )}
    </div>
  );
}
